export const REQUEST_ORDER_LIST = "REQUEST_ORDER_LIST";
export const REQUEST_ORDER_LIST_SUCCESS = "REQUEST_ORDER_LIST_SUCCESS";
export const REQUEST_ORDER_LIST_FAILURE = "REQUEST_ORDER_LIST_FAILURE";

export const REQUEST_UPDATE_ORDER_STATUS = "REQUEST_UPDATE_ORDER_STATUS";
export const REQUEST_UPDATE_ORDER_STATUS_SUCCESS = "REQUEST_UPDATE_ORDER_STATUS_SUCCESS";
export const REQUEST_UPDATE_ORDER_STATUS_FAILURE = "REQUEST_UPDATE_ORDER_STATUS_FAILURE";
export const GET_INVOICE_LINK = "GET_INVOICE_LINK";
export const GET_INVOICE_LINK_SUCCESS = "GET_INVOICE_LINK_SUCCESS";
export const GET_INVOICE_LINK_FAILED = "GET_INVOICE_LINK_FAILED";

export const fetchOrderList = (payload) => {
	return {
		type: REQUEST_ORDER_LIST,
		payload
	}
};

export const updateOrderStatus = (payload) => {
	return {
		type: REQUEST_UPDATE_ORDER_STATUS,
		payload
	}
};

export const ActionGetInvoiceLink = (data) => {
    return {
        type: GET_INVOICE_LINK,
        data
    }
}

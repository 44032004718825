import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import * as LoginActions from '../../actions/LoginActions'
import { clearAllTimerID } from '../../actions/CommonActions'
import { clearCouponCode } from '../../actions/PaymentActions'
import { eboRemoveCoupon } from '../../actions/ProductListActions'
import { bindActionCreators } from 'redux'

import { MerchantStoreStyled } from './styles/MerchantStyles'
import {
  getMerchantOptions,
  parseToMapAgentDetailsKeyStoreCode,
} from './selectors'
import classnames from 'classnames'

class MerchantSelectionPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedMerchant: null,
      selectedCity: null,
      selectedStore: null,
      agentDetails: props.agentDetails,
    }
  }
  componentDidMount() {
    if (this.props.timerIDs && this.props.timerIDs.length > 0) {
      this.props.timerIDs.forEach((id) => {
        clearInterval(id)
      })
      this.props.clearAllTimerID()
    }
    if (this.props.appliedCoupon) {
      const data = {}
      if (this.props.takeawayCartId)
        data['offline_cart_id'] = this.props.takeawayCartId
      if (this.props.deliveryCartId)
        data['online_cart_id'] = this.props.deliveryCartId
      this.props.eboRemoveCoupon({
        coupon_code: this.props.appliedCoupon,
        customer_key: this.props.customer_key,
        data: data,
      })
      this.props.clearCouponCode()
    }
  }
  componentDidUpdate(prevProps) {
    const { agentDetails: prevAgentDetails } = prevProps
    const { agentDetails } = this.props
    if (prevAgentDetails !== agentDetails) {
      this.setState({
        agentDetails,
      })
    }
  }
  handleMerchantSelect = (event) => {
    const { setActiveMerchant, setActiveCity, setActiveStore } = this.props
    const { value } = event.target
    setActiveMerchant(value)
    if (value === 'default') {
      setActiveCity(value)
      setActiveStore(value)
    }
  }
  handleCitySelect = (event) => {
    const { setActiveCity } = this.props
    const { value } = event.target

    setActiveCity(value)
  }
  handleStoreSelect = (event) => {
    const { setActiveStore } = this.props
    const { value } = event.target
    setActiveStore(value)
  }

  storeSelectDone = () => {
    const { isValidForm } = this.props
    if (isValidForm) {
      this.updateMarchentStoreId()
      // this.fetchHubsByCityID();
      const { history } = this.props
      history.push('/dashboard-orders')
    }
  }

  updateMarchentStoreId = () => {
    const { storeId, organizationInfoStoreCodeKey } = this.props
    localStorage.setItem(
      'storeDetails',
      JSON.stringify(organizationInfoStoreCodeKey[storeId]),
    )
  }

  // fetchHubsByCityID = () => {
  //   const { fetchAllHubsByCityID, cityId } = this.props;
  //   fetchAllHubsByCityID({ data: { cityId } });
  // };

  render() {
    const {
      merchantsUnique,
      cityUnique,
      storesUnique,
      storeId,
      merchantId,
      cityId,
      agentDetails,
      isValidForm,
    } = this.props

    return (
      <MerchantStoreStyled className="merchant-selection-conatiner">
        <div className="merchant-select-title">Select your store</div>
        <div className="store-select-container">
          <div>
            <div className="store-selection-wrapper">
              <div className="merchant-name-select">
                <label>City</label>
                <select value={cityId} onChange={this.handleCitySelect}>
                  <option value={'default'} selected disabled>
                    Select City
                  </option>
                  {cityUnique.map((org) => (
                    <option key={org.city_id} value={org.city_id}>
                      {org.city}
                    </option>
                  ))}
                  ;
                </select>
              </div>

              {cityId !== 'default' && (
                <div className="merchant-store-select">
                  <label>Store</label>
                  {storesUnique.length ? (
                    <select value={storeId} onChange={this.handleStoreSelect}>
                      <option value={'default'} selected disabled>
                        Select Store
                      </option>
                      {storesUnique.map((store) => (
                        <option value={store.store_code} key={store.store_code}>
                          {store.store_code}
                        </option>
                      ))}
                      ;
                    </select>
                  ) : (
                    <select />
                  )}
                </div>
              )}
              <div className="store-select-done">
                <button
                  className={classnames('confirm-button', {
                    active: isValidForm,
                  })}
                  onClick={() => {
                    this.storeSelectDone()
                  }}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </MerchantStoreStyled>
    )
  }
}

const mapStateToProps = (state) => {
  const { agentDetails, storeId, merchantId, cityId } = state.login
  const { timerIDs } = state.commonReducer
  const { appliedCoupon } = state.paymentDetails
  const { customerDetails: customer_key } = state.otpReducer
  const { takeawayCartId } = state.takeAwayReducer
  const { deliveryCartId } = state.DeliveryOrderReducer
  const { merchantsUnique, cityUnique, storesUnique } = getMerchantOptions(
    state,
  )
  const isValidForm = cityId !== 'default' && storeId !== 'default'
  const organizationInfoStoreCodeKey = parseToMapAgentDetailsKeyStoreCode(state)
  return {
    agentDetails,
    merchantsUnique,
    cityUnique,
    storesUnique,
    storeId,
    merchantId,
    cityId,
    isValidForm,
    allHubsByCity: state.login.allHubsByCity,
    allHubsByCityFailed: state.login.allHubsByCityFailed,
    organizationInfoStoreCodeKey,
    timerIDs,
    appliedCoupon,
    customer_key,
    takeawayCartId,
    deliveryCartId,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      ...LoginActions,
      clearAllTimerID,
      clearCouponCode,
      eboRemoveCoupon,
    },
    dispatch,
  )
}

const MerchantSelectionConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MerchantSelectionPage)

export default withRouter(MerchantSelectionConnect)

import React from "react"
import ReactDOM from "react-dom"

import {ModalContainer} from "./styles";

class Modal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showModal: true
    }
  }

  handleClick = (e) => {
    e.stopPropagation()
    this.setState({
      showModal: false
    }, () => {
      this.props.closeAction && this.props.closeAction()
    })
  }

  getModalComponent = () => {
    const { containerStyles, children, autoClose } = this.props
    return (
      !autoClose || this.state.showModal?
      <ModalContainer
        onClick={this.handleClick}
        styles={containerStyles}>
        {this.props.children}
      </ModalContainer> :
      null
    )
  }

  render() {
    const ModalComponent = this.getModalComponent()
    return ReactDOM.createPortal((
      ModalComponent
    ), document.getElementById("modal-root"))
  }
}

export default Modal

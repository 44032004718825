export const tabletWidth = "800px";

export const denominations = {
  "100": { value: 100, unit: 0 },
  "200": { value: 200, unit: 0 },
  "500": { value: 200, unit: 0 },
  "2000": { value: 200, unit: 0 },
  "50": { value: 50, unit: 0 },
  "2": { value: 2, unit: 0 },
  "1": { value: 1, unit: 0 },
  "20": { value: 20, unit: 0 },
  "5": { value: 5, unit: 0 },
  "10": {value:10,unit:0}
};

import React, { Fragment } from "react";
import { connect } from "react-redux";
import { ReactComponent as IconTick } from "../../assets/new-ebo-icons/states/Icon-Tick.svg";
import { ReactComponent as RadioIcon } from "../../assets/new-ebo-icons/Icon-Tick.svg";

import { ReactComponent as UncheckedRadio } from "../../assets/new-ebo-icons/whites/Icon-Tick.svg";
import "./Stepper.scss";
import classnames from "classnames";
import { setProgressStep } from "../../actions/CommonActions";

const Stepper = (props) => {
  const { orderJourneyReducer } = props;
  return (
    <>
      <div className="stepper">
        {props.steps.map((step, index) => (
          <Fragment key={`step--${step.text}`}>
            <div className="step-content">
              {step.active ? (
                <RadioIcon />
              ) : (
                <>{step.isComplete ? <IconTick /> : <UncheckedRadio />}</>
              )}

              <div
                className={classnames({
                  "active-step":
                    index === orderJourneyReducer.orderJourneyActiveStep,
                })}
              >
                {step.text}
              </div>
            </div>
            {index !== props.steps.length - 1 ? (
              <div className="arrow-div">
                <span className="cover-arrow" />
              </div>
            ) : null}
          </Fragment>
        ))}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return state;
};
const mapDispatchToProps = (dispatch) => {
  return {
    orderJourneyStepChange: (step) => {
      dispatch(setProgressStep(step));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Stepper);

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import AddToCart from "../../../AddToCart/AddToCart";
import CatalogContainer from "../../../CatalogContainer/CatalogContainer";
import "./DeliveryOrder.scss";
import {
  setProgressStep,
  getCustomerAddress,
} from "../../../../actions/CommonActions";
import { searchLocality } from "../../../../utils/SearchLocality";
import LocalityModal from "../../../Modals/LocalityModal";
import {
  getCatalog,
  updateFiltersRequest,
  getPastOrdersRequest,
  getCategories,
} from "../../../../actions/TakeAwayActions";
import CatalogDataSelector from "../../../../utils/CatalogDataSelector";
import {
  getAutocompleteLocality,
  getGeoLocationRequest,
  localityModal,
  setDeliveryOrderLoading,
  setLocalityHubRequest,
  changeOfLocationAfterReview,
  resetDeliveryCart,
} from "../../../../actions/DeliveryOrderActions";
function DeliveryOrders(props) {
  const [showLocalityDropdown, setLocalityDropdown] = useState(false);

  const {
    categories,
    data,
    searchData,
    pastOrders,
    searchText,
  } = props.takeAwayReducer;
  const CatalogData = data && CatalogDataSelector({ data });
  const { customer_key } = props.otpReducer.customerDetails;
  const { AutocompleteSuggestions } = props.DeliveryOrderReducer;
  const hub_id = props.DeliveryOrderReducer.selectedLocationHub;
  const [storeName, setStoreName] = useState(
    props.DeliveryOrderReducer.selectedLocality &&
      props.DeliveryOrderReducer.selectedLocality.description
      ? props.DeliveryOrderReducer.selectedLocality.description.split(",")[0]
      : "Select Locality"
  );
  useEffect(() => {
    if (!Object.keys(props.DeliveryOrderReducer.selectedLocality).length) {
      props.localityModalChange(true);
    }
  }, []);

  useEffect(() => {
    if (props.DeliveryOrderReducer.changedNewAddress) {
      setStoreName(props.DeliveryOrderReducer.changedNewAddress);
      props.setLocalityHubAction({ params: { hub_id: hub_id } });
    }
    if (hub_id !== null) {
      let params = {
        hub_id: hub_id,
      };
      let pastOrderParams = {
        hubId: hub_id,
        customer_key: customer_key,
      };
      // props.FetchPastOrders({params: pastOrderParams})
      props.getCategoriesRequest({ params });
    }
  }, [hub_id]);

  const confirmLocation = () => {
    let params = {
      place_id: props.DeliveryOrderReducer.selectedLocality.place_id,
      customer_key: customer_key,
    };
    props.load(true);

    props.getGeoLocation({
      params: params,
      setStoreName: setStoreName,
      storeNameData: props.DeliveryOrderReducer.selectedLocality.description.split(
        ","
      )[0],
    });
    props.localityModalChange(false);
  };
  const handleLocalityModalClose = () => {
    props.localityModalChange(false);
    if (
      !(
        props.DeliveryOrderReducer &&
        props.DeliveryOrderReducer.selectedLocationHub
      )
    ) {
      props.orderJourneyStepChange(1);
    }
  };
  return (
    <>
      <div className="wrap-class delivery-order">
        <div className="left-delivery-container">
          <CatalogContainer
            data={CatalogData}
            stepName="delivery"
            categories={categories}
            SearchProducts={props.SearchProducts}
            searchText={searchText}
            pastOrderData={pastOrders}
            customerKey={customer_key}
            searchData={searchData}
            FetchCatalogProducts={props.FetchCatalogProducts}
            hub_id={hub_id}
            storeName={storeName}
          />
        </div>
        <div className="right-delivery-container">
          <AddToCart
            stepName="delivery"
            hub_id={hub_id}
            customerKey={customer_key}
          />
        </div>
      </div>
      <div className="wrap-class delivery-order">
        <LocalityModal
          show={props.DeliveryOrderReducer.localityModal}
          className="locality-search-modal"
          cancel={handleLocalityModalClose}
          handleChange={() => {
            if (props.DeliveryOrderReducer.searchedText) {
              searchLocality(
                props.DeliveryOrderReducer.searchedText,
                props.getAutocompleteLocality,
                customer_key,
                setLocalityDropdown
              );
            }
          }}
          confirmLocation={confirmLocation}
          AutocompleteSuggestions={AutocompleteSuggestions}
          showLocalityDropdown={showLocalityDropdown}
          handleLocalityModalClose={handleLocalityModalClose}
        />
      </div>
    </>
  );
}
const mapDispatchToProps = (dispatch) => {
  return {
    FetchCatalogProducts: ({ params }) => {
      dispatch(getCatalog({ params }));
    },
    SearchProducts: ({ params }) => {
      dispatch(updateFiltersRequest({ params }));
    },
    FetchPastOrders: ({ params }) => {
      dispatch(getPastOrdersRequest({ params }));
    },
    orderJourneyStepChange: (step) => {
      dispatch(setProgressStep(step));
    },
    getCategoriesRequest: ({ params }) => {
      dispatch(getCategories({ params }));
    },
    getAutocompleteLocality: ({ params }) => {
      dispatch(getAutocompleteLocality({ params }));
    },
    getGeoLocation: (payload) => {
      dispatch(getGeoLocationRequest(payload));
    },
    load: (value) => {
      dispatch(setDeliveryOrderLoading(value));
    },
    localityModalChange: (value) => {
      dispatch(localityModal(value));
    },
    getAddress: (payload) => {
      dispatch(getCustomerAddress(payload));
    },
    setLocalityHubAction: ({ params }) => {
      dispatch(setLocalityHubRequest({ params }));
    },
    makeLocationRequest: (payload) => {
      dispatch(changeOfLocationAfterReview(payload));
    },
    resetTotalDeliveryCart: (payload) => {
      dispatch(resetDeliveryCart(payload));
    },
  };
};

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryOrders);

import React from "react";
import PlacesAutocomplete, {
} from "react-places-autocomplete";

// import {
//   LocalitySearchBox,
//   LocalitySearchBoxStyled,
// } from "../../locality-search-box/styles";

const localitySearch = (props) => {
  const {
    selectedLocality,
  } = this.props;

  return (
    <div>
      <PlacesAutocomplete
        value={!!selectedLocality ? selectedLocality : ""}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <label className="loc-serach-label">Enter customer locality</label>
            <input
              autoFocus={true}
              {...getInputProps({
                placeholder: "Search Places ...",
                className: "location-search-input",
                type: "search",
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: "#fafafa", cursor: "pointer" }
                  : { backgroundColor: "#ffffff", cursor: "pointer" };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </div>
  );
};

localitySearch.propTypes = {};

export default localitySearch;

import styled, { css } from "styled-components";

import { tabletWidth } from "../../../utils/consts";

const FlexLayout = css`
  display: flex;
`;
const activeCss = css`
  background-color: #e41d36;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
`;
export const AddAddressModalStyled = styled.div`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.19);
  background-color: #ffffff;
  position: fixed;
  z-index: 10;
  padding-top: 60px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);

  > div {
    // background-color: #FFF;
    margin: auto;
    position: relative;
    border: 1px solid transparent;
    border-radius: 0.5rem;
    > form {
      // box-sizing: inherit;
      // padding: 10px 5px 10px 18px;
      box-sizing: inherit;
      padding: 10px 5px 10px 18px;
      width: 40%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  input {
    width: 100%;
    box-sizing: border-box;
    height: 30px;
    padding-left: 10px;
    margin: 0 auto;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 0;
    text-align: left;
    background: #ffffff;
  }

  .add-address-title {
    text-align: left;
    color: #000000;
    align-self: flex-start;
  }
  .addr-line-1 {
    position: relative;
    // padding: 5px 10px;
    margin-top: 16px;
    width: 90%;
    label {
      padding-bottom: 15px;
    }
    .location-search-input {
      input {
        height: 30px;
        padding-left: 10px;
        border: none;
      }
    }
  }

  .addr-line-2,
  .landmark-input,
  .city-field,
  .phone-no {
    width: 90%;
    // padding: 5px 10px;
    position: relative;
    // margin-top: 20px;
    margin-top: 16px;
  }
  .save-proceed {
    width: 90%;
    margin-top: 18px;
    .dismiss-modal-text {
      text-align: center;
      margin: 1rem;
      cursor:pointer;
    }
    button {
      height: 48px;
      border-radius: 5px;
      box-shadow: 0 2px 4px 0 rgba(196, 196, 196, 0.5);
      background-color: #cbcbcb;
      padding: 0;
      width: 100%;
      margin: 0 auto;
      border: none;
      color: #ffffff;
      font-weight: bold;
      ${(props) => (props.isActiveBtn ? activeCss : "")}
    }
    .no-error {
      background-color: #e41d36;
      color: #fff;
      font-size: 18px;
    }
  }
`;

export const AddressAndMapContainerStyled = styled.div`
  display: flex;
  width: 80%;
  background: #fff;
  @media screen and (max-width: ${tabletWidth}) {
    width: 90%;
  }
  height: 80%;
  position: relative;
  #map {
    width: 70%;
    border-top-right-radius: 0.5rem;
  }
  .close-btn {
    display: flex;
    align-item: center;
    justify-content: center;
    text-align: center;
    border: 1px solid #ffffff;
    position: absolute;
    top: -41px;
    font-size: 16px;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    width: 35px;
    height: 35px;
    left: 50%;
    border-radius: 50%;
  }
  .map-notif-toast {
    border-radius: 2px;
    box-shadow: 0 0 4px 0 rgba(212, 112, 21, 0.44);
    background-color: #fffaf1;
    border: 1px solid #d47015;
    display: none;
    z-index: 5;
    width: 96%;
    margin: 10px 2%;
    height: 40px;
    position: absolute;
    .notif-text {
      color: #d47015;
      text-align: center;
      line-height: 40px;
    }
  }
`;
const SuggestionsCss = css`
  background-color: rgb(255, 255, 255);
  cursor: pointer;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  border-top: none;
  border-right: none;
  border-left: none;
  /* border-image: initial; */
  border-bottom: 1px solid rgb(230, 230, 230);
  padding: 10px 6px !important;
`;
export const GoogleAutoCompleteContainer = styled.div`
  position: relative;
  .autocomplete-dropdown-container {
    position: absolute;
    top: 30px;
    width: 100%;
    z-index: 1;
    box-shadow: 0px 0 15px rgba(0, 0, 0, 0.15);
    .suggestion-item {
      ${SuggestionsCss} // &:hover {
      //   background: red !important;
      // }
    }
    .suggestion-item--active {
      ${SuggestionsCss}
      background-color: rgb(230,230,230) !important;
    }
  }
`;

export const MapContainerStyled = styled.div`
  width: 70%;
  height: 100%;
  display: flex;
  justify-content: center;
  .marker-text,
  .map-marker {
    position: absolute;
    align-self: center;
    z-index: 20;
    /* justify-self: normal; */
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;
  }
  .marker-text {
    background-color: #000;
    padding: 6px 15px;
    transform: translateY(-68px);
    letter-spacing: 1.1px;
    color: #fff;
    &:after {
      content: "";
      position: absolute;
      width: 0px;
      height: 0px;
      border-top: 12px solid #000;
      border-bottom: 12px solid transparent;
      border-right: 12px solid transparent;
      z-index: 20;
      border-left: 12px solid transparent;
      bottom: -22px;
    }
  }
  .map-marker {
    transform: translateY(-21px);
  }
  #map {
    height: 100%;
    width: 100%;
  }
`;

export const ConfirmModalSectionStyled = styled.div`
  width: 50%;
  display: flex;
  font-family: Lato;
  /* align-items: flex-end; */
  flex-direction: column;
  justify-content: flex-end;
  padding: 20px 8px 10px 8px;
  display: none;
  height: 175px;
  background: #fff;
  position: absolute;
  bottom: 0px;
  z-index: 20;
  .navigation-section {
    // display: flex;
    // justify-content: flex-start;
    // align-items: center;
    margin-bottom: 16px;
    color: #cbcbcb;
    font-weight: bold;
    position: relative;
    .icon-back {
      margin-right: 40px;
      cursor: pointer;
      position: relative;
    }
    .icon-back:before {
      content: "";
      width: 9px;
      height: 9px;
      border-top: 1px solid #e41d36;
      border-left: 1px solid #e41d36;
      transform: rotate(-45deg) scale(0.8);
      position: absolute;
      left: 0;
      top: 5px;
    }

    .icon-back:after {
      content: "";
      width: 20px;
      height: 1px;
      border-top: 1px solid #e41d36;
      position: absolute;
      //left: 1px;
      top: 9px;
    }
  }
  .search-locality-txt {
    font-size: 12px;
    color: #cbcbcb;
    margin-bottom: 8px;
    display: block;
  }

  .address-line {
    display: flex;
    margin-bottom: 16px;
    border-radius: 3px;
    background-color: #f8f8f8;
    height: 40px;
    align-items: center;
    padding-left: 10px;
    span {
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 90%;
      overflow: hidden;
    }
  }
  .action-btn {
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(196, 196, 196, 0.5);
    background-color: #e41d36;
    height: 48px;
    color: #fff;
    font-size: 18px;
    border: none;
    width: 100%;
  }
`;
export const ErrorRow = styled.div`
  color: #e41d36;
  text-align: center;
  margin-top: 10px;
`;
export const MandatoryStyled = styled.span`
  color: #e41d36;
  font-size: 27px;
  position: absolute;
  left: -14px;
  top: -5px;
`;

import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import "./RemoveCombo.scss";
import {
  removeComboModal,
  updateDeliveryCartRequest,
  removeProductFromDeliveryCart
} from "../../actions/DeliveryOrderActions";
import { setProgressStep } from "../../actions/CommonActions";
import Loader from "../Loader/Loader";
function RemoveCombo(props) {
  const [removeComboLoader, setRemoveComboLoader] = useState(false);

  const editOrder = () => {
    props.removeComboModal({value:false,removeProduct:{}})
    setRemoveComboLoader(false)
  };

  const confirmRemoveItem = () => {
    setRemoveComboLoader(true)
    const quantity=props.DeliveryOrderReducer.removeProduct.quantity
    const product_id=props.DeliveryOrderReducer.removeProduct.product_id
    const hub_id=props.DeliveryOrderReducer.removeProduct.hub_id
    const updateApi =
    quantity.toString() === '1'
      ? props.removeProductFromDelivery
      : props.updateDeliveryOrder
  updateApi({
    params: {
      customer_key: props.otpReducer.customerDetails.customer_key,
      product_id,
      hub_id,
      quantity: parseInt(quantity) > 1
            ? parseInt(quantity) - 1
            : 0,
    closeRemoveModal: props.removeComboModal,
    removeLoader:setRemoveComboLoader
    },
  })
  };
  return (
    <div className="remove-combo">
        <div>
        <div className="modal-title">{props.title}</div>
        <div className="message">
        {props.description}
        </div>
        </div>
      <div className="button">
        <button className="edit-btn" onClick={(e) => editOrder()}>
          Don't Remove
        </button>
        <button
            className="confirm-btn"
            onClick={(e) => confirmRemoveItem()}
          >
            {removeComboLoader ? <Loader /> : "Remove"}
          </button>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    removeComboModal:(payload)=>{
        dispatch(removeComboModal(payload))
    },
    updateDeliveryOrder: (payload) => {
        dispatch(updateDeliveryCartRequest(payload))
      },
      removeProductFromDelivery: (payload) => {
        dispatch(removeProductFromDeliveryCart(payload))
      },
  };
};

const mapStateToProps = (state) => {
  return state;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RemoveCombo);

import classNames from "classnames";
import React from "react";
import "./ConfirmButton.scss";

const ConfirmButton = (props) => {
  return (
    <button
      className={classNames("confirm-button", props.className)}
      onClick={props.onClick}
      {...props}
    >
      {props.children}
    </button>
  );
};

export default ConfirmButton;

import { createSelector } from "reselect"
import uniqBy from "lodash/uniqBy"


const getAgentDetails = state => state.login.agentDetails
const getActiveMerchantId = state => state.login.merchantId
const getActiveCity = state => state.login.cityId
const getActiveStore = state => state.login.storeId

export const getMerchantOptions = createSelector(
  getAgentDetails,
  getActiveMerchantId,
  getActiveCity,
  getActiveStore,
  (
    agentDetails,
    activeMerchantId,
    activeCityId,
    activeStoreId,
  ) => {
    const baseReturn = {
      merchantsUnique: [],
      cityUnique: [],
      storesUnique: []
    }
    if(!agentDetails) return baseReturn
    const organizations = agentDetails.organization
    const merchantsUnique  = uniqBy(organizations, (element) => {
      return element.organization_id
    })
    const cityUnique  = uniqBy(organizations, (element) => {
      return element.city_id
    }).filter((element) => {
      if(activeMerchantId === "default") return true
      return element.organization_id === activeMerchantId
    })
    const storesUnique  = uniqBy(organizations, (element) => {
      return element.store_code
    }).filter((element) => {
      if(activeCityId === "default") {
        return true
      }
      return element.city_id === activeCityId
    })
    return {
      merchantsUnique,
      cityUnique,
      storesUnique
    }
  }
)

export const parseToMapAgentDetailsKeyStoreCode = createSelector(
  getAgentDetails,
  (agentDetails) => {
    if(!agentDetails) return []
    return agentDetails.organization.reduce((acc, element) => {
      const key = element.store_code
       acc[key] = element
       return acc
    }, {})
  }
)
import { round } from "lodash";

function CatalogDataSelector(DataSelector) {
  let CatalogData = { Products: [] };
  if (DataSelector && DataSelector.searchData) {
    DataSelector.searchData.length &&
      DataSelector.searchData.forEach((item, index) => {
        CatalogData.Products.push({
          productImage: item.item_image,
          productName: item.item_name,
          productId: item.item_id,
          is_combo:
            item.productMerchantdising && item.productMerchantdising.is_combo
              ? true
              : false,
          gross: item.gross,
          categoryId: item.category_id,
          net: item.net,
          mrp: item.productPricing && item.productPricing.base_price,
          hub_id: item.hub_id,
          stock: item.productInventory && item.productInventory.stock_units,
          next_available_by:
            item.productInventory && item.productInventory.next_available_by,
          uom: item.uom,
          unit_gram: item.productPricing.unit_gram,
          slot_custom_message: item.productInventory
            ? item.productInventory.slot_custom_message !== ""
              ? item.productInventory.slot_custom_message
              : item.productInventory.next_available_by
            : "",
          discount_price:
            item.productPricing &&
            round(
              parseFloat(
                item.productPricing.base_price -
                  (item.productPricing.base_price *
                    item.productPricing.base_discount) /
                    100
              ).toFixed(2)
            ),
          discount: item.productPricing && item.productPricing.base_discount,
        });
        if (item.productMerchantdising && item.productMerchantdising.is_combo) {
          CatalogData.Products[index]["combo_products"] =
            item.productMerchantdising &&
            item.productMerchantdising.combo_props;
        }
      });
  } else if (DataSelector && DataSelector.data.length) {
    DataSelector.data.length &&
      DataSelector.data.forEach((item, index) => {
        CatalogData.Products.push({
          productImage:
            item.product_merchantdising && item.product_merchantdising.pr_image,
          is_combo:
            item.product_merchantdising && item.product_merchantdising.is_combo
              ? true
              : false,
          productName: item.product_master && item.product_master.pr_name,
          productId: item.product_master && item.product_master.product_id,
          gross: item.product_master && item.product_master.gross,
          net: item.product_master && item.product_master.net,
          uom: item.product_master && item.product_master.uom,
          mrp: item.product_pricing && item.product_pricing.base_price,
          base_discount:
            item.product_pricing && item.product_pricing.base_discount,
          stock: item.product_inventory && item.product_inventory.stock_units,
          slot_custom_message:
            item.product_inventory &&
            item.product_inventory.slot_custom_message !== ""
              ? item.product_inventory.slot_custom_message
              : item.product_inventory.next_available_by,
          categoryId: item.product_inventory && item.product_inventory.cat_id,
          hub_id: item.product_inventory && item.product_inventory.hub_id,
          next_available_by:
            item.product_inventory && item.product_inventory.next_available_by,
          discount_price:
            item.product_pricing &&
            round(
              parseFloat(
                item.product_pricing.base_price -
                  (item.product_pricing.base_price *
                    item.product_pricing.rounded_base_discount) /
                    100
              ).toFixed(2)
            ),
          discount:
            item.product_pricing && item.product_pricing.rounded_base_discount,
          unit_gram: item.product_pricing.unit_gram,
        });

        if (
          item.product_merchantdising &&
          item.product_merchantdising.is_combo
        ) {
          CatalogData.Products[index]["combo_products"] =
            item.product_merchantdising &&
            item.product_merchantdising.combo_props;
        }
      });
  }

  return CatalogData;
}

export default CatalogDataSelector;

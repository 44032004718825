export const SET_PROGRESS_STEP = 'SET_PROGRESS_STEP'
export const GET_CUSTOMER_ADDRESS = 'GET_CUSTOMER_ADDRESS'
export const SAVE_USER_ADDR = 'SAVE_USER_ADDR'
export const GET_WALLET_DETAILS = 'GET_WALLET_DETAILS'
export const UPDATE_WALLET_DETAILS = 'UPDATE_WALLET_DETAILS'
export const DATA_LOADING = 'DATA_LOADING'
export const STOCK_RELEASE = 'STOCK_RELEASE'
export const SET_NO_ORDERS_MODAL = 'SET_NO_ORDERS_MODAL'
export const SET_CART_LOADING = 'SET_CART_LOADING'
export const SET_POLLING_TIMER = 'SET_POLLING_TIMER'
export const CLEAR_TIMER_IDS = 'CLEAR_TIMER_IDS'
export const CLEAR_REGISTER_USER_DATA = "CLEAR_REGISTER_USER_DATA";
export const CLEAR_USER_PROFILE = "CLEARUSERPROFILE";

export const setProgressStep = (payload) => ({
  type: SET_PROGRESS_STEP,
  payload: payload,
})

export const getCustomerAddress = (payload) => ({
  type: GET_CUSTOMER_ADDRESS,
  payload: payload,
})

export const saveUserAddress = (payload) => ({
  type: SAVE_USER_ADDR,
  payload: payload,
})

export const getWalletDetails = (payload) => ({
  type: GET_WALLET_DETAILS,
  payload: payload,
})

export const saveWalletDetails = (data) => ({
  type: UPDATE_WALLET_DETAILS,
  payload: data,
})

export const isLoading = (payload) => ({
  type: DATA_LOADING,
  payload: payload,
})

export const stockRelease = (payload) => ({
  type: STOCK_RELEASE,
  payload,
})

export const setNoOrdersModal = (payload) => ({
  type: SET_NO_ORDERS_MODAL,
  payload,
})

export const setCartLoading = (payload) => ({
  type: SET_CART_LOADING,
  payload,
})

export const setPollingTimerID = (payload) => ({
  type: SET_POLLING_TIMER,
  payload
})

export const clearAllTimerID = () => ({
  type: CLEAR_TIMER_IDS
})
export const clearRegisterUserData = () => ({
  type: CLEAR_REGISTER_USER_DATA,
});


export const clearUserProfile = () => ({
  type: CLEAR_USER_PROFILE,
});

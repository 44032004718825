import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import "./ConfirmTakeaway.scss";
import {
  lockTakeAwayOrderRequest,
  showConfirmTakeaway,
} from "../../actions/TakeAwayActions";
import { setProgressStep } from "../../actions/CommonActions";
import Loader from "../Loader/Loader";
import { triggerEvent } from "../../utils/segment";
function ConfirmTakeaway(props) {
  const [confirmOrderLoading, setConfirmOrderLoading] = useState(false);

  const editOrder = () => {
    props.showConfirmModal(false);
  };

  const confirmTakeawayOrder = () => {
    if (props.stepName === "takeaway") {
      // props.showConfirmModal(false);
      // props.orderJourneyStepChange(2);
      triggerEvent({
        action_type: "cart_confirmation_takeaway",
        is_guest: props.otpReducer.customer_type === "guest" ? true : false,
        order_id: "",
        is_existing_customer:
          props.otpReducer.customer_type !== "guest" &&
          !props.otpReducer.new_customer
            ? true
            : false,
        attributes: {},
        item_count_takeaway:
          props.takeAwayReducer &&
          props.takeAwayReducer.cartData &&
          props.takeAwayReducer.cartData.cartItems &&
          props.takeAwayReducer.cartData.cartItems.length
            ? props.takeAwayReducer.cartData.cartItems.length
            : "",
        item_count_delivery:
          props.DeliveryOrderReducer &&
          props.DeliveryOrderReducer.deliveryCart &&
          props.DeliveryOrderReducer.deliveryCart.products &&
          props.DeliveryOrderReducer.deliveryCart.products.length
            ? props.DeliveryOrderReducer.deliveryCart.products.length
            : "",
        customer_key: props.otpReducer.customerDetails.customer_key,
        id: props.otpReducer.uid,
      });
      props.lockTakeAwayOrder({
        customer_key: props.otpReducer.customerDetails.customer_key,
        setConfirmOrderLoading: setConfirmOrderLoading,
        showConfirmModal: props.showConfirmModal,
      });
      setConfirmOrderLoading(true);
    }
    if (props.stepName === "delivery") {
      triggerEvent({
        action_type: "cart_confirmation_delivery",
        is_guest: props.otpReducer.customer_type === "guest" ? true : false,
        order_id: "",
        is_existing_customer:
          props.otpReducer.customer_type !== "guest" &&
          !props.otpReducer.new_customer
            ? true
            : false,
        attributes: {},
        item_count_takeaway:
          props.takeAwayReducer &&
          props.takeAwayReducer.cartData &&
          props.takeAwayReducer.cartData.cartItems &&
          props.takeAwayReducer.cartData.cartItems.length
            ? props.takeAwayReducer.cartData.cartItems.length
            : "",
        item_count_delivery:
          props.DeliveryOrderReducer &&
          props.DeliveryOrderReducer.deliveryCart &&
          props.DeliveryOrderReducer.deliveryCart.products &&
          props.DeliveryOrderReducer.deliveryCart.products.length
            ? props.DeliveryOrderReducer.deliveryCart.products.length
            : "",
        customer_key: props.otpReducer.customerDetails.customer_key,
        id: props.otpReducer.uid,
      });
      if (Object.keys(props.DeliveryOrderReducer.deliveryCart).length === 0) {
        props.showConfirmModal(false);
        props.orderJourneyStepChange(4);
      } else {
        props.showConfirmModal(false);
        props.orderJourneyStepChange(3);
      }
    }
    if (props.stepName === "DeliveryDetails") {
      props.showConfirmModal(false);
      props.orderJourneyStepChange(4);
    }
  };
  return (
    <div className="confirm-takeaway">
      {props.stepName === "takeaway" && (
        <div>
          <div className="modal-title">Confirm Takeaway Order!</div>
          <div className="message">
            Are you sure you want to confirm Takeaway order and continue
            shopping?
          </div>
        </div>
      )}
      {props.stepName === "delivery" && (
        <div>
          <div className="modal-title">Confirm Order!</div>
          <div className="message">
            Are you sure you want to confirm your order and continue to add
            Delivery details for checkout?
          </div>
        </div>
      )}
      <div className="button">
        <button className="edit-btn" onClick={(e) => editOrder()}>
          Edit Order
        </button>
        <button className="confirm-btn" onClick={(e) => confirmTakeawayOrder()}>
          {confirmOrderLoading ? <Loader /> : "Confirm Order"}
        </button>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    showConfirmModal: (value) => {
      dispatch(showConfirmTakeaway(value));
    },
    orderJourneyStepChange: (step) => {
      dispatch(setProgressStep(step));
    },
    lockTakeAwayOrder: (payload) => {
      dispatch(lockTakeAwayOrderRequest(payload));
    },
  };
};

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmTakeaway);

import { UPDATE_WALLET_DETAILS } from '../actions/CommonActions'
import { UPDATE_COMBINE_CART_DATA } from '../actions/DeliveryOrderActions'
import {
  CREATE_ORDER_SUCCESS,
  CREATE_PAYMENT_DETAILS,
  ORDER_REQUEST_SUCCESS,
  CLEAR_ORDER_DATA,
  SET_PAYMENT_PROGRESS,
  UPDATE_CONTACTLESS_DELIVERY_SUCCESS,
  COMBINE_CART_DATA_LOADING,
  SET_PAYMENT_METHOD,
  SET_PAYMENT_CONFIMRATION_MODAL,
  SET_ORDER_TYPE,
  SET_COUPON_APPLIED,
  CLEAR_COUPON_APPLIED,
  SET_CREATE_ORDER_LOADING,
  UPDATE_SHIPMENT_INFO
} from '../actions/PaymentActions'

const initialState = {
  walletDetails: {},
  combineCartData: null,
  orderData: {},
  order_status_timer: false,
  order_status: null,
  paymentProgress: false,
  contactLessDelivery: true,
  combineCartDataLoading: false,
  paymentMethod: null,
  showRetryModal: false,
  orderType: '',
  appliedCoupon: '',
  createOrderLoading: false,
  shipmentInfo:{}
}
const PaymentDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PAYMENT_PROGRESS: {
      return {
        ...state,
        paymentProgress: action.payload,
      }
    }
    case UPDATE_WALLET_DETAILS: {
      return {
        ...state,
        walletDetails: action.data,
      }
    }
    case UPDATE_COMBINE_CART_DATA: {
      return {
        ...state,
        combineCartData: action.payload,
      }
    }
    case CREATE_ORDER_SUCCESS: {
      return {
        ...state,
        combineCartData: action.payload,
      }
    }

    case CREATE_PAYMENT_DETAILS: {
      return {
        ...state,
        ...initialState,
      }
    }
    case ORDER_REQUEST_SUCCESS: {
      return {
        ...state,
        orderData: action.payload,
      }
    }
    case CLEAR_ORDER_DATA: {
      return {
        ...state,
        ...initialState,
      }
    }

    case UPDATE_CONTACTLESS_DELIVERY_SUCCESS: {
      return {
        ...state,
        contactLessDelivery: action.payload,
      }
    }
    case COMBINE_CART_DATA_LOADING: {
      return {
        ...state,
        combineCartDataLoading: action.payload,
      }
    }
    case SET_PAYMENT_METHOD: {
      return {
        ...state,
        paymentMethod: action.payload,
      }
    }
    case SET_PAYMENT_CONFIMRATION_MODAL: {
      return {
        ...state,
        showRetryModal: action.payload,
      }
    }
    case SET_ORDER_TYPE: {
      return {
        ...state,
        orderType: action.payload,
      }
    }
    case SET_COUPON_APPLIED: {
      return {
        ...state,
        appliedCoupon: action.payload,
      }
    }
    case CLEAR_COUPON_APPLIED: {
      return {
        ...state,
        appliedCoupon: '',
      }
    }
    case SET_CREATE_ORDER_LOADING: {
      return {
        ...state,
        createOrderLoading: action.payload,
      }
    }
    case UPDATE_SHIPMENT_INFO:{
      return {
        ...state,
        shipmentInfo: action.payload
      }
    }
    default:
      return state
  }
}

export default PaymentDetailsReducer

export default function FetchCatalogData(catId,fetchFunction,loadFunction,setFilterData,data,pastOrderData,hub_id) {
    const { Products } = data;
    let filteredProducts;
    if (catId === 0) {
      filteredProducts =pastOrderData;
      setFilterData({ Products: filteredProducts });
    } else {
      let params = {
        cat_id: catId,
        hubId: hub_id,
      };
      loadFunction(true);
      fetchFunction({ params: params });
    }
}

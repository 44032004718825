import React from "react";
import { connect } from "react-redux";
import "./CategoryFilterBar.scss";
import classnames from "classnames";
import { setScanActive } from "../../actions/TakeAwayActions";
function CategoryFilterBar(props) {
  const { customer_type } = props.otpReducer;
  if (customer_type !== "guest" && props.stepName !== "merchantorders") {
    props.categories.length &&
      props.categories[0].cat_name !== "Past Orders" &&
      props.categories.unshift({ id: 0, cat_name: "Past Orders" });
  }
  // useEffect(() => {
  //   const scrollContainer = document.getElementById('new-ebo-category-bar-id')
  //   scrollContainer.addEventListener('wheel', (evt) => {
  //     evt.preventDefault()
  //     scrollContainer.scrollLeft += evt.deltaY
  //   })
  // }, [])
  return (
    <div className="new-ebo-category-bar" id={"new-ebo-category-bar-id"}>
      {props.categories.length &&
        props.categories.map((item) => {
          return (
            <button
              className={classnames("button-styles", {
                active: item.id === props.selectedCategoryFilter,
                disabled: props.scanActive,
              })}
              onClick={() => {
                // if (!props.scanActive)
                props.handleFilters(item.id);
                props.setProductScanActive(false);
              }}
            >
              {item.cat_name}
            </button>
          );
        })}
      <div className="dummy-space">
        &nbsp; {/* justo create a bit of space at the end of filter buttons  */}
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    setProductScanActive: (payload) => dispatch(setScanActive(payload)),
  };
};
const CategoryFilterBarConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoryFilterBar);

export default CategoryFilterBarConnect;

import { put, takeEvery, call, fork, take, select } from "redux-saga/effects";
import {
  GET_AUTOCOMPLETE_LOCALITY_SUCCESS,
  GET_AUTOCOMPLETE_LOCALITY,
  GET_GEOLOCATION_REQUEST,
  GET_LOCATION_HUB_SUCCESS,
  GET_DELIVERY_CART_SUCCESS,
  GET_DELIVERY_CART_FAILED,
  GET_DELIVERY_CART_REQUEST,
  UPDATE_DELIVERY_CART_REQUEST,
  UPDATE_DELIVERY_CART_FAIL,
  UPDATE_DELIVERY_CART_SUCCESS,
  RESET_DELIVERY_CART,
  REMOVE_PRODUCT_FROM_DELIVERY_CART_REQUEST,
  HUB_LOCALITY_REQUEST,
  LOCALITY_MODAL_SHOWN,
  SET_DELIVERY_ORDER_LOADING,
  CHANGE_OF_LOCATION_DETECTED,
  LOCATION_CHANGE_AFTER_REVIEW,
  MERGE_SHIPMENT_REQUEST,
  CHANGE_LOCALITY_ADDRESS,
  SET_ADDING_TO_DELIVERY_CART_LOAD,
  GET_LOCATION_CITYNAME,
  UPDATE_DELIVERY_ADDRESS_CITY,
  MERGE_SHIPMENT_LOADING,
  CONFIRM_ADDRESS_SELECTION_LOADING,
  REVIEW_LOCATION_LOADING,
  UPDATE_SHIPMENT_SLOT,
  SHOW_SHIPMENT_SLOT_MODAL,
} from "../actions/DeliveryOrderActions";
import { DATA_LOADING, SET_PROGRESS_STEP } from "../actions/CommonActions";
import ToastMessage from "../utils/ToastMessage";
import {
  API_DeleteFromDeliveryCart,
  API_GetAutocompleteLocality,
  API_GetDeliveryCart,
  API_GetGeoLocation,
  API_GetLocationHub,
  API_ResetDeliveryCart,
  API_UpdateDeliveryCart,
  API_UpdateDeliveryTotal,
  API_GetLocationCityName,
} from "../apiServices/DeliveryOrderAPI";

import { CHANGE_CUSTOMER_ADDRESS } from "../actions/DeliveryOrderActions";
import {
  API_GetShipmentDetailsV3,
  API_LocationChange,
  API_locationChangeAfterReview,
  API_MergeShipmentSlots,
  API_RemoveLoyalty,
  API_UpdateShipmentSlotsNewEbo,
} from "../services/APIServices";
import {
  GET_SHIP_DETAILS,
  SHIP_DETAILS_FAILED,
  SHIP_DETAILS_SUCCESS,
} from "../actions/ProductListActions";
import { DeliveryHubSet } from "../utils/cleverTap";
import { SET_ADDING_TO_TAKEAWAY_CART_LOAD } from "../actions/TakeAwayActions";

function* getAutocompleteLocation(action) {
  let errMsg = "Failed to Fetch Catalog Products";
  try {
    let response = yield call(
      API_GetAutocompleteLocality,
      action.payload.params
    );
    if (response.type === "success") {
      yield put({ type: DATA_LOADING, payload: false });
      yield put({
        type: GET_AUTOCOMPLETE_LOCALITY_SUCCESS,
        payload: response.data,
      });
    } else {
      if (!!response.message || response.statusCode === 504) {
        yield put({ type: DATA_LOADING, payload: false });
        errMsg = response.message ? response.message : response.statusMessage;
      }
      ToastMessage(errMsg);
    }
  } catch (error) {
    if (!!error.message) {
      yield put({ type: DATA_LOADING, payload: false });
      errMsg = error.message;
    }
    ToastMessage(errMsg);
  }
}

function* getGeoLocation(action) {
  let errMsg = "failed to fetch Hub";
  try {
    let response = yield call(API_GetGeoLocation, action.payload.params);
    if (response.type === "success") {
      yield put({ type: SET_DELIVERY_ORDER_LOADING, payload: false });
      let params = {
        lat: response.data[0].lat,
        lng: response.data[0].lng,
      };

      try {
        yield put({ type: SET_DELIVERY_ORDER_LOADING, payload: true });
        let hubApiResponse = yield call(API_GetLocationHub, params);
        const selectedHub = yield select(
          (state) => state.DeliveryOrderReducer.selectedLocationHub
        );
        const customerData = yield select((state) => state.otpReducer);
        if (selectedHub && selectedHub !== hubApiResponse.hub) {
          yield put({ type: CHANGE_LOCALITY_ADDRESS, payload: "" });
          yield put({
            type: RESET_DELIVERY_CART,
            payload: {
              params: {
                customer_key: customerData.customerDetails.customer_key,
              },
            },
          });
        }
        const loginDetails = yield select((state) => state.login);

        const localityName = yield select(
          (state) => state.DeliveryOrderReducer.selectedLocality.description
        );
        if (hubApiResponse.status === "success") {
          yield put({
            type: GET_LOCATION_HUB_SUCCESS,
            payload: hubApiResponse.hub,
          });
          action.payload.setStoreName(action.payload.storeNameData);

          const cleverTapPayload = {
            customer_key: customerData.customerDetails.customer_key,
            city: loginDetails.agentDetails.organization.find(
              (item) => item.store_code === loginDetails.storeId
            ).city,
            city_id: loginDetails.agentDetails.organization.find(
              (item) => item.store_code === loginDetails.storeId
            ).city_id,
            store_id: loginDetails.storeId,
            user_type: customerData.new_customer ? "New user" : "Existing user",
            status: "success",
            meatopia_status: customerData.new_customer
              ? "non-member"
              : customerData.loyalityBenefits &&
                customerData.loyalityBenefits.subscribed &&
                customerData.loyalityBenefits.subscribed.days_left > 0
              ? "member"
              : "non_member",

            phone: customerData.customerDetails.phone,
            email: customerData.customerDetails.email,
            name: customerData.customerDetails.name,
            hub_id: hubApiResponse.hub,
            is_serviceable: "yes",
            delivery_city_name: hubApiResponse.city_name,
          };

          DeliveryHubSet(cleverTapPayload);
        } else {
          if (!!hubApiResponse.message) {
            yield put({ type: LOCALITY_MODAL_SHOWN, payload: true });
            yield put({ type: SET_DELIVERY_ORDER_LOADING, payload: false });
            errMsg = hubApiResponse.message;
          }
          const cleverTapPayload = {
            customer_key: response.data.customer_key,
            city: loginDetails.agentDetails.organization.find(
              (item) => item.store_code === loginDetails.storeId
            ).city,
            city_id: loginDetails.agentDetails.organization.find(
              (item) => item.store_code === loginDetails.storeId
            ).city_id,
            store_id: loginDetails.storeId,
            user_type: customerData.new_customer ? "New user" : "Existing user",
            phone: customerData.customerDetails.phone,
            email: customerData.customerDetails.email,
            name: customerData.customerDetails.name,
            hub: "NA",
            meatopia_status: customerData.new_customer
              ? "non-member"
              : customerData.loyalityBenefits &&
                customerData.loyalityBenefits.subscribed &&
                customerData.loyalityBenefits.subscribed.days_left > 0
              ? "member"
              : "non_member",
            locality_name: localityName,
            delivery_city_name: "NA",
            status: "failed",
            status_reason: hubApiResponse.message,
            is_serviceable: "no",
          };
          DeliveryHubSet(cleverTapPayload);
          ToastMessage(errMsg);
        }
      } catch (error) {
        if (!!error.message) {
          yield put({ type: SET_DELIVERY_ORDER_LOADING, payload: false });
          errMsg = error.message;
        }

        const loginDetails = yield select((state) => state.login);
        const customerData = yield select((state) => state.otpReducer);
        const localityName = yield select(
          (state) => state.DeliveryOrderReducer.selectedLocality.description
        );
        const cleverTapPayload = {
          customer_key: customerData.customerDetails.customer_key,
          city: loginDetails.agentDetails.organization.find(
            (item) => item.store_code === loginDetails.storeId
          ).city,
          city_id: loginDetails.agentDetails.organization.find(
            (item) => item.store_code === loginDetails.storeId
          ).city_id,
          store_id: loginDetails.storeId,
          user_type: customerData.new_customer ? "New user" : "Existing user",
          phone: customerData.customerDetails.phone,
          email: customerData.customerDetails.email,
          name: customerData.customerDetails.name,
          hub: "NA",
          meatopia_status: customerData.new_customer
            ? "non-member"
            : customerData.loyalityBenefits &&
              customerData.loyalityBenefits.subscribed &&
              customerData.loyalityBenefits.subscribed.days_left > 0
            ? "member"
            : "non_member",
          locality_name: localityName,
          delivery_city_name: "NA",
          status: "failed",
          status_reason: errMsg,
          is_serviceable: "NA",
        };
        DeliveryHubSet(cleverTapPayload);
        ToastMessage(errMsg);
      }
    } else {
      if (!!response.message || response.statusCode === 504) {
        yield put({ type: SET_DELIVERY_ORDER_LOADING, payload: false });
        errMsg = response.message ? response.message : response.statusMessage;
      }
      const loginDetails = yield select((state) => state.login);
      const customerData = yield select((state) => state.otpReducer);
      const localityName = yield select(
        (state) => state.DeliveryOrderReducer.selectedLocality.description
      );
      const cleverTapPayload = {
        customer_key: customerData.customerDetails.customer_key,
        store_city_name: loginDetails.agentDetails.organization.find(
          (item) => item.store_code === loginDetails.storeId
        ).city,
        city_id: loginDetails.agentDetails.organization.find(
          (item) => item.store_code === loginDetails.storeId
        ).city_id,
        store_id: loginDetails.storeId,
        user_type: customerData.new_customer ? "New user" : "Existing user",
        phone: customerData.customerDetails.phone,
        email: customerData.customerDetails.email,
        name: customerData.customerDetails.name,
        hub: "NA",
        meatopia_status: customerData.new_customer
          ? "non-member"
          : customerData.loyalityBenefits &&
            customerData.loyalityBenefits.subscribed &&
            customerData.loyalityBenefits.subscribed.days_left > 0
          ? "member"
          : "non_member",
        locality_name: localityName,
        delivery_city_name: "NA",
        status: "failed",
        status_reason: errMsg,
        is_serviceable: "NA",
      };
      DeliveryHubSet(cleverTapPayload);
      ToastMessage(errMsg);
    }
  } catch (error) {
    if (!!error.message) {
      yield put({ type: SET_DELIVERY_ORDER_LOADING, payload: false });
      errMsg = error.message;
    }
    ToastMessage(errMsg);
  }
}

function* setLocalityHub(action) {
  try {
    yield put({
      type: GET_LOCATION_HUB_SUCCESS,
      payload: action.payload.params.hub_id,
    });
  } catch (e) {}
}

function* getDeliveryCartSaga(action) {
  let errMsg = "Failed to Fetch Cart";
  yield put({ type: SET_ADDING_TO_DELIVERY_CART_LOAD, payload: true });
  try {
    let response = yield call(API_GetDeliveryCart, action.payload.params);
    if (response.statusCode === 200) {
      if (response.data.loyalty_item && response.data.loyalty_item.length) {
        const hub = yield select(
          (state) => state.DeliveryOrderReducer.selectedLocationHub
        );
        yield call(API_RemoveLoyalty, {
          data: {
            customer_key: action.payload.params.customer_key,
            program_id: response.data.loyalty_item[0].program_id,
            subprogram_id: response.data.loyalty_item[0].subprogram_id,
          },
          hubid: hub,
        });
      }
      if (action.payload.nextCall === "get-shipment") {
        yield put({
          type: GET_SHIP_DETAILS,
          payload: action.payload.nextCallPayload,
        });
      }
      yield put({ type: SET_ADDING_TO_DELIVERY_CART_LOAD, payload: false });
      yield put({ type: DATA_LOADING, payload: false });

      if (action.payload.closeRemoveModal && action.payload.removeLoader) {
        action.payload.closeRemoveModal({ value: false, removeProduct: {} });
        action.payload.removeLoader(false);
      }
      yield put({
        type: GET_DELIVERY_CART_SUCCESS,
        payload: response.data,
      });
      yield put({ type: SET_ADDING_TO_DELIVERY_CART_LOAD, payload: false });
      yield put({ type: SET_ADDING_TO_TAKEAWAY_CART_LOAD, payload: false });
      yield put({ type: SET_DELIVERY_ORDER_LOADING, payload: false });
    } else if (response.statusCode === 202 || response.statusCode === 504) {
      yield put({ type: SET_ADDING_TO_DELIVERY_CART_LOAD, payload: false });
      yield put({ type: SET_ADDING_TO_TAKEAWAY_CART_LOAD, payload: false });
      yield put({ type: SET_DELIVERY_ORDER_LOADING, payload: false });
      if (response.statusMessage === "success") {
        if (response.data.errorMessage === "empty_cart") {
          if (action.payload.closeRemoveModal && action.payload.removeLoader) {
            action.payload.closeRemoveModal({
              value: false,
              removeProduct: {},
            });
            action.payload.removeLoader(false);
          }
          yield put({
            type: UPDATE_DELIVERY_CART_SUCCESS,
            payload: {},
          });
        }
        if (action.payload.getShipmentCall) {
          yield put({
            type: GET_SHIP_DETAILS,
            payload: {
              customer_key: action.payload.params.customer_key,
            },
          });
        }
        yield put({ type: DATA_LOADING, payload: false });
        yield put({
          type: UPDATE_DELIVERY_CART_FAIL,
        });
      } else ToastMessage(response.statusMessage);
    }
  } catch (error) {
    yield put({ type: SET_ADDING_TO_DELIVERY_CART_LOAD, payload: false });
    if (!!error.message) {
      yield put({ type: DATA_LOADING, payload: false });
      yield put({
        type: GET_DELIVERY_CART_FAILED,
      });
      if (action.payload.closeRemoveModal && action.payload.removeLoader) {
        action.payload.closeRemoveModal({ value: false, removeProduct: {} });
        action.payload.removeLoader(false);
      }
      errMsg = error.message;
    }
    ToastMessage(errMsg);
  }
}
function* updateDeliveryCartSaga(action) {
  let errMsg = "Failed to Fetch Cart";
  yield put({ type: SET_ADDING_TO_DELIVERY_CART_LOAD, payload: true });
  try {
    let response = yield call(API_UpdateDeliveryCart, action.payload.params);
    if (response) {
      yield put({ type: SET_ADDING_TO_DELIVERY_CART_LOAD, payload: false });
    }
    if (response.status === "success") {
      let getCartPayload = {
        params: { customer_key: action.payload.params.customer_key },
      };
      if (action.payload.getShipmentCall) {
        getCartPayload["getShipmentCall"] = action.payload.getShipmentCall;
      }
      if (
        action.payload.params.closeRemoveModal &&
        action.payload.params.removeLoader
      ) {
        getCartPayload["closeRemoveModal"] =
          action.payload.params.closeRemoveModal;
        getCartPayload["removeLoader"] = action.payload.params.removeLoader;
      }

      yield put({
        type: GET_DELIVERY_CART_REQUEST,
        payload: {
          ...getCartPayload,
        },
      });
    } else {
      if (response.status === "error" || response.statusCode === 504) {
        yield put({
          type: UPDATE_DELIVERY_CART_FAIL,
        });
        errMsg = response.msg ? response.msg : response.statusMessage;
      }
      yield put({ type: SET_ADDING_TO_DELIVERY_CART_LOAD, payload: false });
      ToastMessage(errMsg);
    }
  } catch (error) {
    yield put({ type: SET_ADDING_TO_DELIVERY_CART_LOAD, payload: false });
    if (!!error.message) {
      yield put({ type: SET_ADDING_TO_DELIVERY_CART_LOAD, payload: false });
      yield put({ type: DATA_LOADING, payload: false });
      yield put({
        type: UPDATE_DELIVERY_CART_FAIL,
      });
      errMsg = error.message;
    }
    ToastMessage(errMsg);
  }
}
function* deleteProductEntryDeliveryCartSaga(action) {
  let errMsg = "Failed to Fetch Cart";
  try {
    let response = yield call(
      API_DeleteFromDeliveryCart,
      action.payload.params
    );
    if (response.status === "success") {
      let getCartPayload = {
        params: { customer_key: action.payload.params.customer_key },
      };
      if (
        action.payload.params.closeRemoveModal &&
        action.payload.params.removeLoader
      ) {
        action.payload.params.closeRemoveModal({
          value: false,
          removeProduct: {},
        });
        action.payload.params.removeLoader(false);
      }

      if (action.payload.getShipmentCall) {
        getCartPayload["getShipmentCall"] = action.payload.getShipmentCall;
      }
      yield put({
        type: GET_DELIVERY_CART_REQUEST,
        payload: {
          ...getCartPayload,
        },
      });
    } else {
      if (response.status == "error" || response.statusCode === 504) {
        if (action.payload.closeRemoveModal && action.payload.removeLoader) {
          action.payload.closeRemoveModal({ value: false, removeProduct: {} });
          action.payload.removeLoader(false);
        }

        yield put({
          type: UPDATE_DELIVERY_CART_FAIL,
        });
        errMsg = response.message ? response.message : response.statusMessage;
      }
      ToastMessage(errMsg);
    }
  } catch (error) {
    if (!!error.message) {
      yield put({
        type: UPDATE_DELIVERY_CART_FAIL,
      });
      errMsg = error.message;
    }
    ToastMessage(errMsg);
  }
}
function* resetDeliveryCart(action) {
  let errMsg = "Failed to Fetch Cart";
  try {
    let response = yield call(API_ResetDeliveryCart, action.payload.params);
    if (response.status === "success") {
      yield put({ type: DATA_LOADING, payload: false });
      yield put({
        type: UPDATE_DELIVERY_CART_SUCCESS,
        payload: {},
      });
      // yield put({ type: GET_DELIVERY_CART_REQUEST, payload: action.payload })
    } else {
      if (response.status == "error" || response.statusMessage === "error") {
        yield put({ type: DATA_LOADING, payload: false });
        errMsg = response.message ? response.message : response.statusMessage;
      }
      ToastMessage(errMsg);
    }
  } catch (error) {
    if (!!error.message) {
      yield put({ type: DATA_LOADING, payload: false });
      yield put({
        type: UPDATE_DELIVERY_CART_FAIL,
      });
      errMsg = error.message;
    }
    ToastMessage(errMsg);
  }
}
function* changeCustomerDeliveryAddress(action) {
  let errMsg = "Failed to Change Address";
  try {
    let response = yield call(API_LocationChange, {
      lat: action.data.lat,
      lng: action.data.lng,
      cart_id: action.data.cart_id,
      hub_id: action.data.hub_id,
      customerKey: action.data.customer_key,
    });
    if (response) {
      yield put({ type: CONFIRM_ADDRESS_SELECTION_LOADING, payload: false });
    }
    if (response.code === 200) {
      yield put({ type: CHANGE_OF_LOCATION_DETECTED, payload: true });
      yield put({
        type: CHANGE_LOCALITY_ADDRESS,
        payload: response.data.address,
      });
    } else if (response.code === 201 && response.status === "SERVICABLE") {
      yield put({
        type: CHANGE_LOCALITY_ADDRESS,
        payload: response.data.address,
      });
      yield put({
        type: GET_LOCATION_HUB_SUCCESS,
        payload: response.data.hub_id,
      });
      yield put({
        type: GET_SHIP_DETAILS,
        payload: {
          customer_key: action.data.customer_key,
        },
      });
    } else if (
      (response.code === 202 && response.status === "ERROR") ||
      response.statusCode === 504
    ) {
      // action.data.setConfirmBtnLoading(false)
      errMsg = response.message ? response.message : "error";
      ToastMessage(errMsg);
      yield put({
        type: GET_SHIP_DETAILS,
        payload: {
          customer_key: action.data.customer_key,
        },
      });
    }
  } catch (error) {
    if (!!error.message) {
      yield put({ type: DATA_LOADING, payload: false });
      yield put({
        type: UPDATE_DELIVERY_CART_FAIL,
      });
      errMsg = error.message;
    }
    ToastMessage(errMsg);
  }
}
function* locationChangeAfterReview(action) {
  let errMsg = "Failed to Change Address";

  try {
    let response = yield call(API_locationChangeAfterReview, {
      lat: action.payload.lat,
      lng: action.payload.lng,
      cart_id: action.payload.cart_id,
      customerKey: action.payload.customer_key,
    });
    if (response) {
      yield put({ type: REVIEW_LOCATION_LOADING, payload: false });
      yield put({ type: CHANGE_OF_LOCATION_DETECTED, payload: false });
    }
    if (response.status === "LOCATION_CHANGED" || response.status === "ERROR") {
      let hubApiResponse = yield call(API_GetLocationHub, {
        lat: action.payload.lat,
        lng: action.payload.lng,
        customerKey: action.payload.customer_key,
      });

      if (hubApiResponse.status === "success") {
        yield put({
          type: GET_LOCATION_HUB_SUCCESS,
          payload: hubApiResponse.hub,
        });

        yield put({
          type: GET_DELIVERY_CART_REQUEST,
          payload: {
            params: { customer_key: action.payload.customer_key },
            nextCall: "get-shipment",
            nextCallPayload: {
              customer_key: action.payload.customer_key,
            },
          },
        });
      } else if (
        hubApiResponse.status === "error" ||
        hubApiResponse.statusCode === 504
      ) {
        ToastMessage(hubApiResponse.message);
      }
    } else if (response.status === "ERROR" && response.code === 202)
      ToastMessage(response.message);
  } catch (error) {
    if (!!error.message) {
      yield put({ type: REVIEW_LOCATION_LOADING, payload: false });
      yield put({ type: CHANGE_OF_LOCATION_DETECTED, payload: false });
      errMsg = error.message;
    }
    ToastMessage(errMsg);
  }
}
function* mergeShipmenthandler(action) {
  let errMsg = "Failed to Change Address";
  yield put({ type: MERGE_SHIPMENT_LOADING, payload: true });
  try {
    let response = yield call(API_MergeShipmentSlots, {
      customerKey: action.payload.customer_key,
    });
    yield call(API_UpdateDeliveryTotal, action.payload);
    if (response.statusCode === 200) {
      ToastMessage("Shipment Merged");
      yield put({ type: SET_PROGRESS_STEP, payload: 4 });
      yield put({ type: MERGE_SHIPMENT_LOADING, payload: false });
    } else if (response.statusCode === 202) {
      yield put({ type: MERGE_SHIPMENT_LOADING, payload: false });
      ToastMessage(response.data.message);
    } else {
      if (response.statusCode == 504) {
        yield put({ type: DATA_LOADING, payload: false });
        yield put({ type: MERGE_SHIPMENT_LOADING, payload: false });
        errMsg = response.statusMessage;
      }
      ToastMessage(errMsg);
    }
  } catch (error) {
    if (!!error.message) {
      yield put({ type: DATA_LOADING, payload: false });

      errMsg = error.message;
    }
    yield put({ type: MERGE_SHIPMENT_LOADING, payload: false });
    ToastMessage(errMsg);
  }
}

function* getLocationCityName(action) {
  let errMsg = "Failed to Fetch Cart";
  try {
    let response = yield call(API_GetLocationCityName, action.payload);
    if (response.status === "success") {
      yield put({
        type: UPDATE_DELIVERY_ADDRESS_CITY,
        payload: response.data.city_name,
      });
    } else {
      if (response.status == "error" || response.statusCode === 504) {
        errMsg = response.message ? response.message : response.statusMessage;
      }
      ToastMessage(errMsg);
    }
  } catch (error) {
    if (!!error.message) {
      errMsg = error.message;
    }
    ToastMessage(errMsg);
  }
}

function* getShipmentDetailsV3(action) {
  let errMsg = "Failed to get shipment details";
  yield put({ type: SET_ADDING_TO_DELIVERY_CART_LOAD, payload: true });
  try {
    let response = yield call(API_GetShipmentDetailsV3, action.payload);
    if (response) {
      yield put({ type: SET_ADDING_TO_DELIVERY_CART_LOAD, payload: false });
    }
    if (response.statusCode === 200) {
      yield put({ type: SHIP_DETAILS_SUCCESS, data: response.data });
    } else if (response.statusCode === 202 || response.statusCode === 504) {
      yield put({ type: SHIP_DETAILS_FAILED, data: {} });
      let error = response.statusMessage ? response.statusMessage : "Error";
      ToastMessage(error);
      yield put({
        type: GET_DELIVERY_CART_REQUEST,
        payload: {
          params: { customer_key: action.payload.customer_key },
        },
      });
    } else {
      yield put({ type: CHANGE_OF_LOCATION_DETECTED, payload: false });

      ToastMessage(errMsg);
      if (action.payload.setConfirmBtnLoading) {
        action.payload.setConfirmBtnLoading(false);
      }
      // yield put({ type: UPDATE_ADDR_FAILED, data: resposne });
    }
  } catch (error) {
    // ToastMessage(errMsg);
    yield put({ type: SET_ADDING_TO_DELIVERY_CART_LOAD, payload: false });
    if (action.payload.setConfirmBtnLoading) {
      action.payload.setConfirmBtnLoading(false);
    }
    yield put({ type: SET_ADDING_TO_DELIVERY_CART_LOAD, payload: false });
    // yield put({ type: UPDATE_ADDR_FAILED, data: error });
  }
}
function* updateShipSlot(action) {
  const errMsg = "Failed to update the slot";
  try {
    let resposne = yield call(
      API_UpdateShipmentSlotsNewEbo,
      action.payload.params
    );
    if (resposne) {
      yield put({ type: SHOW_SHIPMENT_SLOT_MODAL, payload: false });
    }
    if (resposne.statusMessage === "success") {
      yield put({ type: SHIP_DETAILS_SUCCESS, data: resposne.data });
    } else {
      // ToastMessage(errMsg)
      if (
        !!resposne.data &&
        resposne.data.message === "Error while processing request"
      ) {
        yield put({
          type: GET_DELIVERY_CART_REQUEST,
          payload: {
            params: {
              customer_key: action.payload.params.customer_key,
            },
          },
        });
      }
      // yield put({ type: UPDATE_ADDR_FAILED, data: resposne });
    }
  } catch (error) {
    yield put({ type: SHOW_SHIPMENT_SLOT_MODAL, payload: false });
    ToastMessage(errMsg);
  }
}

export default function* DeliveryOrderSagas() {
  yield [fork(takeEvery, GET_AUTOCOMPLETE_LOCALITY, getAutocompleteLocation)];
  yield [fork(takeEvery, GET_GEOLOCATION_REQUEST, getGeoLocation)];
  yield [fork(takeEvery, HUB_LOCALITY_REQUEST, setLocalityHub)];
  yield [fork(takeEvery, GET_DELIVERY_CART_REQUEST, getDeliveryCartSaga)];
  yield [fork(takeEvery, UPDATE_DELIVERY_CART_REQUEST, updateDeliveryCartSaga)];
  yield [fork(takeEvery, RESET_DELIVERY_CART, resetDeliveryCart)];
  yield [
    fork(
      takeEvery,
      REMOVE_PRODUCT_FROM_DELIVERY_CART_REQUEST,
      deleteProductEntryDeliveryCartSaga
    ),
  ];
  yield [
    fork(takeEvery, CHANGE_CUSTOMER_ADDRESS, changeCustomerDeliveryAddress),
  ];
  yield [
    fork(takeEvery, LOCATION_CHANGE_AFTER_REVIEW, locationChangeAfterReview),
  ];
  yield [fork(takeEvery, MERGE_SHIPMENT_REQUEST, mergeShipmenthandler)];
  yield [fork(takeEvery, GET_LOCATION_CITYNAME, getLocationCityName)];
  yield takeEvery(GET_SHIP_DETAILS, getShipmentDetailsV3);
  yield takeEvery(UPDATE_SHIPMENT_SLOT, updateShipSlot);
}

import {
  Add_TO_MERCHANT_CART,
  CLEAR_MERCHANT_ORDER_DATA,
  SAVE_MERCHANT,
  SAVE_MERCHANT_ORDER_ID,
  SAVE_ORDER_STATUS,
  SET_LOADING_FOR_MERCHANT_ORDERS,
} from "../actions/MerchantOrderActions";

const initialState = {
  isLoadingMerchantOrder: false,
  products: [],
  orderId: "",
  merchant: "",
  order_status: "not_placed",
};
const MerchantOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case Add_TO_MERCHANT_CART:
      return { ...state, isLoading: false, products: action.payload };
    case SAVE_MERCHANT:
      return { ...state, merchant: action.payload };
    case SAVE_MERCHANT_ORDER_ID:
      return { ...state, orderId: action.payload };
    case CLEAR_MERCHANT_ORDER_DATA:
      return { ...initialState };
    case SAVE_ORDER_STATUS:
      return { ...state, order_status: action.payload };

    case SET_LOADING_FOR_MERCHANT_ORDERS:
      return { ...state, isLoadingMerchantOrder: action.payload };
    default:
      return state;
  }
};

export default MerchantOrderReducer;

import React from "react";
import "./Input.scss";

const Input = ({ placeHolder }) => {
  return (
    <div className="input-container">
      <input placeholder={placeHolder} />
    </div>
  );
};

export default Input;

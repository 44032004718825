import React from 'react'

import SmileyIcon from '../../assets/qc-loader.gif'

const SmileyLoader = (props) => {
  return (
    <div
      style={{
        backgroundColor: 'rgba(256, 256, 256, 0.5)',
        height: '100%',
        width: '100%',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1,
      }}
    >
      <div style={{ position: 'relative' }}>
        <img
          src={SmileyIcon}
          style={{
            width: '100px',
            height: '100px',
          }}
          alt="licious_smiley"
        />
        <img
          src={'https://d2407na1z3fc0t.cloudfront.net/Banner/licious_smile.png'}
          alt="licious_loader"
          style={{
            position: 'absolute',
            top: '40px',
            left: '40px',
          }}
        />
      </div>
    </div>
  )
}

SmileyLoader.propTypes = {}

export default SmileyLoader

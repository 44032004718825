import React, { useEffect, useState } from "react";
// import Checkbox from "../../checkbox/checkbox";
import Card from "../../../Card/Card";
import Switch from "../../../Switch/Switch";
import "./Payment.scss";
import { ReactComponent as IconTick } from "../../../../assets/new-ebo-icons/states/Icon-Tick.svg";
import { ReactComponent as IconDelete } from "../../../../assets/new-ebo-icons/Icon-Delete.svg";

import { ReactComponent as UncheckedRadio } from "../../../../assets/new-ebo-icons/whites/Icon-Tick.svg";

import { ReactComponent as CheckedRadio } from "../../../../assets/new-ebo-icons/Icon-Tick.svg";

import { ReactComponent as IconDiscount } from "../../../../assets/new-ebo-icons/Icon-Discount.svg";
import GenericModal from "../../../GenricModal/GenricModal";
import ConfirmButton from "../../../Buttons/ConfirmButton/ConfirmButton";
import { connect } from "react-redux";
import {
  clearAllTimerID,
  getWalletDetails,
  setPollingTimerID,
} from "../../../../actions/CommonActions";
import {
  eboApplyCoupon,
  eboRemoveCoupon,
} from "../../../../actions/ProductListActions";
import AddToCart from "../../../AddToCart/AddToCart";
import OtpModal from "../../../Modals/OtpModal";
import { denominations } from "../../../../utils/consts";
import classnames from "classnames";
import MeatopiaCard from "../../../LoyalityCard/MeatopiaCard";
import { getCombinedCartRequest } from "../../../../actions/DeliveryOrderActions";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  createOrderRequest,
  clearCouponCode,
  retryPayment,
  setCouponCode,
  setOrderType,
  setPaymentMethod,
  setPaymentPrgoress,
  updateContactlessDelivery,
} from "../../../../actions/PaymentActions";
import {getOtp , verifyOtp} from "../../../../actions/OtpActions";
import ToastMessage from "../../../../utils/ToastMessage";
import SmileyLoader from "../../../Loader/SmileyLoader";
import { CustomerOnPaymentPage } from "../../../../utils/cleverTap";
import Loader from "../../../Loader/Loader";
import { triggerEvent } from "../../../../utils/segment";
import { v4 as uuid } from "uuid";

const PaymentDetails = (props) => {
  const [cashModal, setCashModal] = useState(false);
  const [paymentMethodsAllowed, setPaymentMethodsAllowed] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(
    props.paymentDetails &&
      props.paymentDetails.orderData &&
      props.paymentDetails.orderData.statusCode === 201
      ? true
      : false
  );

  const [apiCalled, setAPICalledFlag] = useState(false);
  // const [contactLessDelivery, setContactLessDelivery] = useState(false)
  const [couponModal, setCouponModal] = useState(false);
  const [walletActive, setWalletActive] = useState(
    props.paymentDetails.combineCartData &&
      props.paymentDetails.combineCartData.licious_wallet
        .payable_wallet_amount > 0
      ? false
      : false
  );
  const [cashOut, setCashOut] = useState(0);
  const [pending, setPending] = useState(0);
  const [remarks, setRemarks] = useState("");

  const [couponValue, setCouponValue] = useState("");
  const [couponApplied, setCouponApplied] = useState(false);
  const { cartData } = props.takeAwayReducer.updateTakeawayCart;
  const { dummyCartData } = props.takeAwayReducer;
  const [activeDenominations, setActiveDenominations] = useState({});
  const [cashIn, setCashIn] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [show , setShow] = useState(false);
  const [otpValue, setOtpValue] = useState("");
  const [isUserVerified , setIsUserVerfied] = useState(false);
  const [callBackFunctionPostOTPVerification , setCallbackFunction] =  useState(() => {});
  const [timer, setTimer] = useState(0);
  const [timerId, setTimerID] = useState(null);
  const phone = props.otpReducer.customerDetails.phone;
  const is_OTPverified = props.otpReducer.customerDetails.is_OTPverified;
  const newCustomer = props.otpReducer.customerDetails.new_customer;
  const isGuestUser =  props.otpReducer.customer_type === "guest" ? true : false;
  const id = uuid().slice(0, 8);
  const reloadPage = () => window.location.reload();

  useEffect(() => {
    if (showConfirmation && !props.navigatingFrom) {
      props.history.push("/order-status");
    }
  }, [showConfirmation]);
  useEffect(() => {
    let order_type = "";
    if (
      props.takeAwayReducer.TotalCartItems &&
      props.takeAwayReducer.TotalCartItems.cartItems &&
      props.takeAwayReducer.TotalCartItems.cartItems.length &&
      Object.keys(props.DeliveryOrderReducer.deliveryCart).length
    ) {
      order_type = "ebo";
    } else if (
      props.takeAwayReducer.TotalCartItems &&
      props.takeAwayReducer.TotalCartItems.cartItems &&
      props.takeAwayReducer.TotalCartItems.cartItems.length
    ) {
      order_type = "TO";
    } else if (Object.keys(props.DeliveryOrderReducer.deliveryCart).length) {
      order_type = "DO";
    }
    props.saveOrderType(order_type);
    if (props.navigatingFrom && props.navigatingFrom === "order-status") {
      props.getCombinedCartData({
        customer_key: props.otpReducer.customerDetails.customer_key,
        shipment_id: props.paymentDetails.orderData.order_id,
      });
    } else {
      props.getCombinedCartData({
        customer_key: props.otpReducer.customerDetails.customer_key,
        type: order_type,
      });
    }
  }, []);

  useEffect(() => {
    if (
      props.commonReducer.timerIDs &&
      props.commonReducer.timerIDs.length > 0
    ) {
      props.commonReducer.timerIDs.forEach((id) => {
        clearInterval(id);
      });
      props.clearAllTimerID();
    }
  }, []);

  const handleCashIn = (item, action) => {
    const cashDetail = { ...activeDenominations };
    if (action === "add") {
      if (cashDetail[item]) {
        cashDetail[item].unit += 1;
      } else {
        cashDetail[item] = { unit: 1 };
      }
    } else {
      if (cashDetail[item] && cashDetail[item].unit >= 1) {
        cashDetail[item].unit -= 1;
        if (cashDetail[item].unit === 0) delete cashDetail[item];
      }
    }
    let total = Object.keys(cashDetail).reduce((a, b) => {
      return a + parseInt(b) * cashDetail[b].unit;
    }, 0);
    setCashIn(total);
    const orderTotal = walletActive
      ? props.paymentDetails.combineCartData.total -
        props.paymentDetails.combineCartData.licious_wallet
          .payable_wallet_amount
      : props.paymentDetails.combineCartData.total;
    const totalCashOut = orderTotal >= total ? 0 : total - orderTotal;
    setCashOut(totalCashOut);
    const totalPending = orderTotal <= total ? 0 : orderTotal - total;
    setPending(totalPending);
    setActiveDenominations(cashDetail);
    // cas
  };
  const handleCreateOrder = () => {
    const activeStore = props.login.storeId;
    const storeId =
      props.login.agentDetails &&
      props.login.agentDetails.organization.find(
        (item) => item.store_code === activeStore
      ).hub_id;
    const create_order_hub =
      Object.keys(props.DeliveryOrderReducer.deliveryCart).length > 0
        ? props.DeliveryOrderReducer.selectedLocationHub
        : storeId;

    const params = {
      store_code: activeStore,
      remarks: remarks,
      store_user: {
        id: props.login.agentDetails.id,
        name: props.login.agentDetails.name,
        email: props.login.agentDetails.email,
        is_guest: props.otpReducer.customer_type === "guest" ? true : false,
      },
      customer_key: props.otpReducer.customerDetails.customer_key,
      source: "ebo",
      hub_id: create_order_hub,
      address_id:
        props.DeliveryOrderReducer && props.DeliveryOrderReducer.customerAddress
          ? props.DeliveryOrderReducer.customerAddress.address_id
          : "",
      payments: [],
      total: props.paymentDetails.combineCartData.total,
      wallet_amount:
        props.paymentDetails.combineCartData.licious_wallet
          .payable_wallet_amount,
    };
    let ebo_order_type = "";
    if (walletActive) {
      if (
        props.paymentDetails.combineCartData.licious_wallet
          .payable_wallet_amount >= props.paymentDetails.combineCartData.total
      ) {
        setPaymentMethod("wallet");
      }
      params.payments.push({
        type: "wallet",
        amount_to_deduct:
          props.paymentDetails.combineCartData.total >
          props.paymentDetails.combineCartData.licious_wallet
            ? Math.abs(
                props.paymentDetails.combineCartData.total -
                  props.paymentDetails.combineCartData.licious_wallet
                    .payable_wallet_amount
              )
            : props.paymentDetails.combineCartData.total <
              props.paymentDetails.combineCartData.licious_wallet
                .payable_wallet_amount
            ? Math.abs(
                props.paymentDetails.combineCartData.licious_wallet
                  .payable_wallet_amount -
                  props.paymentDetails.combineCartData.total
              )
            : props.paymentDetails.combineCartData.licious_wallet
                .payable_wallet_amount,
      });
    }
    if (
      walletActive &&
      props.paymentDetails.combineCartData.total >
        props.paymentDetails.combineCartData.licious_wallet
          .payable_wallet_amount
    ) {
      if (paymentMethod === "others") {
        props.savePaymentMethod({
          type: "others",
          amount_to_deduct:
            props.paymentDetails.combineCartData.total -
            props.paymentDetails.combineCartData.licious_wallet
              .payable_wallet_amount,
        });
      }
      params.payments.push({
        type: paymentMethod,
        amount_to_deduct:
          props.paymentDetails.combineCartData.total -
          props.paymentDetails.combineCartData.licious_wallet
            .payable_wallet_amount,
      });
    } else if (!walletActive) {
      if (paymentMethod === "others") {
        props.savePaymentMethod({
          type: "others",
          amount_to_deduct: props.paymentDetails.combineCartData.total,
        });
      }
      params.payments.push({
        type: paymentMethod,
        amount_to_deduct: props.paymentDetails.combineCartData.total,
      });
    }
    if (
      props.takeAwayReducer &&
      props.takeAwayReducer.cartData &&
      props.takeAwayReducer.cartData.cartItems &&
      props.takeAwayReducer.cartData.cartItems.length > 0
    ) {
      ebo_order_type += "Takeaway";
    }
    if (
      props.DeliveryOrderReducer.deliveryCart &&
      props.DeliveryOrderReducer.deliveryCart.products &&
      props.DeliveryOrderReducer.deliveryCart.products.length > 0
    ) {
      if (ebo_order_type) {
        ebo_order_type += "AndDelivery";
      } else {
        ebo_order_type += "Delivery";
      }
    }
    if (
      props.DeliveryOrderReducer.deliveryCart &&
      props.DeliveryOrderReducer.deliveryCart.exotic &&
      props.DeliveryOrderReducer.deliveryCart.exotic.length > 0
    ) {
      if (
        ebo_order_type &&
        ebo_order_type !== "Delivery" &&
        ebo_order_type !== "TakeawayAndDelivery"
      ) {
        ebo_order_type += "AndDelivery";
      } else if (!ebo_order_type) {
        ebo_order_type += "Delivery";
      }
    }
    params.ebo_order_type = ebo_order_type;
    if (props.navigatingFrom && props.navigatingFrom === "order-status") {
      props.retryPayment({
        params: {
          customer_key: props.otpReducer.customerDetails.customer_key,
          order_id: props.paymentDetails.orderData.order_id,
        },
        data: {
          store_code: activeStore,
          remarks,
          payments: params.payments,
          parent_order_id: props.paymentDetails.orderData.order_id,
        },
        setShowConfirmation,
        reloadPage,
      });
    } else {
      props.createOrderRequest({ params, setShowConfirmation });
    }

    CustomerOnPaymentPage({
      master_paymode: paymentMethod,
      user_type: props.otpReducer.customer_type,
      meatopia_status:
        props.otpReducer.loyalityBenefits &&
        props.otpReducer.loyalityBenefits.subscribed &&
        props.otpReducer.loyalityBenefits.subscribed.days_left > 0
          ? "member"
          : "non_member",
      store_city_name: props.login.storeId,
      delivery_city_name:
        props.DeliveryOrderReducer.selectedLocality.description || "NA",
      store_id: create_order_hub,
      hub_id: props.DeliveryOrderReducer.selectedLocationHub || "NA",
      delivery_item_name: props.DeliveryOrderReducer.deliveryCartId
        ? getIDs("delivery", "item_name")
        : "NA",
      takeaway_item_name: props.takeAwayReducer.takeawayCartId
        ? getIDs("takeaway", "item_name")
        : "NA",
      coupon_name: props.paymentDetails.appliedCoupon || "NA",
      bill_payableamount: props.paymentDetails.combineCartData.total,
      order_type: ebo_order_type,
    });
  };

  const handlePayNow = () => {
    if (
      walletActive &&
      props.paymentDetails.combineCartData.total >
        props.paymentDetails.combineCartData.licious_wallet
          .payable_wallet_amount
    ) {
      if (!paymentMethod) {
        ToastMessage("Please select a mode to pay.");
      } else {
        if (paymentMethod === "cash") {
          setCashModal(true);
        } else {
          handleCreateOrder();
        }
      }
    } else if (!walletActive) {
      if (!paymentMethod) {
        ToastMessage("Please select a mode to pay.");
      } else {
        if (paymentMethod === "cash") {
          setCashModal(true);
        } else {
          handleCreateOrder();
        }
      }
    } else if (
      walletActive &&
      props.paymentDetails.combineCartData.total <=
        props.paymentDetails.combineCartData.licious_wallet
          .payable_wallet_amount
    ) {
      handleCreateOrder("wallet");
    }
  };
  const handleWalletHelper = () => {
    setIsUserVerfied(true);
    const walletAllowed =
      props.paymentDetails.combineCartData &&
      props.paymentDetails.combineCartData.licious_wallet
        .payable_wallet_amount > 0;
    const status = walletAllowed ? !walletActive : false;
    setWalletActive(status);
    if (
      status &&
      props.paymentDetails.combineCartData &&
      props.paymentDetails.combineCartData.licious_wallet
        .payable_wallet_amount < props.paymentDetails.combineCartData.total
    ) {
      setPaymentMethodsAllowed(true);
    } else if (
      status &&
      props.paymentDetails.combineCartData &&
      props.paymentDetails.combineCartData.licious_wallet
        .payable_wallet_amount >= props.paymentDetails.combineCartData.total
    ) {
      setPaymentMethodsAllowed(false);
    } else if (!status) {
      setPaymentMethodsAllowed(true);
    }
  };
  const handleWallet =  () => {
    if(isUserVerified || newCustomer || is_OTPverified ||
      props.paymentDetails.combineCartData &&
      props.paymentDetails.combineCartData.licious_wallet
        .payable_wallet_amount === 0){
      handleWalletHelper();
    }
    else{
      verifyUser();
      setShow(true);
      setCallbackFunction(() => handleWalletHelper);
    }
  }
  const handleContactlessDelivery = () => {
    props.updateContactlessDeliveryHandler({
      customer_key: props.otpReducer.customerDetails.customer_key,
      selected: !props.paymentDetails.contactLessDelivery,
    });
  };

  const startTimer = () => {
    let counter = 30;
    let interval = setInterval(() => {
      counter--;
      // Display 'counter' wherever you want to display it.
      if (counter <= 0) {
        setTimer(0);
        clearInterval(interval);
        setTimerID(null);
        return;
      } else {
        if (counter < 10 && counter > 0) {
          counter = "0" + counter;
        }
        setTimer("0:" + counter);
      }
    }, 1000);
    setTimerID(interval);
  };

  const verifyUser = () => {
    if(!apiCalled) {
      setAPICalledFlag(true);
      props.getOtpDetails({ phoneNumber: phone, verifyModal: setShow });
      setTimeout(() => {
        setAPICalledFlag(false);
      }, 60 * 1000);
    }
        setTimer(0);
        startTimer();
        clearInterval(timerId);
        setOtpValue("");
        setShow(true);
        triggerEvent({
          action_type: "auth_initiated",
          is_guest: false,
          id: id,
          customer_key: "",
          order_id: "",
          is_existing_customer: "",
          item_count_takeaway: "",
          item_count_delivery: "",
          attributes: {},
        });
      
  };

  const applyCouponCardHelper  = () => {
    setCouponModal(true);
    setCouponValue("");
  }
  
  const applyCouponCardHandler = () => {
      applyCouponCardHelper();  
  }

  const getIDs = (type, param) => {
    let ids = "";
    switch (type) {
      case "takeaway":
        props.takeAwayReducer &&
          props.takeAwayReducer.TotalCartItems &&
          props.takeAwayReducer.TotalCartItems.cartItems.forEach((item) => {
            if (param === "category_id") ids += `${item.productId};`;
            else if (param === "item_name") ids += `${item.productName};`;
            else ids += `${item.productId};`;
          });
        return ids.slice(0, -1); //removing ; in the end
      case "delivery":
        props.DeliveryOrderReducer.deliveryCart &&
          props.DeliveryOrderReducer.deliveryCart.products &&
          props.DeliveryOrderReducer.deliveryCart.products.forEach((item) => {
            if (param === "category_id") ids += `${item.category_id};`;
            else if (param === "item_name") ids += `${item.product_name};`;
            else ids += `${item.product_id};`;
          });
        return ids.slice(0, -1);
      default:
        return ids;
    }
  };
  const handleRemarks = (value) => {
    setRemarks(value);
  };
  return (
    <>
      {props.paymentDetails.combineCartDataLoading ? (
        <SmileyLoader />
      ) : (
        <>
          <div className="payment-details-step">
            <div className="payment-details-container">
              <>
                <div className="total-bill-container">
                  <div>
                    Total Bill: &#8377;
                    {props.paymentDetails.combineCartData
                      ? props.paymentDetails.combineCartData.total
                      : ""}
                  </div>
                  {props.otpReducer.customer_type !== "guest" &&
                    Object.keys(props.DeliveryOrderReducer.deliveryCart)
                      .length > 0 && (
                      <div>
                        Contactless Delivery &nbsp; &nbsp;
                        <Switch
                          active={props.paymentDetails.contactLessDelivery}
                          callback={handleContactlessDelivery}
                        />
                      </div>
                    )}
                </div>
                {props.otpReducer.customer_type !== "guest" && (
                  <div className="loyality-program-text">Loyalty Programs</div>
                )}
                <div className="loyality-program-container" />
                {props.otpReducer.customer_type !== "guest" && (
                  <div className="promo-card-holder">
                    <Card>
                      <div className="card-type-holder">
                        <div className="card-title">
                          <IconTick />
                          <div>
                            <span style={{ fontWeight: "bold" }}>
                              Licious Wallet
                            </span>
                          </div>
                        </div>
                        <div>
                          <span style={{ color: "#cccccc" }}>Usable Cash:</span>
                          &#8377;
                          {props.paymentDetails.combineCartData &&
                            props.paymentDetails.combineCartData.licious_wallet
                              .payable_wallet_amount}
                        </div>
                      </div>
                      <div className="validity-holder">
                        <div>
                          Licious cash:
                          {props.paymentDetails.combineCartData &&
                            props.paymentDetails.combineCartData.licious_wallet
                              .transactional_balance}{" "}
                          | Bonus Cash :
                          {props.paymentDetails.combineCartData &&
                            props.paymentDetails.combineCartData.licious_wallet
                              .promotional_balance}
                        </div>
                        <div>
                          <Switch
                            active={walletActive}
                            callback={handleWallet}
                          />
                        </div>
                      </div>
                    </Card>

                    <MeatopiaCard />
                  </div>
                )}
                {!(
                  props.navigatingFrom &&
                  props.navigatingFrom === "order-status"
                ) ? (
                  <div className="promo-card-holder">
                    <Card
                      onOptionClick={() => {
                        applyCouponCardHandler()
                      }}
                    >
                      <div className="card-type-holder">
                        <div className="card-title coupon-card-title">
                          <IconDiscount />
                          <div className="card-value">
                            <div style={{ fontWeight: "bold" }}>
                              {props.paymentDetails.appliedCoupon
                                ? props.paymentDetails.appliedCoupon
                                : "Apply Coupon"}
                            </div>
                            <IconDelete
                              onClick={(event) => {
                                event.stopPropagation();
                                if (props.paymentDetails.appliedCoupon) {
                                  const data = {};
                                  if (props.takeAwayReducer.takeawayCartId) {
                                    data["offline_cart_id"] =
                                      props.takeAwayReducer.takeawayCartId;
                                  }
                                  if (
                                    props.DeliveryOrderReducer.deliveryCartId
                                  ) {
                                    data["online_cart_id"] =
                                      props.DeliveryOrderReducer.deliveryCartId;
                                  }
                                  props.removeCoupon({
                                    coupon_code: couponValue,
                                    customer_key:
                                      props.otpReducer.customerDetails
                                        .customer_key,
                                    data: data,
                                  });
                                  props.clearCouponCode();
                                  setCouponModal(false);
                                  setCouponValue("");
                                  setCouponApplied(false);
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>
                ) : null}
                <div className="loyality-program-text">
                  Select Payment Method
                </div>
                <div className="payment-methods">
                  <div>
                    <Card
                      onOptionClick={() => {
                        setPaymentMethod("cash");
                      }}
                    >
                      {paymentMethodsAllowed && paymentMethod === "cash" ? (
                        <CheckedRadio />
                      ) : (
                        <UncheckedRadio />
                      )}
                      <div>Cash</div>
                    </Card>
                  </div>
                  <div>
                    <Card onOptionClick={() => setPaymentMethod("others")}>
                      {paymentMethodsAllowed && paymentMethod === "others" ? (
                        <CheckedRadio />
                      ) : (
                        <UncheckedRadio />
                      )}
                      <div>Others</div>
                    </Card>
                  </div>
                </div>
                <ConfirmButton
                  className="pay-now-button"
                  onClick={() => {
                    if (!props.navigatingFrom) {
                      triggerEvent({
                        action_type: "payment_initiated",
                        is_guest:
                          props.otpReducer.customer_type === "guest"
                            ? true
                            : false,
                        order_id: props.paymentDetails.orderData.order_id,
                        is_existing_customer:
                          props.otpReducer.customer_type !== "guest" &&
                          !props.otpReducer.new_customer
                            ? true
                            : false,
                        attributes: {},
                        item_count_takeaway:
                          props.takeAwayReducer &&
                          props.takeAwayReducer.cartData &&
                          props.takeAwayReducer.cartData.cartItems &&
                          props.takeAwayReducer.cartData.cartItems.length
                            ? props.takeAwayReducer.cartData.cartItems.length
                            : "",
                        item_count_delivery:
                          props.DeliveryOrderReducer &&
                          props.DeliveryOrderReducer.deliveryCart &&
                          props.DeliveryOrderReducer.deliveryCart.products &&
                          props.DeliveryOrderReducer.deliveryCart.products
                            .length
                            ? props.DeliveryOrderReducer.deliveryCart.products
                                .length
                            : "",
                        customer_key:
                          props.otpReducer.customerDetails.customer_key,
                        id: props.otpReducer.uid,
                      });
                    }
                    if (!props.paymentDetails.createOrderLoading) {
                      handlePayNow();
                    }
                  }}
                >
                  {props.paymentDetails.createOrderLoading ? (
                    <Loader />
                  ) : !props.navigatingFrom ? (
                    "Pay Now"
                  ) : (
                    "Retry Payment"
                  )}
                </ConfirmButton>
                {/* {paymentMethod === "cash" ? (
              <>
                <div className="awaiting-confirmation-text">
                  Awaiting Confirmation...
                </div>
                <ConfirmButton className="cash-instead-button">
                  Take Cash Insted
                </ConfirmButton>
              </>
            ) : null} */}
              </>
            </div>

            <GenericModal
              className="cash-modal"
              show={cashModal}
              cancel={setCashModal}
            >
              <div className="total-bill-amount">
                &#8377;
                {props.paymentDetails.showRetryModal
                  ? props.paymentDetails.paymentMethod.amount_to_deduct
                  : props.paymentDetails.combineCartData && walletActive
                  ? props.paymentDetails.combineCartData.total -
                    props.paymentDetails.combineCartData.licious_wallet
                      .payable_wallet_amount
                  : props.paymentDetails.combineCartData &&
                    props.paymentDetails.combineCartData.total}{" "}
                | Enter Cash Amount
              </div>
              <div className="cash-option-container">
                {Object.keys(denominations).map((item, index) => (
                  <Card
                    className={classnames({
                      "cash-denominations":
                        Object.keys(activeDenominations).length &&
                        activeDenominations[item] &&
                        activeDenominations[item].unit > 0,
                    })}
                    key={`note-of-${item}-${index}`}
                    onOptionClick={() => {
                      if (!Object.keys(activeDenominations).includes(item)) {
                        handleCashIn(item, "add");
                      }
                    }}
                  >
                    {Object.keys(activeDenominations).length &&
                    activeDenominations[item] &&
                    activeDenominations[item].unit > 0 ? (
                      <div
                        className={classnames({
                          "cash-denominations-modify-active":
                            Object.keys(activeDenominations).length &&
                            activeDenominations[item] &&
                            activeDenominations[item].unit > 0,
                        })}
                        onClick={() => handleCashIn(item, "remove")}
                      >
                        <FontAwesomeIcon
                          icon={faMinus}
                          className={classnames("cash-denominations-modify", {
                            "cash-denominations-modify-active":
                              Object.keys(activeDenominations).length &&
                              activeDenominations[item] &&
                              activeDenominations[item].unit > 0,
                          })}
                        />
                      </div>
                    ) : null}
                    <div className="denomination-figure">&#8377;{item}</div>
                    {Object.keys(activeDenominations).length &&
                    activeDenominations[item] &&
                    activeDenominations[item].unit > 0 ? (
                      <div
                        className={classnames({
                          "cash-denominations-modify-active":
                            Object.keys(activeDenominations).length &&
                            activeDenominations[item] &&
                            activeDenominations[item].unit > 0,
                        })}
                        onClick={() => handleCashIn(item, "add")}
                      >
                        <FontAwesomeIcon
                          icon={faPlus}
                          className={classnames("cash-denominations-modify", {
                            "cash-denominations-modify-active":
                              Object.keys(activeDenominations).length &&
                              activeDenominations[item] &&
                              activeDenominations[item].unit > 0,
                          })}
                        />
                      </div>
                    ) : null}
                  </Card>
                ))}
              </div>
              <div className="cash-details-container">
                <div className="cash-details-holder">
                  <div>Cash In</div>
                  <div className="cashIn-input">
                    <input value={cashIn} />
                    <IconDelete
                      className="clear-cashIn-icon"
                      onChange={(e) => {
                        setActiveDenominations({});
                        setCashIn(e.target.value);
                      }}
                      onClick={() => {
                        setCashIn(0);
                        setCashOut(0);
                        setPending(0);
                        setActiveDenominations({});
                      }}
                    />
                  </div>
                  <div className="calculation-holder">
                    {Object.keys(activeDenominations).map((key, index) => (
                      <>
                        {activeDenominations[key].unit > 0 && (
                          <div className="denominations-calculation-pills">
                            {key} * {activeDenominations[key].unit}
                          </div>
                        )}
                        <div>
                          {index !== Object.keys(activeDenominations).length - 1
                            ? `+`
                            : null}
                        </div>
                      </>
                    ))}
                  </div>
                </div>
                <div>
                  <div className="cash-details-holder">
                    <div>Cash Out</div>
                    <input value={cashOut} />
                  </div>
                  <div className="cash-details-holder">
                    <div>Pending</div>
                    <input value={pending} />
                  </div>
                </div>
              </div>
              <div className="cash-payment-remark-holder">
                <textarea
                  placeholder="Enter Remarks"
                  value={remarks}
                  onChange={(e) => handleRemarks(e.target.value)}
                />
              </div>

              <button
                className={classnames("confirm-button", {
                  // active: cashIn >= props.paymentDetails.combineCartData.total,
                })}
                onClick={() => {
                  let total = walletActive
                    ? Math.abs(
                        props.paymentDetails.combineCartData.total -
                          props.paymentDetails.combineCartData.licious_wallet
                            .payable_wallet_amount
                      )
                    : props.paymentDetails.combineCartData.total;

                  if (cashIn >= total) {
                    handleCreateOrder();
                    setCashModal(false);
                  }
                  // setShowConfirmation(true);
                }}
              >
                Submit
              </button>
            </GenericModal>

            <GenericModal
              className="coupon-modal"
              show={couponModal}
              cancel={setCouponModal}
            >
              <input
                value={couponValue}
                onChange={(e) => setCouponValue(e.target.value)}
              />
              <ConfirmButton
                onClick={() => {
                  const data = {};
                  if (props.paymentDetails.orderType === "TO")
                    data["offline_cart_id"] =
                      props.takeAwayReducer.takeawayCartId;
                  else if (props.paymentDetails.orderType === "DO")
                    data["online_cart_id"] =
                      props.DeliveryOrderReducer.deliveryCartId;
                  else {
                    data["offline_cart_id"] =
                      props.takeAwayReducer.takeawayCartId;
                    data["online_cart_id"] =
                      props.DeliveryOrderReducer.deliveryCartId;
                  }

                  // if (
                  //   props.takeAwayReducer.takeawayCartId &&
                  //   props.paymentDetails.orderType
                  // ) {
                  //   data['offline_cart_id'] =
                  //     props.takeAwayReducer.takeawayCartId
                  // }
                  // if (props.DeliveryOrderReducer.deliveryCartId) {
                  //   data['online_cart_id'] =
                  //     props.DeliveryOrderReducer.deliveryCartId
                  // }
                  props.applyCoupon({
                    coupon_code: couponValue,
                    customer_key: props.otpReducer.customerDetails.customer_key,
                    data: data,
                  });
                  props.setCouponCode(couponValue);
                  setCouponApplied(true);
                  setCouponModal(false);
                }}
              >
                Apply
              </ConfirmButton>
            </GenericModal>
            {/* <PaymentModal setCashModal={setCashModal} /> */}
            <div className="add-to-cart-container">
              <AddToCart
                cartData={cartData}
                dummyCartData={dummyCartData}
                stepName="payment"
                walletActive={walletActive}
                walletUsableAmount={
                  props.paymentDetails.combineCartData &&
                  props.paymentDetails.combineCartData.licious_wallet
                    .payable_wallet_amount
                }
                // orderConfirmation={order}
              />
            </div>
            <OtpModal 
               show={show}
               setOtpValue={setOtpValue}
               setTimer={setTimer}
               phoneNumber={phone}
               otpValue={otpValue}
               timer={timer}
               isPaymentPage={true}
               callBackFunctionOnVerificatioin={callBackFunctionPostOTPVerification}
               history={props.history}
               verifyUser={verifyUser}
               setShow={setShow}
            />
          </div>
        </>
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  return state;
};
const mapDispatchToProps = (dispatch) => {
  return {
    getWalletDetails: (payload) => dispatch(getWalletDetails(payload)),
    applyCoupon: (payload) => dispatch(eboApplyCoupon(payload)),
    setCouponCode: (payload) => dispatch(setCouponCode(payload)),
    getCombinedCartData: (payload) => dispatch(getCombinedCartRequest(payload)),
    createOrderRequest: (payload) => dispatch(createOrderRequest(payload)),
    paymentSuccess: (payload) => dispatch(setPaymentPrgoress(payload)),
    updateContactlessDeliveryHandler: (payload) =>
      dispatch(updateContactlessDelivery(payload)),
    savePaymentMethod: (payload) => dispatch(setPaymentMethod(payload)),
    saveOrderType: (payload) => dispatch(setOrderType(payload)),
    retryPayment: (payload) => dispatch(retryPayment(payload)),
    removeCoupon: (payload) => dispatch(eboRemoveCoupon(payload)),
    clearCouponCode: () => dispatch(clearCouponCode()),
    clearAllTimerID: () => dispatch(clearAllTimerID()),
    getOtpDetails: (number) => dispatch(getOtp(number)),
    verifyOtpHandler: (payload) => dispatch(verifyOtp(payload)),
    setPollingTimerID: (payload) => dispatch(setPollingTimerID(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PaymentDetails);

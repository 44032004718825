import React, { useEffect } from "react";
import { connect } from "react-redux";
import AddToCart from "../../components/AddToCart/AddToCart";
import CatalogContainer from "../../components/CatalogContainer/CatalogContainer";
import "./MerchantOrder.scss";
import {
  getCatalog,
  updateFiltersRequest,
  dummyCartRequest,
  getCartRequest,
  setAddingToTakeawayCartLoad,
} from "../../actions/TakeAwayActions";
import CatalogDataSelector from "../../utils/CatalogDataSelector";
import GenericModal from "../../components/GenricModal/GenricModal";
import MerchantOrderModal from "../../components/Modals/MerchantOrderModal/MerchantOrderModal";
import SmileyLoader from "../../components/Loader/SmileyLoader";
const MerchantOrder = (props) => {
  const { categories } = props.takeAwayReducer;
  const CatalogData =
    props.takeAwayReducer.data &&
    CatalogDataSelector({ data: props.takeAwayReducer.data });
  const activeStore = props.login.storeId;
  const storeId =
    props.login.agentDetails &&
    props.login.agentDetails.organization.find(
      (item) => item.store_code === activeStore
    ).hub_id;
  const storeline =
    props.login.agentDetails &&
    props.login.agentDetails.organization.find(
      (item) => item.store_code === activeStore
    ).line2;
  const {
    searchData,
    cartData,
    pastOrders,
    dummyCartData,
    searchText,
  } = props.takeAwayReducer;
  const { customer_key } = props.otpReducer.customerDetails;

  // useEffect(() => {
  //   let getCartparams = {
  //     customer_key: customer_key,
  //   };
  //   props.getCartRequest({ params: getCartparams });
  //   props.setAddingToTakeawayCartLoad(true);
  // }, []);
  return (
    <div className="takeaway-order">
      {props.merchantOrder.isLoadingMerchantOrder ? <SmileyLoader /> : null}
      <div className="left-catalog-container">
        <CatalogContainer
          data={CatalogData}
          stepName="merchantorders"
          categories={categories}
          SearchProducts={props.SearchProducts}
          searchText={searchText}
          pastOrderData={pastOrders}
          updateTakeAwayCart={props.updateTakeAwayCart}
          customerKey={customer_key}
          hub_id={storeId}
          dummyCartData={dummyCartData}
          dummyCart={props.dummyCartRequest}
          searchData={searchData}
          FetchCatalogProducts={props.FetchCatalogProducts}
          storeName={storeline}
        />
      </div>
      <div className="right-cart-container">
        <AddToCart
          stepName="merchantorders"
          cartData={cartData}
          dummyCartData={dummyCartData}
          dummyCart={props.dummyCartRequest}
          hub_id={storeId}
          customerKey={customer_key}
        />
      </div>
      <GenericModal
        show={
          props.merchantOrder.order_status === "failed" ||
          props.merchantOrder.order_status === "success"
        }
        showCancel={false}
        cancel={() => {}}

        // className="modal-position"
      >
        <MerchantOrderModal history={props.history} />
      </GenericModal>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    FetchCatalogProducts: ({ params }) => {
      dispatch(getCatalog({ params }));
    },
    SearchProducts: ({ params }) => {
      dispatch(updateFiltersRequest({ params }));
    },
    dummyCartRequest: ({ dummyCartItems }) => {
      dispatch(dummyCartRequest({ dummyCartItems }));
    },
    getCartRequest: ({ params }) => {
      dispatch(getCartRequest({ params }));
    },
    setAddingToTakeawayCartLoad: (payload) => {
      dispatch(setAddingToTakeawayCartLoad(payload));
    },
  };
};

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps, mapDispatchToProps)(MerchantOrder);

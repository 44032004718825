import React from 'react'
import { faRupeeSign } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import GenericModal from '../GenricModal/GenricModal'

const CancelOrderModal = ({ modalActive, setModalActive, content }) => {
  return (
    <GenericModal
      show={modalActive}
      className={'confirm-cancel-order-modal'}
      cancel={setModalActive}
    >
      <div className="confirm-cancel-order-modal-heading">
        <div className="text-bold">Confim Cancellation</div>
        <div>Are you sure you want to cancel?</div>
        <div>Shipment ID: K50X6PGI</div>
      </div>
      <table>
        <tbody>
          <tr>
            <td className="text-bold" colSpan={3}>
              Chicken Drumsticks
            </td>
            <td>500gms |</td>
            <td>Qty:01</td>
            <td className="order-status-text text-bold" align="right">
              <FontAwesomeIcon icon={faRupeeSign} /> 200
            </td>
          </tr>
          <tr>
            <td className="text-bold" colSpan={3}>
              Chicken Drumsticks yydrtdxxr rhdytdyddytht
            </td>
            <td>500gms |</td>
            <td>Qty:01</td>
            <td className="order-status-text text-bold" align="right">
              <FontAwesomeIcon icon={faRupeeSign} /> 200
            </td>
          </tr>
        </tbody>
      </table>
      <div className="text-pink">
        * The complete amount will be refunded to customer’s Licious Wallet.
      </div>
      <div className="button-container">
        <button className="dismiss-button ">Dismiss</button>
        <button className="confirm-button ">Confirm</button>
      </div>
    </GenericModal>
  )
}

export default CancelOrderModal

export const removeItemFromCart = (
  product,
  gms,
  removeItemFromCartRequest,
  setAddingToTakeawayCartLoad,
  hubId,
  customer_key
) => {
  let params = {
    product_id: product.productId,
    hub_id: hubId.toString(),
    is_combo: product.is_combo,
    customer_key: customer_key,
  };
  if (product.is_combo) {
    params.combo_product_details = product.childProducts.map((item) => {
      return {
        product_id: item.product_id,
        actual_weight: item.actual_weight,
      };
    });
  } else {
    params.actual_weight =
      product.uom.toLowerCase() === "unit" ||
      product.uom.toLowerCase() === "units"
        ? "1"
        : gms.toString();
  }
  removeItemFromCartRequest({ params });
  setAddingToTakeawayCartLoad(true);
};

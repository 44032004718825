import { debounce } from "lodash";
export const searchLocality = debounce(
    (e,getAutocompleteLocality,customer_key,setLocalityDropdown) => {
      let params = {
        input: e,
        customer_key: customer_key,
      };
      getAutocompleteLocality({ params });
      setLocalityDropdown(true)
    },
    1000,
    { leading: false, trailing: true }
  );
import React, { useState } from "react";
import { connect } from "react-redux";
import "./PastOrders.scss";
import { format } from "date-fns";
import ProductCardForPastOrders from "../ProductCard/ProductCardForPastOrders";
function PastOrders(props) {
  // const [pastOrderCart, setPastOrderCart] = useState({ cartItems: [] });

  const deliveryCartData =
    (props.DeliveryOrderReducer &&
      props.DeliveryOrderReducer.deliveryCart.products) ||
    [];

  let deliveryCartItemsId = [];
  if (deliveryCartData.length) {
    deliveryCartData.forEach((item) =>
      deliveryCartItemsId.push({
        product_id: item.product_id,
        quantity: item.quantity,
      })
    );
  }
  // const handleMultipleAddToCart = (products) => {
  //   const { cartData } = props.takeAwayReducer;
  //   if (resetTakeawayCart) {
  //     pastOrderCart.cartItems = [];
  //     products.map((item) => {
  //       if (item.product_inventory.available_qt !== 0) {
  //         pastOrderCart.cartItems.push({
  //           productId: item.product_master.product_id,
  //           productName: item.product_master.pr_name,
  //           mrp: item.product_pricing.base_price,
  //         });
  //         let newData = { ...pastOrderCart };
  //         setPastOrderCart(newData);
  //       }
  //     });
  //   } else {
  //     products.map((item) => {
  //       if (item.product_inventory.available_qt !== 0) {
  //         pastOrderCart.cartItems.push({
  //           productId: item.product_master.product_id,
  //           productName: item.product_master.pr_name,
  //           mrp: item.product_pricing.base_price,
  //         });
  //         let newData = { ...pastOrderCart };
  //         setPastOrderCart(newData);
  //       }
  //     });
  //   }
  //   let combineData = [...cartData.cartItems, ...pastOrderCart.cartItems];
  //   props.setTotalItemsInCart({ cartItems: combineData });
  //   props.resetTakeawayCart(false);
  //   props.dummyCart({ dummyCartItems: pastOrderCart });
  // };
  return (
    <div className="pastorder">
      <div className="pastorder-container past-order-date-detail">
        <div className="past-order-date">
          {format(
            new Date(props.order.created_at.split(" ", 1)),
            "MMMM dd,yyyy"
          )}
        </div>
      </div>
      <div className="product-card-container">
        {props.order &&
          props.order.products.map((product) => {
            return (
              <ProductCardForPastOrders
                cardData={product}
                deliveryCartItemsId={deliveryCartItemsId}
                deliveryCartData={deliveryCartData}
                addToCart={(item) => props.addToCart(item)}
                stepName={props.stepName}
                dummyCart={props.dummyCart}
                dummyCartData={props.dummyCartData}
                pastOrderCart={props.pastOrderCart}
              />
            );
          })}
      </div>
      <div className="line" />
    </div>
  );
}
const mapStateToProps = (state) => {
  return state;
};
const PastOrdersConnect = connect(
  mapStateToProps,
  null
)(PastOrders);

export default PastOrdersConnect;

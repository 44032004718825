import {
  GET_CATALOG_SUCCESS,
  UPDATE_FILTER_SUCCESS,
  GET_PAST_ORDERS_SUCCESS,
  UPDATE_TAKEAWAY_CART_REQUEST,
  UPDATE_TAKEAWAY_CART_SUCCESS,
  UPDATE_TAKEAWAY_CART_FAILURE,
  REMOVE_ITEM_FROM_CART_SUCCESS,
  GET_DUMMY_CART_SUCCESS,
  GET_CART_SUCCESS,
  GET_CART_FAILED,
  GET_CATEGORY_SUCCESS,
  UPDATE_FILTER_REQUEST,
  SHOW_CONFIRM_MODAL,
  RESET_TAKEAWAY_CART,
  SET_CART_DATA_SUCCESS,
  SET_TOTAL_ITEMS_SUCCESS,
  REMOVE_ITEM_FROM_CART_FAILURE,
  RESET_TAKEAWAY_CART_SUCCESS,
  SET_TAKEAWAY_ORDER_LOADING,
  SET_ADDING_TO_TAKEAWAY_CART_LOAD,
  CLEAR_TAKEAWAY_DATA,
  SET_SCAN_ACTIVE_STATUS,
  SET_SCAN_VALUE,
} from '../actions/TakeAwayActions'
const initialState = {
  data: {},
  scanActive: false,
  categories: {},
  searchData: {},
  pastOrders: {},
  updateTakeawayCart: {},
  isUpdatingCart: false,
  isUpdatedCart: false,
  isFailureUpdatingCart: false,
  reorderUpdateCart: {},
  removeItemFromCartData: {},
  dummyCartData: { cartItems: [] },
  searchText: '',
  CustomerCartDataFromApi: {},
  isShowingConfirmModal: false,
  isResetTakeawayCart: false,
  cartData: {},
  TotalCartItems: {},
  removeItemFailed: false,
  failedCartProduct: {},
  resetCartSuccess: false,
  getCartSuccess: false,
  isLoading: false,
  addingToTakeawayCartLoad: false,
  removeItemSuccess: false,
  takeawayCartId: '',
  scanValue: '',
}
const TakeAwayReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CATALOG_SUCCESS: {
      return {
        ...state,
        data: action.payload,
      }
    }
    case UPDATE_FILTER_REQUEST: {
      return {
        ...state,
        searchText: action.payload.params.searchText,
      }
    }
    case UPDATE_FILTER_SUCCESS: {
      return {
        ...state,
        searchData: action.payload.searchEntities,
        searchText: action.payload.searchText,
      }
    }
    case GET_PAST_ORDERS_SUCCESS: {
      return {
        ...state,
        pastOrders: action.payload.orders.splice(0, 3), //in order to show only last 3 past orders
      }
    }
    case UPDATE_TAKEAWAY_CART_REQUEST: {
      return {
        ...state,
        updateTakeawayCart: {},
        isUpdatingCart: true,
        isUpdatedCart: false,
        isFailureUpdatingCart: false,
        failedCartProduct: {},
        getCartSuccess: false,
      }
    }
    case UPDATE_TAKEAWAY_CART_SUCCESS: {
      return {
        ...state,
        updateTakeawayCart: action.payload,
        isUpdatingCart: false,
        isUpdatedCart: true,
        isFailureUpdatingCart: false,
        failedCartProduct: {},
      }
    }

    case UPDATE_TAKEAWAY_CART_FAILURE: {
      return {
        ...state,
        updateTakeawayCart: {},
        isUpdatingCart: false,
        isUpdatedCart: false,
        isFailureUpdatingCart: true,
        failedCartProduct: action.payload,
      }
    }
    case GET_CATEGORY_SUCCESS: {
      return {
        ...state,
        categories: action.payload,
      }
    }
    case REMOVE_ITEM_FROM_CART_SUCCESS: {
      return {
        ...state,
        removeItemFromCartData: action.payload,
        removeItemSuccess: true,
        getCartSuccess: false,
      }
    }
    case REMOVE_ITEM_FROM_CART_FAILURE: {
      return {
        ...state,
        removeItemSuccess: false,
      }
    }
    case GET_DUMMY_CART_SUCCESS: {
      return {
        ...state,
        dummyCartData: action.payload.dummyCartItems,
        failedCartProduct: {},
        isResetTakeawayCart: false,
      }
    }
    case GET_CART_SUCCESS: {
      return {
        ...state,
        getCartSuccess: true,
        resetCartSuccess: false,
        takeawayCartId: action.payload.cart_id,
        CustomerCartDataFromApi: action.payload.ebo_products,
        removeItemSuccess: false,
        addingToTakeawayCartLoad: false,
      }
    }
    case GET_CART_FAILED: {
      let dummyCartData = { ...state.dummyCartData }
      return {
        ...state,
        getCartSuccess: false,
        updateTakeawayCart: {},
        CustomerCartDataFromApi: {},
        TotalCartItems: dummyCartData,
        cartData: {},
        addingToTakeawayCartLoad: false,
        takeawayCartId: '',
      }
    }
    case SHOW_CONFIRM_MODAL: {
      return {
        ...state,
        isShowingConfirmModal: action.payload,
      }
    }
    case RESET_TAKEAWAY_CART: {
      return {
        ...state,
        isResetTakeawayCart: true,
      }
    }
    case SET_CART_DATA_SUCCESS: {
      return {
        ...state,
        cartData: action.payload,
        isUpdatedCart: false,
      }
    }
    case SET_TOTAL_ITEMS_SUCCESS: {
      return {
        ...state,
        TotalCartItems: action.payload,
      }
    }
    case RESET_TAKEAWAY_CART_SUCCESS: {
      return {
        ...state,
        TotalCartItems: [],
        dummyCartData: { cartItems: [] },
        resetCartSuccess: true,
      }
    }
    case SET_TAKEAWAY_ORDER_LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      }
    }
    case SET_ADDING_TO_TAKEAWAY_CART_LOAD: {
      return {
        ...state,
        addingToTakeawayCartLoad: action.payload,
      }
    }
    case SET_SCAN_ACTIVE_STATUS: {
      return {
        ...state,
        scanActive: action.payload,
      }
    }
    case SET_SCAN_VALUE: {
      return {
        ...state,
        scanValue: action.payload,
      }
    }
    case CLEAR_TAKEAWAY_DATA: {
      return {
        ...state,
        ...initialState,
      }
    }

    default:
      return state
  }
}

export default TakeAwayReducer

import React from "react";
import "./Card.scss";
import classnames from "classnames";

const Card = (props) => {
  return (
    <div
      className={classnames("ebo-dashboard-card", props.className, {
        "active-dashboard-card": props.active,
      })}
      onClick={props.onOptionClick ? () => props.onOptionClick() : () => {}}
    >
      {props.children}
    </div>
  );
};
export default Card;

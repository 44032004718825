import React from "react";
import { connect } from "react-redux";
import { addToMerchantCart } from "../../actions/MerchantOrderActions";
import CancelIcon from "../../assets/cancel_icon.png";
const MerchantCart = (props) => {
  const handleAddToMerchantCart = (product, action) => {
    const items = [...props.merchantOrder.products];

    const foundIndex = items.findIndex((item) => item.id === product.id);
    if (action === "add") {
      items[foundIndex]["qty"] = items[foundIndex]["qty"] + 1;
      items[foundIndex]["total_price"] += items[foundIndex]["base_total_price"];
      items[foundIndex]["price"] += items[foundIndex]["base_price"];
      items[foundIndex]["discount"] += items[foundIndex]["base_discount"];
    } else {
      items[foundIndex]["qty"] = items[foundIndex]["qty"] - 1;
      items[foundIndex]["total_price"] -= items[foundIndex]["base_total_price"];
      items[foundIndex]["price"] -= items[foundIndex]["base_price"];
      items[foundIndex]["discount"] -= items[foundIndex]["base_discount"];
    }
    if (items[foundIndex]["qty"] === 0) {
      items.splice(foundIndex, 1);
    }
    props.addItemToMerchantCart(items);
  };
  return (
    <>
      {props.merchantOrder &&
        props.merchantOrder.products &&
        props.merchantOrder.products.length && (
          <div className="delivery-cart">
            <div className="heading-content">
              <div className="heading">
                Merchant Order &nbsp;(
                {props.merchantOrder.products.length} Items)
              </div>

              <button
                className="reset-btn"
                onClick={(e) => {
                  props.addItemToMerchantCart([]);
                }}
              >
                Reset Cart
              </button>
            </div>
          </div>
        )}
      <div className="product-item-container">
        {props.merchantOrder &&
          props.merchantOrder.products &&
          props.merchantOrder.products.length &&
          props.merchantOrder.products.map((product) => (
            <div className="each-product-entry" key={product.id}>
              <div className="each-product-entry-details">
                <div>{product.productName}</div>

                <img
                  src={CancelIcon}
                  className="cancel-icon"
                  alt=""
                  onClick={(e) => {
                    handleAddToMerchantCart(product, "remove");
                  }}
                />
              </div>
              <div className="each-product-entry-price-details-holder">
                <div className="price-container">
                  <div className="delivery-price-container">
                    <div className="price-styles">
                      &nbsp;&nbsp;&#x20b9;
                      {parseFloat(product.total_price).toFixed(2)}
                    </div>
                  </div>
                </div>
                {props.stepName !== "payment" && (
                  <div className="product-quantity">
                    <div
                      // className={classnames({
                      //   disabled: parseInt(item.quantity) <= 0,
                      // })}
                      onClick={() => {
                        handleAddToMerchantCart(product, "remove");
                      }}
                    >
                      &#8211;
                    </div>
                    <div>{product.qty}</div>
                    <div
                      // className={classnames({
                      // //   disabled: parseInt(item.stock) <= 0,
                      // })}
                      onClick={() => {
                        handleAddToMerchantCart(product, "add");
                      }}
                    >
                      &#43;
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
      </div>
      <div className="total-view">
        <div className="heading">Merchant Order Total</div>

        <div className="heading">
          &#x20b9;
          {props.merchantOrder.products.reduce((a, b) => {
            return b.total_price + a;
          }, 0)}
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return state;
};
const mapDispatchToProps = (dispatch) => {
  return {
    addItemToMerchantCart: (payload) => {
      dispatch(addToMerchantCart(payload));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MerchantCart);

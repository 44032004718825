import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { ReactComponent as ScanBarcode } from "../../assets/new-ebo-icons/whites/Icon-Scan.svg";
import { ReactComponent as RadioIcon } from "../../assets/new-ebo-icons/Icon-Tick.svg";
import { ReactComponent as UncheckedRadio } from "../../assets/new-ebo-icons/whites/Icon-Tick.svg";
import "./ScanModal.scss";
import Loader from "../Loader/Loader";
import { updateTakeAwayCartRequest } from "../../actions/TakeAwayActions";
import classnames from "classnames";
import { API_getProduct } from "../../services/APIServices";
import GenericModal from "../GenricModal/GenricModal";
import { setDeliveryOrderLoading } from "../../actions/DeliveryOrderActions";
import ToastMessage from "../../utils/ToastMessage";

const ScanModal = (props) => {
  const activeStore = props.login.storeId;
  const hubId =
    props.login.agentDetails &&
    props.login.agentDetails.organization.find(
      (item) => item.store_code === activeStore
    ).hub_id;
  const [products, setProducts] = useState(null);

  const [addProductLoading, setAddProductLoading] = useState(false);
  const [contentLoading, setContentLoading] = useState(false);

  const [active, setActive] = useState(false);
  const [gms, setGms] = useState("");
  const getProductName = (product_id) =>
    API_getProduct({
      product_id: product_id,
      hub_id: hubId,
    });
  useEffect(() => {
    setContentLoading(true);
    setProducts(null);
    setGms("");

    setActive(false);
    if (
      props.productForScan &&
      props.productForScan.is_combo &&
      props.productForScan.combo_products.length
    ) {
      let childs = [];
      let quantity = props.productForScan.combo_products.reduce(
        (a, b) => a + b.quantity,
        0
      );
      props.productForScan.combo_products.forEach((product) => {
        const childQuatity = new Array(product.quantity).fill(0);

        childQuatity.forEach(async (child) => {
          let pr = await getProductName(product.product_id);

          childs.push({
            ...product,
            product_name: pr.data.product_master.pr_name,
            quantity: 1,
            actual_weight:
              pr.data.product_master.uom.toLowerCase() === "pieces"
                ? pr.data.product_pricing.unit_gram
                : pr.data.product_master.uom.toLowerCase() === "unit"
                ? pr.data.product_pricing.unit_gram
                : "",
            uom: pr.data.product_master.uom,
          });
          if (quantity === childs.length) {
            setProducts(childs);
            setContentLoading(false);
          }
        });
      });
    } else {
      setContentLoading(false);
    }
    if (props.productForScan) {
      if (
        props.productForScan.uom.toLowerCase() === "pieces" ||
        props.productForScan.uom.toLowerCase() === "unit"
      ) {
        setActive(true);
      }
    }
  }, [props.productForScan]);

  useEffect(() => {
    if (products && products.length) {
      let flag = false;
      let allProductsUom = products.map((item) => {
        if (item.uom === "Pieces" || item.uom === "Unit") flag = true;
        else if (item.uom === "Kg") flag = false;
      });
      flag && setActive(flag);
    }
  }, [products]);
  const handleGrammage = (value, index) => {
    if (index !== undefined) {
      const productsTemp = [...products];
      productsTemp[index].actual_weight = value;
      let statusActive = productsTemp.filter(
        (item) => item.actual_weight === ""
      );

      if (statusActive.length === 0) {
        setActive(true);
      } else {
        setActive(false);
      }
      setProducts(productsTemp);
    } else {
      if (value) {
        setActive(true);
      } else {
        setActive(false);
      }
      setGms(value);
    }
  };

  const AddItemToCart = () => {
    const net = props.productForScan.net.split("gms");
    const netWgt = parseInt(net[0]);
    if (products && products.length) {
      let flag = true;
      {
        // products.map((product) => {
        //   if (
        //     product.uom === "Kg" &&
        //     product.actual_weight < netWgt - (netWgt * 50) / 100
        //   ) {
        //     {
        //       flag = false;
        //       ToastMessage("Weight is too less for " + product.product_name);
        //     }
        //   } else if (
        //     product.uom === "Kg" &&
        //     product.actual_weight > netWgt + (netWgt * 25) / 100
        //   ) {
        //     flag = false;
        //     ToastMessage("Weight is too high for " + product.product_name);
        //   }
        // });
        if (flag) {
          setAddProductLoading(true);
          let params = {
            product_id: props.productForScan.productId,
            hub_id: hubId.toString(),
            customer_key: props.customerKey,
            is_combo: props.productForScan.is_combo,
          };
          params.combo_product_details = products.map((product) => ({
            product_id: product.product_id,
            actual_weight: product.actual_weight,
          }));

          props.updateTakeAwayCartRequest({
            params,
            setAddProductLoading: setAddProductLoading,
            closeModal: props.setShow,
            filterDummyDataAfterUpdate: props.filterDummyDataAfterUpdate,
          });
        }
      }
    } else {
      if (
        !props.productForScan.is_combo &&
        props.productForScan.uom === "Kg" &&
        gms < netWgt - (netWgt * 50) / 100
      ) {
        ToastMessage("Weight is too less");
      } else if (
        !props.productForScan.is_combo &&
        props.productForScan.uom === "Kg" &&
        gms > netWgt + (netWgt * 25) / 100
      ) {
        ToastMessage("Weight is too high");
      } else {
        setAddProductLoading(true);
        let params = {
          product_id: props.productForScan.productId,
          hub_id: hubId.toString(),
          customer_key: props.customerKey,
          is_combo: props.productForScan.is_combo,
        };
        params.actual_weight = gms ? gms : props.productForScan.net;
        params.quantity = 1;
        props.updateTakeAwayCartRequest({
          params,
          setAddProductLoading: setAddProductLoading,
          closeModal: props.setShow,
          filterDummyDataAfterUpdate: props.filterDummyDataAfterUpdate,
        });
      }
    }
  };

  const getAllComboProducts = () => {
    return (
      <>
        {products &&
          products.map((product, index) => (
            <>
              <div className="combo-child-product-name">
                <RadioIcon />
                <div>{product.product_name}</div>
              </div>
              <div
                className={classnames({
                  withCombo: props.productForScan.is_combo,
                })}
              >
                <input
                  placeholder={"Product ID"}
                  className="input-type"
                  disabled
                  value={product.product_id}
                />

                <div
                  className="input-container"
                  uom={product.uom === "Kg" ? "gms" : product.uom}
                >
                  <input
                    type="text"
                    placeholder="Actual Weight"
                    className="input-type"
                    onChange={(event) => {
                      if (
                        !(
                          product.uom.toLowerCase() === "pieces" ||
                          product.uom.toLowerCase() === "unit"
                        )
                      ) {
                        const value = event.target.value;
                        if (!isNaN(value) || value === "") {
                          handleGrammage(value, index);
                        }
                      }
                    }}
                    value={product.actual_weight}
                  />
                </div>
              </div>
            </>
          ))}
      </>
    );
  };
  return (
    <GenericModal
      show={props.show}
      cancel={props.setShow}
      className="modal-main-padding"
    >
      <div className="scan-modal">
        <div className="add-manually">
          <p className="title">Add Weight Manually</p>
          {contentLoading ? (
            <div className={"scan-content-loading"}>
              <Loader />
            </div>
          ) : (
            <>
              {products && products.length ? (
                <>{getAllComboProducts()}</>
              ) : (
                <>
                  <input
                    placeholder={"Product ID"}
                    className="input-type"
                    disabled
                    value={
                      props.productForScan && props.productForScan.productId
                    }
                  />
                  <div
                    className="input-container"
                    uom={
                      props.productForScan.uom === "Kg"
                        ? "gms"
                        : props.productForScan.uom
                    }
                  >
                    <input
                      type="text"
                      placeholder="Actual Weight"
                      className="input-type"
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          AddItemToCart();
                        }
                      }}
                      onChange={(event) => {
                        if (
                          !(
                            props.productForScan.uom.toLowerCase() ===
                              "pieces" ||
                            props.productForScan.uom.toLowerCase() === "unit"
                          )
                        ) {
                          const value = event.target.value;
                          if (!isNaN(value) || value === "") {
                            handleGrammage(value);
                          }
                        }
                      }}
                      value={
                        props.productForScan.uom.toLowerCase() === "pieces"
                          ? props.productForScan.net
                          : props.productForScan.uom.toLowerCase() === "unit"
                          ? "1"
                          : gms
                      }
                    />
                  </div>
                </>
              )}
            </>
          )}

          <button
            className="add-button"
            onClick={() => AddItemToCart()}
            disabled={!active}
          >
            {addProductLoading ? <Loader /> : "Add Item"}
          </button>
        </div>
      </div>
    </GenericModal>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateTakeAwayCartRequest: (payload) => {
      dispatch(updateTakeAwayCartRequest(payload));
    },
  };
};
const mapStateToProps = (state) => {
  return state;
};
const ScanModalConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(ScanModal);

export default ScanModalConnect;

import styled from "styled-components";
import { css } from "styled-components";
import "../../../variable.scss";
export const MerchantStoreStyled = styled.div`
  select {
    border: 1px solid #cccccc;
    border-radius: 4px;
    padding-left: 10px;
    background-color: #fff;
    font-weight: 500;
    font-size: 15px;
    outline:0;
  }
  .logged-in-header {
    padding: 30px 18px 10px;
    position: relative;
    height: 40px;
    .empty-div {
      width: 20%;
    }
    display: flex;
    align-items: center;
    .display-title {
      font-size: 18px;
      font-weight: 600;
      color: #8c94a8;
      width: 100%;
      text-align: center;
    }
    .city-dropdown {
      //width: 40%;
      position: absolute;
      right: 20px;
      bottom: 10px//text-align: right;
        label {
        font-size: 13px;
        font-weight: 500;
        color: #54698d;
        padding-bottom: 5px;
        display: block;
      }
      select {
        //width: 40%;
        height: 36px;
      }
    }
  }
  .store-select-container {
    background-color: #fff;
    width: 40%;
    border: 1px solid #cccccc;
    border-radius: 0.5rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    margin: 0rem auto;
  }
  .merchant-select-title {
    text-align: center;
    font-weight: 600;
    font-size: 1.5rem;
    color: #16325c;
    margin: 7.5rem;
    margin-bottom: 2rem;
  }
  .store-selection-wrapper {
    padding: 30px 0px;
    .merchant-store-select,
    .merchant-name-select {
      padding-bottom: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      select {
        height: 40px;
        flex: 1;
      }
      label {
        font-size: 13px;
        font-weight: 500;
        color: #000000;
        padding-bottom: 5px;
        display: block;
        flex: 0 10%;
      }
    }
  }
  .store-select-done {
    text-align: center;
    .confirm-button {
      height: 40px;
      border-radius: 4px;
      border: none;
      background-color: #cccccc;
      color: #fff;
      outline: 0;
      padding: 0 2rem;
      font-weight: 600;
      font-size: 18px;
      // width: 100%;
      &.active {
        background-color: #ed0e0e;
      }
    }
  }
`;

import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { isLoading } from '../../actions/CommonActions'
import {
  removeProductFromDeliveryCart,
  setAddingToDeliveryCartLoad,
  updateDeliveryCartRequest,
} from '../../actions/DeliveryOrderActions'
import {
  resetTakeawayCart,
  setTotalItemsInCart,
  dummyCartRequest,
} from '../../actions/TakeAwayActions'
import ScooterIcon from '../../assets/new-ebo-icons/Scooter_express.png'
import Loader from '../Loader/Loader'
import classnames from 'classnames'
import './ProductCard.scss'
import { handleDeliveryCartUpdate, handleAddToCart } from './CardUtilFunctions'
import { parse } from '@fortawesome/fontawesome-svg-core'
import { round } from 'lodash'

const ProductCardForPastOrders = (props) => {
  const [dummyCartItems, setDummyCart] = useState(props.pastOrderCart)
  const [addingToCartLoad, setAddingToCartLoad] = useState(false)
  const { cartData, dummyCartData, isResetTakeawayCart } = props.takeAwayReducer
  return (
    <>
      {props.cardData ? (
        <div className="product-card">
          <div>
            <img
              src={props.cardData.product_merchantdising.pr_image}
              className="product-img"
              alt={'product'}
            />
          </div>
          <div className="product-card-details">
            <div className="product-title">
              {props.cardData.product_master.pr_name}
            </div>

            <div className="weight-styles">
              Gross:{props.cardData.product_master.gross} | Net:
              {props.cardData.product_master.net}
            </div>
            <div className="product-card-footer">
              <p className="discount-price-styles">
                &#x20b9;
                {round(
                  parseFloat(
                    props.cardData.product_pricing.base_price -
                      (props.cardData.product_pricing.base_price *
                        props.cardData.product_pricing.base_discount) /
                        100,
                  ).toFixed(2),
                )}
              </p>
              {round(
                parseFloat(
                  props.cardData.product_pricing.base_price -
                    (props.cardData.product_pricing.base_price *
                      props.cardData.product_pricing.base_discount) /
                      100,
                ).toFixed(2),
              ) !== props.cardData.product_pricing.base_price && (
                <p className="base-price-styles">
                  MRP:&#x20b9;{props.cardData.product_pricing.base_price}
                </p>
              )}
              {props.cardData.product_pricing.base_discount ? (
                <p className="discount_percent">
                  {props.cardData.product_pricing.base_discount}% OFF
                </p>
              ) : null}

              {props.stepName !== 'delivery' ? (
                <button
                  className="catalog-add-to-cart-button"
                  onClick={() => {
                    if (
                      parseInt(
                        props.cardData.product_inventory.available_qt,
                      ) !== 0 &&
                      !addingToCartLoad
                    ) {
                      handleAddToCart(
                        props.cardData.product_master.product_id,
                        props.cardData.product_master.pr_name,
                        round(
                          parseFloat(
                            props.cardData.product_pricing.base_price -
                              (props.cardData.product_pricing.base_price *
                                props.cardData.product_pricing.base_discount) /
                                100,
                          ).toFixed(2),
                        ),
                        round(
                          parseFloat(
                            props.cardData.product_pricing.base_price -
                              (props.cardData.product_pricing.base_price *
                                props.cardData.product_pricing.base_discount) /
                                100,
                          ).toFixed(2),
                        ),
                        props.cardData.is_combo,
                        props.cardData.is_combo
                          ? props.product.combo_products
                          : [],
                        props.cardData.product_master.uom,
                        props.cardData.product_master.net,
                        setAddingToCartLoad,
                        props.dummyCart,
                        props.resetCartItems,
                        props.setTotalItemsInCart,
                        setDummyCart,
                        dummyCartItems,
                        isResetTakeawayCart,
                        dummyCartData,
                        cartData,
                      )
                    }
                  }}
                  disabled={
                    parseInt(props.cardData.product_inventory.available_qt) ===
                    0
                      ? true
                      : false
                  }
                >
                  {addingToCartLoad ? <Loader /> : 'Add to Cart'}
                </button>
              ) : (
                <>
                  {props.deliveryCartItemsId.filter(
                    (prod) =>
                      prod.product_id ===
                      props.cardData.product_inventory.product_id,
                  ).length > 0 ? (
                    <div className="product-quantity" data-prod={props.item}>
                      <div
                        onClick={() => {
                          props.setDeliveryOrderLoading(true)
                          handleDeliveryCartUpdate(
                            'remove',
                            props.cardData.product_inventory.product_id,
                            props.deliveryCartItemsId.filter(
                              (prod) =>
                                prod.product_id ===
                                props.cardData.product_inventory.product_id,
                            )[0].quantity,
                            props.cardData.product_inventory.hub_id,
                            props.deliveryCartData,
                            props,
                            // setAddingToDeliveryCartLoad,
                          )
                        }}
                      >
                        &#8211;
                      </div>
                      <div>
                        {
                          props.deliveryCartItemsId.filter(
                            (prod) =>
                              prod.product_id ===
                              props.cardData.product_inventory.product_id,
                          )[0].quantity
                        }
                      </div>
                      <div
                        className={classnames({
                          disabled:
                            parseInt(
                              props.cardData.product_inventory.stock_units,
                            ) <= 0,
                        })}
                        onClick={() => {
                          if (
                            parseInt(
                              props.cardData.product_inventory.stock_units,
                            ) !== 0
                          ) {
                            handleDeliveryCartUpdate(
                              'add',
                              props.cardData.product_inventory.product_id,
                              props.deliveryCartItemsId.filter(
                                (prod) =>
                                  prod.product_id ===
                                  props.cardData.product_inventory.product_id,
                              )[0].quantity,
                              props.cardData.product_inventory.hub_id,
                              props.deliveryCartData,
                              props,
                              setAddingToCartLoad,
                            )
                          }
                        }}
                      >
                        &#43;
                      </div>
                    </div>
                  ) : (
                    <button
                      className="catalog-add-to-cart-button"
                      onClick={() =>
                        handleDeliveryCartUpdate(
                          'add',
                          props.cardData.product_inventory.product_id,
                          0,
                          props.cardData.product_inventory.hub_id,
                          props.deliveryCartData,
                          props,
                          setAddingToCartLoad,
                        )
                      }
                      disabled={
                        (props.cardData.product_inventory.stock_units > 0) ||
                        (props.cardData.product_inventory.slot_custom_message!=="All slots full" && props.cardData.product_inventory.slot_available!==2) ||
                        (props.cardData.product_inventory.stock_units!=0 && props.cardData.product_inventory.next_available_by!=="Out of Stock")
                          ? false
                          : true
                      }
                    >
                      {addingToCartLoad ? <Loader /> : 'Add to Cart'}
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
          {props.stepName === 'delivery' && (
            <div className="delivery-time-detail">
              <div>
                <img src={ScooterIcon} alt="scooter-delivery" />
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    props.cardData.product_inventory.slot_custom_message !== ''
                      ? props.cardData.product_inventory.slot_custom_message
                      : props.cardData.product_inventory &&
                        props.cardData.product_inventory.next_available_by
                      ? props.cardData.product_inventory.next_available_by
                      : '',
                }}
              />
            </div>
          )}
        </div>
      ) : null}
    </>
  )
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetCartItems: (value) => {
      dispatch(resetTakeawayCart(value))
    },
    setTotalItemsInCart: (payload) => {
      dispatch(setTotalItemsInCart(payload))
    },
    updateDeliveryOrder: (payload) => {
      dispatch(updateDeliveryCartRequest(payload))
    },
    removeProductFromDelivery: (payload) => {
      dispatch(removeProductFromDeliveryCart(payload))
    },
    setDataLoading: (payload) => {
      dispatch(isLoading(payload))
    },
    setDeliveryOrderLoading: (payload) => {
      dispatch(setAddingToDeliveryCartLoad(payload))
    },
    dummyCartRequest: (payload) => {
      dispatch(dummyCartRequest(payload))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProductCardForPastOrders)

import Cookies from "js-cookie";
import { select, put, takeEvery, call, fork } from "redux-saga/effects";
import {
  DO_LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  FETCH_HUBS_BY_CITY_ID,
  FETCH_ALL_HUBS_SUCCESS,
  FETCH_ALL_HUBS_FAILURE,
} from "../actions/LoginActions";
import {
  SEARCH_USER_BY_PHONE,
  SEARCH_USER_SUCCESS,
  SEARCH_USER_FAILURE,
  SAVER_USER_SUCCESS,
  SAVER_USER_FAILED,
  UPDATE_ADDR_SUCCESS,
  UPDATE_ADDR_FAILED,
  UPDATE_USER_ADDR,
  SAVER_USER_DETAILS,
} from "../actions/AddUserActions";
import { CUSTOMER_ALL_ADDRESS } from "../actions/DeliveryOrderActions";
import {
  GET_ALL_CATALOGS,
  GET_ALL_CATALOGS_SUCCESS,
  GET_ALL_CATALOGS_FAILED,
  UPDATE_CART,
  UPDATE_CART_SUCCESS,
  UPDATE_CART_FAILED,
  SHIP_DETAILS_SUCCESS,
  SHIP_DETAILS_FAILED,
  CREATE_ORDER,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_FAILED,
  APPLY_COUPON,
  APPLY_COUPON_SUCCESS,
  APPLY_COUPON_FAILED,
  REMOVE_COUPON,
  REMOVE_COUPON_SUCCESS,
  REMOVE_COUPON_FAILED,
  UPDATE_SHIP_SLOT,
  UPDATE_SHIP_SLOT_SUCCESS,
  UPDATE_SHIP_SLOT_FAILED,
  MERGE_SHIPMENT,
  MERGE_SHIPMENT_SUCCESS,
  MERGE_SHIPMENT_FAILED,
  DELETE_CART_ITEM,
  DELETE_CART_ITEM_SUCCESS,
  DELETE_CART_ITEM_FAILED,
  CLEAR_CUSTOMER_CART,
  APPLY_COUPON_EBO,
  REMOVE_COUPON_EBO,
} from "../actions/ProductListActions";

import {
  DATA_LOADING,
  SET_PROGRESS_STEP,
  UPDATE_WALLET_DETAILS,
  STOCK_RELEASE,
} from "../actions/CommonActions";
import { GET_COMBINE_CART_REQUEST } from "../actions/DeliveryOrderActions";
//import { revealSelector } from '../selectors';,
// import POSOnboardSagas from '../pos-offline/onboard/sagas'
// import POSProductScanSagas from '../pos-offline/scanning/sagas'
// import POSProductBillingSagas from '../pos-offline/billing/sagas'
// import POSPaymentSagas from '../pos-offline/payment/sagas'
// import POSWrapperSagas from '../pos-merchant-wrapper/sagas'
// import CancelOrderSagas from '../pos-offline/order-cancellation/sagas'
// import LocalitySearchBoxSagas from '../locality-search-box/sagas'
// import OrderListSagas from '../components/order-list/sagas'
import { getSelectedPOSMerchantOption } from "../pos-merchant-wrapper/selectors";
import TakeAwayOrderSagas from "../sagas/TakeAwayOrderSagas";
import DeliveryOrderSagas from "../sagas/DeliveryOrderSagas";

import {
  API_AssistantLogin,
  API_GetCatalogs,
  API_GetAllHubsByCity,
  API_SearchUser,
  API_UpdateCart,
  API_SaveUserDetails,
  API_UpdateAddress,
  API_GetShipmentDetails,
  API_CreateOrder,
  API_ApplyCoupon,
  API_RemoveCoupon,
  API_UpdateShipmentSlots,
  API_MergeShipmentSlots,
  API_GetOrders,
  API_UpdateOrderStatus,
  API_DeleteItemFromCart,
  API_ClearCustomerCart,
  API_GetOtp,
  API_VerifyOtp,
  API_SaveNewCustomerDetails,
  API_GetAddresses,
  API_SaveAddress,
  API_GetWalletDetails,
  API_ApplyCouponNewEbo,
  API_StoreDetails,
  API_LoyalityBenefits,
  API_ReleaseStock,
  API_GetCustomerDetails,
} from "../services/APIServices";
import {
  REQUEST_ORDER_LIST,
  REQUEST_ORDER_LIST_SUCCESS,
  REQUEST_ORDER_LIST_FAILURE,
  REQUEST_UPDATE_ORDER_STATUS,
  REQUEST_UPDATE_ORDER_STATUS_SUCCESS,
  REQUEST_UPDATE_ORDER_STATUS_FAILURE,
} from "../actions/OrderListActions";
import historyInstance from "../utils/history";
import ToastMessage from "../utils/ToastMessage";

import ErrorHandlerUtil from "../utils/ErrorHandlerUtil";
import {
  SAVE_CUSTOMER_DETAILS,
  SAVE_CUSTOMER_STATUS,
  SAVE_NEW_CUSTOMER_DETAILS,
  GET_OTP,
  VERIFY_OTP,
  SAVE_CUSTOMER_TYPE,
  SAVE_STORE_INFO,
  GET_LOYALITY_BENEFITS,
  SAVE_LOYALITY_BENEFITS,
  VERIFY_OTP_LOADING,
  PROCEED_WITHOUT_OTP,
  VERIFY_OTP_FOR_WALLET_AND_COUPON,
} from "../actions/OtpActions";
import {
  GET_CUSTOMER_ADDRESS,
  GET_WALLET_DETAILS,
  SAVE_USER_ADDR,
} from "../actions/CommonActions";
import PaymentSaga from "../sagas/PaymentSaga";
import {
  CustomerProfile,
  CustomerDetailConFirmed,
  UserVerified,
  CustomerSignUpSuccess,
} from "../utils/cleverTap";
import { CLEAR_COUPON_APPLIED } from "../actions/PaymentActions";
import { RESET_TAKEAWAY_CART } from "../actions/TakeAwayActions";
import { triggerEvent } from "../utils/segment";

function* getOrders(action) {
  const errMsg = "Failed to get order list";
  try {
    let response = yield call(API_GetOrders, action.payload);
    if (response.statusMessage === "success") {
      const selectedMerchantOption = yield select(getSelectedPOSMerchantOption);
      const isPOSSelected = selectedMerchantOption === "posRetail";
      const filteredOrders = getPOSOrMerchantOrders(
        isPOSSelected,
        response.data
      );
      //yield put({type: REQUEST_ORDER_LIST_SUCCESS, payload: response.data});
      yield put({ type: REQUEST_ORDER_LIST_SUCCESS, payload: filteredOrders });
    } else {
      //const errorMessage = (response && response.statusMessage)? response.statusMessage: ''
      ToastMessage(errMsg);
      yield put({ type: REQUEST_ORDER_LIST_FAILURE });
    }
  } catch (error) {
    ToastMessage(errMsg);
    yield put({ type: REQUEST_ORDER_LIST_FAILURE });
  }
}

const getPOSOrMerchantOrders = (isPOSSelected, list) => {
  if (isPOSSelected) {
    return list.filter((item) => item.source === "liciousretailpos");
  } else {
    return list.filter((item) => item.source === "Merchant-kiosk");
  }
};

function* getOrdersSaga() {
  yield takeEvery(REQUEST_ORDER_LIST, getOrders);
}

function* updateOrdersStatus(action) {
  const errMsg = "Failed to update the order status";
  try {
    let response = yield call(API_UpdateOrderStatus, action.payload);
    if (response.statusMessage === "success") {
      const { store_code } = JSON.parse(localStorage.storeDetails);
      yield put({ type: REQUEST_UPDATE_ORDER_STATUS_SUCCESS });
    } else {
      //const errorMessage = (response && response.statusMessage)? response.statusMessage: ''
      ToastMessage(errMsg);
      yield put({ type: REQUEST_UPDATE_ORDER_STATUS_FAILURE });
    }
  } catch (error) {
    ToastMessage(errMsg);
    yield put({ type: REQUEST_UPDATE_ORDER_STATUS_FAILURE });
  }
}

function* updateOrdersStatusSaga() {
  yield takeEvery(REQUEST_UPDATE_ORDER_STATUS, updateOrdersStatus);
}

function* userLogin(action) {
  const errMsg = "Failed to login";
  try {
    let response = yield call(API_AssistantLogin, action.value);
    if (response.status == "success") {
      const { token } = response.data;
      if (token) {
        Cookies.set("accessToken", token, { expires: 7 });
        historyInstance.push("/merchant-selection");
      }
      yield put({ type: LOGIN_SUCCESS, data: response.data });
      // const agentPermissions = CommonUitls.getAgentPermissions(
      //   response.data.permissions
      // );
      // yield put({ type: AGENT_PERMISSIONS, data: agentPermissions });
    } else {
      const errorMessage = response.statusMessage
        ? response.statusMessage
        : errMsg;
      ToastMessage(errorMessage);
      yield put({ type: LOGIN_FAILURE, data: response });
      Cookies.remove("accessToken");
    }
  } catch (error) {
    ToastMessage(errMsg);
    yield put({ type: LOGIN_FAILURE, data: error });
  }
}

function* loginAssitsant() {
  yield takeEvery(DO_LOGIN, userLogin);
}

function* fetchAllHubsByID(action) {
  const errMsg = "Failed to get HUB details";
  try {
    let resposne = yield call(API_GetAllHubsByCity, action.value);
    if (resposne.status === "success") {
      yield put({ type: FETCH_ALL_HUBS_SUCCESS, data: resposne.data });
    } else {
      //const errorMessage = resposne.statusMessage? resposne.statusMessage: ''
      ToastMessage(errMsg);
      yield put({ type: FETCH_ALL_HUBS_FAILURE, data: resposne });
    }
  } catch (error) {
    ToastMessage(errMsg);
    yield put({ type: FETCH_ALL_HUBS_FAILURE, data: error });
  }
}

function* fetchAllHubs() {
  yield takeEvery(FETCH_HUBS_BY_CITY_ID, fetchAllHubsByID);
}

function* searchUser(action) {
  try {
    let resposne = yield call(API_SearchUser, action.value);
    if (resposne.status === "success") {
      yield put({ type: SEARCH_USER_SUCCESS, data: resposne.data });
    } else {
      //const errorMessage = (resposne && resposne.statusMessage)? resposne.statusMessage: ''
      //ToastMessage(errorMessage)
      yield put({ type: SEARCH_USER_FAILURE, data: resposne });
    }
  } catch (error) {
    //ToastMessage()
    yield put({ type: SEARCH_USER_FAILURE, data: error });
  }
}

function* searchUserByPhone() {
  yield takeEvery(SEARCH_USER_BY_PHONE, searchUser);
}

function* getProductCatalogs(action) {
  const errMsg = "Failed to get catalogs";
  try {
    let resposne = yield call(API_GetCatalogs, action.value);
    if (resposne.statusCode === 200) {
      yield put({ type: GET_ALL_CATALOGS_SUCCESS, data: resposne.data });
    } else {
      //const errorMessage = (resposne && resposne.statusMessage)? resposne.statusMessage: ''
      ToastMessage(errMsg);
      yield put({ type: GET_ALL_CATALOGS_FAILED, data: resposne });
    }
  } catch (error) {
    ToastMessage(errMsg);
    yield put({ type: GET_ALL_CATALOGS_FAILED, data: error });
  }
}

function* getCatalogs() {
  yield takeEvery(GET_ALL_CATALOGS, getProductCatalogs);
}

function* updateCartWithProducts(action) {
  const errMsg = "Failed to update cart";
  try {
    let resposne = yield call(API_UpdateCart, action.value);
    if (resposne.status == "success") {
      yield put({ type: UPDATE_CART_SUCCESS, data: resposne }); //response format is like this
    } else {
      //const errorMessage = (resposne && resposne.statusMessage)? resposne.statusMessage: ''
      if (ErrorHandlerUtil.showToastMsg(resposne)) {
        ToastMessage(errMsg);
      }
      yield put({ type: UPDATE_CART_FAILED, data: resposne });
    }
  } catch (error) {
    if (ErrorHandlerUtil.showToastMsg(error)) {
      ToastMessage(errMsg);
    }
    yield put({ type: UPDATE_CART_FAILED, data: error });
  }
}

function* updateCart() {
  yield takeEvery(UPDATE_CART, updateCartWithProducts);
}

function* getShippingDetails(action) {
  const errMsg = "Failed to get shipment details";
  try {
    let resposne = yield call(API_GetShipmentDetails, action.value);
    if (resposne.statusCode == 200) {
      yield put({ type: SHIP_DETAILS_SUCCESS, data: resposne.data }); //response format is like this
    } else {
      //const errorMessage = (resposne && resposne.statusMessage)? resposne.statusMessage: ''
      if (ErrorHandlerUtil.showToastMsg(resposne)) {
        ToastMessage(errMsg);
      }
      yield put({ type: SHIP_DETAILS_FAILED, data: resposne });
    }
  } catch (error) {
    if (ErrorHandlerUtil.showToastMsg(error)) {
      ToastMessage(errMsg);
    }
    yield put({ type: SHIP_DETAILS_FAILED, data: error });
  }
}

// function* getShipmentDetails() {
//   yield takeEvery(GET_SHIP_DETAILS, getShippingDetails);
// }

function* saveUserDetails(action) {
  const errMsg = "Failed to add user";
  try {
    let resposne = yield call(API_SaveUserDetails, action.value);
    if (resposne.status == "success") {
      yield put({ type: SAVER_USER_SUCCESS, data: resposne.data });
    } else {
      //const errorMessage = (resposne && resposne.statusMessage)? resposne.statusMessage: ''
      ToastMessage(errMsg);
      yield put({ type: SAVER_USER_FAILED, data: resposne });
    }
  } catch (error) {
    ToastMessage(errMsg);
    yield put({ type: SAVER_USER_FAILED, data: error });
  }
}

function* saveUser() {
  yield takeEvery(SAVER_USER_DETAILS, saveUserDetails);
}

function* updateUserAddress(action) {
  const errMsg = "Failed to update user address";
  try {
    let response = yield call(API_UpdateAddress, action.value);
    if (response.statusMessage == "success") {
      yield put({ type: UPDATE_ADDR_SUCCESS, data: response });
      yield put({ type: GET_CUSTOMER_ADDRESS, payload: action });
    } else {
      //const errorMessage = (resposne && resposne.statusMessage)? resposne.statusMessage: ''
      ToastMessage(errMsg);
      yield put({ type: UPDATE_ADDR_FAILED, data: response });
    }
  } catch (error) {
    ToastMessage(errMsg);
    yield put({ type: UPDATE_ADDR_FAILED, data: error });
  }
}

function* saveAddress() {
  yield takeEvery(UPDATE_USER_ADDR, updateUserAddress);
}

function* orderCreate(action) {
  const errMsg = "Failed to create order";
  try {
    let resposne = yield call(API_CreateOrder, action.value);
    if (resposne.status == "success") {
      yield put({ type: CREATE_ORDER_SUCCESS, data: resposne });
    } else {
      //const errorMessage = (resposne && resposne.statusMessage)? resposne.statusMessage: ''
      // if (ErrorHandlerUtil.showToastMsg(resposne)) {
      // 	ToastMessage(errMsg)
      // }
      yield put({ type: CREATE_ORDER_FAILED, data: resposne });
    }
  } catch (error) {
    // if (ErrorHandlerUtil.showToastMsg(error)) {
    // 	ToastMessage(errMsg)
    // }
    yield put({ type: CREATE_ORDER_FAILED, data: error });
  }
}

function* createOrder() {
  yield takeEvery(CREATE_ORDER, orderCreate);
}

function* applyCouponCode(action) {
  try {
    let resposne = yield call(API_ApplyCoupon, action.value);
    if (resposne.status == "success") {
      yield put({ type: APPLY_COUPON_SUCCESS, data: resposne });
    } else {
      //const errorMessage = (resposne && resposne.statusMessage)? resposne.statusMessage: ''
      //ToastMessage(errorMessage)
      yield put({ type: APPLY_COUPON_FAILED, data: resposne });
    }
  } catch (error) {
    //ToastMessage()
    yield put({ type: APPLY_COUPON_FAILED, data: error });
  }
}

function* applyCoupon() {
  yield takeEvery(APPLY_COUPON, applyCouponCode);
}

function* removeCouponCode(action) {
  try {
    let resposne = yield call(API_RemoveCoupon, action.value);
    if (resposne.status == "success") {
      yield put({ type: REMOVE_COUPON_SUCCESS, data: resposne });
    } else {
      //const errorMessage = (resposne && resposne.statusMessage)? resposne.statusMessage: ''
      ToastMessage("error");
      yield put({ type: REMOVE_COUPON_FAILED, data: resposne });
    }
  } catch (error) {
    //ToastMessage()
    yield put({ type: REMOVE_COUPON_FAILED, data: error });
  }
}

function* removeCoupon() {
  yield takeEvery(REMOVE_COUPON, removeCouponCode);
}

function* updateShipmentDetails(action) {
  const errMsg = "Failed to update shipment details";
  try {
    let resposne = yield call(API_UpdateShipmentSlots, action.value);
    if (resposne.statusCode == 200) {
      yield put({ type: UPDATE_SHIP_SLOT_SUCCESS, data: resposne.data });
    } else {
      //const errorMessage = (resposne && resposne.statusMessage)? resposne.statusMessage: ''
      if (ErrorHandlerUtil.showToastMsg(resposne)) {
        ToastMessage(errMsg);
      }
      yield put({ type: UPDATE_SHIP_SLOT_FAILED, data: resposne });
    }
  } catch (error) {
    if (ErrorHandlerUtil.showToastMsg(error)) {
      ToastMessage(errMsg);
    }
    yield put({ type: UPDATE_SHIP_SLOT_FAILED, data: error });
  }
}

function* updateShipmentSlots() {
  yield takeEvery(UPDATE_SHIP_SLOT, updateShipmentDetails);
}

function* mergeShipmentDetails(action) {
  const errMsg = "Failed to perform merge shipment";
  try {
    let resposne = yield call(API_MergeShipmentSlots, action.value);
    if (resposne.statusCode == 200) {
      yield put({ type: MERGE_SHIPMENT_SUCCESS, data: resposne.data });
    } else {
      //const errorMessage = (resposne && resposne.statusMessage)? resposne.statusMessage: ''
      if (ErrorHandlerUtil.showToastMsg(resposne)) {
        ToastMessage(errMsg);
      }
      yield put({ type: MERGE_SHIPMENT_FAILED, data: resposne });
    }
  } catch (error) {
    if (ErrorHandlerUtil.showToastMsg(error)) {
      ToastMessage(errMsg);
    }
    yield put({ type: MERGE_SHIPMENT_FAILED, data: error });
  }
}

function* mergeShipment() {
  yield takeEvery(MERGE_SHIPMENT, mergeShipmentDetails);
}

function* deleteItem(action) {
  let errMsg = "Failed to remove item from cart";
  try {
    let resposne = yield call(API_DeleteItemFromCart, action.value);
    if (resposne.status == "success") {
      yield put({ type: DELETE_CART_ITEM_SUCCESS, data: resposne });
    } else {
      if (!!resposne.message || resposne.statusCode === 504) {
        errMsg = resposne.message ? resposne.message : resposne.statusMessage;
      }
      ToastMessage(errMsg);
      yield put({ type: DELETE_CART_ITEM_FAILED, data: resposne });
    }
  } catch (error) {
    if (!!error.message) {
      errMsg = error.message;
    }
    ToastMessage(errMsg);
    yield put({ type: DELETE_CART_ITEM_FAILED, data: error });
  }
}

function* deleteItemFromCart() {
  yield takeEvery(DELETE_CART_ITEM, deleteItem);
}

function* clearCustomerCart(action) {
  let errMsg = "Failed to clear the customer cart";
  try {
    let resposne = yield call(API_ClearCustomerCart, action);
    if (resposne.status == "success") {
      //yield put({type: CLEAR_CUSTOMER_CART_SUCCESS, data: resposne});
    } else {
      if (!!resposne.message || resposne.statusCode === 504) {
        errMsg = resposne.message ? resposne.message : resposne.statusMessage;
      }
      ToastMessage(errMsg);
      //yield put({type: CLEAR_CUSTOMER_CART_FAILED, data: resposne});
    }
  } catch (error) {
    if (!!error.message) {
      errMsg = error.message;
    }
    ToastMessage(errMsg);
    //yield put({type: CLEAR_CUSTOMER_CART_FAILED, data: error});
  }
}

function* clearCartItems() {
  yield takeEvery(CLEAR_CUSTOMER_CART, clearCustomerCart);
}
function* otpAssistant() {
  yield takeEvery(GET_OTP, getOtp);
}
function* otpVerifyAssistant() {
  yield takeEvery(VERIFY_OTP, verifyOtp);
}
function* getOtp(action) {
  try {
    let response = yield call(API_GetOtp, action.payload.phoneNumber);
    if (response.status == "success") {
      yield put({
        type: SAVE_CUSTOMER_STATUS,
        payload: response.data.new_customer,
      });
    } else if (response.statusCode === 504 || response.status === "error") {
      ToastMessage(
        response.statusMessage ? response.statusMessage : response.message
      );
      action.payload.verifyModal(false);
    }
  } catch {}
}
function* verifyOtp(action) {
  const loginDetails = yield select((state) => state.login);
  if (action.payload.user_type === "customer") {
    let errMsg = "Failed to verify Otp";
    const customerDetails = yield select((state) => state.otpReducer);

    try {
      let response = yield call(API_VerifyOtp, action.payload);
      response['is_OTPverified'] =  true;
      if (response.status === "success") {
        yield put({
          type: RESET_TAKEAWAY_CART,
          payload: { params: { customer_key: response.data.customer_key } },
        });
        triggerEvent({
          action_type: "auth_validation",
          customer_key: response.data.customer_key,
          order_id: "",
          is_existing_customer: response.data.is_repeat_user ? true : false,
          is_guest: false,
          id: customerDetails.uid,
          item_count_takeaway: "",
          item_count_delivery: "",
          attributes: {},
        });
        const cleverTapPayload = {
          customer_key: response.data.customer_key,
          city: loginDetails.agentDetails.organization.find(
            (item) => item.store_code === loginDetails.storeId
          ).city,
          city_id: loginDetails.agentDetails.organization.find(
            (item) => item.store_code === loginDetails.storeId
          ).city_id,
          store_id: loginDetails.storeId,
          user_type: customerDetails.new_customer
            ? "New User"
            : "Existing User", // taking directly from response
          phone: response.data.phone,
          email: response.data.email,
          name: response.data.name,
          status: "success",
          status_reason: "NA",
        };
        const cleverTapProfile = {
          identity: response.data.customer_key,
          email: response.data.email,
        };

        CustomerProfile(cleverTapProfile);
        UserVerified(cleverTapPayload);
        if (cleverTapPayload.user_type === "New user") {
          CustomerSignUpSuccess({
            ...cleverTapPayload,
            meatopia_status: "non_member",
          });
        }

        yield put({ type: SAVE_CUSTOMER_DETAILS, payload: response.data });
        yield put({ type: SAVE_CUSTOMER_TYPE, payload: "customer" });
        yield put({ type: VERIFY_OTP_LOADING, payload: false });
        action.payload.showModal(false);
        action.payload.history.push("/order-details");
        yield put({
          type: SET_PROGRESS_STEP,
          payload: 0,
        });
        // yield put({ type: DELETE_CART_ITEM_SUCCESS, data: response.data })
      } else {
        if (!!response.message || response.statusCode === 504) {
          errMsg = response.message ? response.message : response.statusMessage;
        }
        const loginDetails = yield select((state) => state.login);
        const cleverTapPayload = {
          customer_key: "",
          city: loginDetails.agentDetails.organization.find(
            (item) => item.store_code === loginDetails.storeId
          ).city,
          city_id: loginDetails.agentDetails.organization.find(
            (item) => item.store_code === loginDetails.storeId
          ).city_id,
          store_id: loginDetails.storeId,
          user_type: "",
          phone: "",
          email: "",
          name: "",
          status: "error",
          status_reason: "Invalid OTP",
        };
        UserVerified(cleverTapPayload);
        ToastMessage(errMsg);
        yield put({ type: VERIFY_OTP_LOADING, payload: false });
        //yield put({type: CLEAR_CUSTOMER_CART_FAILED, data: resposne});
      }
    } catch (error) {
      if (!!error.message) {
        errMsg = error.message;
      }

      const cleverTapPayload = {
        customer_key: "",
        city: loginDetails.agentDetails.organization.find(
          (item) => item.store_code === loginDetails.storeId
        ).city,
        city_id: loginDetails.agentDetails.organization.find(
          (item) => item.store_code === loginDetails.storeId
        ).city_id,
        store_id: loginDetails.storeId,
        user_type: "",
        phone: "",
        email: "",
        name: "",
        status: "error",
        status_reason: !!error.message
          ? error.message
          : "Verify OTP API failure",
      };

      UserVerified(cleverTapPayload);
      ToastMessage(errMsg);
      yield put({ type: VERIFY_OTP_LOADING, payload: false });
      action.payload.setShow(true);
      //yield put({type: CLEAR_CUSTOMER_CART_FAILED, data: error});
    }
  } else {
    let errMsg = "Failed to generate Guest customer key";

    try {
      // let response = yield call(API_GuestUserKey, action.payload)
      let storeInfo = yield call(API_StoreDetails, action.payload.storeId);
      // let storeInfo = yield call(API_StoreDetails, action.payload.storeId)
      if (storeInfo.statusMessage === "success") {
        yield put({
          type: SAVE_STORE_INFO,
          payload: storeInfo.data,
        });
        let userResp = yield call(API_SearchUser, {
          phoneNo: storeInfo.data.default_phone,
          cart_id: undefined,
        });
        if (userResp.status === "success") {
          yield put({
            type: RESET_TAKEAWAY_CART,
            payload: { params: { customer_key: userResp.data.customer_key } },
          });
          yield put({ type: SAVE_CUSTOMER_DETAILS, payload: userResp.data });
          yield put({ type: SAVE_CUSTOMER_TYPE, payload: "guest" });
          yield put({ type: VERIFY_OTP_LOADING, payload: false });
          // action.payload.showModal(false)
          action.payload.history.push("/order-details");
          yield put({
            type: SET_PROGRESS_STEP,
            payload: 0,
          });
          const cleverTapPayload = {
            customer_key: userResp.data.customer_key,
            city: loginDetails.agentDetails.organization.find(
              (item) => item.store_code === loginDetails.storeId
            ).city,
            city_id: loginDetails.agentDetails.organization.find(
              (item) => item.store_code === loginDetails.storeId
            ).city_id,
            store_id: loginDetails.storeId,
            user_type: "guest",
            phone: storeInfo.data.default_phone,
            email: "guest@licious.in",
            name: "guest",
            status: "success",
            status_reason: "NA",
          };

          UserVerified(cleverTapPayload);
        }
        // if (response.status == 'success') {
        //   yield put({
        //     type: SAVE_CUSTOMER_DETAILS,
        //     payload: { customer_key: response.data },
        //   })

        //   yield put({ type: SAVE_CUSTOMER_TYPE, payload: 'guest' })
        //   yield put({ type: DATA_LOADING, payload: false })
        //   action.payload.history.push('/order-details')
        //   yield put({
        //     type: SET_PROGRESS_STEP,
        //     payload: 0,
        //   })
        //   // yield put({ type: DELETE_CART_ITEM_SUCCESS, data: response.data })
        // } else {
        //   // if (!!response.message) {
        //   //   errMsg = storeInfo.message
        //   // }
        //   ToastMessage(errMsg)
        //   yield put({ type: DATA_LOADING, payload: false })
        //   //yield put({type: CLEAR_CUSTOMER_CART_FAILED, data: resposne});
        // }

        // yield put({ type: DELETE_CART_ITEM_SUCCESS, data: response.data })

        //  else {
        //   if (!!response.data.errorMessage) {
        //     errMsg = response.message
        //   }
        //   ToastMessage(errMsg)
        //   yield put({ type: DATA_LOADING, payload: false })
        //   //yield put({type: CLEAR_CUSTOMER_CART_FAILED, data: resposne});
        // }
      } else if (storeInfo.statusCode === 504) {
        ToastMessage(storeInfo.statusMessage);
      }
    } catch (error) {
      if (!!error.message) {
        errMsg = error.message;
      }
      ToastMessage(errMsg);
      yield put({ type: DATA_LOADING, payload: false });
      //yield put({type: CLEAR_CUSTOMER_CART_FAILED, data: error});
    }
  }
}

function* proceedWithoutOTP(action) {
  try{
    let response = {} ;
    const phone = action.payload.phoneNumber
    response = yield call(API_GetCustomerDetails, phone);
    if( !!response.statusMessage || response.status === 504 || response.status === 500){
       action.payload.verifyUser();
    }
    else{
      response['new_customer'] = false;
      yield put({
        type: RESET_TAKEAWAY_CART,
        payload: { params: { customer_key: response.customer_key } },
      });
      yield put({ type: SAVE_CUSTOMER_DETAILS, payload: response });
      yield put({ type: SAVE_CUSTOMER_TYPE, payload: "customer" });
      yield put({ type: VERIFY_OTP_LOADING, payload: false });
      action.payload.showModal(false);
      action.payload.history.push("/order-details");
      yield put({
        type: SET_PROGRESS_STEP,
        payload: 0,
      });
    }
  }
  catch(err){
    action.payload.verifyUser();
  }
}

function* verifyUserOTPforWalletAndCoupon(action){
  const loginDetails = yield select((state) => state.login);
  const customerDetails = yield select((state) => state.otpReducer);
  let errMsg = "Failed to verify OTP";
  try{
    let response = yield call(API_VerifyOtp, action.payload);
      if (response.status === "success") {
        triggerEvent({
          action_type: "auth_validation",
          customer_key: response.data.customer_key,
          order_id: "",
          is_existing_customer: response.data.is_repeat_user ? true : false,
          is_guest: false,
          id: customerDetails.uid,
          item_count_takeaway: "",
          item_count_delivery: "",
          attributes: {},
        });
        const cleverTapPayload = {
          customer_key: response.data.customer_key,
          city: loginDetails.agentDetails.organization.find(
            (item) => item.store_code === loginDetails.storeId
          ).city,
          city_id: loginDetails.agentDetails.organization.find(
            (item) => item.store_code === loginDetails.storeId
          ).city_id,
          store_id: loginDetails.storeId,
          user_type: customerDetails.new_customer
            ? "New User"
            : "Existing User", // taking directly from response
          phone: response.data.phone,
          email: response.data.email,
          name: response.data.name,
          status: "success",
          status_reason: "NA",
        };
        const cleverTapProfile = {
          identity: response.data.customer_key,
          email: response.data.email,
        };

        CustomerProfile(cleverTapProfile);
        UserVerified(cleverTapPayload);
        if (cleverTapPayload.user_type === "New user") {
          CustomerSignUpSuccess({
            ...cleverTapPayload,
            meatopia_status: "non_member",
          });
        }
        action.payload.showModal(false);
        action.payload.applyChanges();
      }
      else {
        if (!!response.message || response.statusCode === 504) {
          errMsg = response.message ? response.message : response.statusMessage;
        }
        const loginDetails = yield select((state) => state.login);
        const cleverTapPayload = {
          customer_key: "",
          city: loginDetails.agentDetails.organization.find(
            (item) => item.store_code === loginDetails.storeId
          ).city,
          city_id: loginDetails.agentDetails.organization.find(
            (item) => item.store_code === loginDetails.storeId
          ).city_id,
          store_id: loginDetails.storeId,
          user_type: "",
          phone: "",
          email: "",
          name: "",
          status: "error",
          status_reason: "Invalid OTP",
        };
        UserVerified(cleverTapPayload);
        ToastMessage(errMsg);
        yield put({ type: VERIFY_OTP_LOADING, payload: false });
        //yield put({type: CLEAR_CUSTOMER_CART_FAILED, data: resposne});
      }
    }
    catch(error){
      console.error("error",error);
    }
}

function* verifyUserOTPforWalletAndCouponAssistant(){
  yield takeEvery(VERIFY_OTP_FOR_WALLET_AND_COUPON,verifyUserOTPforWalletAndCoupon);
}

function* proceedWithoutOTPAssitant() {
  yield takeEvery(PROCEED_WITHOUT_OTP, proceedWithoutOTP);
}

function* newUserDetailsSaveAssistant() {
  yield takeEvery(SAVE_NEW_CUSTOMER_DETAILS, savenewcustomer);
}
function* savenewcustomer(action) {
  let errMsg = "Failed to save details";
  const customerDetails = yield select((state) => state.otpReducer);
  try {
    let response = yield call(API_SaveNewCustomerDetails, action.payload);
    if (response.status === "success") {
      const loginDetails = yield select((state) => state.login);
      const otpReducer = yield select((state) => state.otpReducer);
      const cleverTapPayload = {
        customer_key: response.data.customer_key,
        city: loginDetails.agentDetails.organization.find(
          (item) => item.store_code === loginDetails.storeId
        ).city,
        city_id: loginDetails.agentDetails.organization.find(
          (item) => item.store_code === loginDetails.storeId
        ).city_id,
        store_id: loginDetails.storeId,
        user_type: customerDetails.new_customer ? "New User" : "Existing User", // taking directly from response
        status: "success",
        phone: response.data.phone,
        email: response.data.email,
        name: response.data.name,
        meatopia_status: otpReducer.loyalityBenefits.is_subscribed
          ? "member"
          : "non_member",
      };

      CustomerDetailConFirmed(cleverTapPayload);
      // yield put({ type: SAVE_CUSTOMER_DETAILS, payload: response.data });
      // yield put({ type: DELETE_CART_ITEM_SUCCESS, data: response.data })
    } else {
      if (!!response.message || response.statusCode === 504) {
        errMsg = response.message ? response.message : response.statusMessage;
      }
      const loginDetails = yield select((state) => state.login);
      const otpReducer = yield select((state) => state.otpReducer);
      const cleverTapPayload = {
        customer_key: otpReducer.customerDetails.customer_key,
        city: loginDetails.agentDetails.organization.find(
          (item) => item.store_code === loginDetails.storeId
        ).city,
        city_id: loginDetails.agentDetails.organization.find(
          (item) => item.store_code === loginDetails.storeId
        ).city_id,
        store_id: loginDetails.storeId,
        user_type: customerDetails.new_customer ? "New User" : "Existing User", // taking directly from response
        status: "error",
        status_reason: errMsg,
        phone: action.payload.phone,
        email: action.payload.email,
        name: action.payload.name,
        meatopia_status: otpReducer.loyalityBenefits.is_subscribed
          ? "member"
          : "non_member",
      };

      CustomerDetailConFirmed(cleverTapPayload);
      ToastMessage(errMsg);
      //yield put({type: CLEAR_CUSTOMER_CART_FAILED, data: resposne});
    }
  } catch (error) {
    if (!!error.message) {
      errMsg = error.message;
    }
    const loginDetails = yield select((state) => state.login);
    const otpReducer = yield select((state) => state.otpReducer);
    const cleverTapPayload = {
      customer_key: otpReducer.customerDetails.customer_key,
      city: loginDetails.agentDetails.organization.find(
        (item) => item.store_code === loginDetails.storeId
      ).city,
      city_id: loginDetails.agentDetails.organization.find(
        (item) => item.store_code === loginDetails.storeId
      ).city_id,
      store_id: loginDetails.storeId,
      user_type: customerDetails.new_customer ? "New User" : "Existing User", // taking directly from response
      status: "error",
      status_reason: errMsg,
      phone: action.payload.phone,
      email: action.payload.email,
      name: action.payload.name,
      meatopia_status: otpReducer.loyalityBenefits.is_subscribed
        ? "member"
        : "non_member",
    };
    CustomerDetailConFirmed(cleverTapPayload);
    ToastMessage(errMsg);
    //yield put({type: CLEAR_CUSTOMER_CART_FAILED, data: error});
  }
}
function* getAddressAssistant() {
  yield takeEvery(GET_CUSTOMER_ADDRESS, getAddress);
}
function* getAddress(action) {
  let errMsg = "Failed to fetch address details";
  try {
    let response = yield call(API_GetAddresses, action.payload);
    if (response.statusCode === 200) {
      yield put({
        type: CUSTOMER_ALL_ADDRESS,
        data: {
          customerAllAddresses: response.data.address,
          defaultAddress: response.data.defaultaddress,
        },
      });
      // yield put({
      //   type: CUSTOMER_ADDRESS,
      //   data: response.data.defaultaddress[0],
      // });
      // yield put({ type: DELETE_CART_ITEM_SUCCESS, data: response.data })
    } else if (response.statusCode === 203 || response.statusCode === 504) {
      errMsg = response.data.errorMessage
        ? response.data.errorMessage
        : "error";
      ToastMessage(errMsg);
      //yield put({type: CLEAR_CUSTOMER_CART_FAILED, data: resposne});
    }
  } catch (error) {
    if (!!error.message) {
      errMsg = error.message;
    }
    ToastMessage(errMsg);
    //yield put({type: CLEAR_CUSTOMER_CART_FAILED, data: error});
  }
}
function* saveCusotmerAddress() {
  yield takeEvery(SAVE_USER_ADDR, saveUserAddress);
}

function* saveUserAddress(action) {
  let errMsg = "Failed to update user address";
  try {
    let resposne = yield call(API_SaveAddress, action.payload);
    if (resposne.statusCode === 200) {
      yield put({ type: UPDATE_ADDR_SUCCESS, data: resposne });
      yield put({
        type: GET_CUSTOMER_ADDRESS,
        payload: {
          customer_key: action.payload.data.customer_key,
          hubId: action.payload.hub_id,
        },
      });
    } else {
      errMsg = resposne.data.errorMessage
        ? resposne.data.errorMessage
        : resposne.statusMessage;

      ToastMessage(errMsg);
      yield put({ type: UPDATE_ADDR_FAILED, data: resposne });
    }
  } catch (error) {
    // ToastMessage(errMsg);

    yield put({ type: UPDATE_ADDR_FAILED, data: error });
  }
}

function* walletDetails() {
  yield takeEvery(GET_WALLET_DETAILS, getWalletDetails);
}
function* getWalletDetails(action) {
  const errMsg = "Failed to get wallet details";
  try {
    let resposne = yield call(API_GetWalletDetails, action.payload);
    if (resposne.status === "success") {
      yield put({ type: UPDATE_WALLET_DETAILS, data: resposne.licious_wallet });
    } else {
      ToastMessage(errMsg);
      yield put({ type: UPDATE_ADDR_FAILED, data: resposne });
    }
  } catch (error) {
    // ToastMessage(errMsg);

    yield put({ type: UPDATE_ADDR_FAILED, data: error });
  }
}

function* applyCouponDetails() {
  yield takeEvery(APPLY_COUPON_EBO, applyCouponDetailsEbo);
}

function* applyCouponDetailsEbo(action) {
  const errMsg = "Failed to apply coupon ";
  try {
    let resposne = yield call(API_ApplyCouponNewEbo, action.data);
    if (resposne.status === "success") {
      const customer_key = yield select(
        (state) => state.otpReducer.customerDetails.customer_key
      );
      const order_type = yield select(
        (state) => state.paymentDetails.orderType
      );
      yield put({
        type: GET_COMBINE_CART_REQUEST,
        payload: {
          customer_key: customer_key,
          type: order_type,
        },
      });
      ToastMessage(resposne.message);
    } else {
      errMsg = resposne.message ? resposne.message : resposne.statusMessage;
      ToastMessage(errMsg);
      yield put({ type: UPDATE_ADDR_FAILED });
      yield put({ type: CLEAR_COUPON_APPLIED });
    }
  } catch (error) {
    ToastMessage(errMsg);

    yield put({ type: UPDATE_ADDR_FAILED, data: error });
  }
}
function* removeCouponDetails() {
  yield takeEvery(REMOVE_COUPON_EBO, removeCouponDetailsEbo);
}

function* removeCouponDetailsEbo(action) {
  const errMsg = "Failed to remove coupon ";
  try {
    let resposne = yield call(API_RemoveCoupon, action.data);
    if (resposne.status === "success") {
      const customer_key = yield select(
        (state) => state.otpReducer.customerDetails.customer_key
      );
      const order_type = yield select(
        (state) => state.paymentDetails.orderType
      );
      yield put({
        type: GET_COMBINE_CART_REQUEST,
        payload: {
          customer_key: customer_key,
          type: order_type,
        },
      });
    } else {
      ToastMessage(errMsg);
      yield put({ type: UPDATE_ADDR_FAILED, data: resposne });
    }
  } catch (error) {
    // ToastMessage(errMsg);

    yield put({ type: UPDATE_ADDR_FAILED, data: error });
  }
}

function* getLoyalityBenefitsAssistant() {
  yield takeEvery(GET_LOYALITY_BENEFITS, getLoyalityBenefits);
}
function* getLoyalityBenefits(action) {
  const errMsg = "Failed to get loyality details";
  try {
    let resposne = yield call(API_LoyalityBenefits, action.payload);
    if (resposne.status === "success") {
      yield put({ type: SAVE_LOYALITY_BENEFITS, payload: resposne.data });
    } else {
      ToastMessage(errMsg);
      // yield put({ type: UPDATE_ADDR_FAILED, data: resposne });
    }
  } catch (error) {
    // ToastMessage(errMsg);

    yield put({ type: UPDATE_ADDR_FAILED, data: error });
  }
}
function* getStockReleaseSagas() {
  yield takeEvery(STOCK_RELEASE, getStockRelease);
}

function* getStockRelease(action) {
  const errMsg = "Failed to release the stock";
  try {
    let response = yield call(API_ReleaseStock, action.payload);
    if (response.status === "success") {
    } else {
      ToastMessage(response.message);
    }
  } catch (error) {
    yield put({ type: UPDATE_ADDR_FAILED, data: error });
  }
}
export default function* rootSaga() {
  yield [
    // fork(OrderListSagas),
    // fork(POSWrapperSagas),
    // fork(CancelOrderSagas),
    // fork(LocalitySearchBoxSagas),
    fork(loginAssitsant),
    fork(getCatalogs),
    fork(fetchAllHubs),
    fork(updateCart),
    fork(saveUser),
    fork(saveAddress),
    // fork(getShipmentDetails),
    fork(createOrder),
    fork(applyCoupon),
    fork(removeCoupon),
    fork(mergeShipment),
    fork(updateShipmentSlots),
    fork(searchUserByPhone),
    fork(getOrdersSaga),
    fork(deleteItemFromCart),
    fork(updateOrdersStatusSaga),
    // fork(POSOnboardSagas),
    // fork(POSProductBillingSagas),
    // fork(POSProductScanSagas),
    // fork(POSPaymentSagas),
    fork(clearCartItems),
    fork(otpAssistant),
    fork(otpVerifyAssistant),
    fork(TakeAwayOrderSagas),
    fork(newUserDetailsSaveAssistant),
    fork(getAddressAssistant),
    fork(saveCusotmerAddress),
    fork(walletDetails),
    fork(applyCouponDetails),
    fork(getLoyalityBenefitsAssistant),
    fork(DeliveryOrderSagas),
    fork(PaymentSaga),
    fork(getStockReleaseSagas),
    fork(removeCouponDetails),
    fork(proceedWithoutOTPAssitant),
    fork(verifyUserOTPforWalletAndCouponAssistant),
  ];
}

import { SET_PROGRESS_STEP } from "../actions/CommonActions";

const initialState = {
  orderJourneyActiveStep: null,
};
const OrderJourneyReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROGRESS_STEP: {
      return {
        ...state,
        orderJourneyActiveStep: action.payload,
      };
    }

    default:
      return state;
  }
};

export default OrderJourneyReducer;

import { APPLY_COUPON_EBO } from "../actions/ProductListActions";

const initialState = {
  couponDetails: null,
};
const CouponReducer = (state = initialState, action) => {
  switch (action.type) {
    case APPLY_COUPON_EBO:
      return state;

    default:
      return state;
  }
};

export default CouponReducer;

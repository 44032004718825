/**
 * Wrapper for fetch client
 * to handle auth token
 * it can send token and store it in LocalStorage
 */

//import Cookies from "js-cookie"
import Cookies from 'js-cookie'
import fetch from './fetchTimeOutWrapper'

export const NETWORK_OFFLINE = 'NETWORK_OFFLINE'
const STORAGE_KEY = 'accessToken'
const COOKIE_EXPIRATION_TIME = 14
const COOKIE_CONFIG = {
  expires: COOKIE_EXPIRATION_TIME,
  path: '/',
}

function createError(response) {
  return response
}

export function networkOffline() {
  return { type: NETWORK_OFFLINE }
}

function createHeader(headers) {
  // fetch current auth headers from storage
  //const accessToken = Cookies.get(STORAGE_KEY)
  const nextHeaders = headers || {}

  // Pick client from window or use default value as desktop
  // Todo : Remove one later.
  //   nextHeaders.client = window.client ? window.client : "desktop"
  //   nextHeaders["X-licious-Platform"] = window.client === "m-web" ? "mWeb" : "Desktop"

  nextHeaders['Content-Type'] = 'application/json'

  nextHeaders['token'] = Cookies.get('accessToken') //Cookies.get("access-token")
  //if (accessToken) nextHeaders.Authorization = `Bearer ${accessToken}`

  return nextHeaders
}

export function successHandler(response) {
  if (response.status !== 204) {
    return response
      .json()
      .then((json) => {
        // if (json && json.meta && json.meta.authentication_token) {
        //   Cookies.set(STORAGE_KEY, json.meta.authentication_token, COOKIE_CONFIG)
        // }

        return Promise.resolve(json)
      })
      .catch(() =>
        Promise.resolve({
          status: 'ok',
          data: null,
        }),
      )
  }
  return Promise.resolve({ status: 204 })
}

export function errorHandler(response) {
  if (response.status === 401) {
    Cookies.remove('accessToken') //Cookies.remove("accessToken")
  }

  // if (response.status === 422) {
  //   return response
  //     .json()
  //     .then(json => (
  //       Promise.reject(createError(response, json))
  //     ))
  // }

  return Promise.reject(createError(response))
}

export function fetchWrapper(url, options = {}, etagCacheKey) {
  try {
    const { headers, body } = options
    const updatedOptions = {
      ...options,
      headers: createHeader(headers),
    }
    if (body) {
      updatedOptions.body = JSON.stringify(body)
    }
    const constructedUrl = process.env.REACT_APP_NODE_SERVER_URL + url
    return fetch(constructedUrl, updatedOptions)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          if (etagCacheKey)
            localStorage.setItem(etagCacheKey, response.headers.get('Etag'))
          return successHandler(response)
        }
        return response.json()
      })
      .then((data) => {
        return data
      })
      .catch((error) => {
        return errorHandler(error)
      })
  } catch (e) {
    return {}
  }
}

export default function (url, options, etagCacheKey) {
  if (navigator && !navigator.onLine) {
    return Promise.reject(
      createError({
        statusText: NETWORK_OFFLINE,
        status: 'error',
      }),
    )
  }

  return fetchWrapper(url, options, etagCacheKey)
}

import { SAVE_UID } from "../actions/AddUserActions";
import {
  SAVE_CUSTOMER_DETAILS,
  SAVE_CUSTOMER_STATUS,
  SAVE_CUSTOMER_TYPE,
  SAVE_STORE_INFO,
  SAVE_LOYALITY_BENEFITS,
  VERIFY_OTP_LOADING,
  CLEAR_OTP_DATA,
  PROCEED_WITHOUT_OTP,
  VERIFY_OTP_FOR_WALLET_AND_COUPON,
} from "../actions/OtpActions";

const initialState = {
  otp: "",
  customerDetails: {},
  new_customer: false,
  customer_type: "customer",
  storeInfo: {},
  isLoading: false,
  loyalityBenefits: {},
  uid: null,
};

const OtpReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_CUSTOMER_STATUS: {
      return {
        ...state,
        new_customer: action.payload,
      };
    }
    case SAVE_CUSTOMER_DETAILS: {
      return {
        ...state,
        customerDetails: action.payload,
      };
    }
    case SAVE_CUSTOMER_TYPE: {
      return {
        ...state,
        customer_type: action.payload,
      };
    }
    case SAVE_STORE_INFO: {
      return {
        ...state,
        storeInfo: action.payload,
      };
    }
    case SAVE_LOYALITY_BENEFITS: {
      return {
        ...state,
        loyalityBenefits: action.payload,
      };
    }

    case VERIFY_OTP_LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }

    case CLEAR_OTP_DATA: {
      return {
        ...state,
        ...initialState,
      };
    }

    case SAVE_UID: {
      return {
        ...state,
        uid: action.payload,
      };
    }

    case PROCEED_WITHOUT_OTP : {
      return {
        ...state,
        customerDetails: action.payload,
      };
    }

    case VERIFY_OTP_FOR_WALLET_AND_COUPON : {
      return {
        ...state,
      };
    }

    default:
      return state;
  }
};

export default OtpReducer;

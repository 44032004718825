import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ProductCard from "../ProductCard/SImpleProductCard";
import SearchBar from "../SearchBar/SearchBar";
import CategoryFilterBar from "../CategoryFilterBar/CategoryFilterBar";
import BackButton from "../../assets/new-ebo-icons/Button-Back.svg";
import "./CatalogContainer.scss";
import { setProgressStep } from "../../actions/CommonActions";
import CatalogDataSelector from "../../utils/CatalogDataSelector";
import GenericModal from "../GenricModal/GenricModal";
import PastOrders from "../PastOrders/PastOrders";
import {
  showConfirmTakeaway,
  getPastOrdersRequest,
  getCategories,
  dummyCartRequest,
  setTakeawayOrderLoading,
  resetTakeawayCart,
  setAddingToTakeawayCartLoad,
  setTotalItemsInCart,
  setScanActive,
} from "../../actions/TakeAwayActions";
import ConfirmTakeaway from "../ConfirmTakeAwayModal/ConfirmTakeaway";
import { ReactComponent as EditIcon } from "../../assets/new-ebo-icons/Icon-Edit.svg";
import FetchCatalogData from "../../utils/FetchCatagoryData";
import { searchProduct } from "../../utils/SearchProduct";
import { localityModal } from "../../actions/DeliveryOrderActions";
import { resetDeliveryCart } from "../../actions/DeliveryOrderActions";
import RemoveCombo from "../RemoveComboModal/RemoveCombo";
import SmileyLoader from "../Loader/SmileyLoader";
import ScanBarcodeConnect from "../ScanBarcode/ScanBarcode";
import { merchants_list } from "../../utils/merchantList";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleDown,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { useCallback } from "react";
import {
  saveMerchant,
  saveMerchantOrderId,
} from "../../actions/MerchantOrderActions";

function CatalogContainer(props) {
  const blockUi =
    props.stepName === "delivery" && props.DeliveryOrderReducer.localityModal
      ? true
      : false;
  const [selectedCategoryId, setSelectedCategoryId] = useState(0);
  const [filteredData, setFilterData] = useState(props.data);
  const [searchState, setSearchState] = useState("");
  const [hideCatFilter, setFilterbar] = useState(true);
  const pastOrderCart = { cartItems: [] };
  const { data, isShowingConfirmModal, TotalCartItems } = props.takeAwayReducer;
  const [dummyCartItems, setDummyCart] = useState({ cartItems: [] });
  const { customer_type } = props.otpReducer;
  const { customer_key } = props.otpReducer.customerDetails;
  const [showSkipBtn, setShowSkipBtn] = useState(true);
  const [showOtherMerchant, setShowOtherMerchant] = useState(false);
  const fetchCatalogByCategory = (catId) => {
    if (props.hub_id !== null) {
      if (customer_type !== "guest") {
        if (props.stepName !== "merchantorders") {
          let pastOrderParams = {
            hubId: props.hub_id,
            customer_key: customer_key,
          };
          props.FetchPastOrders({ params: pastOrderParams });
        }
      }
      let params = {
        hub_id: props.hub_id,
      };
      props.getCategoriesRequest({ params });
      props.dummyCartRequest({});
      props.showConfirmModal(false);
    }
  };

  useEffect(() => {
    fetchCatalogByCategory(0);
    if (props.stepName === "takeaway") {
      props.setProductScanActive(true);
    } else {
      props.setProductScanActive(false);
    }
  }, []);

  useEffect(() => {
    let id = Object.keys(props.categories).length && props.categories[0].id;
    handleFilters(id);
  }, [props.categories.length]);

  const handleFilters = (Id) => {
    const { Products } = props.data;
    setSelectedCategoryId(Id);
    FetchCatalogData(
      Id,
      props.FetchCatalogProducts,
      props.setTakeawayOrderLoadingRequest,
      setFilterData,
      Products,
      props.pastOrderData,
      props.hub_id
    );
  };

  useEffect(() => {
    const productList = data && CatalogDataSelector({ data });
    setFilterData(productList);
  }, [data]);

  const handleSearchClear = (e) => {
    setSearchState("");
    let params = {
      query: "",
      hubId: props.hub_id,
      customerKey: customer_key,
      searchText: "",
    };
    props.setTakeawayOrderLoadingRequest(true);
    props.SearchProducts({
      params,
    });
    setFilterData(props.data);
    setFilterbar(true);
    setSelectedCategoryId(selectedCategoryId);
    handleFilters(selectedCategoryId);
  };

  const AddTocart = (productId, productName, mrp) => {
    dummyCartItems.cartItems.push({ productId, productName, mrp });
    setDummyCart(dummyCartItems);
    props.dummyCartRequest({ dummyCartItems });
  };

  useEffect(() => {
    if (props.searchText !== "" && props.searchData.length) {
      const data = CatalogDataSelector({ searchData: props.searchData });
      setFilterData(data);
    } else setFilterData();
  }, [props.searchData]);

  const resetCart = () => {
    let params = {
      customer_key: customer_key,
    };
    props.resetTakeawayCartRequest({ params });
    props.setAddingToTakeawayCartLoad(true);
    let dummyCartItems = { cartItems: [] };
    props.dummyCartRequest({ dummyCartItems });
    props.setTotalItemsInCart({ cartItems: [] });
  };

  const skipToDelivery = () => {
    if (
      props.takeAwayReducer.TotalCartItems &&
      props.takeAwayReducer.TotalCartItems.cartItems &&
      props.takeAwayReducer.TotalCartItems.cartItems.length
    ) {
      resetCart();
    }
    props.orderJourneyStepChange(2);
  };
  const handleMerchantSelection = (e) => {
    props.saveMerchantName(e.target.dataset.value);
  };
  useEffect(() => {
    if (
      props.stepName === "delivery" &&
      Object.keys(TotalCartItems).length &&
      TotalCartItems.cartItems.length > 0
    ) {
      setShowSkipBtn(true);
    }
    if (
      props.stepName === "delivery" &&
      TotalCartItems.cartItems &&
      TotalCartItems.cartItems.length === 0
    ) {
      setShowSkipBtn(false);
    }
  }, [TotalCartItems]);

  const { addingToTakeawayCartLoad } = props.takeAwayReducer;
  const { addingToDeliveryCart } = props.DeliveryOrderReducer;
  const handelShowMerchant = useCallback(() => {
    setShowOtherMerchant(!showOtherMerchant);
  }, [showOtherMerchant]);

  return (
    <>
      {addingToTakeawayCartLoad || addingToDeliveryCart ? (
        <SmileyLoader />
      ) : null}
      <div className="new-ebo-catalog-container">
        {props.stepName !== "merchantorders" && (
          <>
            <GenericModal
              show={isShowingConfirmModal}
              cancel={!isShowingConfirmModal}
              className="modal-position"
            >
              <ConfirmTakeaway stepName={props.stepName} />
            </GenericModal>
            <GenericModal
              show={props.DeliveryOrderReducer.showRemoveComboModal}
              cancel={!props.DeliveryOrderReducer.showRemoveComboModal}
              className="modal-position"
            >
              <RemoveCombo
                title={
                  Object.keys(props.DeliveryOrderReducer.deliveryCart).length &&
                  props.DeliveryOrderReducer.deliveryCart.products.length > 0 &&
                  props.DeliveryOrderReducer.deliveryCart.products[0]
                    .combo_pop_up_messages.title
                }
                description={
                  Object.keys(props.DeliveryOrderReducer.deliveryCart).length &&
                  props.DeliveryOrderReducer.deliveryCart.products.length > 0 &&
                  props.DeliveryOrderReducer.deliveryCart.products[0]
                    .combo_pop_up_messages.description
                }
              />
            </GenericModal>
          </>
        )}
        {props.stepName !== "merchantorders" ? (
          <div>
            <div className="search-container">
              <div className="store-heading">
                {props.storeName}
                {props.stepName === "delivery" && (
                  <EditIcon
                    height="10"
                    width="20"
                    onClick={() => {
                      props.localityModalChange(true);
                    }}
                  />
                )}
              </div>
              <SearchBar
                searchProduct={(event) => {
                  const e = event.target;
                  searchProduct(
                    e,
                    props.data,
                    props.hub_id,
                    props.customerKey,
                    props.setTakeawayOrderLoadingRequest,
                    props.SearchProducts,
                    setFilterbar,
                    setFilterData,
                    setSearchState
                  );
                }}
                searchState={searchState}
                setProductScanActive={props.setProductScanActive}
                handleSearchClear={(event) => {
                  const e = event.target;
                  handleSearchClear(e);
                }}
                scanActive={props.takeAwayReducer.scanActive}
              />
              <div>
                {props.stepName !== "delivery" && (
                  <button
                    className="scan-button"
                    onClick={(e) => {
                      props.setProductScanActive(true);
                    }}
                  >
                    Scan
                  </button>
                )}
                {showSkipBtn && props.otpReducer.customer_type !== "guest" && (
                  <button
                    className="skip-button"
                    onClick={(e) => {
                      props.setProductScanActive(false);
                      if (
                        props.orderJourneyReducer.orderJourneyActiveStep === 1
                      ) {
                        skipToDelivery();
                      } else if (
                        props.orderJourneyReducer.orderJourneyActiveStep === 2
                      ) {
                        props.orderJourneyStepChange(4);
                        props.resetDeliveryCart({
                          params: {
                            customer_key:
                              props.otpReducer.customerDetails.customer_key,
                          },
                        });
                      }
                    }}
                  >
                    Skip &#187;
                  </button>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="merchant-order-header">
            <div className="merchant-order-options">
              <div>Select Merchant : </div>
              <div
                className={classnames({
                  "merchant-active":
                    props.merchantOrder.merchant === "Swiggy Restaurant",
                })}
                data-value="Swiggy Restaurant"
                onClick={(e) => handleMerchantSelection(e)}
              >
                Swiggy R
              </div>
              <div
                data-value="Zomato Restaurant"
                className={classnames({
                  "merchant-active":
                    props.merchantOrder.merchant === "Zomato Restaurant",
                })}
                onClick={(e) => handleMerchantSelection(e)}
              >
                Zomato R
              </div>
              <div
                className={classnames("other-merchant", {
                  "merchant-active": merchants_list.includes(
                    props.merchantOrder.merchant
                  ),
                })}
                onClick={handelShowMerchant}
                title={
                  merchants_list.includes(props.merchantOrder.merchant)
                    ? props.merchantOrder.merchant
                    : ""
                }
              >
                <div>
                  <div>
                    {merchants_list.includes(props.merchantOrder.merchant)
                      ? props.merchantOrder.merchant
                      : "Others"}
                  </div>
                  <div>
                    <FontAwesomeIcon
                      icon={showOtherMerchant ? faChevronUp : faChevronDown}
                      style={{ textAlign: "right" }}
                    />
                  </div>
                </div>
                <div
                  className={classnames("merchants-list", {
                    active: showOtherMerchant,
                  })}
                >
                  {merchants_list.map((merchantName) => (
                    <div
                      key={merchantName}
                      data-value={merchantName}
                      className={classnames({
                        "merchant-active":
                          props.merchantOrder.merchant === merchantName,
                      })}
                      onClick={(e) => handleMerchantSelection(e)}
                    >
                      {merchantName}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="merchant-order-number">
              <div>Order ID :</div>{" "}
              <input
                value={props.merchantOrder.orderId}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value !== " ") props.saveMerchantOrderId(e.target.value);
                }}
              />
            </div>
            <div className="merchant-order-type">
              <div> Delivery Type:</div>
              <div className="delivery-type">Express</div>
            </div>
          </div>
        )}
        {hideCatFilter && (
          <CategoryFilterBar
            categories={props.categories}
            handleFilters={(id) => handleFilters(id)}
            selectedCategoryFilter={selectedCategoryId}
            pastOrderData={props.pastOrderData}
            scanActive={props.takeAwayReducer.scanActive}
            stepName={props.stepName}
          />
        )}
        {searchState !== "" && !props.takeAwayReducer.isLoading && (
          <div className="back-from-search">
            <img
              src={BackButton}
              alt=""
              className="icon"
              onClick={() => handleSearchClear()}
            />
            {filteredData ? (
              <p>Search Results ({filteredData.Products.length})</p>
            ) : (
              <p>No Products Found </p>
            )}
          </div>
        )}
        <div
          className={classnames("product-catalog", {
            "merchant-catalog": props.stepName === "merchantorders",
          })}
        >
          {!props.takeAwayReducer.scanActive ? (
            <>
              {selectedCategoryId === 0 &&
              !!hideCatFilter &&
              customer_type !== "guest" ? (
                <>
                  {!props.takeAwayReducer.isLoading && !blockUi ? (
                    <>
                      {props.pastOrderData.length ? (
                        props.pastOrderData.map((order) => {
                          return (
                            <>
                              <PastOrders
                                {...props}
                                order={order}
                                pastOrderCart={pastOrderCart}
                                dummyCartData={props.dummyCartData}
                                addToCart={(item) => AddTocart(item)}
                              />
                            </>
                          );
                        })
                      ) : (
                        <div className="no-pastOrder-message">
                          No Past Orders
                        </div>
                      )}
                    </>
                  ) : (
                    <SmileyLoader />
                  )}
                </>
              ) : (
                <>
                  {!props.takeAwayReducer.isLoading ? (
                    <div className="product-card-container">
                      {filteredData &&
                        filteredData.Products.length &&
                        filteredData.Products.map((product) => (
                          <ProductCard
                            product={product}
                            stepName={props.stepName}
                            customerKey={props.customerKey}
                            dummyCartData={props.dummyCartData}
                            pastOrderCart={pastOrderCart}
                            hub_id={props.hub_id}
                          />
                        ))}
                    </div>
                  ) : (
                    <SmileyLoader />
                  )}
                </>
              )}
            </>
          ) : (
            <>
              <ScanBarcodeConnect
                hub_id={props.hub_id}
                customerKey={props.customerKey}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
}
const mapDispatchToProps = (dispatch) => {
  return {
    orderJourneyStepChange: (step) => {
      dispatch(setProgressStep(step));
    },
    showConfirmModal: (value) => {
      dispatch(showConfirmTakeaway(value));
    },
    FetchPastOrders: ({ params }) => {
      dispatch(getPastOrdersRequest({ params }));
    },
    getCategoriesRequest: ({ params }) => {
      dispatch(getCategories({ params }));
    },
    dummyCartRequest: ({ dummyCartItems }) => {
      dispatch(dummyCartRequest({ dummyCartItems }));
    },
    localityModalChange: (value) => {
      dispatch(localityModal(value));
    },
    setTakeawayOrderLoadingRequest: (value) => {
      dispatch(setTakeawayOrderLoading(value));
    },
    resetTakeawayCartRequest: ({ params }) => {
      dispatch(resetTakeawayCart({ params }));
    },
    setAddingToTakeawayCartLoad: (value) => {
      dispatch(setAddingToTakeawayCartLoad(value));
    },
    setTotalItemsInCart: (payload) => {
      dispatch(setTotalItemsInCart(payload));
    },
    setProductScanActive: (payload) => {
      dispatch(setScanActive(payload));
    },
    resetDeliveryCart: (payload) => {
      dispatch(resetDeliveryCart(payload));
    },
    saveMerchantOrderId: (payload) => {
      dispatch(saveMerchantOrderId(payload));
    },
    saveMerchantName: (payload) => dispatch(saveMerchant(payload)),
  };
};
const mapStateToProps = (state) => {
  return state;
};
const CatalogContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(CatalogContainer);

export default CatalogContainerConnect;

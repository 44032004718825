import React from 'react'
import { connect } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import ConfirmButton from '../Buttons/ConfirmButton/ConfirmButton'
import Card from '../Card/Card'
import { ReactComponent as IconTick } from '../../assets/new-ebo-icons/states/Icon-Tick.svg'
import './Meatopia.scss'
import { format } from 'date-fns'
import moment from 'moment'
import '../../variable.scss'
const MeatopiaCard = ({ loyalityBenefits }) => {
  let ends = moment(
    loyalityBenefits.subscribed && loyalityBenefits.subscribed.end_date,
  )
  let starts = moment(
    loyalityBenefits.subscribed && loyalityBenefits.subscribed.start_date,
  )
  let years = ends.diff(starts, 'year')
  starts.add(years, 'years')
  let months = ends.diff(starts, 'months')
  starts.add(months, 'months')
  let days = ends.diff(starts, 'days')
  let dateString =
    `${years > 0 ? `${years} years,` : ''}` +
    `${months > 0 ? `${months} months` : ''}` +
    `${days > 0 ? `, ${days} days left` : ''}`
  let data = ''
  loyalityBenefits.subscribed &&
    loyalityBenefits.subscribed.benefits.forEach((item, index) => {
      if (
        index === 0 &&
        index !== loyalityBenefits.subscribed.benefits.length - 1
      ) {
        data = `<ul><li>${item.description}</li>`
      } else if (index === loyalityBenefits.subscribed.benefits.length - 1) {
        data += `<li>${item.description}</li></ul>`
      } else {
        data += '<li>${item}</li>'
      }
    })
  return (
    <>
      {loyalityBenefits &&
      loyalityBenefits.subscribed &&
      loyalityBenefits.subscribed.days_left ? (
        <Card>
          <div className="loyality-card">
            <div className="loyality-card-name">
              <div className="icon-holder">
                <IconTick />
              </div>

              <div>
                <span style={{ fontWeight: 'bold' }}>Meatopia</span>
              </div>
            </div>
            <div
              className="benefits-button"
              // onClick={showMetopiaBenefits}
              data-tip={data}
              data-html="true"
              data-event="mouseover"
              data-event-off="mouseout"
            >
              See Benefits
            </div>
            <ReactTooltip html={true} className="meatopia-loyality-card-tip" />
          </div>
          <div className="validity-holder">
            <div>
              From:{' '}
              {format(
                new Date(loyalityBenefits.subscribed.start_date),
                'd, MMM, yyyy',
              )}{' '}
              | To:{' '}
              {format(
                new Date(loyalityBenefits.subscribed.end_date),
                'd, MMM, yyyy',
              )}
            </div>
            <div>
              <span style={{ color: '$ebo-active-red' }}>{dateString}</span>
            </div>
          </div>
        </Card>
      ) : null}
    </>
  )
}
const mapDispatchToProps = (dispatch) => {
  return {}
}
const mapStateToProps = (state) => {
  return { loyalityBenefits: state.otpReducer.loyalityBenefits }
}
export default connect(mapStateToProps, mapDispatchToProps)(MeatopiaCard)

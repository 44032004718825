import React from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

import { GoogleAutoCompleteContainer } from "./AddAddressStyles";

class LocationSearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { address: this.props.addrLine1, cityLatLong: {} };
    //this.cityLatLong = {};
  }

  componentWillReceiveProps(nextProps) {
    // if (!!nextProps.city && nextProps.city !== this.props.city) {
    //   this.setLatLngForCity(nextProps.city);
    // }
  }
  componentDidUpdate(prevProps, prevState) {
    // const {

    // }
    const { addrLine1: prevAddrLine1, lat: prevLat, lng: prevLng } = prevProps;
    const { addrLine1, lat, lng } = this.props;
    if (addrLine1 !== prevAddrLine1) {
      this.getAccurateLatLng(addrLine1);
    }
    if (lat !== prevLat || lng !== prevLng) {
      this.setState({
        address: this.props.addrLine1,
        lat:lat,
        lng:lng
      });
    }
  }
  handleChange = (address) => {
    this.setState({ address });
    const { changeValidityLine1 } = this.props;
    changeValidityLine1(false);
    //this.props.addrLine1Change(address);
  };

  handleSelect = (address) => {
    this.props.addrLine1Change(address);
    this.setState({ address });
  };

  getAccurateLatLng = (address) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        this.props.updateLatLng(latLng);
      })
      .catch((error) => console.error("LatLng Error", error));
  };

  setLatLngForCity = (city) => {
    // geocodeByAddress(city).then(results => getLatLng(results[0]))
    //   .then(latLng => {this.setState({address: adress}) this.cityLatLong = latLng; return;})
    //   .catch(error => this.cityLatLong = {})
    geocodeByAddress(city)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => this.setState({ cityLatLong: latLng }))
      .catch((error) => this.setState({ cityLatLong: {} }));
  };

  render() {
    let searchOptions = {
      componentRestrictions: { country: "in" },
      zoom: 13,
    };
    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        searchOptions={searchOptions}
        debounce={500}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <GoogleAutoCompleteContainer className="cutsom-container">
            <input
              {...getInputProps({
                placeholder: "Enter a Location",
                className: "location-search-input",
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                // inline style for demonstration purpose
                // const style = suggestion.active
                //   ? { backgroundColor: '#fafafa', cursor: 'pointer', padding: '10px' }
                //   : { backgroundColor: '#ffffff', cursor: 'pointer', padding: '10px' };
                const style = {};
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </GoogleAutoCompleteContainer>
        )}
      </PlacesAutocomplete>
    );
  }
}

export default LocationSearchInput;
import React, { Component } from 'react'

import { connect } from 'react-redux'
import { geocodeByAddress } from 'react-places-autocomplete'

import {
  getDistanceFromLatLonInKm,
  determineZoomLevel,
  getMapIcon,
} from '../../../utils/mapUtils'

import Loader from '../../Loader/Loader'

import {
  AddAddressModalStyled,
  AddressAndMapContainerStyled,
  MapContainerStyled,
  ConfirmModalSectionStyled,
  ErrorRow,
  MandatoryStyled,
} from './AddAddressStyles'

import GoogleMapSearchBox from './GoogleMapSearchBox'

import { saveUserAddr } from '../../../actions/AddUserActions'
import { saveUserAddress } from '../../../actions/CommonActions'
const markerEventListener = ({
  map,
  lat,
  lng,
  marker,
  zoom,
  formattedAddr,
  context,
}) => {
  window.google.maps.event.addListener(marker, 'click', () => {
    const ltLngObj = new window.google.maps.LatLng(lat, lng)
    map.setZoom(zoom)
    map.panTo(ltLngObj)
    context.setState({
      lat,
      lng,
      addrLine1: formattedAddr,
      nearestAddrInfo: {
        ...context.nearestAddrInfo,
        lat,
        lng,
        addrLine1: formattedAddr,
      },
    })
  })
}
class AddAddress extends Component {
  constructor(props) {
    super(props)
    const ConfirmModalRef = React.createRef()
    const FormRef = React.createRef()
    const MapRef = React.createRef()
    const ModalHandle = React.createRef()
    const MapNotifMsg = React.createRef()

    this.ConfirmModalRef = ConfirmModalRef
    this.FormRef = FormRef
    this.MapRef = MapRef
    this.ModalHandle = ModalHandle
    this.MapNotifMsg = MapNotifMsg
    const { choosenAddr } = props.customerProfile
    const { lat, lng } = choosenAddr
    const stateValue = {
      addrLine1: props.editAddress
        ? props.customerProfile.choosenAddr.line2
        : '',
      addrLine2: props.editAddress
        ? props.customerProfile.choosenAddr.line1
        : '',
      landmark: props.editAddress
        ? props.customerProfile.choosenAddr.landmark
        : '',
      city: props.cityName,
      phoneNo: props.editAddress
        ? props.customerProfile.choosenAddr.contact
        : props.customerProfile.custPhone,
      lat: Number(lat) || 12.971891,
      lng: Number(lng) || 77.64115449999997,
      zoom: 15,
      isValidAddressLine1:
        props.editAddress && props.customerProfile.choosenAddr.line1,
      nearestAddrInfo: {},
    }
    const { addrLine1, addrLine2, phoneNo } = stateValue
    let isValidForm = false
    if (!!addrLine1 && !!addrLine2 && !!phoneNo) {
      isValidForm = true
    }
    this.state = {
      ...stateValue,
      showLoader: false,
      isValidForm,
    }
    this.storeDetails = JSON.parse(localStorage.getItem('storeDetails'))
    this.postalCode = ''
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.updateUserAddrSuccess &&
      this.props.updateUserAddrSuccess !== nextProps.updateUserAddrSuccess
    ) {
      //this.proceedToCatalogs();
      this.setState({ showLoader: false })
      this.props.updateUserAddressSuccess(
        nextProps.updateUserAddrSuccess.address,
      )
      this.closeModal()
    } else if (nextProps.updateUserAddrFailed) {
      this.setState({ showLoader: false })
    }
  }
  changeValidityLine1 = (isvalid) => {
    this.setState({
      isValidAddressLine1: isvalid,
    })
  }
  componentDidUpdate(prevProps, prevState) {
    // if (prevProps.updateUserAddrSuccess) {
    //   //this.proceedToCatalogs();
    //   this.props.updateUserAddressSuccess(prevProps.updateUserAddrSuccess.address)
    // } else if (prevProps.updateUserAddrFailed) {

    // }
    const { lat, lng } = prevState
    const { lat: currentLat, lng: currentLng, zoom } = this.state
    if (lat !== currentLat || currentLng !== lng) {
      this.initMap({
        lat: currentLat,
        lng: currentLng,
        zoom,
      })
      this.props.getLocationCityName({
        lat: this.state.lat,
        lng: this.state.lng,
      })
    }
  }

  addUserAddress = (e) => {
    e.preventDefault()
  }

  addrLine1Change = (address) => {
    let isValidForm = false
    if (this.isValidityForm() || address) {
      isValidForm = true
    }
    this.setState({
      addrLine1: address,
      isValidForm,
      isValidAddressLine1: true,
      errorTxt: !isValidForm ? 'Mandatory fields are empty' : '',
    })
  }

  handleAddrLine2Change = (e) => {
    let isValidForm = false
    if (this.isValidityForm() && e.target.value !== '') {
      isValidForm = true
    }
    this.setState({
      addrLine2: e.target.value,
      isValidForm,
      errorTxt: !isValidForm ? 'Mandatory fields are empty' : '',
    })
  }

  handleLandMarkChange = (e) => {
    this.setState({
      landmark: e.target.value,
    })
  }

  updateLatLng = (latLng) => {
    const { lat, lng } = latLng
    this.setState({
      lat,
      lng,
    })
  }

  handlePhoneNoChange = (e) => {
    let isValidForm = false
    if (
      this.isValidityForm() &&
      e.target.value !== '' &&
      e.target.value.length == 10
    ) {
      isValidForm = true
      this.setState({
        phoneNo: e.target.value,
        isValidForm,
        errorTxt: !isValidForm ? 'Mandatory fields are empty' : '',
      })
    } else {
      this.setState({
        phoneNo: e.target.value,
      })
    }
    if (e.target.value.length !== 10) {
      this.setState({
        phoneNo: e.target.value,
        isValidForm,
        errorTxt: 'Phone number should be 10 digit number',
      })
    }
  }
  initMap = ({ lat, lng, zoom = 15 }) => {
    let { map } = this.state
    if (!map) {
      map = new window.google.maps.Map(document.getElementById('map'), {
        center: { lat, lng },
        zoom,
      })
    }
    map.addListener('dragend', () => {
      const centreMapObj = map.getCenter()
      const latCenter = centreMapObj.lat()
      const lngCenter = centreMapObj.lng()
      const geocoder = new window.google.maps.Geocoder()
      geocoder.geocode(
        {
          latLng: {
            lat: latCenter,
            lng: lngCenter,
          },
        },
        (results, status) => {
          if (results && results.length > 0) {
            this.postalCode = this.getPostalCodeFromAddress(
              results[0].address_components,
            )
            const finalLatCenter = results[0].geometry.location.lat()
            const finalLngGeometry = results[0].geometry.location.lng()
            map.panTo({
              lat: finalLatCenter,
              lng: finalLngGeometry,
            })
            this.setState({
              lat: finalLatCenter,
              lng: finalLngGeometry,
              addrLine1: results[0].formatted_address,
              nearestAddrInfo: {
                ...this.state.nearestAddrInfo,
                lat: finalLatCenter,
                lng: finalLngGeometry,
                addrLine1: results[0].formatted_address,
              },
            })
          }
        },
      )
    })
    this.setState({
      map,
    })
  }
  componentDidMount() {
    const { lat, lng, zoom } = this.state
    this.initMap({
      lat,
      lng,
      zoom,
    })
  }

  getPostalCodeFromAddress(address) {
    if (!address) return
    for (let i = address.length - 1; i >= 0; i--) {
      if (address[i].types.indexOf('postal_code') != -1) {
        return address[i].long_name
      }
    }
  }

  isValidityForm = () => {
    const { addrLine2, phoneNo, addrLine1, isValidAddressLine1 } = this.state
    if (!phoneNo || !addrLine2 || !addrLine1 || !isValidAddressLine1) {
      return false
    }
    return true
  }
  updateUserAddress = () => {
    const { addrLine2, phoneNo, addrLine1 } = this.state
    let hasSomeResults = false
    if (!phoneNo || !addrLine2 || !addrLine1) {
      return
    }
    const { lat, lng } = this.state
    let reqObj = {
      data: {
        customer_key: this.props.customer_key,
        line1: this.state.addrLine1,
        line2: this.state.addrLine2,
        lat: lat.toString(),
        lng: lng.toString(),
        landmark: this.state.landmark,
        city: this.props.cityName,
        contact: this.state.phoneNo,
        hub_id: this.props.hub_id,
      },
    }
    const currentLatLng = {
      lat,
      lng,
      formattedAddr: addrLine1,
    }
    geocodeByAddress(`${reqObj.data.line2}, ${reqObj.data.line1}`)
      .then((results) => {
        const isValidResultsArray = results && results.length > 0
        if (isValidResultsArray) {
          this.postalCode = this.getPostalCodeFromAddress(
            results[0].address_components,
          )
        }
        return isValidResultsArray
          ? results.reduce((acc, element) => {
              acc.push({
                lat: element.geometry.location.lat(),
                lng: element.geometry.location.lng(),
                formattedAddr: element.formatted_address,
              })
              return acc
            }, [])
          : []
      })
      .then((latLng) => {
        hasSomeResults = true
        const {
          markerFirstLineAddr: statemarkerFirstLineAddr,
          markerSecondLineAddr: statemarkerSecondLineAddr,
        } = this.state
        if (statemarkerFirstLineAddr || statemarkerSecondLineAddr) {
          statemarkerFirstLineAddr.setMap(null)
          statemarkerSecondLineAddr.setMap(null)
        }
        if (latLng.length > 0) {
          const processedwithDistanceArraySorted = latLng
            .reduce((acc, element) => {
              const distance = getDistanceFromLatLonInKm(
                currentLatLng.lat,
                currentLatLng.lng,
                element.lat,
                element.lng,
              )
              acc.push({
                distance,
                lat: element.lat,
                lng: element.lng,
                formattedAddr: element.formattedAddr,
              })
              return acc
            }, [])
            .sort((a, b) => a.distance - b.distance)

          const icon = getMapIcon()
          const { map, zoom } = this.state
          const markerFirstLineAddr = new window.google.maps.Marker({
            position: { lat: currentLatLng.lat, lng: currentLatLng.lng },
            title: '',
            icon,
          })
          const {
            lat: nearestLat,
            lng: nearestLng,
            distance: minDistance,
            formattedAddr: nearestFormattedAddr,
          } = processedwithDistanceArraySorted[0]
          this.setState({
            nearestAddrInfo: {
              lat: nearestLat,
              lng: nearestLng,
              formattedAddr: nearestFormattedAddr,
            },
          })
          const markerSecondLineAddr = new window.google.maps.Marker({
            position: { lat: nearestLat, lng: nearestLng },
            title: '',
            icon,
          })
          this.setState({
            markerFirstLineAddr,
            markerSecondLineAddr,
          })

          markerEventListener({
            marker: markerFirstLineAddr,
            map,
            zoom,
            lat: currentLatLng.lat,
            lng: currentLatLng.lng,
            formattedAddr: currentLatLng.formattedAddr,
            context: this,
          })

          const ltLngObj = new window.google.maps.LatLng(nearestLat, nearestLng)
          map.setZoom(zoom)
          map.panTo(ltLngObj)
          this.setState({
            addrLine1: nearestFormattedAddr,
          })

          markerEventListener({
            marker: markerSecondLineAddr,
            map,
            zoom,
            lat: nearestLat,
            lng: nearestLng,
            formattedAddr: processedwithDistanceArraySorted[0].formattedAddr,
            context: this,
          })
          const newZoom = determineZoomLevel(minDistance)
          this.setState({
            zoom: newZoom,
          })
          markerFirstLineAddr.setMap(map)
          markerSecondLineAddr.setMap(map)
          map.setZoom(newZoom)
        }
      })
      .then(() => {
        if (hasSomeResults) {
          this.openConfirmModalSection()
        } else {
          this.setState({
            isValidForm: false,
            errorTxt: 'No Results Found !!',
          })
        }
      })
      .catch((error) => {})
  }
  openConfirmModalSection = () => {
    this.ConfirmModalRef.current.style.display = 'block'
    this.MapNotifMsg.current.style.display = 'block'
    this.FormRef.current.style.display = 'none'
    this.MapRef.current.style.width = '100%'
  }
  closeConfirmationSection = () => {
    this.ConfirmModalRef.current.style.display = 'none'
    this.MapNotifMsg.current.style.display = 'none'
    this.FormRef.current.style.display = 'block'
    this.MapRef.current.style.width = '70%'
    const {
      markerFirstLineAddr: statemarkerFirstLineAddr,
      markerSecondLineAddr: statemarkerSecondLineAddr,
    } = this.state
    if (statemarkerFirstLineAddr || statemarkerSecondLineAddr) {
      statemarkerFirstLineAddr.setMap(null)
      statemarkerSecondLineAddr.setMap(null)
    }
  }
  closeModal = () => {
    const { closeModal } = this.props
    closeModal()
  }

  getState = (addressString) => {
    return addressString
      .split(',') //split address line into its constituent sections
      .reverse()[1] //reverse the split address to get 'India' and the state to the front
      .split(' ')[1] //split the second element to get the state from the pincode
  }

  onClickConfirmAddress = () => {
    const {
      lat,
      lng,
      landmark,
      phoneNo,
      addrLine1,
      addrLine2,
      customerProfile,
    } = this.state
    const { cityName, editAddress, saveUserAddrDispatch } = this.props
    let reqObj = {
      data: {
        customer_key: this.props.customer_key,
        line1: addrLine2,
        line2: addrLine1,
        lat: lat.toString(),
        lng: lng.toString(),
        landmark: landmark,
        city: cityName,
        contact: phoneNo,
        pincode: this.postalCode,
        state: this.getState(addrLine1),
      },
      hub_id: this.props.hub_id,
    }
    if (editAddress) {
      reqObj.data.address_id = this.props.customerProfile.choosenAddr.address_id
    }

    this.setState({ showLoader: true })
    saveUserAddrDispatch(reqObj)
    //this.closeModal()
  }
  render() {
    const {
      lat,
      lng,
      phoneNo,
      addrLine1,
      errorTxt,
      isValidAddressLine1,
      nearestAddrInfo,
      addrLine2,
    } = this.state
    const isValidForm =
      !!addrLine1 && !!addrLine2 && !!phoneNo && phoneNo.length === 10
    return (
      <AddAddressModalStyled
        className="add-address-modal"
        ref={this.ModalHandle}
        isActiveBtn={isValidForm}
      >
        {this.state.showLoader && <Loader />}
        <AddressAndMapContainerStyled>
          <div
            className="close-btn"
            onClick={this.closeModal}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            X
          </div>
          <div className="map-notif-toast" ref={this.MapNotifMsg}>
            <div className="notif-text">
              We have identified a location based on your input
            </div>
          </div>
          <ConfirmModalSectionStyled ref={this.ConfirmModalRef}>
            <div className="navigation-section">
              <span
                className="icon-back"
                onClick={this.closeConfirmationSection}
              />
              <span>Confirm Delivery Location</span>
            </div>
            <span className="search-locality-txt">
              Search for Area/Locality
            </span>
            <div
              className="address-line"
              onClick={this.closeConfirmationSection}
            >
              <span>{addrLine1}</span>
            </div>
            <button className="action-btn" onClick={this.onClickConfirmAddress}>
              Confirm Delivery Location
            </button>
          </ConfirmModalSectionStyled>
          <form onSubmit={this.addUserAddress} ref={this.FormRef}>
            <div className="add-address-title">Add new address</div>
            <div className="addr-line-1">
              {/* <label>Search for Area/Locality</label> */}
              <MandatoryStyled>*</MandatoryStyled>
              <GoogleMapSearchBox
                addrLine1Change={this.addrLine1Change}
                addrLine1={this.state.addrLine1}
                cityName={this.props.cityName}
                updateLatLng={this.updateLatLng}
                initMap={this.initMap}
                lat={lat}
                lng={lng}
                changeValidityLine1={this.changeValidityLine1}
              />
            </div>
            <div className="addr-line-2">
              <MandatoryStyled>*</MandatoryStyled>
              <input
                type="text"
                required
                value={this.state.addrLine2}
                onChange={this.handleAddrLine2Change}
                placeholder="Flat no / Building name/Street Name"
              />
            </div>
            <div className="landmark-input">
              <input
                required
                type="text"
                placeholder="Landmark (optional)"
                value={this.state.landmark}
                onChange={this.handleLandMarkChange}
              />
            </div>
            <div className="city-field">
              <input
                type="text"
                placeholder="City"
                value={this.props.cityName}
                disabled
                required
              />
            </div>
            <div className="phone-no">
              <MandatoryStyled>*</MandatoryStyled>
              <input
                required
                type="tel"
                placeholder="Mobile No."
                value={phoneNo}
                onChange={this.handlePhoneNoChange}
                required
              />
            </div>
            <div className="save-proceed">
              <button
                onClick={this.updateUserAddress}
                className="confirm-button"
              >
                Save & Proceed
              </button>
              <div className="dismiss-modal-text" onClick={this.closeModal}>
                Dismiss
              </div>
            </div>

            {!isValidForm && <ErrorRow>{errorTxt}</ErrorRow>}
          </form>
          <MapContainerStyled ref={this.MapRef}>
            <span className="marker-text">Move map to change</span>
            <img
              className="map-marker"
              src="https://d2407na1z3fc0t.cloudfront.net/Banner/location-icon.png"
              alt="Map marker"
            />
            <div id="map" />
          </MapContainerStyled>
        </AddressAndMapContainerStyled>
      </AddAddressModalStyled>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    customer_key: state.otpReducer.customerDetails.customer_key,
    updateUserAddrSuccess: state.registerUser.updateUserAddrSuccess,
    updateUserAddrFailed: state.registerUser.updateUserAddrFailed,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    saveUserAddrDispatch: (...rest) => {
      dispatch(saveUserAddress(...rest))
    },
  }
}

const AddAddressConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddAddress)

export default AddAddressConnect

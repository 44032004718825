import { format } from "date-fns";
import React from "react";

export const ONLY_EGGS_NOTALLED_ERR_MSG =
  "To order only eggs, add another pack";
export const INVALID_PHONE_NUMBER_MSG = "Please enter valid phone number";
export const INVALID_BARCODE_MSG = "Please enter valid barcode";
export const PRODUCT_NOT_AVAILABLE_IN_LIVE =
  "Sorry the selected product not available for selling";

export function getAggrigatedProductQuantity(productId, shipmentDetails) {
  let aggregatedQty = 0;
  const shipSummary = shipmentDetails.shipment_summary;
  shipSummary.map((shipment) => {
    shipment.products.map((product) => {
      if (product.product_id == productId) {
        aggregatedQty += parseInt(product.quantity);
        return;
      }
    });
  });
  return aggregatedQty;
}

export function getSelectedSlot(slots) {
  for (let i = 0; i < slots.length; i++) {
    if (slots[i].selected) {
      return slots[i];
    }
  }
}

export function isExpressDeliveryAvailable(slots) {
  for (let i = 0; i < slots.length; i++) {
    if (slots[i].is_express && slots[i].selected) {
      return true;
    }
  }
}

export function getSplitSlotsCountForProduct(productId, shipSummary) {
  let splitShipmentCount = 0;
  shipSummary.map((shipment) => {
    shipment.products.map((product) => {
      if (product.product_id == productId) {
        splitShipmentCount++;
        return;
      }
    });
  });
  return splitShipmentCount;
}

export function convertedDate(dateString) {
  let newFormat = dateString
    .split("-")
    .reverse()
    .join("-");
  return format(new Date(newFormat), "dd MMMM");
}
export function getAgentPermissions(permissions) {
  // const LiciousPOSStore = organizations.find((organization) => {
  //     return organization.client_id === "liciousretail"
  // });
  // const MerchantStore = organizations.find((organization) => {
  //     return organization.client_id !== "liciousretail"
  // });
  // return {
  //     posAllowed: !!LiciousPOSStore,
  //     endlessAisleAllowed: !!MerchantStore
  // };
  const endlessAisleAllowed = permissions.find(
    (item) => item.permission === "can_view_endless_aisle"
  );
  const posAllowed = permissions.find(
    (item) => item.permission === "can_view_pos_retail"
  );
  return {
    posAllowed: !!posAllowed,
    endlessAisleAllowed: !!endlessAisleAllowed,
  };
}

export const generateRandomString16 = () => {
  return (
    Math.random()
      .toString(36)
      .substring(2, 10) +
    Math.random()
      .toString(36)
      .substring(2, 10)
  );
};

export const PrintHtmlEl = (contents, title) => {
  var frame1 = document.createElement("iframe");
  frame1.name = "frame1";
  frame1.style.position = "absolute";
  frame1.style.top = "-1000000px";
  document.body.appendChild(frame1);
  var frameDoc = frame1.contentWindow
    ? frame1.contentWindow
    : frame1.contentDocument.document
    ? frame1.contentDocument.document
    : frame1.contentDocument;
  frameDoc.document.open();
  frameDoc.document.write(`<html><head><title>${title}</title>`);
  frameDoc.document.write(`</head><body>`);
  frameDoc.document.write(contents);
  frameDoc.document.write(`</body></html>`);
  frameDoc.document.close();
  setTimeout(function() {
    window.frames["frame1"].focus();
    window.frames["frame1"].print();
    document.body.removeChild(frame1);
  }, 500);
  return false;
};

export const PrintPDF = (pdfUrl) => {
  // var frame1 = document.createElement('iframe');
  // frame1.name = "frame1";
  // frame1.style.position = "absolute";
  // frame1.style.top = "-1000000px";
  // frame1.id = "output-frame-id";
  // document.body.appendChild(frame1);
  // frame1.src = pdfUrl;
  // // var frameDoc = frame1.contentWindow ? frame1.contentWindow : frame1.contentDocument.document ? frame1.contentDocument.document : frame1.contentDocument;
  // // frameDoc.document.open();
  // // frameDoc.document.write(`<html><head><title></title>`);
  // // frameDoc.document.write(`</head><body onload="window.print()"`);
  // // //frameDoc.document.write();
  // // frameDoc.document.write(`</body></html>`);
  // //frameDoc.document.close();
  // setTimeout(function () {
  //     window.frames["frame1"].focus();
  //     window.frames["frame1"].print();
  //     document.body.removeChild(frame1);
  // }, 500);
  // return false;
  //Read from URL and set as iframe src
  // var xhr = new XMLHttpRequest();
  // xhr.open('GET', pdfUrl);
  // xhr.onreadystatechange = handler;
  // xhr.responseType = 'blob';
  // xhr.send();
  // function handler() {
  //   if (this.readyState === this.DONE) {
  //     if (this.status === 200) {
  //       // this.response is a Blob, because we set responseType above
  //       var data_url = URL.createObjectURL(this.response);
  //       document.querySelector('#output-frame-id').src = data_url;
  //     } else {
  //       console.error('no pdf :(');
  //     }
  //   }
  // }
  //Show iframe inside iframe
  // var proxyIframe = document.createElement('iframe');
  // var body = document.getElementsByTagName('body')[0];
  // body.appendChild(proxyIframe);
  // proxyIframe.style.width = '100%';
  // proxyIframe.style.height = '100%';
  // proxyIframe.style.display = 'none';
  // var contentWindow = proxyIframe.contentWindow;
  // contentWindow.document.open();
  // // Set dimensions according to your needs.
  // // You may need to calculate the dynamically after the content has loaded
  // let url = "http://www.africau.edu/images/default/sample.pdf"
  // contentWindow.document.write('<iframe src="' + url + '" onload="print();" width="1000" height="1800" frameborder="0" marginheight="0" marginwidth="0">');
  // contentWindow.document.close();
};

export const availabilty_msg = (msg) => {
  let msg_detail = "";
  if (msg.indexOf("<b>")) {
    msg_detail = (
      <div>
        {msg_detail.substring(0, msg.indexOf("<b>"))}
        <span>{msg.substr(msg.indexOf("<b>"), msg.lastIndexOf("</b>"))}</span>
      </div>
    );
  }
  return msg_detail;
};

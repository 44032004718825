export const GET_OTP = "GET_OTP";
export const VERIFY_OTP = "VERIFY_OTP";
export const SAVE_CUSTOMER_DETAILS = "SAVE_CUSTOMER_DETAILS";
export const SAVE_CUSTOMER_STATUS = "SAVE_CUSTOMER_STATUS";
export const SAVE_NEW_CUSTOMER_DETAILS = "SAVE_NEW_CUSTOMER_DETAILS";
export const SAVE_CUSTOMER_TYPE = "SAVE_CUSTOMER_TYPE";
export const SAVE_STORE_INFO = "SAVE_STORE_INFO";
export const SAVE_LOYALITY_BENEFITS = "SAVE_LOYALITY_BENEFITS";
export const GET_LOYALITY_BENEFITS = "GET_LOYALITY_BENEFITS";
export const VERIFY_OTP_LOADING = "VERIFY_OTP_LOADING";
export const CLEAR_OTP_DATA = "CLEAR_OTP_DATA";
export const PROCEED_WITHOUT_OTP = "PROCEED_WITHOUT_OTP";
export const VERIFY_OTP_FOR_WALLET_AND_COUPON = "VERIFY_OTP_FOR_WALLET_AND_COUPON";

export const getOtp = (payload) => {
  return {
    type: GET_OTP,
    payload,
  };
};

export const verifyOtp = (payload) => {
  return {
    type: VERIFY_OTP,
    payload,
  };
};

export const proceedWithoutOTP = (payload) => {
  return {
    type: PROCEED_WITHOUT_OTP,
    payload,
  };
}

export const saveCustomerDetails = (payload) => {
  return {
    type: SAVE_CUSTOMER_DETAILS,
    payload,
  };
};

export const saveCustomerWithNumberStatus = (payload) => {
  return {
    type: SAVE_CUSTOMER_STATUS,
    payload,
  };
};
export const saveNewCustomerDetailsInDb = (payload) => {
  return {
    type: SAVE_NEW_CUSTOMER_DETAILS,
    payload,
  };
};

export const saveCustomerType = (payload) => {
  return {
    type: SAVE_CUSTOMER_TYPE,
    payload: payload,
  };
};

export const saveStoreInfo = (payload) => {
  return {
    type: SAVE_STORE_INFO,
    payload: payload,
  };
};

export const saveLoyalityBenefits = (payload) => {
  return {
    type: SAVE_LOYALITY_BENEFITS,
    payload: payload,
  };
};
export const getLoyalityBenefits = (payload) => {
  return {
    type: GET_LOYALITY_BENEFITS,
    payload: payload,
  };
};

export const verifyOtpLoading = (payload) => {
  return {
    type: VERIFY_OTP_LOADING,
    payload: payload,
  };
};

export const clearOtpData = () => {
  return {
    type: CLEAR_OTP_DATA,
  };
};

export const verifyOTPforWalletAndCoupon = (payload) => {
  return {
    type: VERIFY_OTP_FOR_WALLET_AND_COUPON,
    payload: payload
  }
}
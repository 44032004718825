import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ConfirmButton from "../Buttons/ConfirmButton/ConfirmButton";
import PaymentSuccess from "../../assets/payment-success.gif";
import PaymentFailure from "../../assets/payment-failed.gif";
import "./RetryPayment.scss";
import GenericModal from "../GenricModal/GenricModal";
import Invoice from "../Invoice/Invoice";
import { API_OrderStatus } from "../../services/APIServices";
import CancelOrder from "../CancelOrderModal/CancelOrder";
import Loader from "../Loader/Loader";
import Payment from "../Stepper/Steps/Payment/Payment";
import {
  setProgressStep,
  stockRelease,
  setPollingTimerID,
} from "../../actions/CommonActions";
import {
  clearOrderData,
  cancelOpenTransaction,
  updateShipmentInfo,
} from "../../actions/PaymentActions";
import PaymentModal from "../Modals/PaymentModal";
// import { CancelOrderAction } from '../../pos-offline/order-cancellation/actions'
import { OrderCreated } from "../../utils/cleverTap";
import { triggerEvent } from "../../utils/segment";

const RetryPayment = (props) => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [retryPayment, setRetryPayment] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [orderStatus, setOrderStatus] = useState(null);
  const [invoiceFor, setInvoiceFor] = useState("");
  const [showCancelOrderPopUp, setShowCancelOrderPopUp] = useState(false);
  const [changePaymentLoader, setChangePaymentLoader] = useState(false);
  const paymentMode =
    props.paymentDetails &&
    props.paymentDetails.orderData &&
    props.paymentDetails.orderData.payments &&
    props.paymentDetails.orderData.payments.length &&
    props.paymentDetails.orderData.payments.map((i) => {
      return i.type;
    });
  useEffect(() => {
    setLoading(true);
    if (props.paymentDetails && props.paymentDetails.orderData) {
      const orderApiTimer = setInterval(() => {
        API_OrderStatus({
          order_id: props.paymentDetails.orderData.order_id,
          customer_key: props.otpReducer.customerDetails.customer_key,
          store_code: props.login.storeId,
        }).then((res) => {
          if (res && res.data) {
            props.updateShipmentInfo(res.data.shipments);
            if (
              res.data.payment_info.payment_status !== "CREATED_WITH_UNKNOWN"
            ) {
              clearInterval(orderApiTimer);
              setLoading(false);
              setOrderStatus(res.data.payment_info.payment_status);
              if (
                res.data.payment_info.payment_status === "CREATED_WITH_SUCCESS"
              ) {
                triggerEvent({
                  action_type: "payment_completed",
                  is_guest:
                    props.otpReducer.customer_type === "guest" ? true : false,
                  order_id: props.paymentDetails.orderData.order_id,
                  is_existing_customer:
                    props.otpReducer.customer_type !== "guest" &&
                    !props.otpReducer.new_customer
                      ? true
                      : false,
                  attributes: {},
                  item_count_takeaway:
                    props.takeAwayReducer &&
                    props.takeAwayReducer.cartData &&
                    props.takeAwayReducer.cartData.cartItems &&
                    props.takeAwayReducer.cartData.cartItems.length
                      ? props.takeAwayReducer.cartData.cartItems.length
                      : "",
                  item_count_delivery:
                    props.DeliveryOrderReducer &&
                    props.DeliveryOrderReducer.deliveryCart &&
                    props.DeliveryOrderReducer.deliveryCart.products &&
                    props.DeliveryOrderReducer.deliveryCart.products.length
                      ? props.DeliveryOrderReducer.deliveryCart.products.length
                      : "",
                  customer_key: props.otpReducer.customerDetails.customer_key,
                  id: props.otpReducer.uid,
                });
                triggerEvent({
                  action_type: "order_success",
                  is_guest:
                    props.otpReducer.customer_type === "guest" ? true : false,
                  order_id: props.paymentDetails.orderData.order_id,
                  is_existing_customer:
                    props.otpReducer.customer_type !== "guest" &&
                    !props.otpReducer.new_customer
                      ? true
                      : false,
                  attributes: {},
                  item_count_takeaway:
                    props.takeAwayReducer &&
                    props.takeAwayReducer.cartData &&
                    props.takeAwayReducer.cartData.cartItems &&
                    props.takeAwayReducer.cartData.cartItems.length
                      ? props.takeAwayReducer.cartData.cartItems.length
                      : "",
                  item_count_delivery:
                    props.DeliveryOrderReducer &&
                    props.DeliveryOrderReducer.deliveryCart &&
                    props.DeliveryOrderReducer.deliveryCart.products &&
                    props.DeliveryOrderReducer.deliveryCart.products.length
                      ? props.DeliveryOrderReducer.deliveryCart.products.length
                      : "",
                  customer_key: props.otpReducer.customerDetails.customer_key,
                  id: props.otpReducer.uid,
                });

                const activeStore = props.login.storeId;
                const storeId =
                  props.login.agentDetails &&
                  props.login.agentDetails.organization.find(
                    (item) => item.store_code === activeStore
                  ).hub_id;
                const create_order_hub =
                  Object.keys(props.DeliveryOrderReducer.deliveryCart).length >
                  0
                    ? props.DeliveryOrderReducer.selectedLocationHub
                    : storeId;

                OrderCreated({
                  user_type: props.otpReducer.customer_type,
                  meatopia_status:
                    props.otpReducer.loyalityBenefits &&
                    props.otpReducer.loyalityBenefits.subscribed &&
                    props.otpReducer.loyalityBenefits.subscribed.days_left > 0
                      ? "member"
                      : "non_member",
                  store_city_name: props.login.storeId,
                  delivery_city_name:
                    props.DeliveryOrderReducer.selectedLocality.description ||
                    "",
                  store_id: create_order_hub,
                  hub_id: props.DeliveryOrderReducer.selectedLocationHub || "",
                  order_id: props.paymentDetails.orderData.order_id,
                  delivery_item_names: props.DeliveryOrderReducer.deliveryCartId
                    ? getIDs("delivery", "item_name")
                    : "",
                  takeaway_item_names: props.takeAwayReducer.takeawayCartId
                    ? getIDs("takeaway", "item_name")
                    : "",
                  coupon_name: props.paymentDetails.appliedCoupon || "",
                  delivery_item_count: props.DeliveryOrderReducer.deliveryCartId
                    ? getIDs("delivery", "item_count")
                    : "",
                  takeaway_item_count: props.takeAwayReducer.takeawayCartId
                    ? getIDs("takeaway", "item_count")
                    : "",
                  address_id: props.DeliveryOrderReducer.deliveryCartId
                    ? props.DeliveryOrderReducer.customerAddress.address_id
                    : "",
                  bill_paidamount: Math.abs(
                    props.paymentDetails.combineCartData.total -
                      (props.paymentDetails.combineCartData.licious_wallet
                        .payable_wallet_amount || 0)
                  ),
                  bill_discountamount: props.paymentDetails.combineCartData.order_charges.find(
                    (item) => item.key === "discount"
                  ).value,
                  bill_walletamount: props.paymentDetails.combineCartData.order_charges.find(
                    (item) => item.key === "liciouswallet"
                  ).value,
                  bill_deliveryamount: props.paymentDetails.combineCartData.order_charges.find(
                    (item) => item.key === "deliverycharge"
                  ).value,
                  order_type: props.paymentDetails.orderType,
                });
              }
            }
            if (
              res.data.shipments.some(
                (shipment) =>
                  shipment.shipment_messages.order_status === "Cancelled"
              )
            ) {
              clearInterval(orderApiTimer);
              setLoading(false);
              setOrderStatus("Cancelled");
            }
          }
        });
      }, 5000);
      props.setPollingTimerID(orderApiTimer);
    }
  }, []);
  const cancelOpenTransaction = (changeMethod = false) => {
    props.cancelOpenTransaction({
      data: {
        store_code: props.login.storeId,
        parent_order_id: props.paymentDetails.orderData.order_id,
      },
      goToDashboard: !changeMethod ? goToDashboard : null,
    });
  };

  const getIDs = (type, param) => {
    let ids = "";
    switch (type) {
      case "takeaway":
        props.takeAwayReducer.TotalCartItems.cartItems &&
          props.takeAwayReducer.TotalCartItems.cartItems.forEach((item) => {
            if (param === "category_id") ids += `${item.productId};`;
            else if (param === "item_name") ids += `${item.productName};`;
            else ids += `${item.productId};`;
          });
        ids = ids.slice(0, -1); //removing ; in the end
        return param === "item_count"
          ? props.takeAwayReducer.TotalCartItems.cartItems.length
          : ids;
      case "delivery":
        props.DeliveryOrderReducer.deliveryCart.products &&
          props.DeliveryOrderReducer.deliveryCart.products.forEach((item) => {
            if (param === "category_id") ids += `${item.category_id};`;
            else if (param === "item_name") ids += `${item.product_name};`;
            else ids += `${item.product_id};`;
          });
        ids = ids.slice(0, -1);
        return param === "item_count"
          ? props.DeliveryOrderReducer.deliveryCart.products.length
          : ids;
      default:
        return null;
    }
  };

  const goToDashboard = () => {
    if (props.takeAwayReducer.takeawayCartId) {
      props.stockRelease({ cart_id: props.takeAwayReducer.takeawayCartId });
    }
    if (props.DeliveryOrderReducer.deliveryCartId) {
      props.stockRelease({
        cart_id: props.DeliveryOrderReducer.deliveryCartId,
      });
    }
    props.history.push("/dashboard-orders");
    props.clearOrderData();
  };

  const cancelOrder = () => {
    setShowCancelOrderPopUp(!showCancelOrderPopUp);
  };

  return (
    <>
      {loading ? (
        <>
          <div className="button-container">
            <button
              className="cancel-order"
              onClick={(e) => {
                setShowCancelOrderPopUp(true);
              }}
            >
              START NEW ORDER
            </button>
          </div>
          <div className="order-status-loader">
            <div className="loader-container">
              <div class="loader"></div>
              <img
                src={
                  "https://d2407na1z3fc0t.cloudfront.net/Banner/licious_smile.png"
                }
                alt="licious_loader"
              />
            </div>
            {paymentMode && paymentMode.includes("pinelabs") && (
              <button
                className="change-payment"
                onClick={(e) => {
                  cancelOpenTransaction(true);
                  setChangePaymentLoader(true);
                }}
              >
                {changePaymentLoader ? <Loader /> : "Change Payment Method"}
              </button>
            )}
          </div>
        </>
      ) : (
        <>
          {orderStatus === "CREATED_WITH_SUCCESS" ? (
            <div className="order-confirmation">
              <img
                src={PaymentSuccess}
                alt="payment-success"
                className="payment-success-image"
              />
              <div>
                Order ID:
                <b>
                  {props.paymentDetails &&
                    props.paymentDetails.orderData &&
                    props.paymentDetails.orderData.order_id}
                </b>
              </div>
              <div>Paid!</div>
              <div className="receipt-holder">
                {props.paymentDetails &&
                  props.paymentDetails.orderData &&
                  props.paymentDetails.orderData.orderDetails.shipment_order_ids.map(
                    (item) => (
                      <ConfirmButton
                        onClick={() => {
                          triggerEvent({
                            action_type: "print_invoice",
                            is_guest:
                              props.otpReducer.customer_type === "guest"
                                ? true
                                : false,
                            order_id: props.paymentDetails.orderData.order_id,
                            is_existing_customer:
                              props.otpReducer.customer_type !== "guest" &&
                              !props.otpReducer.new_customer
                                ? true
                                : false,
                            attributes: {},
                            item_count_takeaway:
                              props.takeAwayReducer &&
                              props.takeAwayReducer.cartData &&
                              props.takeAwayReducer.cartData.cartItems &&
                              props.takeAwayReducer.cartData.cartItems.length
                                ? props.takeAwayReducer.cartData.cartItems
                                    .length
                                : "",
                            item_count_delivery:
                              props.DeliveryOrderReducer &&
                              props.DeliveryOrderReducer.deliveryCart &&
                              props.DeliveryOrderReducer.deliveryCart
                                .products &&
                              props.DeliveryOrderReducer.deliveryCart.products
                                .length
                                ? props.DeliveryOrderReducer.deliveryCart
                                    .products.length
                                : "",
                            customer_key:
                              props.otpReducer.customerDetails.customer_key,
                            id: props.otpReducer.uid,
                          });

                          setOrderId(item.order_id);
                          if (item.is_takeaway) {
                            setInvoiceFor("Takeaway");
                          } else {
                            setInvoiceFor("Delivery");
                          }
                          setShow(item);
                        }}
                      >
                        {item.is_takeaway
                          ? "Print TakeAway Receipt"
                          : "Print Delivery Receipt"}
                      </ConfirmButton>
                    )
                  )}
              </div>
              <ConfirmButton
                onClick={() => {
                  triggerEvent({
                    action_type: "invoice_completed",
                    is_guest:
                      props.otpReducer.customer_type === "guest" ? true : false,
                    order_id: props.paymentDetails.orderData.order_id,
                    is_existing_customer:
                      props.otpReducer.customer_type !== "guest" &&
                      !props.otpReducer.new_customer
                        ? true
                        : false,
                    attributes: {},
                    item_count_takeaway:
                      props.takeAwayReducer &&
                      props.takeAwayReducer.cartData &&
                      props.takeAwayReducer.cartData.cartItems &&
                      props.takeAwayReducer.cartData.cartItems.length
                        ? props.takeAwayReducer.cartData.cartItems.length
                        : "",
                    item_count_delivery:
                      props.DeliveryOrderReducer &&
                      props.DeliveryOrderReducer.deliveryCart &&
                      props.DeliveryOrderReducer.deliveryCart.products &&
                      props.DeliveryOrderReducer.deliveryCart.products.length
                        ? props.DeliveryOrderReducer.deliveryCart.products
                            .length
                        : "",
                    customer_key: props.otpReducer.customerDetails.customer_key,
                    id: props.otpReducer.uid,
                  });

                  goToDashboard();
                }}
              >
                GO TO DASHBOARD
              </ConfirmButton>
              {orderId &&
              props.paymentDetails.shipmentInfo &&
              props.paymentDetails.shipmentInfo.length ? (
                <GenericModal
                  show={show}
                  cancel={setShow}
                  className="receipt-modal"
                >
                  <Invoice
                    orderId={orderId}
                    setShow={setShow}
                    shipmentInfo={props.paymentDetails.shipmentInfo}
                    invoiceFor={invoiceFor}
                  />
                </GenericModal>
              ) : null}
            </div>
          ) : orderStatus === "CREATED_WITH_FAILURE" ? (
            <>
              {retryPayment ? (
                <>
                  <div className="button-container">
                    <button
                      className="cancel-order"
                      onClick={(e) => {
                        setShowCancelOrderPopUp(true);
                      }}
                    >
                      START NEW ORDER
                    </button>
                  </div>
                  <Payment navigatingFrom={"order-status"} />
                </>
              ) : (
                <PaymentModal setRetryPayment={setRetryPayment} />
              )}
            </>
          ) : orderStatus === "Cancelled" ? (
            <div className="order-confirmation">
              <img
                src={PaymentFailure}
                alt="payment-failure"
                className="payment-success-image"
              />
              <div>
                Order ID:{" "}
                <b>
                  {props.paymentDetails &&
                    props.paymentDetails.orderData &&
                    props.paymentDetails.orderData.order_id}
                </b>
              </div>
              <div>Order could not be completed!</div>
              <ConfirmButton
                onClick={() => {
                  goToDashboard();
                }}
              >
                GO TO DASHBOARD
              </ConfirmButton>
            </div>
          ) : null}
        </>
      )}
      {props.paymentDetails.shipmentInfo &&
        props.paymentDetails.shipmentInfo.length && (
          <GenericModal
            show={showCancelOrderPopUp}
            cancel={() => {
              setShowCancelOrderPopUp(false);
            }}
            className="main-cancel-order-modal"
          >
            <CancelOrder
              cancelOpenTransaction={cancelOpenTransaction}
              cancelOrder={(e) => cancelOrder()}
              shipmentInfo={props.paymentDetails.shipmentInfo}
            />
          </GenericModal>
        )}
    </>
  );
};

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    orderJourneyStepChange: (step) => {
      dispatch(setProgressStep(step));
    },
    clearOrderData: () => {
      dispatch(clearOrderData());
    },
    stockRelease: (payload) => {
      dispatch(stockRelease(payload));
    },
    setPollingTimerID: (payload) => dispatch(setPollingTimerID(payload)),
    // cancelOrder: (payload) => dispatch(CancelOrderAction(payload)),
    cancelOpenTransaction: (payload) =>
      dispatch(cancelOpenTransaction(payload)),
    updateShipmentInfo: (payload) => {
      dispatch(updateShipmentInfo(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RetryPayment);

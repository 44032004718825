import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import Login from "./components/login/Login";
import MerchantSelectionPage from "./components/login/MerchantSelectionPage";
import Cookies from "js-cookie";

import LiciousLogo from "./assets/LiciousLogo.svg";
import LoggedOut from "./assets/LoggedOut.svg";
import LoggedIn from "./assets/LoggedIn.svg";
import { clearLoginDetails } from "./actions/LoginActions";
import { clearUserProfile } from "./actions/CommonActions";
import { ToastContainer } from "react-toastify";
// import {
//   ResetCustomerCacheAction,
//   ResetCustomerDetailsAction,
// } from './pos-offline/onboard/actions'
import { resetUserCartAction } from "./actions/ProductListActions";
// import {
//   ResetHubByLatLngAction,
//   SetSelectedLocality,
// } from './locality-search-box/actions'

import Dashboard from "./pages/newDashboard/dashboard";
import VerifyUser from "./pages/verifyUser/VerifyUser";
import OrderDetails from "./pages/orderDetails/OrderDetails";
import classnames from "classnames";
import RetryPayment from "../src/components/RetryPayment/RetryPayment";
import OrderHistory from "./pages/orderHistory/OrderHistory";
import MerchantOrder from "./pages/merchantOrder/MerchantOrder";
class ReduxComponent extends Component {
  container = React.createRef();
  constructor(props) {
    super(props);

    this.state = {
      agentLoginStatus: false,
      logoutDropdown: false,
    };
    this.agentDetails = null;
  }

  // componentDidMount() {
  //   document.addEventListener("mousedown", this.handleClickOutside);
  // }
  // componentWillUnmount() {
  //   document.removeEventListener("mousedown", this.handleClickOutside);
  // }

  componentWillReceiveProps(nextProps) {
    // if (nextProps.agentDetails) {
    // 	this.agentProfle = nextProps.agentDetails;
    // 	localStorage.setItem("accessToken", this.agentProfle.token);
    //   this.setState({agentLoginStatus: true});
    //   this.props.history.push("/merchant-selection")
    // } else if (nextProps.loginFailed ) {//&& !this.props.loginFailed
    // 	localStorage.removeItem("accessToken");
    //   this.setState({agentLoginStatus: false});
    //   this.props.history.push("/login")
    // 	this.setState({agentLoginStatus: "loggedIn"});
    // } else if (nextProps.loginFailed ) {//&& !this.props.loginFailed
    // 	localStorage.removeItem("accessToken");
    // 	this.setState({agentLoginStatus: false});
    // }
  }
  // handleClickOutside = (event) => {
  //   if (
  //     this.container.current &&
  //     !this.container.current.contains(event.target)
  //   ) {
  //     this.setState({
  //       logoutDropdown: false,
  //     });
  //   }
  // };
  getAllStoresForMarchent = (agentProfle) => {
    let storeIds = [];
    agentProfle.organization.forEach((org) => {
      storeIds.push(org.store_code);
    });
    return storeIds;
  };

  checkAgentLoginStatus = () => {
    const accessToken = localStorage.getItem("accessToken");
    const storeDetails = localStorage.getItem("storeDetails");
    if (accessToken && storeDetails) {
      this.setState({ agentLoginStatus: true });
    }
    // else if (accessToken && !storeDetails) {//only loggedIn, store selection not done
    // 	this.setState({agentLoginStatus: "loggedIn"});
    // }
    else {
      //DO logout
      this.doLogout();
    }
  };

  updateAgentStoreSelection = () => {
    this.setState({ agentLoginStatus: "complete" });
  };

  doLogout = () => {
    localStorage.clear();
    const {
      clearLoginDetails,
      clearUserProfile,
      // ResetHubByLatLngAction,
      resetUserCartAction,
      // SetSelectedLocality,
    } = this.props;
    // if (isLoggedIn) {
    clearLoginDetails();
    clearUserProfile();
    // ResetHubByLatLngAction()
    resetUserCartAction();
    // SetSelectedLocality('')
    Cookies.remove("accessToken");
    this.props.history.push("/login");
    this.forceUpdate();
    // }
  };

  render() {
    const { match, isLoggedIn } = this.props;
    return (
      <div className="merchant-app-container">
        <ToastContainer autoClose={4000} hideProgressBar={true} />
        <div className="merchant-app-header">
          <div className="header-left">
            <img className="app-logo" src={LiciousLogo} alt="logo" />
          </div>
          <div className="header-right">
            {!isLoggedIn ? (
              <img className="logout-icon" src={LoggedOut} alt="user" />
            ) : (
              <div className="agent-details-holder">
                <img className="login-icon" src={LoggedIn} alt="user" />
                <div
                  onClick={() =>
                    this.setState({
                      logoutDropdown: !this.state.logoutDropdown,
                    })
                  }
                >
                  {this.props.agentDetails.name}
                  {/* <ChevronDown height={8} /> */}
                </div>
                <div
                  className={classnames("logout-dropdown")}
                  // ref={this.container}
                  onClick={this.doLogout}
                >
                  Logout
                </div>
              </div>
            )}
          </div>
        </div>

        <Switch>
          <Route exact path={`${match.url}login`} component={Login} />
          {/* <Route exact path={`${match.url}order-list`} component={OrderList} /> */}
          <Route
            path={`${match.url}merchant-selection`}
            exact
            render={(routeProps) =>
              isLoggedIn ? (
                <MerchantSelectionPage {...routeProps} />
              ) : (
                <Redirect to="/login" />
              )
            }
          />
          <Route
            path={`/dashboard-orders`}
            exact
            render={(routeProps) =>
              isLoggedIn ? (
                <Dashboard {...routeProps} />
              ) : (
                <Redirect to="/login" />
              )
            }
          />
          <Route
            path={`/verify-user`}
            exact
            render={(routeProps) =>
              isLoggedIn ? (
                <VerifyUser {...routeProps} />
              ) : (
                <Redirect to="/login" />
              )
            }
          />
          <Route
            path={`/order-history`}
            exact
            render={(routeProps) =>
              isLoggedIn ? (
                <OrderHistory {...routeProps} />
              ) : (
                <Redirect to="/login" />
              )
            }
          />
          <Route
            path={`/merchant-orders`}
            exact
            render={(routeProps) =>
              isLoggedIn ? (
                <MerchantOrder {...routeProps} />
              ) : (
                <Redirect to="/login" />
              )
            }
          />
          <Route
            path={`/order-status`}
            exact
            render={(routeProps) =>
              isLoggedIn ? (
                <RetryPayment {...routeProps} />
              ) : (
                <Redirect to="/login" />
              )
            }
          />
          <Route
            path={`/order-details`}
            exact
            render={(routeProps) =>
              isLoggedIn ? (
                <OrderDetails {...routeProps} />
              ) : (
                <Redirect to="/login" />
              )
            }
          />
          <Route
            exact
            path={"/"}
            render={() =>
              isLoggedIn ? <Redirect to="/merchant-selection" /> : <Login />
            }
          />
          <Route
            path={`${match.url}merchant-selection`}
            exact
            render={(routeProps) => <MerchantSelectionPage {...routeProps} />}
          />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { agentDetails } = state.login;
  const isLoggedIn = !!agentDetails && !!Cookies.get("accessToken");
  return {
    agentDetails: state.login.agentDetails,
    loginFailed: state.login.loginFailed,
    isLoggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      clearLoginDetails,
      clearUserProfile,
      // ResetCustomerCacheAction,
      // ResetCustomerDetailsAction,
      resetUserCartAction,
      // ResetHubByLatLngAction,
      // SetSelectedLocality,
    },
    dispatch
  );
};
const ReduxComponentConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReduxComponent);

export default withRouter(ReduxComponentConnect);

import { put, takeEvery, call, fork, select } from "redux-saga/effects";
import {
  GET_COMBINE_CART_REQUEST,
  UPDATE_COMBINE_CART_DATA,
  UPDATE_COMBINE_CART_REQUEST,
} from "../actions/DeliveryOrderActions";
import {
  COMBINE_CART_DATA_LOADING,
  CREATE_ORDER_REQUEST,
  ORDER_REQUEST_SUCCESS,
  RETRY_PAYMENT_REQUEST,
  SET_PAYMENT_PROGRESS,
  UPDATE_CONTACTLESS_DELIVERY,
  UPDATE_CONTACTLESS_DELIVERY_SUCCESS,
  CANCEL_OPEN_TRANSACTION,
  SET_CREATE_ORDER_LOADING,
  POS_CANCEL_ORDER,
  POS_CANCEL_ORDER_FAILED,
  POS_CANCEL_ORDER_SUCCESS,
} from "../actions/PaymentActions";
import {
  API_CancelOpenTransaction,
  API_combineCart,
  API_CreateOrderEbo,
  API_RetryPayment,
  API_UpdateContactlessDelivery,
  API_RejectOrder,
} from "../services/APIServices";
import ToastMessage from "../utils/ToastMessage";
function* getCombineCartData(action) {
  let errMsg = "Failed to Change Address";
  yield put({ type: COMBINE_CART_DATA_LOADING, payload: true });
  const orderType = yield select((state) => state.paymentDetails.orderType);
  let api_payload = {
    customer_key: action.payload.customer_key,
    type: orderType,
  };
  if (action.payload.shipment_id) {
    api_payload["shipment_id"] = action.payload.shipment_id;
  }
  try {
    let response = yield call(API_combineCart, api_payload);

    if (response.status === "success") {
      yield put({ type: COMBINE_CART_DATA_LOADING, payload: false });

      yield put({
        type: UPDATE_COMBINE_CART_DATA,
        payload: {
          licious_wallet: response.licious_wallet,
          order_charges: response.order_charges,
          order_total: response.order_total,
          total: response.order_charges.find((item) => item.key === "total")
            .value,
        },
      });
      // yield put({ type: DATA_LOADING, payload: false });
      // yield put({
      //   type: UPDATE_DELIVERY_CART_SUCCESS,
      //   payload: {},
      // });
    } else {
      if (response.statusMessage == "error") {
        errMsg = response.message;
      }
      ToastMessage(errMsg);
    }
  } catch (error) {
    yield put({ type: COMBINE_CART_DATA_LOADING, payload: false });

    if (!!error.message) {
      //   yield put({ type: DATA_LOADING, payload: false });
      yield put({
        // type: UPDATE_DELIVERY_CART_FAIL,
      });
      errMsg = error.message;
    }
    ToastMessage(errMsg);
  }
}

function* createOrderSagas(action) {
  let errMsg = "Failed to Create order";
  // const getDeliveryProducts = yield select(
  //   (state) => state.DeliveryOrderReducer.deliveryCart.products,
  // )
  // const getTakeawayProducts = yield select(
  //   (state) => state.takeAwayReducer.TotalCartItems.cartItems,
  // )
  yield put({
    type: SET_CREATE_ORDER_LOADING,
    payload: true,
  });
  try {
    let response = yield call(API_CreateOrderEbo, action.payload.params);
    yield put({ type: SET_PAYMENT_PROGRESS, payload: true });

    if (response.order_id) {
      yield put({
        type: ORDER_REQUEST_SUCCESS,
        payload: response,
      });
      action.payload.setShowConfirmation(true);
      ToastMessage(response.statusMessage);
    } else if (response.status === "error" || response.statusCode === 504) {
      // yield put({ type: DATA_LOADING, payload: false });
      errMsg = response.statusMessage ? response.statusMessage : "error";

      ToastMessage(errMsg);
    }
    yield put({ type: SET_CREATE_ORDER_LOADING, payload: false });
  } catch (error) {
    if (!!error.message) {
      //   yield put({ type: DATA_LOADING, payload: false });
      yield put({
        // type: UPDATE_DELIVERY_CART_FAIL,
      });
      errMsg = error.message;
    }
    ToastMessage(errMsg);
    yield put({ type: SET_CREATE_ORDER_LOADING, payload: false });
  }
}

function* retryPayment(action) {
  let errMsg = "Failed to Create order";
  // const getDeliveryProducts = yield select(
  //   (state) => state.DeliveryOrderReducer.deliveryCart.products,
  // )
  // const getTakeawayProducts = yield select(
  //   (state) => state.takeAwayReducer.TotalCartItems.cartItems,
  // )
  yield put({ type: SET_CREATE_ORDER_LOADING, payload: true });
  try {
    let response = yield call(API_RetryPayment, action.payload);
    yield put({ type: SET_PAYMENT_PROGRESS, payload: true });

    if (response.order_id) {
      yield put({
        type: ORDER_REQUEST_SUCCESS,
        payload: response,
      });
      action.payload.setShowConfirmation(true);
      ToastMessage(response.statusMessage);
      action.payload.reloadPage();
    } else if (response.status === "error" || response.statusCode === 504) {
      // yield put({ type: DATA_LOADING, payload: false });
      errMsg = response.statusMessage;

      ToastMessage(errMsg);
    }
    yield put({ type: SET_CREATE_ORDER_LOADING, payload: false });
  } catch (error) {
    if (!!error.message) {
      //   yield put({ type: DATA_LOADING, payload: false });
      yield put({
        // type: UPDATE_DELIVERY_CART_FAIL,
      });
      errMsg = error.message;
    }
    ToastMessage(errMsg);
    yield put({ type: SET_CREATE_ORDER_LOADING, payload: false });
  }
}
function* updateContactlessDelivery(action) {
  let errMsg = "Failed to Change Address";

  try {
    let response = yield call(API_UpdateContactlessDelivery, action.payload);

    if (response.statusMessage === "success") {
      yield put({
        type: UPDATE_CONTACTLESS_DELIVERY_SUCCESS,
        payload: response.data.selected,
      });
      yield put({
        type: GET_COMBINE_CART_REQUEST,
        payload: {
          customer_key: action.payload.customer_key,
        },
      });
      // yield put({ type: DATA_LOADING, payload: false });
      // yield put({
      //   type: UPDATE_DELIVERY_CART_SUCCESS,
      //   payload: {},
      // });
    } else {
      if (response.status == "error" || response.statusCode === 504) {
        errMsg = "error";
      }
      ToastMessage(errMsg);
    }
  } catch (error) {
    if (!!error.message) {
      //   yield put({ type: DATA_LOADING, payload: false });
      yield put({
        // type: UPDATE_DELIVERY_CART_FAIL,
      });
      errMsg = error.message;
    }
    ToastMessage(errMsg);
  }
}

function* cancelOpenTransaction(action) {
  try {
    let response = yield call(API_CancelOpenTransaction, action.payload.data);
    if (response.status && action.payload.goToDashboard) {
      const shipmentInfo = yield select(
        (state) => state.paymentDetails.shipmentInfo
      );
      const agentDetailName = yield select(
        (state) => state.login.agentDetails.name
      );
      if (shipmentInfo.length) {
        for (let i = 0; i < shipmentInfo.length; i++) {
          yield put({
            type: POS_CANCEL_ORDER,
            payload: {
              order_id: shipmentInfo[i].order_id,
              by_user: agentDetailName,
              reject_reason: "payment-failure",
            },
          });
        }
      }
      action.payload.goToDashboard();
    }
  } catch (e) {
    ToastMessage(e.message);
    const shipmentInfo = yield select(
      (state) => state.paymentDetails.shipmentInfo
    );
    const agentDetailName = yield select(
      (state) => state.login.agentDetails.name
    );
    if (shipmentInfo.length) {
      for (let i = 0; i < shipmentInfo.length; i++) {
        yield put({
          type: POS_CANCEL_ORDER,
          payload: {
            order_id: shipmentInfo[i].order_id,
            by_user: agentDetailName,
            reject_reason: "payment-failure",
          },
        });
      }
    }
    action.payload.goToDashboard();
  }
}

function* cancelOrder(action) {
  let errMsg = "Failed to reject order";
  try {
    const response = yield call(API_RejectOrder, action.payload);
    if (response.status === "success") {
      yield put({
        type: POS_CANCEL_ORDER_SUCCESS,
        data: response,
      });
      ToastMessage("Order has been cancelled", "SUCCESS");
    } else {
      if (!!response.message) {
        errMsg = response.message;
      }
      ToastMessage(errMsg);
      yield put({
        type: POS_CANCEL_ORDER_FAILED,
        data: response,
      });
    }
  } catch (e) {
    if (!!e.message) {
      errMsg = e.message;
    }
    ToastMessage(errMsg);
    yield put({
      type: POS_CANCEL_ORDER_FAILED,
      data: e,
    });
  }
}

export default function* PaymentSaga() {
  yield [fork(takeEvery, GET_COMBINE_CART_REQUEST, getCombineCartData)];
  yield [
    fork(takeEvery, UPDATE_CONTACTLESS_DELIVERY, updateContactlessDelivery),
  ];
  yield [fork(takeEvery, CREATE_ORDER_REQUEST, createOrderSagas)];
  yield [fork(takeEvery, RETRY_PAYMENT_REQUEST, retryPayment)];
  yield [fork(takeEvery, CANCEL_OPEN_TRANSACTION, cancelOpenTransaction)];
  yield [fork(takeEvery, POS_CANCEL_ORDER, cancelOrder)];
}

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import history from "../../utils/history";
import "./CancelOrder.scss";
import { resetDeliveryCart } from "../../actions/DeliveryOrderActions";
import {
  removeItemFromCartRequest,
  dummyCartRequest,
  resetTakeawayCart,
  getCartRequest,
  setAddingToTakeawayCartLoad,
} from "../../actions/TakeAwayActions";
import { stockRelease } from "../../actions/CommonActions";
import { triggerEvent } from "../../utils/segment";
// import { CancelOrderAction } from '../../pos-offline/order-cancellation/actions'
function CancelOrder(props) {
  const confirmCancelOrder = () => {
    // let params = {
    //   customer_key: customer_key,
    // }
    // props.resetTakeawayCartRequest({ params })
    // let getCartparams = {
    //   customer_key: customer_key,
    // }
    // props.getCartRequest({ params: getCartparams })
    // if (props.otpReducer.customer_type !== 'guest') {
    //   props.resetTotalDeliveryCart({
    //     params: {
    //       customer_key: customer_key,
    //     },
    //   })
    // }
    // if (props.takeAwayReducer.takeawayCartId) {
    //   props.stockRelease({ cart_id: props.takeAwayReducer.takeawayCartId })
    // }
    // if (props.DeliveryOrderReducer.deliveryCartId) {
    //   props.stockRelease({ cart_id: props.DeliveryOrderReducer.deliveryCartId })
    // }
    triggerEvent({
      action_type: "order_cancelled",
      is_guest: props.otpReducer.customer_type === "guest" ? true : false,
      order_id: props.paymentDetails.orderData.order_id,
      is_existing_customer:
        props.otpReducer.customer_type !== "guest" &&
        !props.otpReducer.new_customer
          ? true
          : false,
      attributes: {},
      item_count_takeaway:
        props.takeAwayReducer &&
        props.takeAwayReducer.cartData &&
        props.takeAwayReducer.cartData.cartItems &&
        props.takeAwayReducer.cartData.cartItems.length
          ? props.takeAwayReducer.cartData.cartItems.length
          : "",
      item_count_delivery:
        props.DeliveryOrderReducer &&
        props.DeliveryOrderReducer.deliveryCart &&
        props.DeliveryOrderReducer.deliveryCart.products &&
        props.DeliveryOrderReducer.deliveryCart.products.length
          ? props.DeliveryOrderReducer.deliveryCart.products.length
          : "",
      customer_key: props.otpReducer.customerDetails.customer_key,
      id: props.otpReducer.uid,
    });
    props.setAddingToTakeawayCartLoad(true);
    let dummyCartItems = { cartItems: [] };
    props.dummyCartRequest({ dummyCartItems });
    if (props.shipmentInfo && props.shipmentInfo.length) {
      props.cancelOpenTransaction();
    }
    history.push("/dashboard-orders");
  };

  return (
    <div className="cancel-order-container">
      <div className="container-heading">Cancel Order</div>
      <div className="sub-heading">
        Are you sure you want to cancel this order?{" "}
      </div>
      <div className="note">
        {" "}
        Note: Cancellation will remove all the Items from the cart and restart a
        new order
      </div>
      <div className="button-class">
        <button className="dismiss-btn" onClick={(e) => props.cancelOrder()}>
          Dismiss
        </button>
        <button className="cancel-btn" onClick={(e) => confirmCancelOrder()}>
          Cancel Order
        </button>
      </div>
    </div>
  );
}
const mapDispatchToProps = (dispatch) => {
  return {
    removeItemFromCartRequest: ({ params }) => {
      dispatch(removeItemFromCartRequest({ params }));
    },
    dummyCartRequest: ({ dummyCartItems }) => {
      dispatch(dummyCartRequest({ dummyCartItems }));
    },
    resetTakeawayCartRequest: ({ params }) => {
      dispatch(resetTakeawayCart({ params }));
    },
    getCartRequest: ({ params }) => {
      dispatch(getCartRequest({ params }));
    },
    setAddingToTakeawayCartLoad: (value) => {
      dispatch(setAddingToTakeawayCartLoad(value));
    },
    resetTotalDeliveryCart: (payload) => {
      dispatch(resetDeliveryCart(payload));
    },
    stockRelease: (payload) => {
      dispatch(stockRelease(payload));
    },
    // cancelOrder: (payload) => dispatch(CancelOrderAction(payload)),
  };
};
const mapStateToProps = (state) => {
  return state;
};
const CancelOrderConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(CancelOrder);

export default CancelOrderConnect;

import React, { useState, useEffect } from "react";
import ScanModal from "./ScanModal";
import { ReactComponent as ScanBarcode } from "../../assets/Icon_Scan.svg";
import { ReactComponent as ScanBarcodeRed } from "../../assets/new-ebo-icons/Icon-Scan.svg";
import CancelIcon from "../../assets/cancel_icon.png";
import { connect } from "react-redux";
import {
  resetTakeawayCart,
  setTotalItemsInCart,
  dummyCartRequest,
  setCartData,
  setAddingToTakeawayCartLoad,
  removeItemFromCartRequest,
} from "../../actions/TakeAwayActions";
import "./AddToCart.scss";
import { removeItemFromCart } from "./utils/removeFromTakeaway";
const TakeAwayCart = (props) => {
  const activeStore = props.login.storeId;
  const hubId =
    props.login.agentDetails &&
    props.login.agentDetails.organization.find(
      (item) => item.store_code === activeStore
    ).hub_id;
  const [show, setShow] = useState(false);
  const [productForScan, setProductForScan] = useState(null);
  const {
    cartData,
    TotalCartItems,
    dummyCartData,
    isShowingConfirmModal,
    CustomerCartDataFromApi,
    addingToTakeawayCartLoad,
  } = props.takeAwayReducer;
  const data = dummyCartData && dummyCartData.cartItems;
  const { customer_key } = props.otpReducer.customerDetails;
  const item_count =
    TotalCartItems &&
    TotalCartItems.cartItems &&
    TotalCartItems.cartItems.length;
  const [gms, setGms] = useState();
  const [noItemsInCart, setNoItemsInCart] = useState(true);
  const filterDummyDataAfterUpdate = (productId) => {
    let dummyCartItems = { cartItems: [] };
    const index = data.findIndex((p) => {
      return p.productId === productId;
    });
    data.splice(index, 1);
    data.map((item) => dummyCartItems.cartItems.push(item));
    let combineData;
    if (cartData.cartItems && cartData.cartItems.length) {
      combineData = [...cartData.cartItems, ...dummyCartItems.cartItems];
    } else {
      combineData = [...dummyCartItems.cartItems];
    }
    props.dummyCartRequest({ dummyCartItems });
    props.setTotalItemsInCart({ cartItems: combineData });
  };

  useEffect(() => {
    const showNoItems =
      (Object.keys(TotalCartItems).length === 0 ||
        (TotalCartItems && TotalCartItems.cartItems.length === 0)) &&
      Object.keys(props.DeliveryOrderReducer.deliveryCart).length === 0
        ? true
        : false;
    setNoItemsInCart(showNoItems);
  }, [TotalCartItems, props.DeliveryOrderReducer.deliveryCart]);

  useEffect(() => {
    let data = { cartItems: [] };
    //write action to set cartdata from API response---
    CustomerCartDataFromApi.individual_products &&
      CustomerCartDataFromApi.individual_products.forEach((item) => {
        let cartProducts = {
          productId: item.product_id,
          productName: item.product_name,
          mrp: item.actual_selling_price,
          gms: item.actual_weight,
          data_from_api: true,
          actual_price: item.actual_price,
          uom: item.uom === "Kg" ? "gms" : item.uom,
          category_id: item.category_id,
        };
        data.cartItems.push(cartProducts);
      });
    CustomerCartDataFromApi.combo &&
      CustomerCartDataFromApi.combo.forEach((item) => {
        let comboProduct = {
          productId: item.product_id,
          productName: item.product_name,
          is_combo: true,
          gms: 0,
          childProducts: item.child_products,
          mrp: item.child_products.reduce(
            (a, b) => a + b.actual_selling_price,
            0
          ),
          data_from_api: true,
          actual_price: item.child_products.reduce(
            (a, b) => a + b.actual_price,
            0
          ),
          category_id: item.category_id,
        };

        data.cartItems.push(comboProduct);
      });
    props.setCartData(data);
    let combineData;
    if (dummyCartData && dummyCartData.cartItems.length) {
      combineData = [...data.cartItems, ...dummyCartData.cartItems];
    } else {
      combineData = [...data.cartItems];
    }
    props.setTotalItemsInCart({ cartItems: combineData });
  }, [
    CustomerCartDataFromApi.individual_products &&
      CustomerCartDataFromApi.individual_products.length,
    CustomerCartDataFromApi.combo && CustomerCartDataFromApi.combo.length,
  ]);

  const handleConfirmWeight = (product) => {
    setShow(!show);
    setProductForScan(product);
    props.setAddingToTakeawayCartLoad(false);
  };

  const resetCart = () => {
    let params = {
      customer_key: customer_key,
    };
    props.resetTakeawayCartRequest({ params });
    props.setAddingToTakeawayCartLoad(true);
    let dummyCartItems = { cartItems: [] };
    props.dummyCartRequest({ dummyCartItems });
    props.setTotalItemsInCart({ cartItems: [] });
  };

  const removeItem = (product, gms, dataFrom) => {
    if (gms || dataFrom) {
      removeItemFromCart(
        product,
        gms,
        props.removeItemFromCartRequest,
        props.setAddingToTakeawayCartLoad,
        hubId,
        customer_key
      );
    } else {
      let dummyCartItems = { cartItems: [] };
      const index = data.findIndex((p) => {
        return p.productId === product.productId;
      });

      data.splice(index, 1);
      data.map((item) => dummyCartItems.cartItems.push(item));
      let combineData;
      if (cartData.cartItems && cartData.cartItems.length) {
        combineData = [...cartData.cartItems, ...dummyCartItems.cartItems];
      } else {
        combineData = [...dummyCartItems.cartItems];
      }
      props.setTotalItemsInCart({ cartItems: combineData });
      props.dummyCartRequest({ dummyCartItems });
    }
  };
  // const removeItemFromCart = (product, gms) => {
  //   let params = {
  //     product_id: product.productId,
  //     hub_id: hubId.toString(),
  //     is_combo: product.is_combo,
  //     customer_key: customer_key,
  //   }
  //   if (product.is_combo) {
  //     params.combo_product_details = product.childProducts.map((item) => {
  //       return {
  //         product_id: item.product_id,
  //         actual_weight: item.actual_weight,
  //       }
  //     })
  //   } else {
  //     params.actual_weight = gms.toString()
  //   }
  //   props.removeItemFromCartRequest({ params })
  //   props.setAddingToTakeawayCartLoad(true)
  // }

  const getChildProductsListing = (childProducts) => {
    let childs = [];

    childProducts.forEach((product) => {
      childs.push({
        productId: product.product_id,
        productName: product.product_name,
        mrp: product.actual_selling_price,
        gms: product.actual_weight,
        actual_price: product.actual_selling_price,
        uom: product.uom === "Kg" ? "gms" : product.uom,
        category_id: product.category_id,
      });
    });

    return (
      <div className="product-item-container">
        {childs.map((item) => (
          <div className="each-product-entry">
            <div className="each-product-entry-details">
              <div>{item.productName}</div>
            </div>
            <div className="each-product-entry-price-details-holder">
              <div className="gms-style">
                <div className="grammage">
                  {item.gms} {item.uom}
                </div>
              </div>

              <div className="price-styles">&#x20b9;{item.mrp}</div>
            </div>
          </div>
        ))}
      </div>
    );
  };
  return (
    <>
      {TotalCartItems &&
      TotalCartItems.cartItems &&
      TotalCartItems.cartItems.length ? (
        <>
          <div className="takeaway-cart">
            <div className="heading-content">
              <div className="heading">
                Takeaway Order&nbsp;({item_count} Items)
              </div>
              {!isShowingConfirmModal &&
                !addingToTakeawayCartLoad &&
                props.stepName !== "payment" && (
                  <button className="reset-btn" onClick={(e) => resetCart()}>
                    Reset Cart
                  </button>
                )}
            </div>
            <div className="product-item-container">
              {TotalCartItems &&
                TotalCartItems.cartItems &&
                TotalCartItems.cartItems.map((item) => {
                  return (
                    // <>
                    <div className="each-product-entry">
                      <div className="each-product-entry-details">
                        <div>{item.productName}</div>
                        {isShowingConfirmModal ||
                          (props.stepName !== "payment" && (
                            <img
                              src={CancelIcon}
                              className="cancel-icon"
                              alt=""
                              onClick={(e) => {
                                let dataFrom = item.data_from_api;
                                removeItem(item, item.gms, dataFrom);
                              }}
                            />
                          ))}
                      </div>
                      {item.mrp ? (
                        <div className="price-container">
                          <div className="each-product-entry-price-details-holder">
                            {item.gms ? (
                              <div className="gms-style">
                                <div className="grammage">
                                  {item.gms} {item.uom}
                                </div>
                              </div>
                            ) : item.data_from_api ? (
                              <></>
                            ) : (
                              <div
                                className="grammage-confirm-holder"
                                onClick={(e) => {
                                  handleConfirmWeight(item);
                                }}
                              >
                                <div className="confirm-wght">
                                  CONFIRM WEIGHT
                                </div>
                                <ScanBarcodeRed className="scan-icon" />
                              </div>
                            )}
                            {item.mrp ? (
                              <div className="price-container">
                                {item.actual_price !== null &&
                                  item.actual_price !== item.mrp && (
                                    <>
                                      {item.actual_price && (
                                        <div className="strike-price-styles">
                                          &#x20b9;{item.actual_price}
                                        </div>
                                      )}
                                    </>
                                  )}
                                <div className="price-styles">
                                  &nbsp;&nbsp;&#x20b9;
                                  {parseFloat(item.mrp).toFixed(2)}
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                      <div>
                        {item.data_from_api && item.is_combo
                          ? getChildProductsListing(item.childProducts)
                          : null}
                      </div>
                    </div>
                  );
                })}
            </div>

            <div className="total-view">
              <div className="heading">Takeaway Total</div>
              <div className="heading">
                &#x20b9;{parseFloat(props.total).toFixed(2)}
              </div>
            </div>
            {productForScan && (
              <ScanModal
                productForScan={productForScan}
                updateTakeAwayCartRequest={props.updateTakeAwayCartRequest}
                hub_id={hubId}
                customerKey={props.otpReducer.customerDetails.customer_key}
                closeModal={(e) => {
                  setShow(!show);
                }}
                show={show}
                setShow={(value) => setShow(value)}
                setGms={(item) => setGms(item)}
                gms={gms}
                filterDummyDataAfterUpdate={filterDummyDataAfterUpdate}
              />
            )}
          </div>
        </>
      ) : null}
      {noItemsInCart && !addingToTakeawayCartLoad && (
        <div className="no-items">
          <div className="scan-title ">Scan to add Items</div>
          <div>
            <ScanBarcode height="60" width="60" />
          </div>
        </div>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    setTotalItemsInCart: (payload) => {
      dispatch(setTotalItemsInCart(payload));
    },
    dummyCartRequest: ({ dummyCartItems }) => {
      dispatch(dummyCartRequest({ dummyCartItems }));
    },
    resetTakeawayCartRequest: ({ params }) => {
      dispatch(resetTakeawayCart({ params }));
    },
    setCartData: (params) => {
      dispatch(setCartData(params));
    },
    setAddingToTakeawayCartLoad: (value) => {
      dispatch(setAddingToTakeawayCartLoad(value));
    },
    removeItemFromCartRequest: ({ params }) => {
      dispatch(removeItemFromCartRequest({ params }));
    },
  };
};
const mapStateToProps = (state) => {
  return state;
};
export default connect(mapStateToProps, mapDispatchToProps)(TakeAwayCart);

import {
  faCheck,
  faThumbsDown,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  clearMerchantOrderData,
  saveOrderStatus,
} from "../../../actions/MerchantOrderActions";
import "./MerchantOrderModal.scss";
// import {
//   lockTakeAwayOrderRequest,
//   showConfirmTakeaway,
// } from "../../actions/TakeAwayActions";
// import { setProgressStep } from "../../actions/CommonActions";
// import Loader from "../Loader/Loader";
// import { triggerEvent } from "../../utils/segment";
function ConfirmTakeaway(props) {
  return (
    <div className="confirm-takeaway">
      <div>
        <div className="modal-title">
          {props.merchantOrder.order_status === "failed" ? (
            <div className="title-holder">
              <div>
                <FontAwesomeIcon
                  icon={faThumbsDown}
                  style={{ fontSize: "2rem", marginBottom: "0.5rem" }}
                />
              </div>
              <div>Not able to place order</div>
            </div>
          ) : (
            <div className="title-holder">
              <div>
                <FontAwesomeIcon
                  icon={faThumbsUp}
                  style={{
                    fontSize: "2rem",
                    marginBottom: "0.5rem",
                    color: "green",
                  }}
                />
              </div>
              <div style={{ color: "green" }}>Order Placed successfully</div>
            </div>
          )}
        </div>
      </div>

      <div className="button">
        <button
          className="edit-btn"
          onClick={(e) => {
            if (props.merchantOrder.order_status === "failed") {
              props.saveMerchantOrderStatus("not_placed");
            } else {
              props.clearMerchantOrderData();
            }
          }}
        >
          {props.merchantOrder.order_status === "failed"
            ? "Edit Order"
            : "Create New order"}
        </button>
        <button
          className="confirm-btn"
          onClick={(e) => {
            if (props.merchantOrder.order_status === "failed") {
              props.clearMerchantOrderData();
            } else {
              props.history.goBack();
            }
          }}
        >
          {props.merchantOrder.order_status === "failed"
            ? "Create New order"
            : "Go To Dashboard"}
        </button>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    // showConfirmModal: (value) => {
    //   dispatch(showConfirmTakeaway(value));
    // },
    // orderJourneyStepChange: (step) => {
    //   dispatch(setProgressStep(step));
    // },
    // lockTakeAwayOrder: (payload) => {
    //   dispatch(lockTakeAwayOrderRequest(payload));
    // },
    clearMerchantOrderData: (payload) => {
      dispatch(clearMerchantOrderData(payload));
    },
    saveMerchantOrderStatus: (payload) => {
      dispatch(saveOrderStatus(payload));
    },
  };
};

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmTakeaway);

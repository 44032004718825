//import { createStore, applyMiddleware, compose } from 'redux';
//import createSagaMiddleware from 'redux-saga';

// import rootSaga from './Sagas';
// import rootReducer, { createReducer } from '../reducers/RootReducer';
// import { loadDataFromSessionStorage } from "./actions"

// function ConfigureStore() {

//     // const composeEnhancers =
//     // process.env.NODE_ENV !== 'production' &&
//     //     typeof window === 'object' &&
//     //     window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
//     //     ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
//     //     : compose;
//     const composeEnhancers =
//       typeof window === 'object' &&
//       window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?   
//         window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
//           // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
//         }) : compose;
//     const sagaMiddleware = createSagaMiddleware();
//     const enhancer = composeEnhancers(
//       applyMiddleware(sagaMiddleware),
//       // other store enhancers if any
//     );
//     const store = createStore(rootReducer,enhancer);
//     //const store  = createStore(rootReducer, {},  composeEnhancers(applyMiddleware(...sagaMiddleware)));
//     sagaMiddleware.run(rootSaga);
//     return store;
// }
import { createStore, applyMiddleware, compose } from "redux"
import createSagaMiddleware from "redux-saga"
import { keyForReduxState } from "./consts"
import { saveStoreInSessionStorage, getFromSessionStorage } from "./storeHelpers"
import { loadDataFromSessionStorage } from "./actions"
import rootSaga from './Sagas';
import rootReducer, { createReducer } from '../reducers/RootReducer'
//import rootReducer, { createReducer } from "reducers/rootReducer"

const blackListState = []

// let reduxDevTools = false
// // const history = createHistory()
const sagaMiddleware = createSagaMiddleware()
const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?   
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    }) : compose;
const enhancer = composeEnhancers(
  applyMiddleware(sagaMiddleware),
  // other store enhancers if any
);


// const middlewares = [thunk, sagaMiddleware]

// if (process.env.NODE_ENV === "development") {
//   reduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ //eslint-disable-line no-underscore-dangle
//   middlewares.push(createLogger({
//     level: "info",
//     collapsed: true,
//   }))
// }

// const composeEnhancers = reduxDevTools || compose
// const enhancer = applyMiddleware(...middlewares)

export default function configureStore(initialState) {
  const store = createStore(rootReducer, initialState, enhancer)
  sagaMiddleware.run(rootSaga)
  if (window) {
    window.addEventListener("beforeunload", () => {
      saveStoreInSessionStorage(store, blackListState, keyForReduxState)
    })
    window.addEventListener("unload", () => {
      saveStoreInSessionStorage(store, blackListState, keyForReduxState)
    })
    window.addEventListener("pageshow", () => {
      saveStoreInSessionStorage(store, blackListState, keyForReduxState)
    })
    window.addEventListener("pagehide", () => {
      saveStoreInSessionStorage(store, blackListState, keyForReduxState)
    })
    const stateInSession = getFromSessionStorage(keyForReduxState)
    store.dispatch(loadDataFromSessionStorage(JSON.parse(stateInSession)))
  }
  store.asyncReducers = {}
  return store
}

export function injectAsyncReducer(store, name, reducer) {
  //eslint-disable-next-line
  store.asyncReducers[name] = reducer
  store.replaceReducer(createReducer(store.asyncReducers))
}
import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import "./ProductCard.scss";
import {
  resetTakeawayCart,
  setTotalItemsInCart,
  dummyCartRequest,
  setAddingToTakeawayCartLoad,
  removeItemFromCartRequest,
} from "../../actions/TakeAwayActions";
import {
  removeProductFromDeliveryCart,
  setAddingToDeliveryCartLoad,
  updateDeliveryCartRequest,
} from "../../actions/DeliveryOrderActions";
import ScooterIcon from "../../assets/new-ebo-icons/Scooter_express.png";
import classnames from "classnames";
import Loader from "../Loader/Loader";
import { isLoading } from "../../actions/CommonActions";
import { handleDeliveryCartUpdate, handleAddToCart } from "./CardUtilFunctions";
import { useSelector } from "react-redux";
import { removeItemFromCart } from "../AddToCart/utils/removeFromTakeaway";
import { addToMerchantCart } from "../../actions/MerchantOrderActions";

const ProductCard = (props) => {
  const takeAwayReducer = useSelector((state) => state.takeAwayReducer);
  const dispatch = useDispatch();
  const [dummyCartItems, setDummyCart] = useState(props.pastOrderCart);
  const [addingToCartLoad, setAddingToCartLoad] = useState(false);
  const {
    cartData,
    dummyCartData,
    isResetTakeawayCart,
  } = props.takeAwayReducer;
  const deliveryCartData =
    (props.DeliveryOrderReducer && props.DeliveryOrderReducer.deliveryCart) ||
    [];

  let deliveryCartItemsId = [];

  if (Object.keys(deliveryCartData).length) {
    if (deliveryCartData.combo && deliveryCartData.combo.length) {
      deliveryCartData.combo.forEach((item) =>
        deliveryCartItemsId.push({
          product_id: item.product_id,
          quantity: item.quantity,
        })
      );
    }
    deliveryCartData &&
      deliveryCartData.exotic &&
      deliveryCartData.exotic.length &&
      deliveryCartData.exotic.forEach((item) =>
        deliveryCartItemsId.push({
          product_id: item.product_id,
          quantity: item.quantity,
        })
      );
    deliveryCartData &&
      deliveryCartData.products.forEach((item) =>
        deliveryCartItemsId.push({
          product_id: item.product_id,
          quantity: item.quantity,
        })
      );
  }

  useEffect(() => {
    setDummyCart({ cartItems: [] });
  }, [isResetTakeawayCart]);
  const getAddToCartButtonContent = (product) => {
    const productFound = takeAwayReducer.cartData.cartItems
      ? takeAwayReducer.cartData.cartItems.filter(
          (product) =>
            product.productId === props.product.productId &&
            product.data_from_api
        )
      : [];

    if (productFound.length) {
      return (
        <div className="product-quantity">
          <div
            onClick={() => {
              removeItemFromCart(
                product,
                product.net,
                props.removeItemFromCartRequest,
                props.setAddingToTakeawayCartLoad,
                props.hub_id,
                props.customerKey
              );
            }}
          >
            &#8211;
          </div>
          <div>{productFound.length}</div>
          <div
            onClick={() => {
              props.setAddingToTakeawayCartLoad(true);
              handleAddToCart(
                props.product,
                props.customerKey,
                props.hub_id,
                props.dummyCart,
                props.resetCartItems,
                props.setTotalItemsInCart,
                setDummyCart,
                dummyCartItems,
                isResetTakeawayCart,
                dummyCartData,
                cartData,
                dispatch
              );
            }}
          >
            &#43;
          </div>
        </div>
      );
    } else {
      return (
        <button
          className="catalog-add-to-cart-button"
          onClick={() => {
            // let is_combo = props.takeAwayReducer.categories.filter(item=>item.id === props.product.categoryId)[0].cat_
            props.setAddingToTakeawayCartLoad(true);
            handleAddToCart(
              props.product,
              props.customerKey,
              props.hub_id,
              props.dummyCart,
              props.resetCartItems,
              props.setTotalItemsInCart,
              setDummyCart,
              dummyCartItems,
              isResetTakeawayCart,
              dummyCartData,
              cartData,
              dispatch
            );
          }}
          disabled={props.product.stock > 0 ? false : true}
        >
          {addingToCartLoad ? <Loader /> : "Add to Cart"}
        </button>
      );
    }
  };
  const handleAddToMerchantCart = (product, action) => {
    const items = [...props.merchantOrder.products];
    const prepareProduct = {
      productName: product.productName,
      id: product.productId,

      qty: 1,
      vat: "0.00",
      base_total_price: Math.round(
        product.mrp - (product.mrp * product.base_discount) / 100
      ),
      total_price: Math.round(
        product.mrp - (product.mrp * product.base_discount) / 100
      ),
      base_price: product.mrp,
      price: product.mrp,
      type: "products",
      discount: (product.mrp * product.base_discount) / 100,
      base_discount: (product.mrp * product.base_discount) / 100,
    };

    if (items.find((item) => item.id === product.productId)) {
      const foundIndex = items.findIndex(
        (item) => item.id === product.productId
      );
      if (action === "add") {
        items[foundIndex]["qty"] = items[foundIndex]["qty"] + 1;
        items[foundIndex]["total_price"] =
          items[foundIndex]["total_price"] + prepareProduct.total_price;
        items[foundIndex]["price"] =
          items[foundIndex]["price"] + prepareProduct.price;
        items[foundIndex]["discount"] =
          items[foundIndex]["discount"] + prepareProduct.discount;
      } else {
        items[foundIndex]["qty"] = items[foundIndex]["qty"] - 1;
        items[foundIndex]["total_price"] =
          items[foundIndex]["total_price"] - prepareProduct.total_price;
        items[foundIndex]["price"] =
          items[foundIndex]["price"] - prepareProduct.price;
        items[foundIndex]["discount"] =
          items[foundIndex]["discount"] - prepareProduct.discount;
      }
      if (items[foundIndex]["qty"] === 0) {
        items.splice(foundIndex, 1);
      }
    } else {
      items.push(prepareProduct);
    }
    props.addItemToMerchantCart(items);
  };
  return (
    <>
      {props.product && (
        <div className="product-card">
          {props.stepName !== "merchantorders" && (
            <div className="product-img-holder">
              <img
                src={props.product.productImage}
                className="product-img"
                alt="product-img"
              />
            </div>
          )}
          <div className="product-card-details">
            <div className="product-title">{props.product.productName}</div>

            <div className="weight-styles">
              Gross:{props.product.gross} | Net:{props.product.net}
            </div>
            <div className="product-card-footer">
              <div className="discount-price-styles">
                &#x20b9;
                {props.product.discount_price}
              </div>
              {props.product.mrp !== props.product.discount_price && (
                <div className="base-price-styles">
                  MRP:&#x20b9;
                  {props.product.mrp}
                </div>
              )}
              {props.product.discount ? (
                <p className="discount_percent">
                  {props.product.discount}% OFF
                </p>
              ) : null}
              {props.stepName !== "delivery" &&
              props.stepName !== "merchantorders" ? (
                props.product.uom &&
                props.product.uom.toLowerCase() === "kg" ? (
                  <button
                    className="catalog-add-to-cart-button"
                    onClick={() => {
                      // let is_combo = props.takeAwayReducer.categories.filter(item=>item.id === props.product.categoryId)[0].cat_
                      props.setAddingToTakeawayCartLoad(true);
                      handleAddToCart(
                        props.product,
                        props.customerKey,
                        props.hub_id,
                        props.dummyCart,
                        props.resetCartItems,
                        props.setTotalItemsInCart,
                        setDummyCart,
                        dummyCartItems,
                        isResetTakeawayCart,
                        dummyCartData,
                        cartData,
                        dispatch
                      );
                    }}
                    disabled={props.product.stock > 0 ? false : true}
                  >
                    {addingToCartLoad ? <Loader /> : "Add to Cart"}
                  </button>
                ) : (
                  <>{getAddToCartButtonContent(props.product)}</>
                )
              ) : (
                <>
                  {props.stepName !== "merchantorders" ? (
                    <>
                      {deliveryCartItemsId.filter(
                        (prod) => prod.product_id === props.product.productId
                      ).length > 0 ? (
                        <div
                          className="product-quantity"
                          data-prod={props.product}
                        >
                          <div
                            onClick={() => {
                              props.setDeliveryOrderLoading(true);
                              handleDeliveryCartUpdate(
                                "remove",
                                props.product.productId,
                                deliveryCartItemsId.filter(
                                  (prod) =>
                                    prod.product_id === props.product.productId
                                )[0].quantity,
                                props.product.hub_id,
                                deliveryCartData,
                                props,
                                setAddingToCartLoad
                              );
                            }}
                          >
                            &#8211;
                          </div>
                          <div>
                            {
                              deliveryCartItemsId.filter(
                                (prod) =>
                                  prod.product_id === props.product.productId
                              )[0].quantity
                            }
                          </div>
                          <div
                            className={classnames({
                              disabled: parseInt(props.product.stock) <= 0,
                            })}
                            onClick={() => {
                              if (parseInt(props.product.stock) !== 0) {
                                handleDeliveryCartUpdate(
                                  "add",
                                  props.product.productId,
                                  deliveryCartItemsId.filter(
                                    (prod) =>
                                      prod.product_id ===
                                      props.product.productId
                                  )[0].quantity,
                                  props.product.hub_id,
                                  deliveryCartData,
                                  props,
                                  setAddingToCartLoad
                                );
                              }
                            }}
                          >
                            &#43;
                          </div>
                        </div>
                      ) : (
                        <button
                          className="catalog-add-to-cart-button"
                          onClick={() => {
                            if (!addingToCartLoad) {
                              props.setDeliveryOrderLoading(true);
                              handleDeliveryCartUpdate(
                                "add",
                                props.product.productId,
                                0,
                                props.product.hub_id,
                                deliveryCartData,
                                props,
                                setAddingToCartLoad
                              );
                            }
                          }}
                          disabled={
                            props.product.stock > 0 &&
                            props.product.slot_custom_message !==
                              "All slots full" &&
                            props.product.next_available_by !== "Out of Stock"
                              ? false
                              : true
                          }
                        >
                          {addingToCartLoad ? <Loader /> : "Add to Cart"}
                        </button>
                      )}
                    </>
                  ) : (
                    <>
                      {props.merchantOrder &&
                      props.merchantOrder.products &&
                      props.merchantOrder.products.length &&
                      props.merchantOrder.products.filter(
                        (prod) => prod.id === props.product.productId
                      ).length > 0 ? (
                        <div
                          className="product-quantity"
                          data-prod={props.product}
                        >
                          <div
                            onClick={() => {
                              handleAddToMerchantCart(props.product, "remove");
                            }}
                          >
                            &#8211;
                          </div>
                          <div>
                            {props.merchantOrder &&
                              props.merchantOrder.products &&
                              props.merchantOrder.products.length &&
                              props.merchantOrder.products.find(
                                (prod) => prod.id === props.product.productId
                              ).qty}
                          </div>
                          <div
                            className={classnames({
                              disabled: parseInt(props.product.stock) <= 0,
                            })}
                            onClick={() => {
                              if (parseInt(props.product.stock) !== 0) {
                                handleAddToMerchantCart(props.product, "add");
                              }
                            }}
                          >
                            &#43;
                          </div>
                        </div>
                      ) : (
                        <button
                          className="catalog-add-to-cart-button"
                          onClick={() => {
                            if (!props.merchantOrder.isLoading) {
                              handleAddToMerchantCart(props.product, "add");
                            }
                          }}
                          disabled={
                            props.product.stock > 0 &&
                            props.product.slot_custom_message !==
                              "All slots full" &&
                            props.product.next_available_by !== "Out of Stock"
                              ? false
                              : true
                          }
                        >
                          {props.merchantOrder.isLoading ? (
                            <Loader />
                          ) : (
                            "Add to Cart"
                          )}
                        </button>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          {props.stepName === "delivery" && (
            <div className="delivery-time-detail">
              <div>
                <img src={ScooterIcon} alt="scooter-delivery" />
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: props.product.slot_custom_message,
                }}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    resetCartItems: (value) => {
      dispatch(resetTakeawayCart(value));
    },
    setTotalItemsInCart: (payload) => {
      dispatch(setTotalItemsInCart(payload));
    },
    updateDeliveryOrder: (payload) => {
      dispatch(updateDeliveryCartRequest(payload));
    },
    removeProductFromDelivery: (payload) => {
      dispatch(removeProductFromDeliveryCart(payload));
    },
    setDataLoading: (payload) => {
      dispatch(isLoading(payload));
    },
    dummyCart: (payload) => {
      dispatch(dummyCartRequest(payload));
    },
    setDeliveryOrderLoading: (payload) => {
      dispatch(setAddingToDeliveryCartLoad(payload));
    },
    setAddingToTakeawayCartLoad: (value) => {
      dispatch(setAddingToTakeawayCartLoad(value));
    },
    removeItemFromCartRequest: ({ params }) => {
      dispatch(removeItemFromCartRequest({ params }));
    },
    addItemToMerchantCart: (payload) => {
      dispatch(addToMerchantCart(payload));
    },
  };
};
const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductCard);



export const GET_AUTOCOMPLETE_LOCALITY = 'GET_AUTOCOMPLETE_LOCALITY'
export const GET_AUTOCOMPLETE_LOCALITY_SUCCESS =
  'GET_AUTOCOMPLETE_LOCALITY_SUCCESS'
export const GET_GEOLOCATION_REQUEST = 'GET_GEOLOCATION_REQUEST'
export const GET_GEOLOCATION_SUCCESS = 'GET_GEOLOCATION_SUCCESS'
export const GET_LOCATION_HUB_SUCCESS = 'GET_LOCATION_HUB_SUCCESS'
export const GET_DELIVERY_CART_SUCCESS = 'GET_DELIVERY_CART_SUCCESS'
export const GET_DELIVERY_CART_FAILED = 'GET_DELIVERY_CART_FAILED'
export const GET_DELIVERY_CART_REQUEST = 'GET_DELIVERY_CART_REQUEST'
export const UPDATE_DELIVERY_CART_REQUEST = 'UPDATE_DELIVERY_CART_REQUEST'
export const UPDATE_DELIVERY_CART_SUCCESS = 'UPDATE_DELIVERY_CART_SUCCESS'
export const UPDATE_DELIVERY_CART_FAIL = 'UPDATE_DELIVERY_CART_FAIL'
export const LOCALITY_MODAL_SHOWN = 'LOCALITY_MODAL_SHOWN'
export const RESET_DELIVERY_CART = 'RESET_DELIVERY_CART'
export const SET_SEARCHED_TEXT = 'SET_SEARCHED_TEXT'
export const SET_SELECTED_LOCALITY = 'SET_SELECTED_HUB'
export const SET_DELIVERY_ORDER_LOADING = 'SET_DELIVERY_ORDER_LOADING'
export const CHANGE_OF_LOCATION_DETECTED = 'CHANGE_OF_LOCATION_DETECTED'
export const LOCATION_CHANGE_AFTER_REVIEW = 'LOCATION_CHANGE_AFTER_REVIEW'
export const GET_COMBINE_CART_REQUEST = 'GET_COMBINE_CART_REQUEST'
export const UPDATE_COMBINE_CART_DATA = 'UPDATE_COMBINE_CART_DATA'
export const MERGE_SHIPMENT_REQUEST = 'MERGE_SHIPMENT_REQUEST'
export const CUSTOMER_ALL_ADDRESS = "CUSTOMER_ALL_ADDRESS";
export const UPDATE_SHIPMENT_SLOT = 'UPDATE_SHIPMENT_SLOT'

export const SET_ADDING_TO_DELIVERY_CART_LOAD =
  'SET_ADDING_TO_DELIVERY_CART_LOAD'

export const REMOVE_PRODUCT_FROM_DELIVERY_CART_REQUEST =
  'REMOVE_PRODUCT_FROM_DELIVERY_CART_REQUEST'
export const REMOVE_PRODUCT_FROM_DELIVERY_CART_SUCCESS =
  'REMOVE_PRODUCT_FROM_DELIVERY_CART_SUCCESS'
export const REMOVE_PRODUCT_FROM_DELIVERY_CART_FAIL =
  'REMOVE_PRODUCT_FROM_DELIVERY_CART_FAIL'
export const HUB_LOCALITY_REQUEST = 'HUB_LOCALITY_REQUEST'
export const RESET_DELIVERY_ORDER_DATA = 'RESET_DELIVERY_ORDER_DATA'
export const SHOW_REMOVE_COMBO_MODAL = 'SHOW_REMOVE_COMBO_MODAL'
export const CHANGE_LOCALITY_ADDRESS = 'CHANGE_LOCALITY_ADDRESS'
export const SET_DELIVERY_CITY_NAME = 'SET_DELIVERY_CITY_NAME'
export const UPDATE_DELIVERY_SLOT_LOADING = "UPDATE_DELIVERY_SLOT_LOADING"
export const MERGE_SHIPMENT_LOADING = "MERGE_SHIPMENT_LOADING"
export const CHANGE_CUSTOMER_ADDRESS = "CHANGE_CUSTOMER_ADDRESS";
export const CUSTOMER_ADDRESS = "CUSTOMERADDRESS";
export const CONFIRM_ADDRESS_SELECTION_LOADING = 'CONFIRM_ADDRESS_SELECTION_LOADING';
export const REVIEW_LOCATION_LOADING = 'REVIEW_LOCATION_LOADING'
export const SHOW_SHIPMENT_SLOT_MODAL = 'SHOW_SHIPMENT_SLOT_MODAL'
export const GET_LOCATION_CITYNAME = 'GET_LOCATION_CITYNAME'
export const UPDATE_DELIVERY_ADDRESS_CITY = 'UPDATE_DELIVERY_ADDRESS_CITY'
export const MERGE_SHIPMENT_REQUEST_SUCCESS = 'MERGE_SHIPMENT_REQUEST_SUCCESS'
export const CANCEL_ORDER  = 'CANCEL_ORDER'
export const getAutocompleteLocality = (payload) => {
  return {
    type: GET_AUTOCOMPLETE_LOCALITY,
    payload,
  }
}

export const getGeoLocationRequest = (payload) => {
  return {
    type: GET_GEOLOCATION_REQUEST,
    payload,
  }
}

export const getDeliveryCart = (payload) => {
  return {
    type: GET_DELIVERY_CART_SUCCESS,
    payload,
  }
}

export const getDeliveryCartRequest = (payload) => {
  return {
    type: GET_DELIVERY_CART_REQUEST,
    payload,
  }
}

export const updateDeliveryCartRequest = (payload) => {
  return {
    type: UPDATE_DELIVERY_CART_REQUEST,
    payload,
  }
}

export const updateDeliveryCartSuccess = (payload) => {
  return {
    type: UPDATE_DELIVERY_CART_SUCCESS,
    payload,
  }
}
export const updateDeliveryCartFail = (payload) => {
  return {
    type: UPDATE_DELIVERY_CART_FAIL,
    payload,
  }
}

export const localityModal = (payload) => {
  return {
    type: LOCALITY_MODAL_SHOWN,
    payload,
  }
}

export const resetDeliveryCart = (payload) => {
  return {
    type: RESET_DELIVERY_CART,
    payload,
  }
}
export const removeProductFromDeliveryCart = (payload) => {
  return {
    type: REMOVE_PRODUCT_FROM_DELIVERY_CART_REQUEST,
    payload,
  }
}

export const setLocalityHubRequest = (payload) => {
  return {
    type: HUB_LOCALITY_REQUEST,
    payload,
  }
}

export const setSearchedText = (payload) => {
  return {
    type: SET_SEARCHED_TEXT,
    payload: payload,
  }
}

export const SetSelectedLocality = (payload) => {
  return {
    type: SET_SELECTED_LOCALITY,
    payload: payload,
  }
}

export const setDeliveryOrderLoading = (payload) => {
  return {
    type: SET_DELIVERY_ORDER_LOADING,
    payload: payload,
  }
}

export const changeOfLocationDetected = (payload) => {
  return {
    type: CHANGE_OF_LOCATION_DETECTED,
    payload: payload,
  }
}
export const changeOfLocationAfterReview = (payload) => {
  return {
    type: LOCATION_CHANGE_AFTER_REVIEW,
    payload: payload,
  }
}

export const getCombinedCartRequest = (payload) => {
  return {
    type: GET_COMBINE_CART_REQUEST,
    payload,
  }
}

export const updateCombinedCartData = (payload) => {
  return {
    type: UPDATE_COMBINE_CART_DATA,
    payload,
  }
}

export const mergeShipmentRequest = (payload) => {
  return {
    type: MERGE_SHIPMENT_REQUEST,
    payload,
  }
}

export const resetDeliveryOrderData = (payload) => {
  return {
    type: RESET_DELIVERY_ORDER_DATA,
    payload,
  }
}

export const removeComboModal = (payload) => {
  return {
    type: SHOW_REMOVE_COMBO_MODAL,
    payload,
  }
}

export const setAddingToDeliveryCartLoad = (payload) => {
  return {
    type: SET_ADDING_TO_DELIVERY_CART_LOAD,
    payload,
  }
}

export const setDeliveryCity = (payload) => {
  return {
    type: SET_DELIVERY_CITY_NAME,
    payload,
  }
}

export const getLocationCityName = (payload) => {
  return {
    type: GET_LOCATION_CITYNAME,
    payload,
  }
}

export const mergeShipmentRequestSucces = (payload) => {
  return {
    type: MERGE_SHIPMENT_REQUEST_SUCCESS,
    payload,
  }
}

export const updateDeliverySlotLoadingAction=(payload)=>{
  return {
    type:UPDATE_DELIVERY_SLOT_LOADING,
    payload:payload
  }
}


export const mergeShipmentLoadingAction = (payload)=>{
  return {
    type:MERGE_SHIPMENT_LOADING,
    payload
  }
}

export const changeCustomerAddress = (data) => ({
  type: CHANGE_CUSTOMER_ADDRESS,
  data,
});

export const customerChoosenAddress = (data) => {
  return {
    type: CUSTOMER_ADDRESS,
    data,
  };
};

export const confirmAddressSelection =(payload)=>{
  return {
    type:CONFIRM_ADDRESS_SELECTION_LOADING,
    payload
  }
}

export const customerAllAddresses = (data) => ({
  type: CUSTOMER_ALL_ADDRESS,
  data,
});


export const reviewLocationLoading = (payload)=>({
  type:REVIEW_LOCATION_LOADING,
  payload
})


export const showShipmentSlotModal = (payload)=>({
  type:SHOW_SHIPMENT_SLOT_MODAL,
  payload
})

export const updateShipmentSlots = (data) => ({
  type: UPDATE_SHIPMENT_SLOT,
  payload: data,
})

export const cancelOrder = (data)=>({
  type:CANCEL_ORDER
})
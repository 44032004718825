import React, { useState, useEffect, useId } from "react";
import { connect } from "react-redux";
import { getDeliveryCartRequest } from "../../actions/DeliveryOrderActions";
import TotalCartFooter from "../TotalCartFooter/TotalCartFooter";
import "./AddToCart.scss";
import { ReactComponent as ScanBarcode } from "../../assets/Icon_Scan.svg";
import DeliveryCart from "./DeliveryCart";
import TakeAwayCart from "./TakeAwayCart";
import MerchantCart from "./MerchantCart";
import ConfirmButton from "../Buttons/ConfirmButton/ConfirmButton";
import {
  API_AddBackInventory,
  API_CreateMerchantOrder,
  API_DepleteInventory,
} from "../../services/APIServices";
import { v4 as uuid } from "uuid";
import ToastMessage from "../../utils/ToastMessage";
import {
  clearMerchantOrderData,
  saveOrderStatus,
  setLoadingForMerchantOrder,
} from "../../actions/MerchantOrderActions";

function AddToCart(props) {
  const { TotalCartItems, addingToTakeawayCartLoad } = props.takeAwayReducer;
  const [total, setTotal] = useState(0);
  useEffect(() => {
    const { customer_type } = props.otpReducer;
    if (
      customer_type !== "guest" &&
      props.hub_id !== null &&
      props.stepName === "delivery"
    )
      props.getDeliveryOrderCart({
        params: { customer_key: props.otpReducer.customerDetails.customer_key },
      });
  }, [props.hub_id]);
  useEffect(() => {
    let mrp = 0;
    TotalCartItems &&
      TotalCartItems.cartItems &&
      TotalCartItems.cartItems.map((i) => {
        return (mrp += parseFloat(i.mrp));
      });
    setTotal(mrp);
  }, [TotalCartItems]);
  const totalCombineCart =
    props.paymentDetails.combineCartData &&
    props.paymentDetails.combineCartData.order_charges.filter((charge) => {
      if (charge.attribute === "Total") return true;
    });
  const handleMerchantOrderCreation = () => {
    const uid = uuid().slice(0, 8);
    if (!props.merchantOrder.merchant) {
      ToastMessage("Please select a Merchant");
    } else if (!props.merchantOrder.orderId) {
      ToastMessage("Please select a Merchant Order ID");
    } else if (!props.merchantOrder.products.length) {
      ToastMessage("Please add Products to cart");
    } else {
      const activeStore = props.login.storeId;
      const storeId =
        props.login.agentDetails &&
        props.login.agentDetails.organization.find(
          (item) => item.store_code === activeStore
        ).hub_id;
      const storelineL1 =
        props.login.agentDetails &&
        props.login.agentDetails.organization.find(
          (item) => item.store_code === activeStore
        ).line1;
      const storelineL2 =
        props.login.agentDetails &&
        props.login.agentDetails.organization.find(
          (item) => item.store_code === activeStore
        ).line2;
      const items = [...props.merchantOrder.products];
      const inventoryDepletePayload = {
        referenceId: `${props.merchantOrder.orderId}_${uid}`,
        lockInventory: false,
        hub_id: storeId,
        items: items.map((item) => {
          return {
            productId: item.id,
            type: "EXPRESS_STOCK",
            quantities: item.qty,
          };
        }),
      };
      const createOrderPayload = {
        address_line1: storelineL1,
        address_line2: storelineL2,
        city:
          props.login.agentDetails &&
          props.login.agentDetails.organization.find(
            (item) => item.store_code === activeStore
          ).city,
        customer_email:
          props.login.agentDetails && props.login.agentDetails.email,
        customer_gender: "male", //
        customer_name:
          props.login.agentDetails && props.login.agentDetails.name,
        delivery: "0",
        discount: "0",
        user: props.login.agentDetails && props.login.agentDetails.name,
        discount_code: "",
        kml_id: "",
        landmark: "",
        latitude: "",
        longitude: "",
        merchant: props.merchantOrder.merchant,
        merchant_orderid: props.merchantOrder.orderId,
        order_exotic_schedule_date: "NULL",
        order_exotic_schedule_time: "NULL",
        order_hub: storeId,
        order_id: null,
        order_paystatus: "PAID",
        order_schedule_date: "",
        order_schedule_time: "",
        order_spl_instruction: "",
        phone: props.login.agentDetails && props.login.agentDetails.phone,
        pincode:
          props.login.agentDetails &&
          props.login.agentDetails.organization.find(
            (item) => item.store_code === activeStore
          ).pincode,
        price: items.reduce((a, b) => {
          return a + b.base_price;
        }, 0),
        state:
          props.login.agentDetails &&
          props.login.agentDetails.organization.find(
            (item) => item.store_code === activeStore
          ).state,
        stockLock_id: "",
        total: items.reduce((a, b) => {
          return a + b.total_price;
        }, 0),
        vat: "0.00",
        products: items.map((item) => {
          return { ...item, vat: "0.00" };
        }),
      };
      props.setLoadingForMerchantOrder(true);
      API_DepleteInventory(inventoryDepletePayload).then((data) => {
        if (data.status === "success") {
          API_CreateMerchantOrder(createOrderPayload).then((response) => {
            if (response.status === "success") {
              ToastMessage("Merchant Order Created Successfully");
              props.saveMerchantOrderStatus("success");
              props.setLoadingForMerchantOrder(false);
            } else {
              props.saveMerchantOrderStatus("failed");
              API_AddBackInventory({
                parentReferenceId: `${props.merchantOrder.orderId}_${uid}`,
                referenceId: `${props.merchantOrder.orderId}_${uid}`,
                items: inventoryDepletePayload.items,
              })
                .then(() => {
                  ToastMessage("Not able to place order. Please try again");
                  props.setLoadingForMerchantOrder(false);
                })
                .catch(() => {
                  props.setLoadingForMerchantOrder(false);
                });
            }
          });
        } else {
          props.setLoadingForMerchantOrder(false);
          ToastMessage("Issue in depleting the inventory . Please try again");
        }
      });
    }
  };
  return (
    <div className="add-to-cart">
      <div className="add-to-cart-content">
        {props.stepName !== "merchantorders" ? (
          <>
            {Object.keys(props.DeliveryOrderReducer.deliveryCart).length ? (
              <DeliveryCart hub_id={props.hub_id} stepName={props.stepName} />
            ) : null}
            <TakeAwayCart
              removeItemFromCart={props.removeItemFromCart}
              total={total}
              stepName={props.stepName}
            />

            {props.stepName === "payment" &&
              props.paymentDetails.combineCartData &&
              props.paymentDetails.combineCartData.order_charges.map(
                (charge) => (
                  <>
                    <div>
                      {charge.attribute !== "Total" && (
                        <div className={"order_charges"}>
                          <div className="price-heading">
                            {charge.attribute}
                          </div>
                          <div className="pricing">
                            {charge.attribute === "Licious Wallet" &&
                            props.walletActive ? (
                              <>
                                -
                                {props.walletUsableAmount <
                                totalCombineCart[0].value
                                  ? Math.abs(props.walletUsableAmount)
                                  : props.walletUsableAmount >=
                                    totalCombineCart[0].value
                                  ? totalCombineCart[0].value
                                  : null}
                              </>
                            ) : (
                              charge.value
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                )
              )}
            {props.stepName === "payment" &&
              props.paymentDetails.combineCartData &&
              totalCombineCart && (
                <div
                  className={"order_charges"}
                  style={{ "border-top": "1px solid lightgray" }}
                >
                  <div className="total">{totalCombineCart[0].attribute}</div>
                  <div className="total_price">
                    {props.walletActive
                      ? props.walletUsableAmount >= totalCombineCart[0].value
                        ? 0
                        : Math.abs(
                            totalCombineCart[0].value - props.walletUsableAmount
                          )
                      : totalCombineCart[0].value}
                  </div>
                </div>
              )}
          </>
        ) : (
          <div className="merchant-cart">
            {props.merchantOrder && !props.merchantOrder.products.length ? (
              <>
                <ScanBarcode className="scan-icon" />
                <div>Add Items to Merchant Cart</div>
              </>
            ) : (
              <MerchantCart />
            )}
          </div>
        )}
      </div>
      {props.stepName !== "merchantorders" && (
        <>
          {!addingToTakeawayCartLoad &&
          (props.DeliveryOrderReducer.deliveryCart.cart_total || total) ? (
            <TotalCartFooter
              walletActive={props.walletActive}
              walletUsableAmount={props.walletUsableAmount}
              totalBill={
                !props.DeliveryOrderReducer.localityModal &&
                props.DeliveryOrderReducer.deliveryCart.cart_total
                  ? parseFloat(
                      props.DeliveryOrderReducer.deliveryCart.cart_total + total
                    ).toFixed(2)
                  : parseFloat(total).toFixed(2)
              }
              stepName={props.stepName}
            />
          ) : null}
        </>
      )}
      {props.stepName === "merchantorders" && (
        <div className="create-merchant-order">
          <ConfirmButton onClick={handleMerchantOrderCreation}>
            Create Order
          </ConfirmButton>
        </div>
      )}
    </div>
  );
}
const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDeliveryOrderCart: (payload) => {
      dispatch(getDeliveryCartRequest(payload));
    },
    clearMerchantOrderData: (payload) => {
      dispatch(clearMerchantOrderData(payload));
    },
    saveMerchantOrderStatus: (payload) => {
      dispatch(saveOrderStatus(payload));
    },
    setLoadingForMerchantOrder: (payload) => {
      dispatch(setLoadingForMerchantOrder(payload));
    },
  };
};
const AddToCartConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddToCart);
export default AddToCartConnect;

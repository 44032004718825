import { put, takeEvery, call, fork, take, select } from "redux-saga/effects";
import {
  GET_CATALOG,
  GET_CATALOG_SUCCESS,
  UPDATE_FILTER_REQUEST,
  UPDATE_FILTER_SUCCESS,
  GET_PAST_ORDERS_REQUEST,
  GET_PAST_ORDERS_SUCCESS,
  UPDATE_TAKEAWAY_CART_REQUEST,
  UPDATE_TAKEAWAY_CART_SUCCESS,
  UPDATE_TAKEAWAY_CART_FAILURE,
  REMOVE_ITEM_FROM_CART_REQUEST,
  REMOVE_ITEM_FROM_CART_SUCCESS,
  REMOVE_ITEM_FROM_CART_FAILURE,
  GET_DUMMY_CART_REQUEST,
  GET_DUMMY_CART_SUCCESS,
  GET_CART_REQUEST,
  GET_CART_SUCCESS,
  GET_CART_FAILED,
  GET_CATEGORY_REQUEST,
  GET_CATEGORY_SUCCESS,
  SHOW_CONFIRM_MODAL,
  RESET_TAKEAWAY_CART,
  SET_CART_DATA,
  SET_CART_DATA_SUCCESS,
  SET_TOTAL_ITEMS_IN_CART,
  SET_TOTAL_ITEMS_SUCCESS,
  RESET_TAKEAWAY_CART_SUCCESS,
  SET_TAKEAWAY_ORDER_LOADING,
  SET_ADDING_TO_TAKEAWAY_CART_LOAD,
  LOCK_TAKEAWAY_ORDER,
  ADD_SCANNED_PRODUCT,
  SET_SCAN_VALUE,
} from "../actions/TakeAwayActions";
import { DATA_LOADING, SET_PROGRESS_STEP } from "../actions/CommonActions";
import ToastMessage from "../utils/ToastMessage";
import {
  API_GetCatalogProducts,
  API_GetPastOrders,
  API_UpdateTakeawayCart,
  API_RemoveItemFromCart,
  API_SearchProduct,
  API_GetCart,
  API_GetCategories,
  API_ResetCart,
  API_lockTakeAwayOrder,
} from "../apiServices/TakeAwayOrderAPI";
import { API_ScanProduct } from "../services/APIServices";
import { SET_DELIVERY_ORDER_LOADING } from "../actions/DeliveryOrderActions";
import { add_to_cart, triggerEvent } from "../utils/segment";

function* getCatalogProducts(action) {
  let errMsg = "Failed to Fetch Catalog Products";
  try {
    let response = yield call(API_GetCatalogProducts, action.payload.params);
    if (response.status === "success") {
      yield put({ type: SET_TAKEAWAY_ORDER_LOADING, payload: false });
      yield put({ type: GET_CATALOG_SUCCESS, payload: response.data });
    } else {
      if (!!response.message || response.statusCode === 504) {
        yield put({ type: SET_TAKEAWAY_ORDER_LOADING, payload: false });
        errMsg = response.message ? response.message : response.statusMessage;
      }
      ToastMessage(errMsg);
    }
  } catch (error) {
    if (!!error.message) {
      yield put({ type: SET_TAKEAWAY_ORDER_LOADING, payload: false });
      errMsg = error.message;
    }
    ToastMessage(errMsg);
  }
}

function* filterSearchData(action) {
  let errMsg = "Failed to Show search results";
  try {
    let response = yield call(API_SearchProduct, action.payload.params);
    if (response.status === true) {
      yield put({ type: SET_TAKEAWAY_ORDER_LOADING, payload: false });
      yield put({
        type: UPDATE_FILTER_SUCCESS,
        payload: response,
      });
    } else if (!!response.message || response.statusCode === 504) {
      yield put({ type: SET_TAKEAWAY_ORDER_LOADING, payload: false });
      errMsg = response.message ? response.message : response.statusMessage;
      if (response && response.searchEntities)
        yield put({
          type: UPDATE_FILTER_SUCCESS,
          payload: response,
        });
      ToastMessage(errMsg);
    } else {
      yield put({ type: SET_TAKEAWAY_ORDER_LOADING, payload: false });
      ToastMessage(errMsg);
    }
  } catch (error) {
    if (!!error.message) {
      yield put({ type: SET_TAKEAWAY_ORDER_LOADING, payload: false });
      errMsg = error.message;
    }
    ToastMessage(errMsg);
  }
}

function* getPastOrders(action) {
  let errMsg = "Failed to Fetch Past Orders";
  try {
    let response = yield call(API_GetPastOrders, action.payload.params);
    if (response.statusCode === 200) {
      yield put({ type: SET_TAKEAWAY_ORDER_LOADING, payload: false });
      yield put({ type: GET_PAST_ORDERS_SUCCESS, payload: response.data });
    } else if (
      response.statusCode === 400 &&
      response.statusMessage === "Something went wrong in fetchOrders !!!"
    ) {
      yield put({ type: SET_TAKEAWAY_ORDER_LOADING, payload: false });
    } else {
      // yield put({ type: SET_TAKEAWAY_ORDER_LOADING, payload: false })
      if (!!response.message || response.statusCode === 504) {
        yield put({ type: SET_TAKEAWAY_ORDER_LOADING, payload: false });
        errMsg = response.message ? response.message : response.statusMessage;
      }
      ToastMessage(errMsg);
    }
  } catch (error) {
    yield put({ type: SET_DELIVERY_ORDER_LOADING, payload: false });
    if (!!error.message) {
      yield put({ type: SET_DELIVERY_ORDER_LOADING, payload: false });
      yield put({ type: SET_TAKEAWAY_ORDER_LOADING, payload: false });
      errMsg = error.message;
    }
    ToastMessage(errMsg);
  }
}

function* updateTakeawayCart(action) {
  let errMsg = "Failed to Add the Product";

  try {
    let response = yield call(API_UpdateTakeawayCart, action.payload.params);
    yield put({ type: SET_ADDING_TO_TAKEAWAY_CART_LOAD, payload: true });
    if (response.status === "success") {
      const customerDetails = yield select((state) => state.otpReducer);
      const scanActive = yield select((state) => state.takeAwayReducer)
        .scanActive;

      triggerEvent({
        action_type: "add_to_cart",
        is_guest: customerDetails.customer_type === "guest" ? true : false,
        id: customerDetails.uid,
        customer_key: customerDetails.customerDetails.customer_key,
        order_id: "",
        is_existing_customer: !customerDetails.new_customer ? true : false,
        item_count_takeaway: "",
        item_count_delivery: "",
        attributes: { cart_addition_method: scanActive ? "scan" : "button" },
      });

      let payloadForGetCart = {
        params: { customer_key: action.payload.params.customer_key },
      };
      if (action.payload.closeModal) {
        payloadForGetCart.params.closeModal = action.payload.closeModal;
      }
      if (action.payload.setAddProductLoading) {
        payloadForGetCart.params.setAddProductLoading =
          action.payload.setAddProductLoading;
      }
      yield put({ type: UPDATE_TAKEAWAY_CART_SUCCESS, payload: response });
      if (action.payload.filterDummyDataAfterUpdate) {
        action.payload.filterDummyDataAfterUpdate(response.product_id);
      }
      yield put({
        type: GET_CART_REQUEST,
        payload: payloadForGetCart,
      });
    } else {
      if (response.message || response.statusCode === 504) {
        if (action.payload.closeModal) action.payload.closeModal(false);
        yield put({ type: UPDATE_TAKEAWAY_CART_FAILURE, payload: response });
        yield put({ type: SET_ADDING_TO_TAKEAWAY_CART_LOAD, payload: false });
        errMsg = response.message ? response.message : response.statusMessage;
      }
      ToastMessage(errMsg);
    }
  } catch (error) {
    if (!!error.message) {
      if (action.payload.closeModal) action.payload.closeModal(false);
      yield put({ type: UPDATE_TAKEAWAY_CART_FAILURE });
      yield put({ type: SET_ADDING_TO_TAKEAWAY_CART_LOAD, payload: false });
      errMsg = error.message;
    }
    ToastMessage(errMsg);
  }
}

function* removeItemFromCart(action) {
  let errMsg = "Failed to Remove item from Cart";
  try {
    let response = yield call(API_RemoveItemFromCart, action.payload.params);
    if (response.status === "success") {
      yield put({ type: SET_TAKEAWAY_ORDER_LOADING, payload: false });
      yield put({
        type: REMOVE_ITEM_FROM_CART_SUCCESS,
        payload: response.data,
      });
      yield put({
        type: GET_CART_REQUEST,
        payload: {
          params: { customer_key: action.payload.params.customer_key },
        },
      });
    } else if (response.status === "error" || response.statusCode === 504) {
      yield put({ type: SET_TAKEAWAY_ORDER_LOADING, payload: false });
      yield put({ type: SET_ADDING_TO_TAKEAWAY_CART_LOAD, payload: false });
      yield put({ type: REMOVE_ITEM_FROM_CART_FAILURE });
      errMsg = response.message ? response.message : response.statusMessage;

      ToastMessage(errMsg);
    }
  } catch (error) {
    if (!!error.message) {
      yield put({ type: SET_TAKEAWAY_ORDER_LOADING, payload: false });
      yield put({ type: SET_ADDING_TO_TAKEAWAY_CART_LOAD, payload: false });
      yield put({ type: REMOVE_ITEM_FROM_CART_FAILURE });
      errMsg = error.message;
    }
    ToastMessage(errMsg);
  }
}

function* updateDummyCart(action) {
  try {
    yield put({
      type: GET_DUMMY_CART_SUCCESS,
      payload: action.payload,
    });
  } catch (e) {}
}

function* getCartSaga(action) {
  let errMsg = "Failed to Fetch Cart";
  try {
    let response = yield call(API_GetCart, action.payload.params);
    if (response.statusCode === 200) {
      yield put({ type: SET_ADDING_TO_TAKEAWAY_CART_LOAD, payload: false });
      yield put({ type: SET_SCAN_VALUE, payload: "" });
      yield put({
        type: GET_CART_SUCCESS,
        payload: response.data,
      });
      yield put({ type: SET_ADDING_TO_TAKEAWAY_CART_LOAD, payload: false });
      if (action.payload.params.closeModal) {
        action.payload.params.closeModal(false);
      }
      if (action.payload.params.setAddProductLoading) {
        action.payload.params.setAddProductLoading(false);
      }
    } else {
      if (response.statusMessage === "error") {
        if (action.payload.params.setAddProductLoading) {
          action.payload.params.setAddProductLoading(false);
        }
        yield put({ type: SET_ADDING_TO_TAKEAWAY_CART_LOAD, payload: false });
        yield put({
          type: GET_CART_FAILED,
        });
        errMsg = response.statusMessage;
      }
      if (response.statusMessage === "empty_cart") {
        yield put({ type: SET_ADDING_TO_TAKEAWAY_CART_LOAD, payload: false });
        yield put({
          type: GET_CART_FAILED,
          payload: { ebo_products: [], cart_id: null },
        });
        errMsg = response.data.message;
      }
    }
  } catch (error) {
    if (!!error.message) {
      if (action.payload.params.setAddProductLoading) {
        action.payload.params.setAddProductLoading(false);
      }
      yield put({ type: SET_ADDING_TO_TAKEAWAY_CART_LOAD, payload: false });
      yield put({
        type: GET_CART_FAILED,
      });
      errMsg = error.message;
    }
    ToastMessage(errMsg);
  }
}

function* getCategorySaga(action) {
  let errMsg = "Failed to Fetch Cart";
  try {
    let response = yield call(API_GetCategories, action.payload.params);
    if (response.status === "success") {
      yield put({ type: SET_TAKEAWAY_ORDER_LOADING, payload: false });
      yield put({
        type: GET_CATEGORY_SUCCESS,
        payload: response.data,
      });
    } else {
      if (!!response.message || response.statusCode === 504) {
        yield put({ type: SET_TAKEAWAY_ORDER_LOADING, payload: false });
        errMsg = response.message ? response.message : response.statusMessage;
      }
      ToastMessage(errMsg);
    }
  } catch (error) {
    if (!!error.message) {
      yield put({ type: SET_TAKEAWAY_ORDER_LOADING, payload: false });
      errMsg = error.message;
    }
    ToastMessage(errMsg);
  }
}

function* stateManager(action) {
  try {
  } catch (e) {}
}

function* setCartDataSaga(action) {
  try {
    yield put({
      type: SET_CART_DATA_SUCCESS,
      payload: action.payload,
    });
  } catch (e) {}
}

function* setTotalItems(action) {
  try {
    yield put({
      type: SET_TOTAL_ITEMS_SUCCESS,
      payload: action.payload,
    });
  } catch (e) {}
}

function* resetTakeawayCart(action) {
  let errMsg = "Failed to Reset Cart";
  try {
    let response = yield call(API_ResetCart, action.payload.params);
    if (response.status === "success") {
      yield put({ type: SET_TAKEAWAY_ORDER_LOADING, payload: false });
      yield put({
        type: RESET_TAKEAWAY_CART_SUCCESS,
        payload: response.data,
      });
      yield put({
        type: GET_CART_REQUEST,
        payload: {
          params: { customer_key: action.payload.params.customer_key },
        },
      });
      yield put({ type: SET_ADDING_TO_TAKEAWAY_CART_LOAD, payload: true });
    } else {
      if (!!response.message || response.statusCode === 504) {
        yield put({ type: SET_TAKEAWAY_ORDER_LOADING, payload: false });
        yield put({ type: SET_ADDING_TO_TAKEAWAY_CART_LOAD, payload: false });
        errMsg = response.message ? response.message : response.statusMessage;
      }
      ToastMessage(errMsg);
    }
  } catch (error) {
    if (!!error.message) {
      yield put({ type: SET_TAKEAWAY_ORDER_LOADING, payload: false });
      errMsg = error.message;
    }
    ToastMessage(errMsg);
  }
}
function* lockTakeAwayOrderReq(action) {
  let errMsg = "Failed to Reset Cart";
  try {
    let response = yield call(API_lockTakeAwayOrder, action.payload);
    if (response.statusMessage === "success") {
      yield put({ type: SHOW_CONFIRM_MODAL, payload: false });
      action.payload.setConfirmOrderLoading(false);
      yield put({ type: SET_PROGRESS_STEP, payload: 2 });
    } else if (response.statusMessage === "error") {
      ToastMessage(response.data.message);
      action.payload.setConfirmOrderLoading(false);
      action.payload.showConfirmModal(false);
      yield put({
        type: GET_CART_REQUEST,
        payload: {
          params: { customer_key: action.payload.customer_key },
        },
      });
    } else {
      ToastMessage("Not able to lock Takeaway Order");
      action.payload.setConfirmOrderLoading(false);
    }
    // if (response.status === "success") {
    //   // yield put({ type: SET_TAKEAWAY_ORDER_LOADING, payload: false });
    //   // yield put({
    //   //   type: RESET_TAKEAWAY_CART_SUCCESS,
    //   //   payload: response.data,
    //   // });
    //   // yield put({
    //   //   type: GET_CART_REQUEST,
    //   //   payload: {
    //   //     params: { customer_key: action.payload.params.customer_key },
    //   //   },
    //   // });
    //   // yield put({ type: SET_ADDING_TO_TAKEAWAY_CART_LOAD, payload: true });
    // } else {
    //   if (!!response.message) {
    //     yield put({ type: SET_TAKEAWAY_ORDER_LOADING, payload: false });
    //     errMsg = response.message;
    //   }
    //   ToastMessage(errMsg);
    // }
  } catch (error) {
    if (!!error.message) {
      yield put({ type: SET_TAKEAWAY_ORDER_LOADING, payload: false });
      errMsg = error.message;
    }
    ToastMessage(errMsg);
  }
}
function* addScannedProduct(action) {
  let errMsg = "Failed to Reset Cart";
  yield put({ type: SET_ADDING_TO_TAKEAWAY_CART_LOAD, payload: true });
  try {
    let response = yield call(API_ScanProduct, action.payload);
    const customerDetails = yield select((state) => state.otpReducer);
    const scanActive = yield select((state) => state.takeAwayReducer)
      .scanActive;
    triggerEvent({
      action_type: "add_to_cart",
      is_guest: customerDetails.customer_type === "guest" ? true : false,
      id: customerDetails.uid,
      customer_key: customerDetails.customerDetails.customer_key,
      order_id: "",
      is_existing_customer: !customerDetails.new_customer ? true : false,
      item_count_takeaway: "",
      item_count_delivery: "",
      attributes: { method: scanActive ? "scan" : "button" },
    });
    if (response.status === "success") {
      window.location.reload();
      yield put({
        type: GET_CART_REQUEST,
        payload: {
          params: { customer_key: action.payload.customer_key },
        },
      });
      // yield put({ type: SET_ADDING_TO_TAKEAWAY_CART_LOAD, payload: true });
    } else if (response.name === "Error" || response.statusCode === 504) {
      yield put({ type: SET_ADDING_TO_TAKEAWAY_CART_LOAD, payload: false });
      if (!!response.message || response.statusMessage === "error") {
        errMsg = response.message ? response.message : response.statusMessage;
      }
      ToastMessage(errMsg);
    } else {
      yield put({ type: SET_ADDING_TO_TAKEAWAY_CART_LOAD, payload: false });
      errMsg = response.message;
      ToastMessage(errMsg);
    }
  } catch (error) {
    yield put({ type: SET_TAKEAWAY_ORDER_LOADING, payload: false });
    if (!!error.message) {
      errMsg = error.message;
    }
    ToastMessage(errMsg);
  }
}
export default function* TakeAwayOrderSagas() {
  yield [fork(takeEvery, GET_CATALOG, getCatalogProducts)];
  yield [fork(takeEvery, UPDATE_FILTER_REQUEST, filterSearchData)]; //search from backend
  yield [fork(takeEvery, GET_PAST_ORDERS_REQUEST, getPastOrders)];
  yield [fork(takeEvery, UPDATE_TAKEAWAY_CART_REQUEST, updateTakeawayCart)];
  yield [fork(takeEvery, REMOVE_ITEM_FROM_CART_REQUEST, removeItemFromCart)];
  yield [fork(takeEvery, GET_DUMMY_CART_REQUEST, updateDummyCart)];
  yield [fork(takeEvery, GET_CART_REQUEST, getCartSaga)];
  yield [fork(takeEvery, GET_CATEGORY_REQUEST, getCategorySaga)];
  yield [fork(takeEvery, SHOW_CONFIRM_MODAL, stateManager)];
  yield [fork(takeEvery, RESET_TAKEAWAY_CART, resetTakeawayCart)];
  yield [fork(takeEvery, SET_CART_DATA, setCartDataSaga)];
  yield [fork(takeEvery, SET_TOTAL_ITEMS_IN_CART, setTotalItems)];
  yield [fork(takeEvery, LOCK_TAKEAWAY_ORDER, lockTakeAwayOrderReq)];
  yield [fork(takeEvery, ADD_SCANNED_PRODUCT, addScannedProduct)];
}

export const merchants_list = [
  "Grofers",
  "Big Basket",
  "Daily Ninja",
  "Botiga",
  "Swiggy Instamart",
  "Service Recovery",
  "Sampling",
  "Amazon SellerCenteral",
  "Milkbasket",
  "Doodhwala",
  "Dunzo",
  "Cloudtail",
  "swiggy",
  "Godrej_Nature_Basket",
  "ApnaComplex",
  "B2B",
  "MgSuperMarket",
  "Zomato",
  "Suprdaily",
  "Flipkart",
  "Dunzo Xpressmart",
  "WNDW",
  "TRICE",
  "GroferHyperlocal",
  "Magicpin",
  "ZEPTO",
  "Pickily",
  "Meatsy",
];

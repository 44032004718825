import React from "react";
import { connect } from "react-redux";
import "./NoOrderModal.scss";
import {setNoOrdersModal} from "../../actions/CommonActions"
import { setProgressStep } from "../../actions/CommonActions";
function NoOrder(props) {

  const takeaway = () => {
    props.orderJourneyStepChange(1);
    props.setNoOrdersModal(false)
  };

  const deliveryOrder = () => {
    props.orderJourneyStepChange(2);
    props.setNoOrdersModal(false)
  };

  return (
    <div className="no-order ">
        <div>
        <div className="modal-title">No Orders in your cart!</div>
        <div className="message">
          Do you want to place takeaway Order or Delivery Order?
        </div>
        </div>
      <div className="button">
        <button className="edit-btn" onClick={(e) => takeaway()}>
          Takeaway Orders
        </button>
        <button
            className="confirm-btn"
            onClick={(e) => deliveryOrder()}
          >
           Delivery Orders
          </button>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    setNoOrdersModal: (payload) => {
      dispatch(setNoOrdersModal(payload));
    },
    orderJourneyStepChange: (step) => {
      dispatch(setProgressStep(step));
    },
  };
};

const mapStateToProps = (state) => {
  return state;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NoOrder);

import {
  GET_AUTOCOMPLETE_LOCALITY_SUCCESS,
  GET_DELIVERY_CART_SUCCESS,
  GET_LOCATION_HUB_SUCCESS,
  LOCALITY_MODAL_SHOWN,
  SET_DELIVERY_ORDER_LOADING,
  SET_SEARCHED_TEXT,
  SET_SELECTED_LOCALITY,
  UPDATE_DELIVERY_CART_SUCCESS,
  CHANGE_OF_LOCATION_DETECTED,
  RESET_DELIVERY_ORDER_DATA,
  SHOW_REMOVE_COMBO_MODAL,
  CHANGE_LOCALITY_ADDRESS,
  SET_ADDING_TO_DELIVERY_CART_LOAD,
  UPDATE_DELIVERY_ADDRESS_CITY,
  UPDATE_DELIVERY_SLOT_LOADING,
  MERGE_SHIPMENT_LOADING,
  CONFIRM_ADDRESS_SELECTION_LOADING,
  CUSTOMER_ADDRESS,
  CUSTOMER_ALL_ADDRESS,
  REVIEW_LOCATION_LOADING,
  SHOW_SHIPMENT_SLOT_MODAL,
} from "../actions/DeliveryOrderActions";

const initialState = {
  AutocompleteSuggestions: {},
  selectedLocationHub: null,
  deliveryCart: {},
  localityModal: true,
  searchedText: "",
  selectedLocality: {},
  loading: false,
  changeOfLocationModal: false,
  showRemoveComboModal: false,
  deliveryCartId: "",
  changedNewAddress: "",
  addingToDeliveryCart: false,
  ChangedCityName: "",
  updateShipmentSlotLoading: false,
  mergeShipmentLoading: false,
  addressSelectionLoading: false,
  reviewLocation:false,
  showShipmentSlotModal:false
}
const DeliveryOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_AUTOCOMPLETE_LOCALITY_SUCCESS: {
      return {
        ...state,
        AutocompleteSuggestions: action.payload,
      };
    }

    case GET_LOCATION_HUB_SUCCESS: {
      return {
        ...state,
        selectedLocationHub: action.payload,
      };
    }
    case GET_DELIVERY_CART_SUCCESS: {
      return {
        ...state,
        deliveryCart: action.payload,
        deliveryCartId: action.payload.cart_id,
      };
    }
    case SET_ADDING_TO_DELIVERY_CART_LOAD: {
      return {
        ...state,
        addingToDeliveryCart: action.payload,
      };
    }
    case UPDATE_DELIVERY_CART_SUCCESS: {
      return {
        ...state,
        deliveryCart: action.payload,
      };
    }
    case LOCALITY_MODAL_SHOWN: {
      return {
        ...state,
        AutocompleteSuggestions: {},
        localityModal: action.payload,
      };
    }
    case SET_SEARCHED_TEXT: {
      return {
        ...state,
        searchedText: action.payload,
      };
    }
    case SET_SELECTED_LOCALITY: {
      return { ...state, selectedLocality: action.payload };
    }
    case SET_DELIVERY_ORDER_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case CHANGE_OF_LOCATION_DETECTED: {
      return {
        ...state,
        changeOfLocationModal: action.payload,
      };
    }
    case RESET_DELIVERY_ORDER_DATA: {
      return {
        ...state,
        ...initialState,
      };
    }
    case SHOW_REMOVE_COMBO_MODAL: {
      return {
        ...state,
        showRemoveComboModal: action.payload.value,
        removeProduct: action.payload.removeProduct,
      };
    }
    case CHANGE_LOCALITY_ADDRESS: {
      return {
        ...state,
        changedNewAddress: action.payload,
      };
    }
    case UPDATE_DELIVERY_ADDRESS_CITY: {
      return {
        ...state,
        ChangedCityName: action.payload,
      };
    }
    case UPDATE_DELIVERY_SLOT_LOADING: {
      return { ...state, updateShipmentSlotLoading: action.payload };
    }
    case MERGE_SHIPMENT_LOADING: {
      return { ...state, mergeShipmentLoading: action.payload };
    }
    case CONFIRM_ADDRESS_SELECTION_LOADING: {
      return { ...state, addressSelectionLoading: action.payload };
    }
    case CUSTOMER_ADDRESS:
      return Object.assign({}, state, { customerAddress: action.data });
    case CUSTOMER_ALL_ADDRESS:
      return Object.assign({}, state, {
        addresses: action.data.customerAllAddresses,
        defaultAddress: action.data.defaultAddress,
      });
    case REVIEW_LOCATION_LOADING: {
      return {
        ...state,
        reviewLocation: action.payload,
      };
    }
    case SHOW_SHIPMENT_SLOT_MODAL:{
      return{
        ...state,
       showShipmentSlotModal:action.payload
      }
    }
    default:
      return state;
  }
};

export default DeliveryOrderReducer;

// Delivery api

//import {BASE_URL, MERCHANT_APP_URL} from "../AppConstants.js";
import fetch from '../utils/FetchAuth'
const { REACT_APP_API_ROUTES } = process.env
const APIROUTES_DIR = 'api-routes'
const SERVICEAPI = require('../' + APIROUTES_DIR + '/' + REACT_APP_API_ROUTES)
const API_Source = 'Merchant-Kiosk'
export const API_GetAutocompleteLocality = (params) => {
  return fetch(`${SERVICEAPI.getAutocompleteLocality}?input=${params.input}`, {
    method: 'GET',
    headers: {
      source: 'website',
      customerkey: params.customer_key,
      'x-csrf-token': 'ZAVAhR5CPSGMtf19FBso2TkrSgS0dqQOHbpiHOm9',
    },
  })
}

export const API_GetGeoLocation = (params) => {
  return fetch(`${SERVICEAPI.getGeoLocation}?place_id=${params.place_id}`, {
    method: 'GET',
    headers: {
      source: 'website',
      customerkey: params.customer_key,
      'x-csrf-token': 'ZAVAhR5CPSGMtf19FBso2TkrSgS0dqQOHbpiHOm9',
    },
  })
}

export const API_GetLocationHub = (params) => {
  return fetch(
    `${SERVICEAPI.getLocationHub}?lat=${params.lat}&lng=${params.lng}`,
    {
      method: 'GET',
    },
  )
}

export const API_GetDeliveryCart = (params) => {
  return fetch(
    `${SERVICEAPI.getDeliveryCart}/?customer_key=${params.customer_key}`,
    {
      method: 'GET',
      headers: {
        source: 'website',
      },
    },
  )
}
export const API_UpdateDeliveryCart = (params) => {
  return fetch(
    `${SERVICEAPI.updateDeliveryCart}/?product_id=${params.product_id}&hub_id=${params.hub_id}&customer_key=${params.customer_key}&quantity=${params.quantity}`,
    {
      method: 'POST',
      headers: {
        source: 'website',
      },
    },
  )
}
export const API_DeleteFromDeliveryCart = (params) => {
  return fetch(
    `${SERVICEAPI.deleteFromDeliveryCart}/?product_id=${params.product_id}&hub_id=${params.hub_id}&customer_key=${params.customer_key}`,
    {
      method: 'POST',
      headers: {
        source: 'website',
      },
    },
  )
}
export const API_ResetDeliveryCart = (params) => {
  return fetch(
    `${SERVICEAPI.resetDeliveryCart}/?customer_key=${params.customer_key}`,
    {
      method: 'DELETE',
      headers: {
        source: 'website',
      },
    },
  )
}

export const API_UpdateDeliveryTotal = (params) => {
  return fetch(
    `${SERVICEAPI.updateDeliveryTotal}/?customer_key=${params.customer_key}`,
    {
      method: 'POST',
    },
  )
}

export const API_GetLocationCityName = (payload) => {
  return fetch(
    `${SERVICEAPI.validateAddress}/?lat=${payload.lat}&lng=${payload.lng}`,
    {
      method: 'GET'
    },
  )
}

import React, { useEffect, useState, useId } from "react";
import { connect } from "react-redux";
import {
  isLoading,
  setProgressStep,
  setPollingTimerID,
  clearAllTimerID,
} from "../../actions/CommonActions";
import classnames from "classnames";
import "./VerifyUser.scss";
import {
  saveCustomerDetails,
  getOtp,
  verifyOtp,
  clearOtpData,
  proceedWithoutOTP,
} from "../../actions/OtpActions";
import ToastMessage from "../../utils/ToastMessage";
import OtpModal from "../../components/Modals/OtpModal";
import { resetDeliveryOrderData } from "../../actions/DeliveryOrderActions";
import { eboRemoveCoupon } from "../../actions/ProductListActions";
import { clearPaymentDetails } from "../../actions/PaymentActions";
import { clearTakeAwayData } from "../../actions/TakeAwayActions";
import { clearRegisterUserData } from "../../actions/CommonActions";
import { triggerEvent } from "../../utils/segment";
import { v4 as uuid } from "uuid";
import { saveUid } from "../../actions/AddUserActions";
const VerifyUser = (props) => {
  const id = uuid().slice(0, 8);

  // props.history.pushState(null, null, null);
  const [timer, setTimer] = useState(0);
  const [timerId, setTimerID] = useState(null);
  const [otpValue, setOtpValue] = useState("");
  const [show, setShow] = useState(false);
  const [verify, setVerify] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  useEffect(() => {
    props.orderJourneyStepChange(null);
    props.saveCustomerDetails();
    props.resetDeliveryOrderReducerData({});
    props.resetPaymentData();
    props.resetOtpData();
    props.resetTakeAwayData();
    props.resetRegisteruser();
  }, []);

  useEffect(() => {
    if (
      props.commonReducer.timerIDs &&
      props.commonReducer.timerIDs.length > 0
    ) {
      props.commonReducer.timerIDs.forEach((id) => {
        clearInterval(id);
      });
      props.clearAllTimerID();
    }
    if (props.paymentDetails.appliedCoupon) {
      const data = {};
      if (props.takeAwayReducer.takeawayCartId)
        data["offline_cart_id"] = props.takeAwayReducer.takeawayCartId;
      if (props.DeliveryOrderReducer.deliveryCartId)
        data["online_cart_id"] = props.DeliveryOrderReducer.deliveryCartId;
      props.removeCoupon({
        coupon_code: props.paymentDetails.appliedCoupon,
        customer_key: props.otpReducer.customerDetails.customer_key,
        data: data,
      });
    }
  }, []);

  const handleCustomertPhone = (e) => {
    const event = e.target;

    if (
      event.value !== "" &&
      !isNaN(event.value) &&
      event.value.length === 10
    ) {
      setVerify(true);
    } else {
      setVerify(false);
    }
    setPhoneNumber(event.value);
  };
  const startTimer = () => {
    let counter = 30;
    let interval = setInterval(() => {
      counter--;
      // Display 'counter' wherever you want to display it.
      if (counter <= 0) {
        setTimer(0);
        clearInterval(interval);
        setTimerID(null);
        return;
      } else {
        if (counter < 10 && counter > 0) {
          counter = "0" + counter;
        }
        setTimer("0:" + counter);
      }
    }, 1000);
    setTimerID(interval);
    props.setPollingTimerID(interval);
  };
  const handleGuestFlow = () => {
    triggerEvent({
      action_type: "auth_initiated",
      is_guest: true,
      id: id,
      customer_key: "",
      order_id: "",
      is_existing_customer: "",
      item_count_takeaway: "",
      item_count_delivery: "",
      attributes: {},
    });
    props.saveId(id);
    props.verifyOtpHandler({
      history: props.history,
      user_type: "guest",
      storeId: props.login.storeId,
    });
  };
  const verifyUser = () => {
    if (verify) {
      if (phoneNumber.length === 10) {
        props.getOtpDetails({ phoneNumber: phoneNumber, verifyModal: setShow });
        setTimer(0);
        startTimer();
        clearInterval(timerId);
        setOtpValue("");
        setShow(true);
        triggerEvent({
          action_type: "auth_initiated",
          is_guest: false,
          id: id,
          customer_key: "",
          order_id: "",
          is_existing_customer: "",
          item_count_takeaway: "",
          item_count_delivery: "",
          attributes: {},
        });
        props.saveId(id);
      } else {
        setShow(false);
        ToastMessage("Please enter a valid number");
      }
    }
  };

  const proceedWithoutOTP = () => {
    if (phoneNumber.length === 10) {
        props.proceedWithoutOTPHandler({ phoneNumber: phoneNumber, showModal: setShow , history: props.history , verifyUser: verifyUser });
    }
  }

  return (
    <>
      <div className="verify-user-otp-screen">
        <div
          className="back-button"
          onClick={() => props.history.push("/dashboard-orders")}
        >
          &#8592; BACK
        </div>
        <div className="verify-user-otp-content">
          <div className="verify-with-number">
            <input
              placeholder={"Enter Customer Phone Number"}
              onChange={(e) => handleCustomertPhone(e)}
              value={phoneNumber}
              autoComplete="off"
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  proceedWithoutOTP();
                }
              }}
            />
            <button
              className={classnames("verify-button", { active: verify })}
              onClick={() => proceedWithoutOTP()}
            >
              Verify User
            </button>
          </div>
          <div className="circle-separator">
            <div className="circle-separator-text">OR</div>
          </div>
          <div className="guest-user-button">
            <button
              className="outline-button"
              onClick={() => handleGuestFlow()}
            >
              Continue as Guest
            </button>
          </div>
        </div>
      </div>

      <OtpModal
        show={show}
        setOtpValue={setOtpValue}
        setTimer={setTimer}
        phoneNumber={phoneNumber}
        otpValue={otpValue}
        timer={timer}
        history={props.history}
        verifyUser={verifyUser}
        setShow={setShow}
      />
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    orderJourneyStepChange: (step) => {
      dispatch(setProgressStep(step));
    },
    load: (value) => {
      dispatch(isLoading(value));
    },
    getOtpDetails: (number) => {
      dispatch(getOtp(number));
    },
    verifyOtpHandler: (payload) => {
      dispatch(verifyOtp(payload));
    },
    proceedWithoutOTPHandler : (payload) => {
      dispatch(proceedWithoutOTP(payload));
    },
    saveCustomerDetails: () => {
      dispatch(saveCustomerDetails({}));
    },
    resetDeliveryOrderReducerData: ({}) => {
      dispatch(resetDeliveryOrderData({}));
    },
    resetPaymentData: () => {
      dispatch(clearPaymentDetails());
    },
    resetOtpData: () => {
      dispatch(clearOtpData());
    },
    resetTakeAwayData: () => {
      dispatch(clearTakeAwayData());
    },
    resetRegisteruser: () => {
      dispatch(clearRegisterUserData());
    },
    setPollingTimerID: (payload) => dispatch(setPollingTimerID(payload)),
    clearAllTimerID: () => dispatch(clearAllTimerID()),
    removeCoupon: (payload) => dispatch(eboRemoveCoupon(payload)),
    saveId: (payload) => dispatch(saveUid(payload)),
  };
};
const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyUser);

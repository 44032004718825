import styled from "styled-components";
import { css } from "styled-components";
import BannerImg from "../../../assets/login-screen-new-banner.jpg";
import "../../../variable.scss";
export const LoginContainerStyled = styled.div`
  .login-form-wrapper {
    border: 1px solid #cccccc;
    border-radius: 0.5rem;
    display: flex;
    margin: 1rem;
    padding: 1rem;
    justify-content: center;
    align-items: center;
    .user-name,
    .user-password {
      margin: 0.5rem;
      label {
        display: block;
        padding-bottom: 5px;
        color: #54698d;
        font-size: 15px;
      }
      input {
        box-sizing: border-box;
        border: 1px solid #cccccc;
        padding-left: 10px;
        height: 40px;
        width: 100%;
        padding-left: 10px;
        font-size: 15px;
        min-width: 240px;
        margin: 0;
        background: #f8f8f8;
      }
    }
    .username {
      margin-left: 0;
    }

    .login-btn {
      margin: 0.5rem;
      width: 100%;
      border-radius: 4px;
      background-color: #cccccc;
      border: none;
      color: #fff;
      height: 40px;
      outline: 0;
      box-shadow: 0 2px 3px 0 rgba(84, 105, 141, 0.5);
      color: #fff;
      font-weight: 600;
      font-size: 18px;
      padding: 0px 20px;
      /** for safari */
      -webkit-appearance: none;
      -webkit-border-radius: 4px;
      cursor: pointer;
      &.active {
        // background-color: #ed0e0f;
        background-color: "$ebo-active-red";
        &:focus {
          background-color: #ed0e0e;
        }
      }
    }
  }
`;

export const LoginScreenContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items:center;
  flex-direction:column;
  background-color: white;
  height:100%;
  .banner-img {
    background-image: url(${BannerImg});
    width: 500px;
    height:500px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: center;
  }

  @media (orientation: portrait) {
    .banner-img {
        width: 768px;
    }
`;

export function showToastMsg(response) {
    if (response.data && response.data.errorMessage) {
        let errmsg = response.data.errorMessage;
        if ((errmsg === "empty_stock") ||  (errmsg === "empty_cart") || (errmsg === "empty_shipment") || (errmsg === "price_mismatch")) {
            return false;
        }
    } if (response.status === "error") {
        if (response.message.toLowerCase().includes("out of stock")) {
            return false;
        } else if (response.message == "To order only eggs, add another pack") {
            return false;
        }
    }
    return true;
}

export const errorCodeToMessageMap = {
    "empty_stock" : {
        error_code: "empty_stock",
        message: "Your checkout session is expired. Rechecking for products availability."
    }, "empty_cart" : {
        error_code: "empty_cart",
        message: "Your cart is empty. Redirecting to Catalog page."
    }, "empty_shipment" : {
        error_code: "empty_shipment",
        message: "Product seems to be out of stock. Redirecting to Catalog page."
    }, "price_mismatch" : {
        error_code: "price_mismatch",
        message: "Products price mismatch"
    }
}

const ErrorHandlerUtil = {
    showToastMsg,
    errorCodeToMessageMap
}

export default ErrorHandlerUtil
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS'
export const CREATE_ORDER_REQUEST = 'CREATE_ORDER_REQUEST'
export const ORDER_REQUEST_SUCCESS = 'ORDER_REQUEST_SUCCESS'
export const CLEAR_ORDER_DATA = 'CLEAR_ORDER_DATA'
export const SET_PAYMENT_PROGRESS = 'SET_PAYMENT_PROGRESS'
export const UPDATE_CONTACTLESS_DELIVERY = 'UPDATE_CONTACTLESS_DELIVERY'
export const UPDATE_CONTACTLESS_DELIVERY_SUCCESS =
  'UPDATE_CONTACTLESS_DELIVERY_SUCCESS'
export const COMBINE_CART_DATA_LOADING = 'COMBINE_CART_DATA_LOADING'
export const SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD'
export const SET_PAYMENT_CONFIMRATION_MODAL = 'SET_PAYMENT_CONFIMRATION_MODAL'
export const SET_ORDER_TYPE = 'SET_ORDER_TYPE'
export const RETRY_PAYMENT_REQUEST = 'RETRY_PAYMENT_REQUEST'
export const SET_COUPON_APPLIED = 'SET_COUPON_APPLIED'
export const CLEAR_COUPON_APPLIED = 'CLEAR_COUPON_APPLIED'
export const CANCEL_OPEN_TRANSACTION = 'CANCEL_OPEN_TRANSACTION'
export const SET_CREATE_ORDER_LOADING = 'SET_CREATE_ORDER_LOADING'
export const POS_CANCEL_ORDER="POS_CANCEL_ORDER"
export const POS_CANCEL_ORDER_SUCCESS="POS_CANCEL_ORDER_SUCCESS"
export const POS_CANCEL_ORDER_FAILED="POS_CANCEL_ORDER_FAILED"
export const UPDATE_SHIPMENT_INFO="UPDATE_SHIPMENT_INFO"
export const createOrderSuccess = (payload) => ({
  type: CREATE_ORDER_SUCCESS,
  payload: payload,
})

export const CREATE_PAYMENT_DETAILS = 'CREATE_PAYMENT_DETAILS'

export const clearPaymentDetails = () => ({
  type: CREATE_PAYMENT_DETAILS,
})

export const createOrderRequest = (payload) => ({
  type: CREATE_ORDER_REQUEST,
  payload: payload,
})

export const clearOrderData = () => ({
  type: CLEAR_ORDER_DATA,
})

export const setPaymentPrgoress = (payload) => ({
  type: SET_PAYMENT_PROGRESS,
  payload,
})

export const updateContactlessDelivery = (payload) => ({
  type: UPDATE_CONTACTLESS_DELIVERY,
  payload,
})
export const updateContactlessDeliverySuccess = (payload) => ({
  type: UPDATE_CONTACTLESS_DELIVERY_SUCCESS,
  payload,
})

export const combineCartDataLoading = (payload) => ({
  type: COMBINE_CART_DATA_LOADING,
  payload,
})

export const setPaymentMethod = (payload) => ({
  type: SET_PAYMENT_METHOD,
  payload,
})

export const setPaymentConfirmationModal = (payload) => ({
  type: SET_PAYMENT_CONFIMRATION_MODAL,
  payload,
})

export const setOrderType = (payload) => ({
  type: SET_ORDER_TYPE,
  payload,
})

export const retryPayment = (payload) => ({
  type: RETRY_PAYMENT_REQUEST,
  payload,
})

export const setCouponCode = (payload) => ({
  type: SET_COUPON_APPLIED,
  payload,
})

export const clearCouponCode = () => ({
  type: CLEAR_COUPON_APPLIED,
})

export const cancelOpenTransaction = (payload) => ({
  type: CANCEL_OPEN_TRANSACTION,
  payload,
})

export const setCreateOrder = (payload) => ({
  type: SET_CREATE_ORDER_LOADING,
  payload,
})

export const updateShipmentInfo=(payload)=>({
  type:UPDATE_SHIPMENT_INFO,
  payload
})

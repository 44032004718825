
const  deg2rad = (deg) => {
  return deg * (Math.PI / 180);
}
export const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1); // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return d * 1000;
}

export const determineZoomLevel = (distance) => {
  let zoom = 13
  if (Math.round(distance) <= 100) {
    zoom = 18;
  } else if (Math.round(distance) <= 500) {
    zoom = 16;
  } else if (Math.round(distance) <= 1000) {
    zoom = 15;
  } else if (Math.round(distance) <= 2000) {
    zoom = 14;
  } else if (Math.round(distance) <= 3000) {
    zoom = 13.5;
  } else if (Math.round(distance) <= 4000) {
    zoom = 13;
  } else if (Math.round(distance) <= 6000) {
    zoom = 12.5;
  } else if (Math.round(distance) <= 8000) {
    zoom = 12;
  } else {
    zoom = 11;
  }
  return zoom
}

export const getMapIcon = () => ({
    url: "https://d2407na1z3fc0t.cloudfront.net/Banner/otherlocation.png",
    scaledSize: new window.google.maps.Size(30, 30),
    anchor: new window.google.maps.Point(15, 15)
})
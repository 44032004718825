import {
  REQUEST_ORDER_LIST,
  REQUEST_ORDER_LIST_SUCCESS,
  REQUEST_ORDER_LIST_FAILURE,
  REQUEST_UPDATE_ORDER_STATUS,
  REQUEST_UPDATE_ORDER_STATUS_SUCCESS,
  REQUEST_UPDATE_ORDER_STATUS_FAILURE,
  GET_INVOICE_LINK_SUCCESS,
  GET_INVOICE_LINK_FAILED
} from '../actions/OrderListActions';

const initState = {
  list: [],
  isFetched: null,
  isFetching: null,
  updateIsFetched: null,
  updateIsFetching: null,
  updateIsFailure: null,
  filterValue: 'all'
}

const orderListReducer = (state = initState, action) => {
	switch(action.type) {
		case REQUEST_ORDER_LIST:
			return {...state, isFetched: false, isFetching: true};
    case REQUEST_ORDER_LIST_SUCCESS:
      return {...state, isFetched: true, isFetching: false, list: action.payload};
    case REQUEST_ORDER_LIST_FAILURE:
      return {...state, isFetched: true, isFetching: false, list: []};
    case REQUEST_UPDATE_ORDER_STATUS:
      return {...state, updateIsFetched: false, updateIsFetching: true, updateIsFailure: false};
    case REQUEST_UPDATE_ORDER_STATUS_SUCCESS:
        return {...state, updateIsFetched: true, updateIsFetching: false};
    case REQUEST_UPDATE_ORDER_STATUS_FAILURE:
      return {...state, updateIsFetched: true, updateIsFetching: false, updateIsFailure: true};
    case GET_INVOICE_LINK_SUCCESS: {
      return {...state, getInvoiceLinkSuccess: action.data, getInvoiceLinkFailed: null};
    }
    case GET_INVOICE_LINK_FAILED: {
      return {...state, getInvoiceLinkFailed: action.data, getInvoiceLinkSuccess: null};
    }
		default:
			return state;
	}
};

export default orderListReducer;

import React, { Component } from 'react'
import Cookies from 'js-cookie'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { loginAction } from '../../actions/LoginActions'
import { clearAllTimerID } from '../../actions/CommonActions'
import classnames from 'classnames'
import './styles/loginStyles.scss'
import { clearCouponCode } from '../../actions/PaymentActions'
import { eboRemoveCoupon } from '../../actions/ProductListActions'
import SmileyLoader from '../Loader/SmileyLoader'

// import {
//   LoginContainerStyled,
//   LoginScreenContainer,
// } from "./styles/LoginContainerStyles";

class LoginComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      userID: '',
      password: '',
      showLoader: false,
    }
  }

  //   componentWillReceiveProps(nextProps) {
  // 	if (nextProps.agentDetails && (this.props.agentDetails !== nextProps.agentDetails)) {
  //           this.setState({showLoader: false});
  //           this.props.loginCallback(nextProps.agentDetails, true);
  // 	} else if (nextProps.loginFailed && (this.props.loginFailed !== nextProps.loginFailed)) {
  //           this.setState({showLoader: false});
  //           //this.notify();
  //           this.props.loginCallback(nextProps.loginFailed, false);
  // 	}
  // }

  //notify = () => toast("Wow so easy !");

  doLogin = (event) => {
    //API call
    event.preventDefault()
    const { loginActionDistpatch, history, isLoggedIn } = this.props
    const { userID, password } = this.state
    loginActionDistpatch({
      data: {
        userID,
        password,
      },
    })
    //history.push("/merchant-selection")
    //localStorage.setItem("accessToken", "dsd");
    // this.props.history.push('/merchant-selection');
  }

  componentDidMount() {
    if (this.props.timerIDs && this.props.timerIDs.length > 0) {
      this.props.timerIDs.forEach((id) => {
        clearInterval(id)
      })
      this.props.clearAllTimerID()
    }
    if (this.props.appliedCoupon) {
      const data = {}
      if (this.props.takeawayCartId)
        data['offline_cart_id'] = this.props.takeawayCartId
      if (this.props.deliveryCartId)
        data['online_cart_id'] = this.props.deliveryCartId
      this.props.removeCoupon({
        coupon_code: this.props.appliedCoupon,
        customer_key: this.props.customer_key,
        data: data,
      })
      this.props.clearCouponCode()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.isLoggedIn && this.props.isLoggedIn) {
      this.props.history.push('/merchant-selection')
    }
  }

  updateUserID = (e) => {
    this.setState({ userID: e.target.value })
  }

  updatePassword = (e) => {
    this.setState({ password: e.target.value })
  }

  render() {
    const { isFetching } = this.props
    console.log("==app started==");
    return (
      <div className="loginScreenContainer">
          {!!isFetching && <SmileyLoader />}
        <div className="loginContainerStyled">
          <div className="login-container">
          
            {/* <ToastContainer /> */}
            <form className="login-form-wrapper" autoComplete="off">
              <div className="user-name">
                <input
                  type="text"
                  placeholder="Username"
                  value={this.state.userID}
                  onChange={this.updateUserID}
                  required
                />
              </div>
              <div className="user-password">
                <input
                  type="password"
                  placeholder="Password"
                  value={this.state.password}
                  onChange={this.updatePassword}
                  required
                />
              </div>

              <button
                type="submit"
                className={classnames('login-btn', {
                  active:
                    this.state.userID !== '' && this.state.password !== '',
                })}
                onClick={(e) => {
                  this.doLogin(e)
                }}
              >
                Login
              </button>
            </form>
          </div>
        </div>
        <div className="banner-img" />
      </div>
    )
  }
}

// LoginComponent.propTypes = {
// 	loginActionDistpatch: PropTypes.func.isRequired
// };

const mapDispatchToProps = (dispatch) => {
  return {
    loginActionDistpatch: (...rest) => {
      dispatch(loginAction(...rest))
    },
    clearAllTimerID: () => dispatch(clearAllTimerID()),
    clearCouponCode: () => dispatch(clearCouponCode()),
    removeCoupon: (payload) => dispatch(eboRemoveCoupon(payload)),
  }
}
const mapStateToProps = (state) => {
  const { agentDetails, isFetching } = state.login
  const { timerIDs } = state.commonReducer
  const { appliedCoupon } = state.paymentDetails
  const { customerDetails: customer_key } = state.otpReducer
  const { takeawayCartId } = state.takeAwayReducer
  const { deliveryCartId } = state.DeliveryOrderReducer
  const isLoggedIn = !!agentDetails && !!Cookies.get('accessToken')
  return {
    isLoggedIn,
    isFetching,
    timerIDs,
    appliedCoupon,
    customer_key,
    takeawayCartId,
    deliveryCartId,
  }
}

const LoginComponentConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginComponent)

export default withRouter(LoginComponentConnect)

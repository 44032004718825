// export const SAMPLE_CALLED = 'test/SAMPLE_CALLED';
// export const sampleCalledAction = value => ({
// 	type: SAMPLE_CALLED,
// 	value
// });

export const DO_LOGIN = 'DOLOGIN'
export const LOGIN_SUCCESS = 'LOGINSUCCESS'
export const LOGIN_FAILURE = 'LOGINFAILURE'
export const SET_ACTIVE_MERCHANT = 'SET_ACTIVE_MERCHANT'
export const SET_ACTIVE_CITY = 'SET_ACTIVE_CITY'
export const SET_ACTIVE_CITY_NAME = 'SET_ACTIVE_CITY_NAME'
export const SET_ACTIVE_STORE = 'SET_ACTIVE_STORE'
export const CLEAR_LOGIN_DETAILS = 'CLEAR_LOGIN_DETAILS'
export const AGENT_PERMISSIONS = 'AGENT_PERMISSIONS'

export const setActiveMerchant = (merchantId) => ({
  type: SET_ACTIVE_MERCHANT,
  merchantId,
})
export const setActiveCity = (cityId) => ({
  type: SET_ACTIVE_CITY,
  cityId,
})
export const setActiveStore = (storeId) => ({
  type: SET_ACTIVE_STORE,
  storeId,
})

export const loginAction = (value) => {
  return {
    type: DO_LOGIN,
    value,
  }
}
export const clearLoginDetails = () => ({
  type: CLEAR_LOGIN_DETAILS,
})

export const setActiveCityName = (payload) => ({
  type: SET_ACTIVE_CITY_NAME,
  payload,
})

export const FETCH_HUBS_BY_CITY_ID = 'FETCHHUBSBYCITYID'
export const FETCH_ALL_HUBS_SUCCESS = 'FETCHALLHUBSSUCCESS'
export const FETCH_ALL_HUBS_FAILURE = 'FETCHALLHUBSFAILURE'
export const SAVE_ALL_HUBS_CITY = 'SAVEALLHUBSCITY'
export const fetchAllHubsByCityID = (value) => {
  return {
    type: FETCH_HUBS_BY_CITY_ID,
    value,
  }
}

import { combineReducers } from "redux";
import loginReducer from "./LoginReducer";
import addUserReducer from "./AddUserReducer";
import productListReducer from "./ProductListReducer";
import orderListReducer from "./OrderListReducer";
import { merger } from "../middleware/storeHelpers";
// import ScanReducer from "../pos-offline/scanning/reducers";
// import OnboardReducer from "../pos-offline/onboard/reducers";
// import ProductBillingReducer from "../pos-offline/billing/reducers";
// import PaymentReducer from "../pos-offline/payment/reducers";
// import POSCancelOrder from "../pos-offline/order-cancellation/reducers";
// import POSWrapperReducer from "../pos-merchant-wrapper/reducers";
// import LocalitySearchBoxReducer from "../locality-search-box/reducers";
// import CatalogsByLocality from "../catalogs-by-locality/reducers";
import { LOAD } from "../middleware/consts";
import OrderJourneyReducer from "./OrderJourneyReducer";
import OtpReducer from "./OtpReducer";
import TakeAwayReducer from "./TakeAwayReducer";
import PaymentDetailsReducer from "./paymentReducer";
import CouponReducer from "./CouponReducer";
import CommonReducer from "./CommonReducer";
import DeliveryOrderReducer from "./DeliveryOrderReducer";
import MerchantOrderReducer from "./MerchantOrderReducer";

const appReducer = (asyncReducers) =>
  combineReducers({
    login: loginReducer,
    productList: productListReducer,
    registerUser: addUserReducer,
    orderList: orderListReducer,
    orderJourneyReducer: OrderJourneyReducer,
    otpReducer: OtpReducer,
    takeAwayReducer: TakeAwayReducer,
    paymentDetails: PaymentDetailsReducer,
    couponDetails: CouponReducer,
    commonReducer: CommonReducer,
    DeliveryOrderReducer: DeliveryOrderReducer,
    merchantOrder: MerchantOrderReducer,
    ...asyncReducers,
  });

export const createReducer = (asyncReducers, state, action) => {
  if (action.type === LOAD && !!action.loadedState) {
    const newState = merger(state, action.loadedState);
    return appReducer(asyncReducers)(newState, action);
  }
  return appReducer(asyncReducers)(state, action);
};

const rootReducer = (state, action) => createReducer(() => {}, state, action);

export default rootReducer;

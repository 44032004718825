import {
  faChevronDown,
  faChevronUp,
  faRupeeSign,
  faChevronRight,
  faChevronLeft,
  faStopCircle,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import './OrderHistory.scss'
import { ReactComponent as ChevronDown } from '../../assets/new-ebo-icons/Chevron_Down.svg'
import { ReactComponent as GoBack } from '../../assets/new-ebo-icons/goBackBlack.svg'
import { connect } from 'react-redux'
import { API_OrderHistory } from '../../services/APIServices'
import { format } from 'date-fns'
import CancelOrderModal from '../../components/CancelOrderModal/CancelOrder'
import { ReactComponent as IconTick } from '../../assets/new-ebo-icons/states/Icon-Tick.svg'
import { ReactComponent as Processing } from '../../assets/Processing.svg'
import SmileyLoader from '../../components/Loader/SmileyLoader'
import ConfirmButton from '../../components/Buttons/ConfirmButton/ConfirmButton'
import ReactHTMLTableToExcel from 'react-html-table-to-excel'
import ToastMessage from '../../utils/ToastMessage'

const OrderHistory = (props) => {
  const [loading, setLoading] = useState(false)
  const [activeOrder, setActiveOrder] = useState(null)
  const [transactionData, setTransactionData] = useState([])
  const [showTransactions, setShowTransactions] = useState(false)
  const [orderExpanded, setOrderExpanded] = useState(null)
  const [modalActive, setModalActive] = useState(false)
  const [selectSearchOptionActive, setSelectSearchOptionActive] = useState(
    false,
  )
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [data, setData] = useState(null)
  const [filter, setFilter] = useState({
    date: format(new Date(), 'yyyy-MM-dd'),
  })
  const [searchText, setSearchText] = useState('')
  const [searchBy, setSearchBy] = useState('phone')

  const getOrderDetails = () => {
    setLoading(true)
    const activeStore = props.login.storeId
    const storeId =
      props.login.agentDetails &&
      props.login.agentDetails.organization.find(
        (item) => item.store_code === activeStore,
      ).hub_id
    API_OrderHistory({ hubid: storeId, ...filter }).then((res) => {
      if(res.statusCode===504){
        setLoading(false)
        ToastMessage(res.statusMessage)
      }
      else{

      setData(res.data)
      const tableData = []
      let cashier_ids = []
      for (let i = 0; i < res.data.length; i++) {
        if (res.data[i].status !== 'Rejected') {
          let cashier_id = res.data[i].store_user.id
          let storeUserName = res.data[i].store_user.name
          let transactions = 0
          let cash = 0
          let online = 0
          if (!cashier_ids.includes(cashier_id)) {
            for (let j = i; j < res.data.length; j++) {
              if (
                cashier_id === res.data[j].store_user.id &&
                res.data[j].status !== 'Rejected'
              ) {
                cashier_ids.push(cashier_id)
                transactions = transactions + 1
                let cashTemp =
                  res.data[j].payment_type === 'cash'
                    ? Math.floor(parseFloat(res.data[j].total))
                    : 0
                cash = cash + cashTemp
                let onlineTemp =
                  res.data[j].payment_type === 'pinelabs'
                    ? Math.floor(parseFloat(res.data[j].total))
                    : 0
                online = online + onlineTemp
              }
            }
            tableData.push([storeUserName, transactions, cash, online])
          }
        }
      }
      let total = ['Total', 0, 0, 0]
      for (let i = 0; i < tableData.length; i++) {
        total = [
          'Total',
          total[1] + tableData[i][1],
          Math.floor(total[2] + tableData[i][2]),
          Math.floor(total[3] + tableData[i][3]),
        ]
      }
      tableData.push(total)
      setTransactionData(tableData)
      setLoading(false)
    }
    })
  }
  useEffect(() => {
    getOrderDetails()
  }, [filter])
  const searchOrder = () => {
    if (searchText === '') {
      setFilter({
        date: format(new Date(), 'yyyy-MM-dd'),
      })
    } else setFilter({ [searchBy]: searchText })
  }
  const toggleDate = (action) => {
    setSearchText('')
    let date_selected = selectedDate
    action === 'prev'
      ? date_selected.setDate(date_selected.getDate() - 1)
      : date_selected.setDate(date_selected.getDate() + 1)
    setSelectedDate(new Date(date_selected))
    setFilter({
      date: format(new Date(date_selected), 'yyyy-MM-dd'),
    })
  }
  return (
    <>
      <div className="new-order-history-page">
        <div className="order-history-nav">
          <div className="order-search-bar">
            <div
              className="go-back-container"
              onClick={() => props.history.goBack()}
            >
              <GoBack /> <div>Go Back</div>
            </div>
            <div className="order-search-bar-input-container">
              <div className="order-search-type">
                <div>{searchBy === 'phone' ? 'Phone Number' : 'Order ID'}</div>
                {selectSearchOptionActive && (
                  <div
                    className="order-search-type-option"
                    // stye={{ zIndex: 99 }}
                    onClick={(event) => {
                      setSearchBy(event.target.attributes['data-value'].value)
                      setSelectSearchOptionActive(!selectSearchOptionActive)
                    }}
                  >
                    <div data-value="phone">Phone Number</div>
                    <div data-value="orderId">Order ID</div>
                  </div>
                )}
                <div
                  onClick={() =>
                    setSelectSearchOptionActive(!selectSearchOptionActive)
                  }
                >
                  <ChevronDown />
                </div>
              </div>
              <input
                placeholder={
                  searchBy === 'phone'
                    ? 'Enter customer phone number'
                    : 'Enter Order Id'
                }
                onChange={(event) => setSearchText(event.target.value)}
                value={searchText}
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    searchOrder()
                  }
                }}
              />
              <button className="search-order" onClick={() => searchOrder()}>
                Search
              </button>
            </div>
          </div>

          <div className="order-date-container">
            {Object.keys(filter).includes('date') && (
              <div>
                <div onClick={() => toggleDate('prev')}>
                  <FontAwesomeIcon icon={faChevronLeft} className="icon" />
                </div>
                <div>{format(new Date(selectedDate), 'dd MMM,yyyy')}</div>
                <div onClick={() => toggleDate('next')}>
                  <FontAwesomeIcon icon={faChevronRight} className="icon" />
                </div>
              </div>
            )}
            {data && data.length ? (
              <ConfirmButton
                onClick={() => setShowTransactions(!showTransactions)}
              >
                See Transactions
              </ConfirmButton>
            ) : null}
          </div>
        </div>
        <div className="order-history-card-container">
          {loading ? <SmileyLoader /> : null}
          {data && data.length ? (
            <>
              {!showTransactions ? (
                <div>
                  {data.map((order, index) => (
                    <div className="order-history-card">
                      <div className="shipment-info">
                        <div className="shipment-info-content">
                          <div style={{ display: 'flex' }}>
                            Shipment ID:
                            <div className="text-bold">
                              &nbsp;{order.order_id}
                            </div>
                          </div>
                          {/* <div className="shipment-details-separator"></div> */}

                          <div style={{ paddingLeft: '20px', display: 'flex' }}>
                            Ordered on:
                            <div className="text-bold">
                              &nbsp;
                              {format(
                                new Date(order.created_at),
                                'dd MMM yyyy,h:mm a ',
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="shipment-info">
                        <div className="shipment-info-content">
                          {order.store_user && (
                            <div style={{ display: 'flex' }}>
                              Cashier:
                              <div className="text-bold">
                                &nbsp;{order.store_user.name}
                              </div>
                            </div>
                          )}
                        </div>
                        {/* {order.status==="Processing" && <button
                      className="cancel-order-button"
                      onClick={() => {
                        setActiveOrder(order)
                        setModalActive(true)
                      }}
                    >
                      Cancel Order
                    </button>} */}
                      </div>
                      <div className="order-status">
                        <div className="shipment-info-content">
                          <div style={{ display: 'flex' }}>
                            Customer:
                            <div className="text-bold">&nbsp;{order.name}</div>
                          </div>

                          <div className="shipment-info-content">
                            <div className="order-type">
                              {order.is_takeaway ? 'TAKEAWAY' : 'DELIVERY'}
                            </div>
                          </div>
                        </div>
                        {order.status !== 'Delivered' &&
                          order.status !== 'Rejected' && (
                            <div className="order-status-text-processing">
                              <Processing />
                              <div>{order.status}</div>
                            </div>
                          )}
                        {order.status === 'Delivered' && (
                          <div className="order-status-text-delivered">
                            <IconTick />
                            <div>{order.status}</div>
                          </div>
                        )}
                        {order.status === 'Rejected' && (
                          <div className="order-status-text-rejected">
                            <FontAwesomeIcon icon={faStopCircle} />
                            <div>{order.status}</div>
                          </div>
                        )}
                      </div>
                      <div className="order-summary">
                        <div className="shipment-info-content">
                          <div
                            className="text-bold"
                            onClick={() => {
                              if (index === orderExpanded) {
                                setOrderExpanded(null)
                              } else setOrderExpanded(index)
                            }}
                          >
                            {order.orderItems.length} Items{' '}
                            {orderExpanded !== index ? (
                              <FontAwesomeIcon icon={faChevronDown} />
                            ) : (
                              <FontAwesomeIcon icon={faChevronUp} />
                            )}
                          </div>
                          {order.payment_type !== 'wallet' &&
                          order.wallet_amount > 0 ? (
                            <div className="payment-type">
                              Paid by:{' '}
                              <div className="text-bold">
                                {order.payment_type}+ Wallet
                              </div>
                            </div>
                          ) : (
                            <div className="payment-type">
                              Paid by:{' '}
                              <div className="text-bold">
                                {order.payment_type}
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="order-status-text text-bold">
                          Total : <FontAwesomeIcon icon={faRupeeSign} />{' '}
                          {order.total}
                        </div>
                      </div>
                      {orderExpanded === index && (
                        <div className="order-items">
                          <table>
                            <tbody>
                              {order.orderItems.map((orderItem) => (
                                <tr>
                                  <td className="text-bold">
                                    {orderItem.pr_name}
                                  </td>
                                  <td>{orderItem.pr_weight} |</td>
                                  <td>Qty:{orderItem.quantity}</td>
                                  <td
                                    className="order-status-text text-bold"
                                    align="right"
                                  >
                                    <FontAwesomeIcon icon={faRupeeSign} />{' '}
                                    {orderItem.price}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <>
                  <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button"
                    table="transactions"
                    filename="transactions"
                    sheet="transactions"
                    buttonText="Download as XLS"
                  />
                  <table id="transactions">
                    <thead>
                      <tr>
                        <th>Cashier Name</th>
                        <th>No of transactions</th>
                        <th>Cash Total</th>
                        <th>Pinelabs</th>
                      </tr>
                    </thead>
                    <tbody>
                      {transactionData.map((d) => (
                        <tr>
                          <td>{d[0]}</td>
                          <td>{d[1]}</td>
                          <td>{d[2]}</td>
                          <td>{d[3]}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              )}
            </>
          ) : (
            <div className="no-orders-found"> No Orders Found</div>
          )}
        </div>
      </div>
      <CancelOrderModal
        content={activeOrder}
        modalActive={modalActive}
        setModalActive={setModalActive}
      />
    </>
  )
}
const mapStateToProps = (state) => {
  return state
}
export default connect(mapStateToProps, null)(OrderHistory)

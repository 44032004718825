import {
  setAddingToTakeawayCartLoad,
  updateTakeAwayCartRequest,
} from "../../actions/TakeAwayActions";

export const handleDeliveryCartUpdate = (
  action,
  id,
  quantity,
  hubId,
  deliveryCartData,
  props,
  setAddingToCartLoad
) => {
  const updateApi =
    quantity.toString() === "1" && action === "remove"
      ? props.removeProductFromDelivery
      : props.updateDeliveryOrder;
  updateApi({
    params: {
      customer_key: props.otpReducer.customerDetails.customer_key,
      product_id: id,
      hub_id: hubId,
      quantity:
        action === "remove"
          ? parseInt(quantity) > 1
            ? parseInt(quantity) - 1
            : 0
          : parseInt(quantity) + 1,
      deliveryCartData: deliveryCartData,
    },
  });
};

export const handleAddToCart = (
  product,
  customerKey,
  hub_id,
  dummyCartRequest,
  resetCartItems,
  setTotalItemsInCart,
  setDummyCart,
  dummyCartItems,
  isResetTakeawayCart,
  dummyCartData,
  cartData,
  dispatch
) => {
  // const dispatch = useDispatch();
  const {
    productId,
    productName,
    actual_price,
    mrp,
    is_combo,
    uom,
    net,
    combo_products,
    unit_gram,
  } = product;
  let product_uom = ["unit", "units", "pieces"];
  let normalProduct = product_uom.includes(uom.toLowerCase()) && !is_combo;
  if (normalProduct) {
    if (
      uom.toLowerCase() === "unit" ||
      uom.toLowerCase() === "units" ||
      uom.toLowerCase() === "pieces"
    ) {
      dispatch(
        updateTakeAwayCartRequest({
          params: {
            actual_weight: unit_gram,
            hub_id: hub_id,
            customer_key: customerKey,
            quantity: "1",
            is_combo: is_combo,
            product_id: productId,
          },
        })
      );
    }
  } else {
    //to display the action on Add to cart Button
    setTimeout(() => {
      dispatch(setAddingToTakeawayCartLoad(false));
    }, 200);
    var newData = { cartItems: [] };
    if (isResetTakeawayCart) {
      dummyCartItems.cartItems = [];
      dummyCartItems.cartItems.push({
        productId,
        productName,
        actual_price,
        mrp,
        is_combo,
        uom,
        net,
        combo_products,
      });
      newData = { ...dummyCartItems };
    } else if (dummyCartData && dummyCartData.cartItems.length > 0) {
      let item = {
        productId,
        productName,
        actual_price,
        mrp,
        is_combo,
        uom,
        net,
      };
      if (is_combo) {
        item.combo_products = combo_products;
      }
      dummyCartData.cartItems.push(item);
      newData = { ...dummyCartData };
    } else {
      dummyCartItems.cartItems = [];
      let item = {
        productId,
        productName,
        actual_price,
        mrp,
        is_combo,
        uom,
        net,
      };
      if (is_combo) {
        item.combo_products = combo_products;
      }
      dummyCartItems.cartItems.push(item);
      newData = { ...dummyCartItems };
    }

    setDummyCart(newData);
    let combineData;
    if (cartData && cartData.cartItems) {
      combineData = [...cartData.cartItems, ...newData.cartItems];
    } else {
      combineData = [...newData.cartItems];
    }
    dummyCartRequest({ dummyCartItems: newData });
    setTotalItemsInCart({ cartItems: combineData });
  }
};

//import {BASE_URL, MERCHANT_APP_URL} from "../AppConstants.js";
import fetch from "../utils/FetchAuth";
const { REACT_APP_API_ROUTES, REACT_APP_NODE_SERVER_URL } = process.env;
const APIROUTES_DIR = "api-routes";
const SERVICEAPI = require("../" + APIROUTES_DIR + "/" + REACT_APP_API_ROUTES);
const API_Source = "Merchant-Kiosk";
const Authorization_key =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c";

export const API_GetCatalogProducts = (params) => {
    return fetch(
        `${SERVICEAPI.getCatalogProducts}?cat_id=${params.cat_id}&hub_id=${
            params.hubId
        }&new_product_attributes=${"include"}`,
        {
            method: "GET",
        }
    );
};

export const API_GetCategories = (params) => {
    return fetch(
        `${SERVICEAPI.getCategories}?hub_id=${params.hub_id}&source=${API_Source}`,
        {
            method: "GET",
        }
    );
};

export const API_GetPastOrders = (params) => {
    return fetch(
        `${SERVICEAPI.pastOrder}?hub_id=${params.hubId}&customer_key=${params.customer_key}&isebo=true`,
        {
            method: "GET",
            headers: {
                source: API_Source,
            },
        }
    );
};

export const API_UpdateTakeawayCart = (params) => {
    return fetch(`${SERVICEAPI.updateTakeawayCart}`, {
        method: "POST",
        body: { ...params },
        headers: {
            Authorization: Authorization_key,
            source: "ebo",
        },
    });
};

export const API_RemoveItemFromCart = (params) => {
    return fetch(`${SERVICEAPI.deleteItemFromCart}`, {
        method: "POST",
        body: {
            ...params,
        },
        headers: {
            source: "ebo",
        },
    });
};
export const API_SearchProduct = (params) => {
    return fetch(
        `${SERVICEAPI.searchProduct}?query=${params.query}&pageNum=1&hubId=${params.hubId}&perPage=100`,
        {
            method: "GET",
            headers: {
                Authorization: Authorization_key,
                source: "msite",
                customerkey: params.customerKey,
            },
        }
    );
};

export const API_GetCart = (params) => {
    return fetch(`${SERVICEAPI.getCart}?customer_key=${params.customer_key}`, {
        method: "GET",
        headers: {
            source: "Merchant-kiosk",
        },
    });
};

export const API_ResetCart = (params) => {
    return fetch(
        `${SERVICEAPI.resetTakeawayCart}?customer_key=${params.customer_key}`,
        {
            method: "DELETE",
            headers: {
                source: "ebo",
            },
        }
    );
};

export const API_lockTakeAwayOrder = (params) => {
    return fetch(
        `${SERVICEAPI.lockTakeAwayOrder}?customer_key=${params.customer_key}`,
        {
            method: "GET",
            headers: {
                source: "Merchant-kiosk",
            },
        }
    );
};

import React, { useState, useEffect } from "react";
import Card from "../../../Card/Card";
import classnames from "classnames";
// import CustomRadio from "../customRadio/customRadio";
import { ReactComponent as ChevronUp } from "../../../../assets/new-ebo-icons/Chevron_Up.svg";
import { ReactComponent as ChevronDown } from "../../../../assets/new-ebo-icons/Chevron_Down.svg";
import { ReactComponent as IconTick } from "../../../../assets/new-ebo-icons/states/Icon-Tick.svg";
import { ReactComponent as RadioIcon } from "../../../../assets/new-ebo-icons/Icon-Tick.svg";
import { ReactComponent as UncheckedRadio } from "../../../../assets/new-ebo-icons/whites/Icon-Tick.svg";
import GenericModal from "../../../GenricModal/GenricModal";
import AddAddressModal from "../../../Modals/AddAddressModal/AddAddressModal";
import Loader from "../../../Loader/Loader";
import ReactTooltip from "react-tooltip";
import "./DeliveryDetails.scss";
import { connect } from "react-redux";
import RemoveCombo from "../../../RemoveComboModal/RemoveCombo";
import {
  getCustomerAddress,
  setProgressStep,
  setNoOrdersModal,
} from "../../../../actions/CommonActions";
import ConfirmButton from "../../../Buttons/ConfirmButton/ConfirmButton";
import {
  changeCustomerAddress,
  confirmAddressSelection,
  customerChoosenAddress,
  reviewLocationLoading,
  showShipmentSlotModal,
  updateShipmentSlots,
} from "../../../../actions/DeliveryOrderActions";
import {
  getShipDetailsAction,
  clearShipment,
} from "../../../../actions/ProductListActions";

import { convertedDate } from "../../../../utils/commonUtils";
import AddToCart from "../../../AddToCart/AddToCart";

import {
  changeOfLocationAfterReview,
  changeOfLocationDetected,
  mergeShipmentRequest,
  updateDeliveryCartRequest,
  removeProductFromDeliveryCart,
  removeComboModal,
  getLocationCityName,
} from "../../../../actions/DeliveryOrderActions";
import ToastMessage from "../../../../utils/ToastMessage";
import SmileyLoader from "../../../Loader/SmileyLoader";
import { triggerEvent } from "../../../../utils/segment";

const DeliveryDetails = (props) => {
  const { DeliveryOrderReducer, productList } = props;
  const [cardActive, setCardActive] = useState({
    selectAddress: false,
    delivery: false,
  });
  const [activeConfirm, setActiveConfirm] = useState(false);
  const [addAddressModal, setAddAddressModal] = useState(false);
  const [slots, setSlots] = useState([]);
  const [activeSlotWindow, setActiveSlotWindow] = useState(0);
  const [activeShipmentId, setActiveShipmentId] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState({});
  const [shipmentData, setShipmentData] = useState([]);
  const [showShipmentDetails, setShowShipmentDetails] = useState(false);
  const updateUserAddressSuccess = (updatedAddr) => {
    console.log(updatedAddr);
  };

  useEffect(() => {
    props.getAddress({
      hubId: props.DeliveryOrderReducer.selectedLocationHub,
      customer_key:
        props.otpReducer && props.otpReducer.customerDetails.customer_key,
    });
    setShowShipmentDetails(false);
    props.clearShipment();
  }, []);

  useEffect(() => {
    props.getShipmentDetails({
      customer_key:
        props.otpReducer && props.otpReducer.customerDetails.customer_key,
    });
  }, [props.DeliveryOrderReducer.deliveryCart]);
  // defaultaddress
  // useEffect(() => {
  //   props.getShipmentDetails({
  //     customer_key:
  //       props.otpReducer && props.otpReducer.customerDetails.customer_key,
  //   })
  // }, [])
  useEffect(() => {
    if (
      props.DeliveryOrderReducer.deliveryCart &&
      !Object.keys(props.DeliveryOrderReducer.deliveryCart).length
    ) {
      props.orderJourneyStepChange(2);
    }
  }, [
    props.DeliveryOrderReducer.deliveryCart &&
      props.DeliveryOrderReducer.deliveryCart.products,
  ]);
  useEffect(() => {
    if (DeliveryOrderReducer.defaultAddress) {
      let address = DeliveryOrderReducer.addresses.filter(
        (address) =>
          address.address_id === DeliveryOrderReducer.defaultAddress[0]
      );
      if (address.length) {
        props.changeDeliveryAddress(address[0]);
      }
    }
  }, [DeliveryOrderReducer && DeliveryOrderReducer.defaultAddress]);
  useEffect(() => {
    if (props.productList.shipDetailsSuccess) {
      let selectedSlots = [];
      const activeShipments = [];
      let shipment_Summary =
        props.productList.shipDetailsSuccess &&
        props.productList.shipDetailsSuccess.shipment_summary;
      shipment_Summary &&
        shipment_Summary.forEach((shipment, shipmentIndex) => {
          let slotObj = {};
          shipment.slots.forEach((slot, slotIndex) => {
            slot.slots.forEach((timeSlot, timeSlotIndex) => {
              if (timeSlot.selected) {
                activeShipments.push(true);
                slotObj = {
                  shipment_id: shipment.id,
                  date: slot.date,
                  time: timeSlot.time,
                  shipmentIndex: shipmentIndex,
                };
                selectedSlots.push(slotObj);
              }
            });
          });
        });
      if (
        shipment_Summary &&
        shipment_Summary.length === activeShipments.length
      ) {
        setActiveConfirm(true);
      }
      setSelectedSlot(selectedSlots);
      setShipmentData(props.productList.shipDetailsSuccess.shipment_summary);
    }
  }, [
    props.productList &&
      props.productList.shipDetailsSuccess &&
      props.productList.shipDetailsSuccess.shipment_summary,
  ]);
  const updateSlotService = () => {
    props.updateShipmentSlot({
      params: {
        customer_key:
          props.otpReducer && props.otpReducer.customerDetails.customer_key,
        shipment_id: activeShipmentId,
        ...selectedSlot,
        type: "dynamic_delivery",
      },
    });
  };
  const changeSlotData = (data, SlotDate) => {
    let slotData = { slot: data.time, date: SlotDate };
    setSelectedSlot(slotData);
  };
  const getButtonText = (shipData) => {
    let buttonText = "Select Delivery Slot";
    let selected = [];
    shipData.slots.forEach((slotItem) => {
      if (slotItem.selected) {
        slotItem.slots.forEach((eachSlot) => {
          if (eachSlot.selected) {
            selected.push({ ...eachSlot, shipmentId: shipData.id });
          }
        });
      }
    });
    if (selected.length > 0) {
      buttonText = selected[0].time;
    }
    return buttonText;
  };

  const handleDeliveryCartUpdate = (action, id, quantity, hubId) => {
    const updateApi =
      quantity.toString() === "1" && action === "remove"
        ? props.removeProductFromDelivery
        : props.updateDeliveryOrder;
    updateApi({
      params: {
        customer_key: props.otpReducer.customerDetails.customer_key,
        product_id: id,
        hub_id: hubId,
        quantity:
          action === "remove"
            ? parseInt(quantity) > 1
              ? parseInt(quantity) - 1
              : 0
            : parseInt(quantity) + 1,
      },
      getShipmentCall: true,
    });
  };
  const handleComboRemove = (
    action,
    id,
    quantity,
    hubId,
    is_combo_child,
    item
  ) => {
    if (is_combo_child === true) {
      props.removeComboModal({ value: true, removeProduct: item });
    } else {
      handleDeliveryCartUpdate("remove", id, quantity, hubId);
    }
  };

  useEffect(() => {
    const takeawayItems =
      props.takeAwayReducer.TotalCartItems &&
      props.takeAwayReducer.TotalCartItems.cartItems &&
      props.takeAwayReducer.TotalCartItems.cartItems.length
        ? true
        : false;
    let deliveryItems =
      props.DeliveryOrderReducer.deliveryCart &&
      props.DeliveryOrderReducer.deliveryCart.products &&
      props.DeliveryOrderReducer.deliveryCart.products.length
        ? true
        : false;
    deliveryItems =
      props.DeliveryOrderReducer.deliveryCart &&
      props.DeliveryOrderReducer.deliveryCart.exotic &&
      props.DeliveryOrderReducer.deliveryCart.exotic.length
        ? true
        : deliveryItems;
    if (!takeawayItems && !deliveryItems) {
      props.setNoOrdersModal(true);
    }
  }, [props.DeliveryOrderReducer.deliveryCart]);
  return (
    <div>
      {props.DeliveryOrderReducer.addingToDeliveryCart ? (
        <SmileyLoader />
      ) : null}
      <div className="delivery-details-step">
        <div>
          <Card
            className={classnames("select-address-card", {
              active: cardActive.selectAddress,
            })}
          >
            <div className="marker">
              {!cardActive.selectAddress ? <RadioIcon /> : <IconTick />}
            </div>
            <div className="select-address-container">
              <div className="select-address-container-header">
                <div>
                  <span style={{ fontWeight: "bold" }}>Select Address</span>{" "}
                  &nbsp;&nbsp;
                  <span style={{ color: "#777777" }}>
                    (
                    {DeliveryOrderReducer &&
                    DeliveryOrderReducer.addresses &&
                    DeliveryOrderReducer.addresses.length
                      ? `${DeliveryOrderReducer.addresses.length} saved addresses`
                      : `0 saved addresses`}
                    )
                  </span>
                </div>

                {cardActive.selectAddress ? (
                  <ChevronDown
                    onClick={() => {
                      const active = { ...cardActive };
                      active.selectAddress = !active.selectAddress;
                      setCardActive(active);
                    }}
                  />
                ) : (
                  <ChevronUp
                    onClick={() => {
                      const active = { ...cardActive };
                      active.selectAddress = !active.selectAddress;
                      setCardActive(active);
                    }}
                  />
                )}
              </div>

              <button
                className="outline-button"
                onClick={() => setAddAddressModal(true)}
              >
                + Add New Address
              </button>
            </div>
            <div className="address-card-main-content">
              <div className="saved-address-text">Saved Addresses</div>
              {DeliveryOrderReducer &&
                DeliveryOrderReducer.addresses &&
                DeliveryOrderReducer.addresses.map((item, index) => (
                  <div className="saved-address-container">
                    {DeliveryOrderReducer.customerAddress &&
                    DeliveryOrderReducer.customerAddress.address_id ===
                      item.address_id ? (
                      <RadioIcon />
                    ) : (
                      <UncheckedRadio />
                    )}
                    <Card
                      className={classnames("address-card", {
                        "outline-card":
                          DeliveryOrderReducer.customerAddress &&
                          DeliveryOrderReducer.customerAddress.address_id ===
                            item.address_id,
                      })}
                      onOptionClick={() => props.changeDeliveryAddress(item)}
                    >
                      <div className="address-content">
                        <div>{item.line1}</div>
                        <div>{item.line2}</div>
                        <div>
                          {item.city} {item.pincode}
                        </div>
                      </div>
                    </Card>
                  </div>
                ))}
              <div className="confirm-button-holder">
                <ConfirmButton
                  onClick={() => {
                    props.confirmAddressSelection(true);
                    props.updateDefaultAddress({
                      ...DeliveryOrderReducer.customerAddress,
                      customer_key:
                        props.otpReducer &&
                        props.otpReducer.customerDetails.customer_key,
                      cart_id: props.DeliveryOrderReducer.deliveryCart.cart_id,
                      hub_id: props.DeliveryOrderReducer.selectedLocationHub,
                    });
                    setShowShipmentDetails(true);
                  }}
                  disabled={DeliveryOrderReducer.customerAddress ? false : true}
                >
                  {props.DeliveryOrderReducer.addressSelectionLoading ? (
                    <Loader />
                  ) : (
                    "Confirm"
                  )}
                </ConfirmButton>
              </div>
            </div>
          </Card>
          {showShipmentDetails &&
          Object.keys(DeliveryOrderReducer).length &&
          props.productList.shipDetailsSuccess &&
          Object.keys(props.productList.shipDetailsSuccess).length &&
          props.DeliveryOrderReducer.addresses ? (
            <Card
              className={classnames("delivery-details-card", {
                active: cardActive.delivery,
              })}
            >
              <div className="marker">
                {!cardActive.delivery ? <RadioIcon /> : <IconTick />}
              </div>
              {shipmentData && shipmentData.length ? (
                <div className="delivery-details-container">
                  <div>
                    <span style={{ fontWeight: "bold" }}>Delivery Summary</span>{" "}
                    &nbsp;&nbsp;
                    <span style={{ color: "#777777" }}>
                      (
                      {shipmentData &&
                        shipmentData.reduce((a, b) => {
                          return a + b.products.length;
                        }, 0)}
                      items to be delivered in{" "}
                      {shipmentData && shipmentData.length} shipments)
                    </span>
                  </div>
                  {cardActive.delivery ? (
                    <ChevronDown
                      onClick={() => {
                        const active = { ...cardActive };
                        active.delivery = !active.delivery;
                        setCardActive(active);
                      }}
                    />
                  ) : (
                    <ChevronUp
                      onClick={() => {
                        const active = { ...cardActive };
                        active.delivery = !active.delivery;
                        setCardActive(active);
                      }}
                    />
                  )}
                </div>
              ) : null}

              <div className="delivery-card-main-content">
                {shipmentData &&
                  shipmentData.map((item, index) => (
                    <div
                      className={classnames("delivery-summary-section", {
                        "fresh-stock-awaited-container":
                          shipmentData.length > 1 && index !== 0,
                      })}
                    >
                      {shipmentData.length > 1 && index !== 0 ? (
                        <div>
                          {productList.shipDetailsSuccess.messages.length &&
                            productList.shipDetailsSuccess.messages[0].message}
                        </div>
                      ) : null}

                      <div>{item.messages.message}</div>
                      <ConfirmButton
                        className="delivery-slot-button"
                        data-for={index === 0 ? "main" : ""}
                        data-tip="Confirm your slot to continue"
                        data-iscapture="true"
                        onClick={() => {
                          setActiveShipmentId(item.id);
                          setSlots(item.slots);
                          props.showShipmentSlotModal(true);
                        }}
                      >
                        {getButtonText(item)}
                      </ConfirmButton>
                      <div className="delivery-details">
                        {item.products.map((product, index) => (
                          <div className="each-delivery-item">
                            <div className="each-delivery-item-number">
                              {index + 1}
                            </div>
                            <div className="product-details">
                              <div className="product-details-header">
                                <div>{product.product_name}</div>
                                <div
                                  onClick={(e) =>
                                    handleDeliveryCartUpdate(
                                      "remove",
                                      product.product_id,
                                      1,
                                      product.hub_id
                                    )
                                  }
                                >
                                  X
                                </div>
                              </div>
                              <div className="product-details-footer">
                                <div className="product-info">
                                  <div>{product.weight}</div>
                                  <div>
                                    &#8377;{product.base_price}{" "}
                                    {product.actual_price !==
                                      product.base_price && (
                                      <span>&#8377;{product.actual_price}</span>
                                    )}
                                  </div>
                                </div>

                                <div className="product-quantity">
                                  <div
                                    onClick={(e) =>
                                      handleComboRemove(
                                        "remove",
                                        product.product_id,
                                        product.quantity,
                                        product.hub_id,
                                        product.is_combo_child,
                                        product
                                      )
                                    }
                                  >
                                    &#8211;
                                  </div>
                                  <div>{product.quantity}</div>
                                  <div
                                    onClick={(e) =>
                                      handleDeliveryCartUpdate(
                                        "add",
                                        product.product_id,
                                        product.quantity,
                                        product.hub_id
                                      )
                                    }
                                  >
                                    +
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}

                <div className="confirm-button-holder">
                  <ConfirmButton
                    onClick={() => {
                      triggerEvent({
                        action_type: "checkout",
                        is_guest:
                          props.otpReducer.customer_type === "guest"
                            ? true
                            : false,
                        order_id: "",
                        is_existing_customer:
                          props.otpReducer.customer_type !== "guest" &&
                          !props.otpReducer.new_customer
                            ? true
                            : false,
                        attributes: {},
                        item_count_takeaway:
                          props.takeAwayReducer &&
                          props.takeAwayReducer.cartData &&
                          props.takeAwayReducer.cartData.cartItems &&
                          props.takeAwayReducer.cartData.cartItems.length
                            ? props.takeAwayReducer.cartData.cartItems.length
                            : "",
                        item_count_delivery:
                          props.DeliveryOrderReducer &&
                          props.DeliveryOrderReducer.deliveryCart &&
                          props.DeliveryOrderReducer.deliveryCart.products &&
                          props.DeliveryOrderReducer.deliveryCart.products
                            .length
                            ? props.DeliveryOrderReducer.deliveryCart.products
                                .length
                            : "",
                        customer_key:
                          props.otpReducer.customerDetails.customer_key,
                        id: props.otpReducer.uid,
                      });
                      if (
                        props.DeliveryOrderReducer &&
                        props.DeliveryOrderReducer.customerAddress
                      ) {
                        props.makeMergeShipmentRequest({
                          customer_key:
                            props.otpReducer &&
                            props.otpReducer.customerDetails.customer_key,
                        });
                      } else {
                        ToastMessage("Please select address for delivery");
                      }
                    }}
                    disabled={!activeConfirm}
                  >
                    {props.DeliveryOrderReducer.mergeShipmentLoading ? (
                      <Loader />
                    ) : (
                      "Confirm"
                    )}
                  </ConfirmButton>
                </div>

                <ReactTooltip id="main" />
              </div>
            </Card>
          ) : null}
          <GenericModal
            show={props.DeliveryOrderReducer.showRemoveComboModal}
            cancel={props.removeComboModal}
            className="remove-combo-modal"
          >
            <RemoveCombo
              title={
                Object.keys(props.DeliveryOrderReducer.deliveryCart).length &&
                props.DeliveryOrderReducer.deliveryCart.products.length > 0 &&
                props.DeliveryOrderReducer.deliveryCart.products[0]
                  .combo_pop_up_messages.title
              }
              description={
                Object.keys(props.DeliveryOrderReducer.deliveryCart).length &&
                props.DeliveryOrderReducer.deliveryCart.products.length > 0 &&
                props.DeliveryOrderReducer.deliveryCart.products[0]
                  .combo_pop_up_messages.description
              }
            />
          </GenericModal>
        </div>
        <div className="add-to-cart-container">
          <AddToCart
            stepName="DeliveryDetails"
            cartData={props.takeAwayReducer.cartData}
            dummyCartData={props.takeAwayReducer.dummyCartData}
          />
        </div>
        <GenericModal
          className="change-of-location-modal"
          show={props.DeliveryOrderReducer.changeOfLocationModal} //
          cancel={props.changeOfLocationModalState}
        >
          <div className="alert-text">Alert ! Location Changed</div>
          <div>
            Your cart maybe modified as delivery address is different from
            locality entered.
          </div>
          <div className="button-contianer">
            <button
              className="change-address"
              onClick={(e) => props.changeOfLocationModalState(false)}
            >
              Change Address
            </button>
            <button
              className="review-button"
              onClick={() => {
                props.reviewLocationLoading(true);
                props.makeLocationRequest({
                  ...DeliveryOrderReducer.customerAddress,
                  customer_key:
                    props.otpReducer &&
                    props.otpReducer.customerDetails.customer_key,
                  cart_id: props.DeliveryOrderReducer.deliveryCart.cart_id,
                  changeOfLocationModalState: props.changeOfLocationModalState,
                });
              }}
            >
              {props.DeliveryOrderReducer.reviewLocation ? (
                <Loader />
              ) : (
                "Review Cart"
              )}
            </button>
          </div>
        </GenericModal>
        <GenericModal
          className="slot-modal"
          show={props.DeliveryOrderReducer.showShipmentSlotModal}
          cancel={props.showShipmentSlotModal}
        >
          <div>Select Delivery Slot</div>
          <div>
            <div className="delivery-date-tabs">
              {slots.map((daySlot, index) => (
                <div
                  className={classnames({
                    active: activeSlotWindow === index,
                  })}
                  onClick={() => setActiveSlotWindow(index)}
                >
                  {convertedDate(daySlot.date)}
                </div>
              ))}
            </div>
            {slots.map((daySlot, index) => (
              <div
                className={classnames("time-slot-container", {
                  active: index === activeSlotWindow,
                })}
              >
                {daySlot.slots.map((slot) => (
                  <Card
                    className={classnames({
                      selected: slot.time === selectedSlot.slot,
                      disabled: slot.status !== 1,
                    })}
                    onOptionClick={() => {
                      if (slot.status === 1) {
                        changeSlotData(slot, daySlot.date);
                      }
                    }}
                  >
                    {slot.time}
                  </Card>
                ))}
              </div>
            ))}
          </div>

          <button
            className={classnames("confirm-button", {
              selected: Object.keys(selectedSlot).length > 0,
            })}
            onClick={() => {
              updateSlotService();
            }}
          >
            Select &#38; Proceed
          </button>
        </GenericModal>

        {addAddressModal ? (
          <AddAddressModal
            cityName={props.DeliveryOrderReducer.ChangedCityName}
            updateUserAddressSuccess={updateUserAddressSuccess}
            customerProfile={{
              activeHubDetails: null,
              choosenAddr: {},
              custAddr: [],
              custEmail: "",
              custName: "",
              custPhone: null,
              customer_key: "",
              gender: "",
            }}
            editAddress={false}
            closeModal={() => setAddAddressModal(false)}
            getLocationCityName={(value) => props.getLocationCityName(value)}
            hub_id={props.DeliveryOrderReducer.selectedLocationHub}
          />
        ) : null}
      </div>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAddress: (payload) => {
      dispatch(getCustomerAddress(payload));
    },
    orderJourneyStepChange: (step) => {
      dispatch(setProgressStep(step));
    },
    changeDeliveryAddress: (addressId) => {
      dispatch(customerChoosenAddress(addressId));
    },
    getShipmentDetails: (payload) => {
      dispatch(getShipDetailsAction(payload));
    },
    updateDefaultAddress: (payload) => {
      dispatch(changeCustomerAddress(payload));
    },
    updateShipmentSlot: (payload) => {
      dispatch(updateShipmentSlots(payload));
    },
    changeOfLocationModalState: (payload) =>
      dispatch(changeOfLocationDetected(payload)),

    makeLocationRequest: (payload) => {
      dispatch(changeOfLocationAfterReview(payload));
    },
    makeMergeShipmentRequest: (payload) => {
      dispatch(mergeShipmentRequest(payload));
    },
    updateDeliveryOrder: (payload) => {
      dispatch(updateDeliveryCartRequest(payload));
    },
    removeProductFromDelivery: (payload) => {
      dispatch(removeProductFromDeliveryCart(payload));
    },
    setNoOrdersModal: (payload) => {
      dispatch(setNoOrdersModal(payload));
    },
    removeComboModal: (payload) => {
      dispatch(removeComboModal(payload));
    },
    clearShipment: () => {
      dispatch(clearShipment());
    },
    getLocationCityName: (params) => {
      dispatch(getLocationCityName(params));
    },
    confirmAddressSelection: (payload) => {
      dispatch(confirmAddressSelection(payload));
    },
    reviewLocationLoading: (payload) => {
      dispatch(reviewLocationLoading(payload));
    },
    showShipmentSlotModal: (payload) => {
      dispatch(showShipmentSlotModal(payload));
    },
  };
};
const mapStateToProps = (state) => {
  return state;
};
export default connect(mapStateToProps, mapDispatchToProps)(DeliveryDetails);

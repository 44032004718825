import { DATA_LOADING, SET_NO_ORDERS_MODAL, SET_POLLING_TIMER, CLEAR_TIMER_IDS } from '../actions/CommonActions'

const initialState = {
  isLoading: false,
  isNoOrderModal: false,
  timerIDs: []
}
const CommonReducer = (state = initialState, action) => {
  switch (action.type) {
    case DATA_LOADING:
      return { ...state, isLoading: action.payload }
    case SET_NO_ORDERS_MODAL:
      return {
        ...state,
        isNoOrderModal: action.payload,
      }
    case SET_POLLING_TIMER: 
      return {
        ...state,
        timerIDs: [...state.timerIDs, action.payload]
      }
    case CLEAR_TIMER_IDS:
      return {
        ...state,
        timerIDs: []
      }
    default:
      return state
  }
}

export default CommonReducer

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { isLoading, setProgressStep } from "../../../../actions/CommonActions";
import {
  getLoyalityBenefits,
  saveNewCustomerDetailsInDb,
} from "../../../../actions/OtpActions";

import { ReactComponent as IconTick } from "../../../../assets/new-ebo-icons/states/Icon-Tick.svg";
import Input from "../../../Input/Input";
import Card from "../../../Card/Card";
import "./CustomerDetails.scss";
import ConfirmButton from "../../../Buttons/ConfirmButton/ConfirmButton";

import MeatopiaCard from "../../../LoyalityCard/MeatopiaCard";
import Loader from "../../../Loader/Loader";
import { CustomerDetailConFirmed } from "../../../../utils/cleverTap";
import { triggerEvent } from "../../../../utils/segment";

const CustomerDetails = (props) => {
  const {
    customerDetails,
    loginDetails,
    new_customer,
    storeInfo,
    getMeatopiaBeneFits,
    isLoading,
    loyalityBenefits,
  } = props;
  const customer_type = props.guest;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  useEffect(() => {
    if (customer_type !== "guest") {
      getMeatopiaBeneFits({ customer_key: customerDetails.customer_key });
      setName(customerDetails.name);
      setEmail(customerDetails.email);
      setGender(customerDetails.gender);
    }
  }, [customerDetails.phone]);

  return (
    <div className="customer-details-step">
      <div className="customer-details-header">
        <div>
          {customer_type === "guest"
            ? "Customer Details - Guest User"
            : new_customer && customer_type !== "guest"
            ? "Customer Details - New User"
            : "Customer Details - Existing User"}
        </div>
        <input
          value={
            customer_type === "guest"
              ? storeInfo.default_phone
              : customerDetails.phone
          }
          disabled
        />
      </div>

      <div className="customer-details-container">
        {customer_type !== "guest" && customerDetails ? (
          <div className="promo-card-holder">
            <Card>
              <div className="loyality-card">
                <div className="loyality-card-name">
                  <div className="icon-holder">
                    <IconTick />
                  </div>
                  <div>
                    <span style={{ fontWeight: "bold" }}>Licious Wallet</span>
                  </div>
                </div>
              </div>
              <div className="validity-holder">
                Licious cash:
                {customerDetails.wallet &&
                  customerDetails.wallet.transactional_balance}{" "}
                | Bonus Cash :
                {customerDetails.wallet &&
                  customerDetails.wallet.promotional_balance}
              </div>
            </Card>
            <MeatopiaCard loyalityBenefits={loyalityBenefits} />
          </div>
        ) : null}
        <div className="customer-details-fields-holder">
          <div className="customer-each-detail">
            <div className="label">Name</div>
            <input
              value={customer_type === "guest" ? "NA" : name}
              disabled={!new_customer || customer_type === "guest"}
              onChange={(e) => {
                const event = e.target;
                if (new_customer && customer_type !== "guest") {
                  setName(event.value);
                }
              }}
            />
          </div>
          <div className="customer-each-detail">
            <div className="label">Number</div>
            <input
              value={customer_type === "guest" ? "NA" : customerDetails.phone}
              disabled={!new_customer || customer_type === "guest"}
            />
            {!new_customer && customer_type !== "guest" ? (
              <IconTick className="tick-icon" />
            ) : null}
          </div>
        </div>
        <div className="customer-details-fields-holder">
          <div className="customer-each-detail">
            <div className="label">Email</div>
            <input
              value={customer_type === "guest" ? "NA" : email}
              type="text"
              disabled={!new_customer || customer_type === "guest"}
              onChange={(e) => {
                const event = e.target;

                if (new_customer && customer_type !== "guest") {
                  setEmail(event.value);
                }
              }}
            />
            {!new_customer && customer_type !== "guest" ? (
              <IconTick className="tick-icon" />
            ) : null}
          </div>
          <div className="customer-each-detail withRadioInput">
            <div className="label">Gender</div>
            <div className="input-container">
              <input
                type="radio"
                name="gender"
                value="Male"
                className="radio-input"
                checked={
                  gender && gender.toLowerCase() === "male" ? true : false
                }
                disabled={customer_type === "guest"}
                onChange={(e) => {
                  const event = e.target;
                  if (new_customer && customer_type !== "guest") {
                    setGender(event.value);
                  }
                }}
              />
              <label className="" htmlFor="male">
                Male
              </label>
              <input
                type="radio"
                name="gender"
                value="Female"
                className="radio-input"
                checked={
                  gender && gender.toLowerCase() === "female" ? true : false
                }
                disabled={customer_type === "guest"}
                onChange={(e) => {
                  const event = e.target;
                  if (new_customer && customer_type !== "guest") {
                    setGender(event.value);
                  }
                }}
              />
              <label htmlFor="female">Female</label>
              <input
                className="radio-input"
                type="radio"
                name="gender"
                value="Others"
                checked={
                  gender && gender.toLowerCase() === "others" ? true : false
                }
                disabled={customer_type === "guest"}
                onChange={(e) => {
                  if (new_customer && customer_type !== "guest") {
                    const event = e.target;
                    setGender(event.value);
                  }
                }}
              />
              <label htmlFor="others">Others/Would not disclose</label>
            </div>
          </div>
        </div>
      </div>
      <div className="confirm-button-holder">
        <ConfirmButton
          onClick={() => {
            triggerEvent({
              action_type: "auth_completion",
              is_guest: customer_type === "guest" ? true : false,
              id: props.otpReducer.uid,
              customer_key: props.otpReducer.customerDetails.customer_key,
              order_id: "",
              is_existing_customer:
                customer_type !== "guest" && !props.otpReducer.new_customer
                  ? true
                  : false,
              item_count_takeaway: "",
              item_count_delivery: "",
              attributes: {},
            });
            if (new_customer && customer_type !== "guest") {
              props.savenewUser({
                name,
                email,
                gender,
                customer_key: customerDetails.customer_key,
                customer_status: new_customer ? "new_customer" : "old_customer",
              });
            } else {
              CustomerDetailConFirmed({
                customer_key: customerDetails.customer_key,
                city: loginDetails.agentDetails.organization.find(
                  (item) => item.store_code === loginDetails.storeId
                ).city,
                city_id: loginDetails.agentDetails.organization.find(
                  (item) => item.store_code === loginDetails.storeId
                ).city_id,
                store_id: loginDetails.storeId,
                user_type:
                  customer_type === "guest"
                    ? "guest"
                    : new_customer
                    ? "New user"
                    : "Existing user",
                meatopia_status:
                  loyalityBenefits &&
                  loyalityBenefits.subscribed &&
                  loyalityBenefits.subscribed.days_left
                    ? "member"
                    : "non-member",
                status: "success",
                status_reason: "NA",
                phone: customerDetails.phone,
                email:
                  customer_type === "guest"
                    ? "guest@licious.in"
                    : customerDetails.email,
                name:
                  customer_type === "guest" ? "guest" : customerDetails.name,
              });
            }

            props.loading(true);
            props.orderJourneyStepChange(1);
            props.loading(false);
          }}
        >
          <>
            {new_customer && customer_type !== "guest" ? (
              <span>SignUp &#38; Proceed</span>
            ) : (
              <span>Confirm &#38; Proceed</span>
            )}
          </>
        </ConfirmButton>
      </div>
    </div>
  );
};

CustomerDetails.propTypes = {};
const mapDispatchToProps = (dispatch) => {
  return {
    orderJourneyStepChange: (step) => {
      dispatch(setProgressStep(step));
    },
    loading: (value) => {
      dispatch(isLoading(value));
    },
    savenewUser: (data) => dispatch(saveNewCustomerDetailsInDb(data)),
    getMeatopiaBeneFits: (data) => dispatch(getLoyalityBenefits(data)),
  };
};
const mapStateToProps = (state) => {
  return {
    otpReducer: state.otpReducer,
    customerDetails: state.otpReducer.customerDetails,
    new_customer: state.otpReducer.new_customer,
    steps: state.orderJourneyReducer.steps,
    guest: state.otpReducer.customer_type,
    storeInfo: state.otpReducer.storeInfo,
    loyalityBenefits: state.otpReducer.loyalityBenefits,
    isLoading: state.commonReducer.isLoading,
    loginDetails: state.login,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetails);

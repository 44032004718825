import { format } from "date-fns";

// import { analytics } from "./analytics";
let date = new Date();
date = format(date, "yyyy-mm-dd hh:mm:ss");
export const triggerEvent = (data = {}) => {
  window.analytics.track(process.env.REACT_APP_SEGMENT_EVENT_NAME, {
    timestamp: date.toString(),
    ...data,
  });
};

import { debounce } from "lodash";
export const searchProduct = debounce(
    (e,data,hub_id,customerKey,loadFunction,SearchProducts,setFilterbar,setFilterData,setSearchState) => {
      setSearchState(e.value);
      let params = {
        query: e.value,
        hubId: hub_id,
        customerKey: customerKey,
        searchText: e.value,
      };
      loadFunction(true);
      SearchProducts({
        params,
      });
      if (e.value !== "") {
        setFilterbar(false);
      } else {
        setFilterData(data);
        setFilterbar(true);
        setSearchState("");
      }
    },
    1000,
    { leading: false, trailing: true }
  );
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Search from '../../assets/searchIcon.png'
import './SearchBar.scss'
function SearchBar(props) {
  const [searchVal, setSearchVal] = useState('')
  const { searchText } = props.takeAwayReducer
  let inputText
  useEffect(() => {
    setSearchVal(searchText)
  }, [searchText])

  const handleSearch = (event) => {
    props.searchProduct(event)
    setSearchVal(inputText.value)
    props.setProductScanActive(false)
  }

  return (
    <div className="new-ebo-catalog-search-box">
      <input
        className="search-input"
        type="search"
        placeholder="Search"
        onChange={(event) => {
          handleSearch(event)
        }}
        // disabled={props.scanActive ? true : false}
        ref={(x) => (inputText = x)}
        value={searchVal}
      />

      {props.searchState !== '' ? (
        <button
          className="clear-button"
          onClick={(e) => props.handleSearchClear(e)}
        >
          CLEAR
        </button>
      ) : (
        <div className="Search-Icon">
          <img className="Icon-Image" src={Search} width="20" alt="" />
        </div>
      )}
    </div>
  )
}
const mapStateToProps = (state) => {
  return state
}

const SearchBarConnect = connect(mapStateToProps)(SearchBar)

export default SearchBarConnect

import React, { forwardRef, useEffect, useRef } from "react";
import { connect } from "react-redux";
import {
  addScanProduct,
  setScanActive,
  setScanValue,
} from "../../actions/TakeAwayActions";
import ScanBarcodeImage from "../../assets/ScanBarcode.png";
import ToastMessage from "../../utils/ToastMessage";
import ConfirmButton from "../Buttons/ConfirmButton/ConfirmButton";
import "./ScanBarcode.scss";
const ScanBarcode = (props) => {
  const scanRef = React.createRef();

  useEffect(() => {
    props.setBarCodeForProduct("");
  }, []);
  const AddScanedProduct = () => {
    if (
      props.takeAwayReducer.scanValue.length === 17 ||
      props.takeAwayReducer.scanValue.length === 32 ||
      props.takeAwayReducer.scanValue.length === 33
    ) {
      props.addScanProductTocart({
        barcodes: props.takeAwayReducer.scanValue,
        quantity: 1,
        hub_id: props.hub_id,
        customer_key: props.customerKey,
      });
    } else {
      return ToastMessage(
        "Barcode can be of length 17,32 and 33 .Please try again with correct barcode"
      );
    }
  };
  return (
    <div className="scan-input">
      <input
        ref={scanRef}
        type="text"
        label="Barcode"
        autoFocus="true"
        value={props.takeAwayReducer.scanValue}
        className="barcode-input"
        onChange={(e) => props.setBarCodeForProduct(e.target.value)}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            AddScanedProduct();
          }
        }}
      />
      <div>
        <ConfirmButton
          className="skip-button"
          onClick={() => {
            props.setBarCodeForProduct("");
            props.setProductScanActive(false);
          }}
        >
          Cancel
        </ConfirmButton>
        <ConfirmButton
          className="skip-button"
          onClick={() => {
            AddScanedProduct();
          }}
        >
          Add Product
        </ConfirmButton>
      </div>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    addScanProductTocart: (payload) => {
      dispatch(addScanProduct(payload));
    },
    setBarCodeForProduct: (payload) => dispatch(setScanValue(payload)),
    setProductScanActive: (payload) => dispatch(setScanActive(payload)),
  };
};
const mapStateToProps = (state) => {
  return state;
};
const ScanBarcodeConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(ScanBarcode);

export default ScanBarcodeConnect;

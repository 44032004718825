export const Add_TO_MERCHANT_CART = "Add_TO_MERCHANT_CART";
export const SAVE_MERCHANT_ORDER_ID = "SAVE_MERCHANT_ORDER_ID";
export const SAVE_MERCHANT = "SAVE_MERCHANT";
export const CLEAR_MERCHANT_ORDER_DATA = "CLEAR_MERCHANT_ORDER_DATA";
export const SAVE_ORDER_STATUS = "SAVE_ORDER_STATUS";
export const SET_LOADING_FOR_MERCHANT_ORDERS =
  "SET_LOADING_FOR_MERCHANT_ORDERS";
export const addToMerchantCart = (payload) => ({
  type: Add_TO_MERCHANT_CART,
  payload,
});

export const saveMerchantOrderId = (payload) => ({
  type: SAVE_MERCHANT_ORDER_ID,
  payload,
});
export const saveMerchant = (payload) => ({
  type: SAVE_MERCHANT,
  payload,
});

export const saveOrderStatus = (payload) => ({
  type: SAVE_ORDER_STATUS,
  payload,
});

export const clearMerchantOrderData = () => ({
  type: CLEAR_MERCHANT_ORDER_DATA,
});

export const setLoadingForMerchantOrder = (payload) => ({
  type: SET_LOADING_FOR_MERCHANT_ORDERS,
  payload,
});

import React, { useState, useEffect } from "react";
import Stepper from "../../components/Stepper/Stepper";
import CustomerDetails from "../../components/Stepper/Steps/CustomerDetails/CustomerDetails";
import "./OrderDetails.scss";
import { connect, useSelector } from "react-redux";
import { setProgressStep, setNoOrdersModal } from "../../actions/CommonActions";
import DeliveryDetails from "../../components/Stepper/Steps/DeliveryDetails/DeliveryDetails";
import TakeAwayOrder from "../../components/Stepper/Steps/TakeAwayOrders/TakeAwayOrders";
import DeliveryOrders from "../../components/Stepper/Steps/DeliveryOrder/DeliveryOrders";
import PaymentDetails from "../../components/Stepper/Steps/Payment/Payment";
import Loader from "../../components/Loader/Loader";
import GenericModal from "../../components/GenricModal/GenricModal";
import CancelOrder from "../../components/CancelOrderModal/CancelOrder";
import NoOrder from "../../components/Modals/NoOrderModal";
import { parseInt } from "lodash";
import { eboRemoveCoupon } from "../../actions/ProductListActions";
import { clearCouponCode } from "../../actions/PaymentActions";
const OrderDetails = (props) => {
  const [steps, setSteps] = useState(
    props.customer_type && props.customer_type !== "guest"
      ? [
          {
            text: "CUSTOMER DETAILS",
            component: <CustomerDetails />,
            isComplete: false,
            active: true,
          },
          {
            text: "TAKEAWAY ORDER",
            component: <TakeAwayOrder />,
            isComplete: false,
            active: false,
          },
          {
            text: "DELIVERY ORDER",
            component: <DeliveryOrders />,
            isComplete: false,
            active: false,
          },
          {
            text: "DELIVERY DETAILS",
            component: <DeliveryDetails />,
            isComplete: false,
            active: false,
          },
          {
            text: "PAYMENT",
            component: <PaymentDetails history={props.history} />,
            isComplete: false,
            active: false,
          },
        ]
      : [
          {
            text: "CUSTOMER DETAILS",
            component: <CustomerDetails />,
            isComplete: false,
            active: true,
          },
          {
            text: "TAKEAWAY ORDER",
            component: <TakeAwayOrder />,
            isComplete: false,
            active: false,
          },
          {
            text: "PAYMENT",
            component: <PaymentDetails history={props.history} />,
            isComplete: false,
            active: false,
          },
        ]
  );

  useEffect(() => {
    handleOrderStepsChange();
  }, [props.orderJourneyActiveStep]);

  const handleOrderStepsChange = () => {
    const newSteps = [...steps];
    newSteps.forEach((step, index) => {
      if (index > props.orderJourneyActiveStep) {
        step.active = false;
        step.isComplete = false;
      } else if (index < props.orderJourneyActiveStep) {
        step.active = false;
        step.isComplete = true;
      } else {
        step.active = true;
        step.isComplete = false;
      }
    });

    setSteps(newSteps);
  };

  const [show, setShow] = useState(false);
  const cancelOrder = () => {
    setShow(!show);
  };
  const manageCoupon = () => {
    if (props.paymentDetails.appliedCoupon) {
      const data = {};
      if (props.takeAwayReducer.takeawayCartId)
        data["offline_cart_id"] = props.takeAwayReducer.takeawayCartId;
      if (props.DeliveryOrderReducer.deliveryCartId)
        data["online_cart_id"] = props.DeliveryOrderReducer.deliveryCartId;
      props.removeCoupon({
        coupon_code: props.paymentDetails.appliedCoupon,
        customer_key: props.otpReducer.customerDetails.customer_key,
        data: data,
      });
      props.clearCouponCode();
    }
  };
  return (
    <>
      {props.orderJourneyActiveStep !== null ||
      props.orderJourneyActiveStep !== undefined ||
      steps.length > 0 ? (
        <>
          <div className="stepper-holder">
            {props.orderJourneyActiveStep === 4 &&
            props.paymentDetails &&
            !Object.keys(props.paymentDetails.orderData).length &&
            !Object.keys(props.DeliveryOrderReducer.deliveryCart).length &&
            Object.keys(props.takeAwayReducer.TotalCartItems.cartItems)
              .length ? (
              <div
                className="step-back-button"
                onClick={() => {
                  manageCoupon();
                  props.orderJourneyStepChange(
                    parseInt(props.orderJourneyActiveStep) - 2
                  );
                }}
              >
                <div> &#8592;</div>
                <div>
                  BACK TO {steps[props.orderJourneyActiveStep - 2].text}
                </div>
              </div>
            ) : null}
            {props.orderJourneyActiveStep === 4 &&
            props.paymentDetails &&
            !Object.keys(props.paymentDetails.orderData).length &&
            Object.keys(props.DeliveryOrderReducer.deliveryCart).length ? (
              // Object.keys(props.takeAwayReducer.TotalCartItems.cartItems) .length
              <div
                className="step-back-button"
                onClick={() => {
                  props.orderJourneyStepChange(
                    parseInt(props.orderJourneyActiveStep) - 1
                  );
                  manageCoupon();
                }}
              >
                <div> &#8592;</div>
                <div>
                  BACK TO {steps[props.orderJourneyActiveStep - 1].text}
                </div>
              </div>
            ) : null}
            {props.orderJourneyActiveStep &&
            props.orderJourneyActiveStep !== 4 &&
            props.paymentDetails &&
            !Object.keys(props.paymentDetails.orderData).length ? (
              <div
                className="step-back-button"
                onClick={() =>
                  props.orderJourneyStepChange(
                    parseInt(props.orderJourneyActiveStep) - 1
                  )
                }
              >
                <div> &#8592;</div>
                <div
                  onClick={() => {
                    manageCoupon();
                    props.orderJourneyStepChange(
                      parseInt(props.orderJourneyActiveStep) - 2
                    );
                  }}
                >
                  BACK TO {steps[props.orderJourneyActiveStep - 1].text}
                </div>
              </div>
            ) : null}
            {props.orderJourneyActiveStep === 0 ? (
              <div onClick={() => props.history.push("/verify-user")}>
                &#8592; BACK
              </div>
            ) : null}

            <Stepper steps={steps} />
            {props.orderJourneyActiveStep !== 0 &&
              props.paymentDetails &&
              !Object.keys(props.paymentDetails.orderData).length && (
                <button className="cancel-order" onClick={(e) => cancelOrder()}>
                  START NEW ORDER
                </button>
              )}
            <GenericModal
              show={show}
              cancel={setShow}
              className="main-cancel-order-modal"
            >
              <CancelOrder cancelOrder={(e) => cancelOrder()} />
            </GenericModal>
            <GenericModal
              show={props.commonReducer.isNoOrderModal}
              cancel={!props.commonReducer.isNoOrderModal}
              className="main-no-order-modal"
            >
              <NoOrder />
            </GenericModal>
          </div>
          {steps[props.orderJourneyActiveStep] &&
          steps[props.orderJourneyActiveStep].component ? (
            steps[props.orderJourneyActiveStep].component
          ) : (
            <Loader />
          )}
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    orderJourneyStepChange: (step) => {
      dispatch(setProgressStep(step));
    },
    setNoOrdersModal: (payload) => {
      dispatch(setNoOrdersModal(payload));
    },
    removeCoupon: (payload) => dispatch(eboRemoveCoupon(payload)),
    clearCouponCode: () => {
      dispatch(clearCouponCode());
    },
  };
};
const mapStateToProps = (state) => {
  return { ...state.orderJourneyReducer, ...state.otpReducer, ...state };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails);

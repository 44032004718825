//import {BASE_URL, MERCHANT_APP_URL} from "../AppConstants.js";
import fetch from "../utils/FetchAuth";
const { REACT_APP_API_ROUTES } = process.env;
const APIROUTES_DIR = "api-routes";
//const SERVICEAPI = {};
const SERVICEAPI = require("../" + APIROUTES_DIR + "/" + REACT_APP_API_ROUTES);

// export const updateDispatchPlan = payload => fetch(`${REACT_APP_L2_SERVICE_URL}/production/dispatch/update`, {
//     method: "POST",
//     body: payload,
//   })
export const API_AssistantLogin = (payload) => {
  return fetch(`${SERVICEAPI.loginAgent}`, {
    ///api/user/auth/login
    method: "GET",
    headers: {
      username: payload.data.userID,
      password: payload.data.password,
    },
  });
};

export const API_GetAllHubsByCity = (payload) => {
  return fetch(`${SERVICEAPI.getAllHubs}?cityId=${payload.data.cityId}`, {
    method: "GET",
  });
};

export const API_SearchUser = (payload) => {
  return fetch(
    `${SERVICEAPI.searchUser}?phone=${payload.phoneNo}&cart_id=${payload.cartId}`,
    {
      method: "GET",
    }
  );
};
export const API_GetCatalogs = (payload) => {
  return fetch(
    `${SERVICEAPI.getCatalogs}/${payload.data.cityId}/${payload.data.hubId}`,
    {
      method: "GET",
      headers: {
        "store-code": payload.data.storeId,
        "X-API-KEY": payload.data.clientId,
      },
    }
  );
};

export const API_UpdateCart = (payload) => {
  return fetch(`${SERVICEAPI.updateCart}`, {
    method: "POST",
    body: { ...payload.data },
  });
};

export const API_GetShipmentDetails = (payload) => {
  return fetch(
    `${SERVICEAPI.getShipments}?customer_key=${payload.customer_key}&organization_id=${payload.organization_id}&store_code=${payload.store_code}`,
    {
      method: "GET",
      headers: {
        source: "Merchant-Kiosk",
      },
    }
  );
};

export const API_UpdateShipmentSlots = (payload) => {
  return fetch(`${SERVICEAPI.updateShipmentSlots}`, {
    method: "POST",
    headers: {
      source: "Merchant-Kiosk",
    },
    body: { ...payload.data },
  });
};

export const API_MergeShipmentSlots = (payload) => {
  return fetch(
    `${SERVICEAPI.mergeShipmentSlots}?customer_key=${payload.customerKey}`,
    {
      method: "GET",
      headers: {
        source: "ios",
      },
    }
  );
};

export const API_CreateOrder = (formData) => {
  return fetch(`${SERVICEAPI.createOrder}`, {
    method: "POST",
    body: { ...formData },
  });
};

export const API_SaveUserDetails = (payload) => {
  return fetch(`${SERVICEAPI.registerUser}`, {
    method: "POST",
    body: { ...payload.data },
  });
};

export const API_UpdateAddress = (payload) => {
  return fetch(`${SERVICEAPI.saveAddress}`, {
    method: "POST",
    body: { ...payload.data },
  });
};

export const API_ApplyCoupon = (payload) => {
  return fetch(`${SERVICEAPI.applyCoupon}/${payload.header.couponCode}`, {
    method: "POST",
    body: { ...payload.body },
  });
};

export const API_RemoveCoupon = (payload) => {
  return fetch(`${SERVICEAPI.removeCouponNewEbo}/${payload.coupon_code}`, {
    method: "POST",
    body: { ...payload.data },
  });
};
export const API_GetOrders = (payload) => {
  return fetch(`${SERVICEAPI.getOrders}?merchant_store_id=${payload}`, {
    method: "GET",
    headers: {
      source: "Merchant-Kiosk",
    },
  });
};

export const API_UpdateOrderStatus = (payload) => {
  const { client_id } = JSON.parse(localStorage.storeDetails);
  return fetch(`${SERVICEAPI.updateOrderStatus}`, {
    method: "PUT",
    body: { merchant_order_id: payload },
    headers: {
      "X-API-KEY": client_id,
    },
  });
};

export const API_DeleteItemFromCart = (payload) => {
  return fetch(`${SERVICEAPI.deleteProductFromCart}`, {
    method: "POST",
    body: { ...payload.data },
  });
};

export const API_GetInvoiceLink = (payload) => {
  return fetch(`${SERVICEAPI.getInvoiceLink}`, {
    method: "POST",
    body: { ...payload.data },
  });
};

export const API_ClearCustomerCart = (payload) => {
  return fetch(`${SERVICEAPI.clearCart}`, {
    method: "DELETE",
    body: { ...payload.data },
  });
};

export const fetchOrdersReport = (payload) => {
  return fetch(`${SERVICEAPI.getOrdersReport}?${payload.data}`, {
    method: "get",
  });
};

export const API_GetOtp = (number) => {
  return fetch(`${SERVICEAPI.getOtp}?phone=${number}&customer_key=g_test`, {
    method: "POST",
  });
};

export const API_VerifyOtp = ({ otp, number }) => {
  return fetch(
    `${SERVICEAPI.verifyOtp}?phone=${number}&customer_key=g_test&otp=${otp}`,
    { method: "POST" }
  );
};

export const API_GetCustomerDetails = (phone) => {
  return fetch(
    `${SERVICEAPI.customerDetails}`,
    {
      method: "GET",
      headers: {
        source: "website",
        phone: phone
      },
    }
  );
}

export const API_SaveNewCustomerDetails = (payload) => {
  return fetch(`${SERVICEAPI.savenewcustomer}`, {
    method: "POST",
    body: { ...payload, source: "ebostore" },
    // headers: {
    //   source: "ebostore",
    // },
  });
};

export const API_GetAddresses = (payload) => {
  return fetch(
    `${SERVICEAPI.getAddress}/?customer_key=${payload.customer_key}&hub_id=${payload.hubId}`,
    {
      method: "GET",
      headers: {
        source: "website",
      },
    }
  );
};
export const API_SaveAddress = (payload) => {
  return fetch(`${SERVICEAPI.saveAddressWithoutWrapper}`, {
    method: "POST",
    body: { ...payload.data },
    headers: {
      source: "website  ",
    },
  });
};

export const API_GetShipmentDetailsV3 = (payload) => {
  return fetch(
    `${SERVICEAPI.getShipmentsV3}?customer_key=${payload.customer_key}`,
    {
      method: "GET",
      headers: {
        source: "ios",
      },
    }
  );
};

export const API_GetWalletDetails = (payload) => {
  return fetch(
    `${SERVICEAPI.getWalletDetails}?customer_key=${payload.customer_key}`,
    {
      method: "GET",
    }
  );
};

export const API_ApplyCouponNewEbo = (payload) => {
  return fetch(`${SERVICEAPI.applyCouponNewEbo}/${payload.coupon_code}`, {
    method: "POST",
    body: { ...payload.data },
  });
};

export const API_UpdateShipmentSlotsNewEbo = (payload) => {
  return fetch(`${SERVICEAPI.updateDeliverySlot}`, {
    method: "POST",
    body: { ...payload },
    headers: {
      source: "msite",
    },
  });
};

export const API_GuestUserKey = (payload) => {
  return fetch(`${SERVICEAPI.guestUserKey}`, {
    method: "GET",
  });
};

export const API_StoreDetails = (payload) => {
  return fetch(`${SERVICEAPI.posGetHubDefaults}/?store_code=${payload}`, {
    method: "GET",
  });
};
export const API_LoyalityBenefits = (payload) => {
  return fetch(
    `${SERVICEAPI.getLoyalityBenefits}?customer_key=${payload.customer_key}`,
    {
      method: "GET",
      cache: "no-cache",
    }
  );
};

export const API_LocationChange = (payload) => {
  return fetch(
    `${SERVICEAPI.locationChange}?lat=${payload.lat}&lng=${payload.lng}&cart_id=${payload.cart_id}&hub_id=${payload.hub_id}`,
    {
      method: "GET",
      headers: {
        customerKey: payload.customerKey,
      },
    }
  );
};
export const API_locationChangeAfterReview = (payload) => {
  return fetch(
    `${SERVICEAPI.locationChangeAfterReview}?lat=${payload.lat}&lng=${payload.lng}&cart_id=${payload.cart_id}`,
    {
      method: "GET",
      headers: {
        customerKey: payload.customerKey,
      },
    }
  );
};

export const API_combineCart = (payload) => {
  return fetch(
    `${SERVICEAPI.combineCart}?customer_key=${payload.customer_key}${
      payload.shipment_id
        ? `&shipment_id=${payload.shipment_id}`
        : `&type=${payload.type}`
    }`,
    {
      method: "GET",
      // headers: {
      //   source: 'website',
      // },
    }
  );
};
export const API_getProduct = (params) => {
  return fetch(
    `${SERVICEAPI.getProduct}?product_id=${params.product_id}&hub_id=${params.hub_id}`,
    {
      method: "GET",
      headers: {
        source: "l2",
      },
    }
  );
};

export const API_CreateOrderEbo = (payload) => {
  return fetch(`${SERVICEAPI.createOrderEbo}`, {
    method: "POST",
    body: { ...payload },
  });
};

export const API_SavePdf = (payload) => {
  return fetch(`${SERVICEAPI.savePdf}`, {
    method: "POST",
    body: { ...payload },
  });
};

export const API_ScanProduct = (payload) => {
  return fetch(`${SERVICEAPI.scanProduct}`, {
    method: "POST",
    body: {
      ...payload,
    },
  });
};

export const API_ReleaseStock = (params) => {
  return fetch(`${SERVICEAPI.releaseStock}`, {
    method: "POST",
    body: {
      ...params,
    },
  });
};

export const API_OrderStatus = (params) => {
  return fetch(
    `${SERVICEAPI.order_status}?order_id=${params.order_id}&customer_key=${params.customer_key}&store_code=${params.store_code}`,
    {
      method: "GET",
      // headers: {
      //   source: 'msite',
      // },
    }
  );
};

export const API_UpdateContactlessDelivery = (params) => {
  return fetch(`${SERVICEAPI.updateContactlessDelivery}`, {
    method: "PUT",
    headers: {
      source: "Merchant-Kiosk",
    },
    body: {
      ...params,
    },
  });
};

export const API_InvoiceData = (params) => {
  return fetch(`${SERVICEAPI.getInvoiceData}?order_id=${params.order_id}`, {
    method: "GET",
  });
};

export const API_RetryPayment = (payload) => {
  return fetch(`${SERVICEAPI.retryPayment}`, {
    method: "POST",
    body: {
      ...payload.data,
    },
  });
};

export const API_CancelOpenTransaction = (payload) => {
  return fetch(`${SERVICEAPI.cancelTransaction}`, {
    method: "POST",
    body: payload,
  });
};

export const API_RemoveLoyalty = (payload) => {
  return fetch(`${SERVICEAPI.removeLoyalty}`, {
    method: "POST",
    body: {
      ...payload.data,
    },
    headers: {
      source: "msite",
      hubid: payload.hubid,
    },
  });
};

export const API_OrderHistory = (payload) => {
  let url = `${SERVICEAPI.orderHistory}`;
  Object.keys(payload).forEach((key, index) => {
    if (index === 0) {
      url += `?${key}=${payload[key]}`;
    } else {
      url += `&${key}=${payload[key]}`;
    }
  });
  return fetch(url, {
    method: "GET",
    // body: payload,
  });
};

export const API_RejectOrder = (payload) => {
  return fetch(`${SERVICEAPI.rejectOrder}`, {
    method: "post",
    body: payload,
  });
};

export const API_CreateMerchantOrder = (payload) => {
  return fetch(`${SERVICEAPI.createMerchantOrder}`, {
    method: "post",
    body: payload,
  });
};

export const API_DepleteInventory = (payload) => {
  return fetch(`${SERVICEAPI.depleteInventory}/${payload.hub_id}/products`, {
    method: "put",
    headers: {
      "X-Request-Source": "ebo",
    },
    body: payload,
  });
};

export const API_AddBackInventory = (payload) => {
  return fetch(`${SERVICEAPI.addBackInventory}`, {
    method: "post",
    headers: {
      client: "cart",
    },
    body: payload,
  });
};

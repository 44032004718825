export const PRODUCT_SEARCH = "PRODUCTSEARCH";
export const SEARCH_SUCCESS = "SERACHSUCCESS";
export const SEARCH_FAILED = "SERACHFAILED";
export const APPLY_COUPON_EBO = "APPLY_COUPON_EBO";
export const REMOVE_COUPON_EBO = "REMOVE_COUPON_EBO";
export const CLEAR_SHIPMENT_DATA="CLEAR_SHIPMENT_DATA"

export const productSearchAction = (value) => {
  return {
    type: PRODUCT_SEARCH,
    value,
  };
};

export const GET_ALL_CATALOGS = "GETALLCATALOGS";
export const GET_ALL_CATALOGS_SUCCESS = "GETALLCATALOGSSUCCESS";
export const GET_ALL_CATALOGS_FAILED = "GETALLCATALOGSFAILED";

export const productCatalogsAction = (value) => {
  return {
    type: GET_ALL_CATALOGS,
    value,
  };
};

export const UPDATE_CART = "UPDATECART";
export const UPDATE_CART_SUCCESS = "UPDATECARTSUCCESS";
export const UPDATE_CART_FAILED = "UPDATECARTFAILED";

export const updateCartAction = (value) => {
  return {
    type: UPDATE_CART,
    value,
  };
};

export const GET_SHIP_DETAILS = "GETSHIPDETAILS";
export const SHIP_DETAILS_SUCCESS = "SHIPDETAILSSUCCESS";
export const SHIP_DETAILS_FAILED = "SHIPDETAILSFAILED";

export const getShipDetailsAction = (payload) => {
  return {
    type: GET_SHIP_DETAILS,
    payload,
  };
};

export const CREATE_ORDER = "CREATEORDER";
export const CREATE_ORDER_SUCCESS = "CREATEORDERSUCCESS";
export const CREATE_ORDER_FAILED = "CREATEORDERFAILED";

export const createOrderAction = (value) => {
  return {
    type: CREATE_ORDER,
    value,
  };
};

export const APPLY_COUPON = "APPLYCOUPON";
export const APPLY_COUPON_SUCCESS = "APPLYCOUPONSUCCESS";
export const APPLY_COUPON_FAILED = "APPLYCOUPONFAILED";

export const applyCouponAction = (value) => {
  return {
    type: APPLY_COUPON,
    value,
  };
};

export const REMOVE_COUPON = "REMOVECOUPON";
export const REMOVE_COUPON_SUCCESS = "REMOVECOUPONSUCCESS";
export const REMOVE_COUPON_FAILED = "REMOVECOUPONFAILED";

export const removeCouponAction = (value) => {
  return {
    type: REMOVE_COUPON,
    value,
  };
};

export const UPDATE_SHIP_SLOT = "UPDATESHIPSLOT";
export const UPDATE_SHIP_SLOT_SUCCESS = "UPDATESHIPSLOTSUCCESS";
export const UPDATE_SHIP_SLOT_FAILED = "UPDATESHIPSLOTFAILED";

export const updateShipSlotAction = (value) => {
  return {
    type: UPDATE_SHIP_SLOT,
    value,
  };
};

export const MERGE_SHIPMENT = "MERGESHIPMENT";
export const MERGE_SHIPMENT_SUCCESS = "MERGESHIPMENTSUCCESS";
export const MERGE_SHIPMENT_FAILED = "MERGESHIPMENTFAILED";

export const mergeShipmentAction = (value) => {
  return {
    type: MERGE_SHIPMENT,
    value,
  };
};

export const DELETE_CART_ITEM = "DELETECARTITEM";
export const DELETE_CART_ITEM_SUCCESS = "DELETECARTITEMSUCCESS";
export const DELETE_CART_ITEM_FAILED = "DELETECARTITEMFAILED";

export const deleteCartItemAction = (value) => {
  return {
    type: DELETE_CART_ITEM,
    value,
  };
};

export const UPDATE_USER_CART = "UPDATEUSERCART";
export const updateUserCartAction = (data) => {
  return {
    type: UPDATE_USER_CART,
    data,
  };
};

export const RESET_USER_CART = "RESETUSERCART";
export const resetUserCartAction = () => {
  return {
    type: RESET_USER_CART,
  };
};

export const ONLY_EGGS_NOT_ALLOWED = "ONLYEGGSNOTALLOWED";
export const onlytEggsInCartAction = (data) => {
  return {
    type: ONLY_EGGS_NOT_ALLOWED,
    data,
  };
};
export const CLEAR_CUSTOMER_CART = "CLEARCUSTOMERCART";
export const CLEAR_CUSTOMER_CART_SUCCESS = "CLEARCUSTOMERCARTSUCCESS";
export const CLEAR_CUSTOMER_CART_FAILED = "CLEARCUSTOMERCARTFAILED";

export const clearCustomerCartAction = (data) => {
  return {
    type: CLEAR_CUSTOMER_CART,
    data,
  };
};

export const eboApplyCoupon = (data) => {
  return {
    type: APPLY_COUPON_EBO,
    data,
  };
};
export const eboRemoveCoupon = (data) => {
  return {
    type: REMOVE_COUPON_EBO,
    data,
  };
};

export const clearShipment=()=>{
  return{
    type:CLEAR_SHIPMENT_DATA
  }
}

import React, { PropTypes } from "react";
import { connect } from "react-redux";
import { changeOfLocationDetected } from "../../actions/DeliveryOrderActions";
import GenericModal from "../GenricModal/GenricModal";
import "./ChangeOfLocationModal.scss";

const ChangeOfLocationModal = (props) => {
  return (
    <GenericModal show={true} cancel={props.changeOfLocationModalState(false)}>
      <div>Alert ! Location Changed</div>
      <div>
        Your cart maybe modified as delivery address is different from locality
        entered.
      </div>
    </GenericModal>
  );
};

const mapStateToProps = (state) => {
  return state;
};
const mapDispatchToProps = (dispatch) => {
  return {
    changeOfLocationModalState: (payload) =>
      dispatch(changeOfLocationDetected(payload)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeOfLocationModal);

import React from "react";
import OtpInput from "react-otp-input";
import classNames from "classnames";
import GenericModal from "../GenricModal/GenricModal";
import {
  getOtp,
  saveCustomerDetails,
  verifyOtp,
  verifyOtpLoading,
  verifyOTPforWalletAndCoupon
} from "../../actions/OtpActions";
import { isLoading, setProgressStep } from "../../actions/CommonActions";
import { connect } from "react-redux";
import classnames from "classnames";
import "./OtpModal.scss";
import Loader from "../Loader/Loader";

const OtpModal = ({
  show,
  setOtpValue,
  setTimer,
  setShow,
  phoneNumber,
  otpValue,
  timer,
  verifyOtpHandler,
  verifyOtpforExistingUser,
  load,
  history,
  verifyUser,
  commonReducer,
  callBackFunctionOnVerificatioin,
  isPaymentPage,
  otpReducer,
}) => {
  // check wether it works without passing history

  const cancelOtpFlow = () => {
    setOtpValue("");
    setTimer(0);
    setShow(false);
  };
  const handleOtp = (otp) => {
    setOtpValue(otp);
    if (otp.length === 5) {
      verifyOtp(otp);
    }
  };
  const verifyOtp = (otp) => {
    // load(true);
    if(isPaymentPage){
      verifyOtpforExistingUser({
        otp: otp,
        number: phoneNumber,
        history: history,
        user_type: "customer",
        showModal: setShow,
        applyChanges:callBackFunctionOnVerificatioin,
        onSuccessCallLogCleverTapEvent: true,
      })
    }
    else{
      verifyOtpHandler({
        otp: otp,
        number: phoneNumber,
        history: history,
        user_type: "customer",
        showModal: setShow,
        onSuccessCallLogCleverTapEvent: true,
      });
    }
  };

  return (
    <GenericModal show={show} cancel={setShow} cancelOtpFlow={cancelOtpFlow}>
      <div className="otp-modal">
        <div>Verify OTP</div>
        <div>OTP sent to {phoneNumber}</div>
        <div className="otp-input-container">
          {show && (
            <OtpInput
              value={otpValue}
              onChange={(otp) => handleOtp(otp)}
              numInputs={5}
              isInputSecure
              inputStyle={{
                width: "2em",
                padding: 0,
                fontSize: "1.5rem",
              }}
              isInputNum
              shouldAutoFocus={show ? true : false}
            />
          )}
        </div>
        <div className="timer">{timer === 0 ? "0:00" : timer}</div>
        <div
          className={classNames("resend-otp", { active: timer === 0 })}
          onClick={() => {
            if (timer === 0) {
              verifyUser();
            }
          }}
        >
          RESEND OTP
        </div>
        <button
          className={classnames("verify-button", {
            active: otpValue.length === 5,
          })}
          onClick={() => {
            if (otpValue.length === 5) {
              verifyOtp();
            }
          }}
        >
          {otpReducer.isLoading ? <Loader /> : "Verify OTP"}
        </button>
      </div>
    </GenericModal>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    orderJourneyStepChange: (step) => {
      dispatch(setProgressStep(step));
    },
    load: (value) => {
      dispatch(verifyOtpLoading(value));
    },
    getOtpDetails: (number) => {
      dispatch(getOtp(number));
    },
    verifyOtpHandler: (payload) => {
      dispatch(verifyOtp(payload));
    },
    saveCustomerDetails: () => {
      dispatch(saveCustomerDetails({}));
    },
    verifyOtpforExistingUser: (payload) => {
      dispatch(verifyOTPforWalletAndCoupon(payload));
    }
  };
};
const mapStateToProps = (state) => {
  return state;
};
export default connect(mapStateToProps, mapDispatchToProps)(OtpModal);

export const GET_CATALOG = "GET_CATALOG";
export const GET_CATALOG_SUCCESS = "GET_CATALOG_SUCCESS";
export const UPDATE_FILTER_REQUEST = "UPDATE_FILTER_REQUEST";
export const UPDATE_FILTER_SUCCESS = "UPDATE_FILTER_SUCCESS";
export const GET_PAST_ORDERS_REQUEST = "GET_PAST_ORDERS_REQUEST";
export const GET_PAST_ORDERS_SUCCESS = "GET_PAST_ORDERS_SUCCESS";
export const UPDATE_TAKEAWAY_CART_REQUEST = "UPDATE_TAKEAWAY_CART_REQUEST";
export const UPDATE_TAKEAWAY_CART_SUCCESS = "UPDATE_TAKEAWAY_CART_SUCCESS";
export const UPDATE_TAKEAWAY_CART_FAILURE = "UPDATE_TAKEAWAY_CART_FAILURE";
export const REORDER_UPDATE_CART_REQUEST = "REORDER_UPDATE_CART_REQUEST";
export const REORDER_UPDATE_CART_SUCCESS = "REORDER_UPDATE_CART_SUCCESS";
export const REMOVE_ITEM_FROM_CART_REQUEST = "REMOVE_ITEM_FROM_CART_REQUEST";
export const REMOVE_ITEM_FROM_CART_SUCCESS = "REMOVE_ITEM_FROM_CART_SUCCESS";
export const REMOVE_ITEM_FROM_CART_FAILURE = "REMOVE_ITEM_FROM_CART_FAILURE";
export const GET_DUMMY_CART_REQUEST = "GET_DUMMY_CART_REQUEST";
export const GET_DUMMY_CART_SUCCESS = "GET_DUMMY_CART_SUCCESS";
export const GET_CART_REQUEST = "GET_CART_REQUEST";
export const GET_CART_SUCCESS = "GET_CART_SUCCESS";
export const GET_CART_FAILED = "GET_CART_FAILED";
export const GET_CATEGORY_REQUEST = "GET_CATEGORY_REQUEST";
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS";
export const SHOW_CONFIRM_MODAL = "SHOW_CONFIRM_MODAL";
export const RESET_TAKEAWAY_CART = "RESET_TAKEAWAY_CART";
export const RESET_TAKEAWAY_CART_SUCCESS = "RESET_TAKEAWAY_CART_SUCCESS";
export const SET_CART_DATA = "SET_CART_DATA";
export const SET_CART_DATA_SUCCESS = "SET_CART_DATA_SUCCESS";
export const SET_TOTAL_ITEMS_IN_CART = "SET_TOTAL_ITEMS_IN_CART";
export const SET_TOTAL_ITEMS_SUCCESS = "SET_TOTAL_ITEMS_SUCCESS";
export const SET_TAKEAWAY_ORDER_LOADING = "SET_TAKEAWAY_ORDER_LOADING";
export const SET_ADDING_TO_TAKEAWAY_CART_LOAD =
  "SET_ADDING_TO_TAKEAWAY_CART_LOAD";
export const LOCK_TAKEAWAY_ORDER = "LOCK_TAKEAWAY_ORDER";
export const CLEAR_TAKEAWAY_DATA = "CLEAR_TAKEAWAY_DATA";
export const SET_SCAN_ACTIVE_STATUS = "SET_SCAN_ACTIVE_STATUS";
export const ADD_SCANNED_PRODUCT = "ADD_SCANNED_PRODUCT";
export const SET_SCAN_VALUE = "SET_SCAN_VALUE";
export const getCatalog = (payload) => {
  return {
    type: GET_CATALOG,
    payload,
  };
};

export const getCategories = (payload) => {
  return {
    type: GET_CATEGORY_REQUEST,
    payload,
  };
};

export const updateFiltersRequest = (payload) => {
  return {
    type: UPDATE_FILTER_REQUEST,
    payload,
  };
};

export const getPastOrdersRequest = (payload) => {
  return {
    type: GET_PAST_ORDERS_REQUEST,
    payload,
  };
};

export const updateTakeAwayCartRequest = (payload) => {
  return {
    type: UPDATE_TAKEAWAY_CART_REQUEST,
    payload,
  };
};

export const reorderUpdateCartRequest = (payload) => {
  return {
    type: REORDER_UPDATE_CART_REQUEST,
    payload,
  };
};

export const removeItemFromCartRequest = (payload) => {
  return {
    type: REMOVE_ITEM_FROM_CART_REQUEST,
    payload,
  };
};

export const dummyCartRequest = (payload) => {
  return {
    type: GET_DUMMY_CART_REQUEST,
    payload,
  };
};

export const getCartRequest = (payload) => {
  return {
    type: GET_CART_REQUEST,
    payload,
  };
};

export const showConfirmTakeaway = (payload) => {
  return {
    type: SHOW_CONFIRM_MODAL,
    payload,
  };
};

export const resetTakeawayCart = (payload) => {
  return {
    type: RESET_TAKEAWAY_CART,
    payload,
  };
};

export const setCartData = (payload) => {
  return {
    type: SET_CART_DATA,
    payload,
  };
};

export const setTotalItemsInCart = (payload) => {
  return {
    type: SET_TOTAL_ITEMS_IN_CART,
    payload,
  };
};

export const setTakeawayOrderLoading = (payload) => {
  return {
    type: SET_TAKEAWAY_ORDER_LOADING,
    payload,
  };
};

export const setAddingToTakeawayCartLoad = (payload) => {
  return {
    type: SET_ADDING_TO_TAKEAWAY_CART_LOAD,
    payload,
  };
};

export const lockTakeAwayOrderRequest = (payload) => {
  return {
    type: LOCK_TAKEAWAY_ORDER,
    payload,
  };
};

export const clearTakeAwayData = () => {
  return {
    type: CLEAR_TAKEAWAY_DATA,
  };
};

export const setScanActive = (payload) => {
  return {
    type: SET_SCAN_ACTIVE_STATUS,
    payload,
  };
};

export const addScanProduct = (payload) => {
  return {
    type: ADD_SCANNED_PRODUCT,
    payload,
  };
};

export const setScanValue = (payload) => {
  return {
    type: SET_SCAN_VALUE,
    payload,
  };
};

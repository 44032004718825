import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import './TotalCartFooter.scss'
import classnames from 'classnames'
import { showConfirmTakeaway } from '../../actions/TakeAwayActions'
import { setProgressStep, stockRelease } from '../../actions/CommonActions'
import history from '../../utils/history'
import { clearOrderData } from '../../actions/PaymentActions'
function TotalCartFooter(props) {
  const { isShowingConfirmModal } = props.takeAwayReducer
  const { dummyCartData } = props.takeAwayReducer
  const [enableConfirmBtn, setConfirmBtn] = useState(false)
  const [buttonName, setButtonName] = useState()
  const { TotalCartItems } = props.takeAwayReducer
  const confirmOrder = () => {
    props.showConfirmModal(true)
  }

  useEffect(() => {
    if (props.stepName === 'delivery') setButtonName('Confirm Order')
    if (props.stepName === 'takeaway') setButtonName('Confirm Takeaway Order')
  }, [])

  useEffect(() => {
    if (
      (props.stepName === 'takeaway' &&
        (Object.keys(TotalCartItems).length === 0 ||
          (TotalCartItems && TotalCartItems.cartItems.length === 0))) ||
      (dummyCartData && dummyCartData.cartItems.length > 0)
    ) {
      setConfirmBtn(true)
    } else {
      setConfirmBtn(false)
    }
    if (
      props.stepName === 'delivery' &&
      Object.keys(props.DeliveryOrderReducer.deliveryCart).length === 0
    ) {
      setConfirmBtn(true)
    }
  }, [TotalCartItems, props.DeliveryOrderReducer.deliveryCart])

  const goToDashboard = () => {
    if (props.takeAwayReducer.takeawayCartId) {
      props.stockRelease({ cart_id: props.takeAwayReducer.takeawayCartId })
    }
    if (props.DeliveryOrderReducer.deliveryCartId) {
      props.stockRelease({ cart_id: props.DeliveryOrderReducer.deliveryCartId })
    }
    history.push('/dashboard-orders')
    props.clearOrderData()
  }
  return (
    <>
      {isShowingConfirmModal ||
      props.stepName === 'DeliveryDetails' ||
      props.stepName === 'payment' ? (
        <div
          className={classnames('total-cart', {
            'zero-cart': !props.totalBill,
          })}
        >
          <div className="left ">Total:&#x20b9;{props.totalBill}</div>
        </div>
      ) : (
        <div
          className={classnames('total-cart', {
            'zero-cart': !props.totalBill,
          })}
        >
          <div className="left ">Total:&#x20b9;{props.totalBill}</div>
          <div
            className={classnames('right', {
              disabled: enableConfirmBtn,
            })}
            onClick={(e) => !enableConfirmBtn && confirmOrder()}
          >
            {buttonName}
          </div>
        </div>
      )}
      {props.stepName === 'payment' &&
      props.paymentDetails &&
      Object.keys(props.paymentDetails.orderData).length ? (
        <div className={classnames('total-cart')}>
          <div className="full-btn" onClick={(e) => goToDashboard()}>
            GO TO DASHBOARD
          </div>
        </div>
      ) : null}
      {props.stepName === 'payment' &&
      props.paymentDetails.combineCartData &&
      !Object.keys(props.paymentDetails.orderData).length ? (
        <div
          className={classnames('total-cart', {
            'zero-cart': !props.totalBill,
          })}
        >
          <div className="left ">
            Total:&#x20b9;
            {props.walletActive
              ? props.walletUsableAmount >=
                props.paymentDetails.combineCartData.total
                ? 0
                : Math.abs(
                    props.paymentDetails.combineCartData.total -
                      props.walletUsableAmount,
                  )
              : props.paymentDetails.combineCartData.total}
          </div>
        </div>
      ) : null}
    </>
  )
}
const mapDispatchToProps = (dispatch) => {
  return {
    showConfirmModal: (value) => {
      dispatch(showConfirmTakeaway(value))
    },
    orderJourneyStepChange: (step) => {
      dispatch(setProgressStep(step))
    },
    clearOrderData: () => {
      dispatch(clearOrderData())
    },
    stockRelease: (payload) => {
      dispatch(stockRelease(payload))
    },
  }
}

const mapStateToProps = (state) => {
  return state
}

export default connect(mapStateToProps, mapDispatchToProps)(TotalCartFooter)

export const SEARCH_USER_BY_PHONE = "SEARCHUSERYPHONE";
export const SEARCH_USER_SUCCESS = "SEARCHUSERSUCCESS";
export const SEARCH_USER_FAILURE = "SEARCHUSERFAILURE";
export const SAVE_USER_PROFILE = "SAVEUSERPROFILE";
export const SAVE_UID = "SAVE_UID";

export const searchUserByPhone = (value) => {
  return {
    type: SEARCH_USER_BY_PHONE,
    value,
  };
};

export const saveUserProfile = (data) => {
  return {
    type: SAVE_USER_PROFILE,
    data,
  };
};

export const SAVER_USER_DETAILS = "SAVERUSERDETAILS";
export const SAVER_USER_SUCCESS = "SAVER_USER_SUCCESS";
export const SAVER_USER_FAILED = "SAVER_USER_FAILED";

export const saveUserInfo = (value) => {
  return {
    type: SAVER_USER_DETAILS,
    value,
  };
};

export const UPDATE_USER_ADDR = "UPDATEUSERADDR";
export const UPDATE_ADDR_SUCCESS = "UPDATEADDRSUCCESS";
export const UPDATE_ADDR_FAILED = "UPDATEADDRFAILED";

export const saveUserAddr = (value) => {
  return {
    type: UPDATE_USER_ADDR,
    value,
  };
};

export const saveUid = (value) => {
  return {
    type: SAVE_UID,
    payload: value,
  };
};

import React, { useEffect } from "react";
import { connect } from "react-redux";
import Card from "../../components/Card/Card";
import { clearAllTimerID } from "../../actions/CommonActions";
import { clearCouponCode } from "../../actions/PaymentActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faHistory } from "@fortawesome/free-solid-svg-icons";
import { eboRemoveCoupon } from "../../actions/ProductListActions";
import "./dashboard.scss";
import {
  addToMerchantCart,
  clearMerchantOrderData,
} from "../../actions/MerchantOrderActions";
import { clearOtpData } from "../../actions/OtpActions";

const Dashboard = (props) => {
  const { history } = props;

  const onOptionClick = () => {
    history.push("/verify-user");
  };

  const showOrderHistory = () => {
    history.push("/order-history");
  };

  useEffect(() => {
    if (
      props.commonReducer.timerIDs &&
      props.commonReducer.timerIDs.length > 0
    ) {
      props.commonReducer.timerIDs.forEach((id) => {
        clearInterval(id);
      });
      props.clearAllTimerID();
    }
    if (props.paymentDetails.appliedCoupon) {
      const data = {};
      if (props.takeAwayReducer.takeawayCartId)
        data["offline_cart_id"] = props.takeAwayReducer.takeawayCartId;
      if (props.DeliveryOrderReducer.deliveryCartId)
        data["online_cart_id"] = props.DeliveryOrderReducer.deliveryCartId;
      props.removeCoupon({
        coupon_code: props.paymentDetails.appliedCoupon,
        customer_key: props.otpReducer.customerDetails.customer_key,
        data: data,
      });
      props.clearCouponCode();
    }
    props.clearCustomerData();
  }, []);

  return (
    <div className="ebo-new-dashboard">
      <div>
        <div style={{ textAlign: "center", margin: "0.5rem 0" }}>Orders</div>
        <div class="separator" />
        <div className="ebo-new-dashboard-top">
          <Card active onOptionClick={onOptionClick} className="active-card">
            <div>
              <div className="ebo-new-order">+</div>
              <div>Start New Order</div>
            </div>
          </Card>
          <Card active onOptionClick={showOrderHistory} className="active-card">
            <div>
              <div className="ebo-new-order">
                <FontAwesomeIcon icon={faHistory} />
              </div>
              <div>Order History</div>
            </div>
          </Card>
          <Card
            active
            className="active-card"
            onOptionClick={() => {
              props.clearMerchantOrderData();
              history.push("/merchant-orders");
            }}
          >
            <div className="ebo-new-order">+</div>
            <div>Merchant Orders</div>
          </Card>
        </div>
        <div style={{ textAlign: "center", margin: "0.5rem 0" }}>
          Other Tasks
        </div>
        <div class="separator" />
        <div className="ebo-new-dashboard-bottom">
          <Card className="inactive-card">{/* <div>Approvals</div> */}</Card>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearAllTimerID: () => dispatch(clearAllTimerID()),
    clearCouponCode: () => dispatch(clearCouponCode()),
    clearMerchantOrderData: (payload) => {
      dispatch(clearMerchantOrderData(payload));
    },
    clearCustomerData: (payload) => {
      dispatch(clearOtpData(payload));
    },
    removeCoupon: (payload) => dispatch(eboRemoveCoupon(payload)),
  };
};

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  getDeliveryCartRequest,
  removeProductFromDeliveryCart,
  resetDeliveryCart,
  updateDeliveryCartRequest,
  removeComboModal,
} from '../../actions/DeliveryOrderActions'
import CancelIcon from '../../assets/cancel_icon.png'
import classnames from 'classnames'

const DeliveryCart = (props) => {
  const { customer_type } = props.otpReducer
  const [displayDeliveryItems, setDisplayDeliveryItems] = useState(false)
  const [totalItem, setTotaltItem] = useState(0)

  const handleComboRemove = (
    action,
    id,
    quantity,
    hubId,
    is_combo_child,
    item,
  ) => {
    if (is_combo_child === true) {
      props.removeComboModal({ value: true, removeProduct: item })
    } else {
      handleDeliveryCartUpdate('remove', id, quantity, hubId)
    }
  }

  const handleDeliveryCartUpdate = (action, id, quantity, hubId) => {
    const updateApi =
      quantity.toString() === '1' && action === 'remove'
        ? props.removeProductFromDelivery
        : props.updateDeliveryOrder
    updateApi({
      params: {
        customer_key: props.otpReducer.customerDetails.customer_key,
        product_id: id,
        hub_id: hubId,
        quantity:
          action === 'remove'
            ? parseInt(quantity) > 1
              ? parseInt(quantity) - 1
              : 0
            : parseInt(quantity) + 1,
      },
    })
  }

  useEffect(() => {
    let deliveryItems = false
    let total = 0
    if (props.DeliveryOrderReducer && props.DeliveryOrderReducer.deliveryCart) {
      if (
        props.DeliveryOrderReducer.deliveryCart.products &&
        props.DeliveryOrderReducer.deliveryCart.products.length > 0
      ) {
        total += props.DeliveryOrderReducer.deliveryCart.products.length
        deliveryItems = true
      }
      if (
        props.DeliveryOrderReducer.deliveryCart.exotic &&
        props.DeliveryOrderReducer.deliveryCart.exotic.length
      ) {
        total += props.DeliveryOrderReducer.deliveryCart.exotic.length
        deliveryItems = true
      }

      if (!props.DeliveryOrderReducer.localityModal) {
        deliveryItems = true
      }
    }
    setTotaltItem(total)

    setDisplayDeliveryItems(deliveryItems)
  }, [props.hub_id])
  return (
    <>
      {displayDeliveryItems && (
        <div className="delivery-cart">
          <div className="heading-content">
            <div className="heading">
              Delivery Order &nbsp;(
              {totalItem} Items)
            </div>

            {(props.stepName === 'delivery' ||
              props.stepName === 'takeaway') && (
              <button
                className="reset-btn"
                onClick={(e) => {
                  props.resetTotalDeliveryCart({
                    params: {
                      customer_key:
                        props.otpReducer.customerDetails.customer_key,
                    },
                  })
                }}
              >
                Reset Cart
              </button>
            )}
          </div>
          <div className="product-item-container">
            {props.DeliveryOrderReducer &&
              props.DeliveryOrderReducer.deliveryCart &&
              props.DeliveryOrderReducer.deliveryCart.products &&
              props.DeliveryOrderReducer.deliveryCart.products.map((item) => {
                return (
                  <div className="each-product-entry" key={item.product_id}>
                    <div className="each-product-entry-details">
                      <div>{item.product_name}</div>
                      {props.stepName !== 'payment' &&
                        !Object.keys(props.paymentDetails.orderData).length && (
                          <img
                            src={CancelIcon}
                            className="cancel-icon"
                            alt=""
                            onClick={(e) => {
                              if (item.is_combo_child) {
                                handleComboRemove(
                                  'remove',
                                  item.product_id,
                                  1,
                                  item.hub_id,
                                  item.is_combo_child,
                                  item,
                                )
                              } else
                                handleDeliveryCartUpdate(
                                  'remove',
                                  item.product_id,
                                  1,
                                  item.hub_id,
                                )
                            }}
                          />
                        )}
                    </div>
                    <div className="each-product-entry-price-details-holder">
                      <div className="price-container">
                        {item.weight && (
                          <div className="gms-style">
                            <div className="grammage">{item.weight}</div>
                          </div>
                        )}
                        <div className="delivery-price-container">
                          {item.actual_price !== item.base_price && (
                            <div className="strike-price-styles">
                              &#x20b9;
                              {parseFloat(item.actual_price).toFixed(2)}
                            </div>
                          )}
                          <div className="price-styles">
                            &nbsp;&nbsp;&#x20b9;
                            {parseFloat(item.base_price).toFixed(2)}
                          </div>
                        </div>
                      </div>
                      {props.stepName !== 'payment' && (
                        <div className="product-quantity">
                          <div
                            className={classnames({
                              disabled: parseInt(item.quantity) <= 0,
                            })}
                            onClick={() => {
                              handleComboRemove(
                                'remove',
                                item.product_id,
                                item.quantity,
                                item.hub_id,
                                item.is_combo_child,
                                item,
                              )
                            }}
                          >
                            &#8211;
                          </div>
                          <div>{item.quantity}</div>
                          <div
                            className={classnames({
                              disabled: parseInt(item.stock) <= 0,
                            })}
                            onClick={() => {
                              if (parseInt(item.stock) > 0) {
                                handleDeliveryCartUpdate(
                                  'add',
                                  item.product_id,
                                  item.quantity,
                                  item.hub_id,
                                )
                              }
                            }}
                          >
                            &#43;
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )
              })}

            {props.DeliveryOrderReducer.deliveryCart.exotic &&
            props.DeliveryOrderReducer.deliveryCart.exotic.length
              ? props.DeliveryOrderReducer.deliveryCart.exotic.map((item) => {
                  return (
                    <div className="each-product-entry">
                      <div className="each-product-entry-details">
                        <div>{item.product_name}</div>
                        {props.stepName !== 'payment' &&
                          !Object.keys(props.paymentDetails.orderData)
                            .length && (
                            <img
                              src={CancelIcon}
                              className="cancel-icon"
                              alt=""
                              onClick={(e) => {
                                handleDeliveryCartUpdate(
                                  'remove',
                                  item.product_id,
                                  1,
                                  item.hub_id,
                                )
                              }}
                            />
                          )}
                      </div>
                      <div className="each-product-entry-price-details-holder">
                        <div className="price-container">
                          {item.weight && (
                            <div className="gms-style">
                              <div className="grammage">{item.weight}</div>
                            </div>
                          )}
                          <div className="delivery-price-container">
                            {item.actual_price !== item.total && (
                              <div className="strike-price-styles">
                                &#x20b9;{item.actual_price}
                              </div>
                            )}
                            <div className="price-styles">
                              &nbsp;&#x20b9;{item.total}
                            </div>
                          </div>
                        </div>
                        <div className="product-quantity">
                          <div
                            className={classnames({
                              disabled: parseInt(item.stock) <= 0,
                            })}
                            onClick={() => {
                              handleDeliveryCartUpdate(
                                'remove',
                                item.product_id,
                                item.quantity,
                                item.hub_id,
                              )
                            }}
                          >
                            &#8211;
                          </div>
                          <div>{item.quantity}</div>
                          <div
                            className={classnames({
                              disabled: parseInt(item.stock) <= 0,
                            })}
                            onClick={() => {
                              if (parseInt(item.stock) > 0) {
                                handleDeliveryCartUpdate(
                                  'add',
                                  item.product_id,
                                  item.quantity,
                                  item.hub_id,
                                )
                              }
                            }}
                          >
                            &#43;
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              : null}
          </div>
          <div className="total-view">
            <div className="heading">Delivery Total</div>

            <div className="heading">
              &#x20b9;{props.DeliveryOrderReducer.deliveryCart.cart_total}
            </div>
          </div>
        </div>
      )}
    </>
  )
}
const mapDispatchToProps = (dispatch) => {
  return {
    getDeliveryOrderCart: (payload) => {
      dispatch(getDeliveryCartRequest(payload))
    },
    updateDeliveryOrder: (payload) => {
      dispatch(updateDeliveryCartRequest(payload))
    },
    removeProductFromDelivery: (payload) => {
      dispatch(removeProductFromDeliveryCart(payload))
    },
    resetTotalDeliveryCart: (payload) => {
      dispatch(resetDeliveryCart(payload))
    },
    removeComboModal: (payload) => {
      dispatch(removeComboModal(payload))
    },
  }
}

const mapStateToProps = (state) => {
  return state
}
export default connect(mapStateToProps, mapDispatchToProps)(DeliveryCart)

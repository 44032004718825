import {
  DO_LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  AGENT_PERMISSIONS,
  FETCH_ALL_HUBS_SUCCESS,
  FETCH_ALL_HUBS_FAILURE,
  SET_ACTIVE_MERCHANT,
  SET_ACTIVE_CITY,
  SET_ACTIVE_STORE,
  CLEAR_LOGIN_DETAILS,
  SET_ACTIVE_CITY_NAME,
} from '../actions/LoginActions'

import { SET_PROGRESS_STEP } from '../actions/CommonActions'

const initialState = {
  activeStep: 1,
  storeId: 'default',
  merchantId: 'default',
  cityId: 'default',
  city: 'default',
}
const LoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case DO_LOGIN: {
      return {
        ...state,
        isFetching: true,
      }
    }
    case CLEAR_LOGIN_DETAILS: {
      return {
        ...state,
        agentDetails: null,
        ...initialState,
      }
    }
    case SET_ACTIVE_MERCHANT: {
      const { merchantId } = action
      if (merchantId !== 'default') {
        return {
          ...state,
          merchantId: parseInt(merchantId),
        }
      }
      return {
        ...state,
        merchantId: action.merchantId,
      }
    }
    case SET_ACTIVE_CITY: {
      const { cityId } = action
      if (cityId !== 'default') {
        return {
          ...state,
          cityId: parseInt(cityId),
        }
      }
      return {
        ...state,
        cityId: cityId,
      }
    }
    case SET_ACTIVE_STORE: {
      const { storeId } = action
      return {
        ...state,
        storeId: storeId,
      }
    }
    case SET_PROGRESS_STEP: {
      return {
        ...state,
        activeStep: action.activeStep,
      }
    }
    case LOGIN_SUCCESS:
      return Object.assign({}, state, {
        agentDetails: action.data,
        isFetching: false,
      })
    case SET_ACTIVE_CITY_NAME:
      return {
        ...state,
        city: action.payload,
      }
    case AGENT_PERMISSIONS:
      return Object.assign({}, state, { agentPermissions: action.data })
    case LOGIN_FAILURE:
      return Object.assign({}, state, {
        loginFailed: action.data,
        agentPermissions: null,
        isFetching: false,
      })
    case FETCH_ALL_HUBS_SUCCESS:
      return Object.assign({}, state, { allHubsByCity: action.data })
    case FETCH_ALL_HUBS_FAILURE:
      return Object.assign({}, state, { allHubsByCityFailed: action.data })
    default:
      return state
  }
}

export default LoginReducer

import React from "react";
import "./GenricModal.scss";
import classnames from "classnames";
const GenericModal = ({
  show,
  cancel,
  children,
  className,
  showCancel = true,
}) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";
  return (
    <div className={classnames(className ? className : "", showHideClassName)}>
      <section className="modal-main">
        <div className="modal-content">
          {showCancel && (
            <div className="modal-close" onClick={() => cancel(!show)}>
              X
            </div>
          )}
          {children}
        </div>
      </section>
    </div>
  );
};

export default GenericModal;

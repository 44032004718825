import {
  GET_ALL_CATALOGS_SUCCESS,
  GET_ALL_CATALOGS_FAILED,
  UPDATE_CART_SUCCESS,
  UPDATE_CART_FAILED,
  SHIP_DETAILS_SUCCESS,
  SHIP_DETAILS_FAILED,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_FAILED,
  APPLY_COUPON_SUCCESS,
  APPLY_COUPON_FAILED,
  REMOVE_COUPON_SUCCESS,
  REMOVE_COUPON_FAILED,
  UPDATE_SHIP_SLOT_SUCCESS,
  UPDATE_SHIP_SLOT_FAILED,
  MERGE_SHIPMENT_SUCCESS,
  MERGE_SHIPMENT_FAILED,
  DELETE_CART_ITEM_SUCCESS,
  DELETE_CART_ITEM_FAILED,
  UPDATE_USER_CART,
  ONLY_EGGS_NOT_ALLOWED,
  RESET_USER_CART,
  CLEAR_SHIPMENT_DATA
} from "../actions/ProductListActions";

const initState = {
  allProductsCart: {
    userCart: {},
    totalCartQty: 0,
  },
};
const ProductLists = (state = initState, action) => {
  switch (action.type) {
    case GET_ALL_CATALOGS_SUCCESS: {
      return Object.assign({}, state, {
        loaderFlag: !state.loaderFlag,
        errorCatalogList: null,
        catalogList: {
          ...action.data,
        },
      });
    }
    case GET_ALL_CATALOGS_FAILED:
      return Object.assign({}, state, {
        loaderFlag: !state.loaderFlag,
        errorCatalogList: action.data,
      });
    case UPDATE_CART_SUCCESS:
      return Object.assign({}, state, { updateCartSuccess: action.data });
    case UPDATE_CART_FAILED:
      return Object.assign({}, state, { updateCartFailed: action.data });
    case SHIP_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        loaderFlag: !state.loaderFlag,
        shipDetailsSuccess: action.data,
      });
    case SHIP_DETAILS_FAILED:
      return Object.assign({}, state, {
        loaderFlag: !state.loaderFlag,
        shipDetailsFailed: action.data,
        shipDetailsSuccess: action.data,
      });
    case CREATE_ORDER_SUCCESS:
      return Object.assign({}, state, { createOrderSuccess: action.data });
    case CREATE_ORDER_FAILED:
      return Object.assign({}, state, { createOrderFailed: action.data });
    case APPLY_COUPON_SUCCESS:
      return Object.assign({}, state, { applyCouponSuccess: action.data });
    case APPLY_COUPON_FAILED:
      return Object.assign({}, state, { applyCouponFailed: action.data });
    case REMOVE_COUPON_SUCCESS:
      return Object.assign({}, state, { removeCouponSuccess: action.data });
    case REMOVE_COUPON_FAILED:
      return Object.assign({}, state, { removeCouponFailed: action.data });
    case UPDATE_SHIP_SLOT_SUCCESS:
      return Object.assign({}, state, { updateShipSlotSuccess: action.data });
    case UPDATE_SHIP_SLOT_FAILED:
      return Object.assign({}, state, { updateShipSlotFailed: action.data });
    case MERGE_SHIPMENT_SUCCESS:
      return Object.assign({}, state, { mergeShipmentSuccess: action.data });
    case MERGE_SHIPMENT_FAILED:
      return Object.assign({}, state, { mergeShipmentFailed: action.data });
    case DELETE_CART_ITEM_SUCCESS:
      return Object.assign({}, state, { deleteCartItemSuccess: action.data });
    case DELETE_CART_ITEM_FAILED:
      return Object.assign({}, state, { deleteCartItemFailed: action.data });
    case UPDATE_USER_CART:
      return Object.assign({}, state, { allProductsCart: action.data });
    case ONLY_EGGS_NOT_ALLOWED:
      return Object.assign({}, state, { shipmentNotAllowed: action.data });
    case RESET_USER_CART:
      return {
        ...state,
        allProductsCart: { userCart: { totalCartQty: 0 } },
        shipmentNotAllowed: null,
      };
      case CLEAR_SHIPMENT_DATA:{
        return{
          ...state,
          shipDetailsSuccess:{}
        }
      }
    default:
      return state;
  }
};
export default ProductLists;

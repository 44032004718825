import { format } from "date-fns";
import JsBarcode from "jsbarcode";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Logo from "../../assets/LiciousLogo.svg";
import { API_InvoiceData, API_SavePdf } from "../../services/APIServices";
import ConfirmButton from "../Buttons/ConfirmButton/ConfirmButton";
import SmileyLoader from "../Loader/SmileyLoader";
import "./Invoice.scss";

const Invoice = (props) => {
  const [invoiceData, setInvoiceData] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    if (props.orderId) {
      API_InvoiceData({
        order_id: props.orderId,
      }).then((res) => {
        const data = res.data;
        data.order.orders_meta = JSON.parse(data.order.orders_meta);

        setInvoiceData(data);
        setLoading(false);
        const barCodeContainer = document.getElementById(
          "order-bar-code-holder"
        );
        if (barCodeContainer) {
          JsBarcode("#order-bar-code-holder", props.orderId, {
            width: 1.7,
            height: "30px",
            fontSize: "15px",
            displayValue: false,
          });
        }
      });
    }
  }, [props.orderId]);
  const handleHTMLExtract = () => {
    const config = {
      data: document.getElementById("invoice-pdf").innerHTML.toString(),
      order_id:
        props.paymentDetails.orderData.orderDetails.shipment_order_ids[0]
          .order_id,
      order_date: format(
        new Date(invoiceData.order.order_processing_date),
        "yyyy-mm-dd"
      ),
    };
    API_SavePdf(config).then((res) => {
      console.log(res);
    });
    function printInfo(ele) {
      const openWindow = window.open("", "title", "attributes");
      openWindow.document.write(
        document.getElementById("invoice-pdf").innerHTML
      );
      openWindow.document.close();
      openWindow.focus();
      openWindow.print();
      openWindow.close();
    }
    printInfo();
  };

  const getDeliveryType = () => {
    if (props.shipmentInfo.every((item) => item.express === "1"))
      return "Express";
    else {
      let deliveryShipment = props.shipmentInfo.find((item) => item.sheduled);
      return deliveryShipment
        ? `${deliveryShipment.sheduled ||
            ""} ${deliveryShipment.sheduled_time || ""}`
        : "";
    }
  };

  return (
    <>
      {loading && !invoiceData ? (
        <SmileyLoader />
      ) : (
        <>
          <div style={{ maxHeight: "500px", overflow: "scroll" }}>
            <div id={"invoice-pdf"}>
              <div
                style={{
                  display: "flex",
                  width: "80mm",
                  fontSize: "14px",
                  pageBreakAfter: "always",
                  marginRight: "30px",
                }}
              >
                <div>
                  <div>
                    <div>
                      <div>
                        <div style={{ textAlign: "center" }}>
                          <img
                            src={Logo}
                            id="logo"
                            className="img-responsive bill-logo"
                            alt=""
                          />
                        </div>
                        <p style={{ textAlign: "center" }}>
                          M/S DELIGHTFUL GOURMET PVT LTD
                        </p>
                        <div className="col-md-12">
                          <p
                            className="text-center"
                            style={{ textAlign: "center" }}
                          >
                            {invoiceData &&
                              invoiceData.licenceDetails &&
                              invoiceData.licenceDetails.licences.hub_data
                                .address1}
                            ,{" "}
                            {invoiceData &&
                              invoiceData.licenceDetails &&
                              invoiceData.licenceDetails.licences.hub_data
                                .address2}
                          </p>
                        </div>
                        <div>
                          <p
                            className="text-center"
                            style={{ textAlign: "center" }}
                          >
                            FSSAI Lic No.:{" "}
                            <span id="fssai">
                              {invoiceData &&
                                invoiceData.licenceDetails &&
                                invoiceData.licenceDetails.licences.hub_data
                                  .license_number}
                            </span>
                          </p>
                        </div>
                        <div>
                          <p
                            className="text-center"
                            style={{ textAlign: "center" }}
                          >
                            CIN: <span id="gstin">U74900KA2015PTC080321</span>
                          </p>
                        </div>

                        <div>
                          <p
                            className="text-center"
                            style={{ textAlign: "center" }}
                          >
                            GSTIN:{" "}
                            <span id="gstin">
                              {invoiceData &&
                                invoiceData.licenceDetails &&
                                invoiceData.licenceDetails.licences.state_data
                                  .license_number}
                            </span>
                          </p>
                        </div>
                        <div>
                          <p id="invoicetitle" style={{ textAlign: "center" }}>
                            {props.invoiceFor === "Takeaway"
                              ? "Invoice-cum-bill of supply"
                              : "TAX INVOICE"}
                          </p>
                        </div>
                        {props.invoiceFor === "Delivery" &&
                        invoiceData &&
                        invoiceData.order &&
                        invoiceData.order.orders_meta &&
                        invoiceData.order.orders_meta
                          .contactless_delivery_enabled ? (
                          <div id="contactless">
                            <p style={{ textAlign: "center" }}>
                              <b>**Contactless Delivery**</b>
                            </p>
                          </div>
                        ) : null}

                        <div style={{ textAlign: "center" }} id={""}>
                          <svg id="order-bar-code-holder"></svg>
                        </div>
                        {props.invoiceFor !== "Takeaway" ? (
                          <>
                            <div>
                              Dear Meat Lover,
                              <br />
                              <br />
                              Thank you for ordering from Licious. Elevate your
                              mood and the taste of your dishes with Licious
                              meats & seafood that come with 100% Quality Ki
                              Guarantee.
                              <br />
                              <br />
                            </div>
                            <div className="col-md-12">
                              Delivery Type:{" "}
                              <span id="print_scheduled">
                                {getDeliveryType()}
                              </span>
                            </div>
                            <div className="col-md-12">
                              Hub:{" "}
                              <span id="outlet">
                                {invoiceData && invoiceData.hubs.hub_name}
                              </span>
                            </div>
                            <div
                              className="col-md-12"
                              style={{ fontWeight: "bold", fontSize: "14px" }}
                            >
                              Kml:{" "}
                              <span id="kml">
                                {invoiceData &&
                                  invoiceData &&
                                  invoiceData.order &&
                                  invoiceData.order.kml_id}
                              </span>
                            </div>
                            <div
                              className="col-md-12"
                              style={{ fontWeight: "bold", fontSize: "14px" }}
                            >
                              Otp: <span id="otp"></span>
                            </div>
                            <div className="col-md-12">
                              Kml: <span id="kml"></span>
                            </div>
                            <div className="col-md-12">
                              Order Id:{" "}
                              <span
                                id="print_orderid"
                                style={{ fontSize: "14px" }}
                              >
                                {invoiceData &&
                                  invoiceData.order &&
                                  invoiceData.order.order_id}
                              </span>
                            </div>
                            <div>
                              Invoice Date/Time:{" "}
                              <span
                                id="print_time"
                                style={{ fontSize: "14px" }}
                              >
                                {format(new Date(), "yyyy-MM-dd")} |{" "}
                                {format(new Date(), "h:m:s")}
                              </span>
                            </div>
                            <div>
                              Customer Number:
                              {props.otpReducer.customer_type !== "guest" && (
                                <span id="print_custPh">
                                  {invoiceData &&
                                    invoiceData.customer &&
                                    invoiceData.customer.phone}
                                </span>
                              )}
                            </div>
                            <div>
                              Customer Name:{" "}
                              <span id="print_custName">
                                {invoiceData &&
                                  invoiceData.customer &&
                                  invoiceData.customer.fname}{" "}
                                {invoiceData &&
                                  invoiceData.customer &&
                                  invoiceData.customer.lname}
                              </span>
                            </div>
                            <div>
                              <span id="print_addr"></span>
                            </div>
                          </>
                        ) : (
                          <div
                            style={{
                              textAlign: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <div className="col-md-12">
                              Hub:{" "}
                              <span id="outlet">
                                {invoiceData && invoiceData.hubs.hub_name}
                              </span>
                            </div>
                            <div className="col-md-12">
                              Order Id:{" "}
                              <span
                                id="print_orderid"
                                style={{ fontSize: "14px" }}
                              >
                                {invoiceData &&
                                  invoiceData.order &&
                                  invoiceData.order.order_id}
                              </span>
                            </div>
                            <div>
                              Invoice Date/Time:{" "}
                              <span
                                id="print_time"
                                style={{ fontSize: "14px" }}
                              >
                                {format(new Date(), "yyyy-MM-dd")} |{" "}
                                {format(new Date(), "h:m:s")}
                              </span>
                            </div>
                            <div>
                              Customer Number:{" "}
                              {props.otpReducer.customer_type !== "guest" ? (
                                <span id="print_custPh">
                                  {invoiceData &&
                                    invoiceData.customer &&
                                    invoiceData.customer.phone}
                                </span>
                              ) : null}
                            </div>
                            <div>
                              Alternate Phone Number:{" "}
                              <span id="print_custaltPh">NA</span>
                            </div>
                            <div>
                              Customer Name:{" "}
                              <span id="print_custName">
                                {invoiceData &&
                                  invoiceData.customer &&
                                  invoiceData.customer.fname}{" "}
                                {invoiceData &&
                                  invoiceData.customer &&
                                  invoiceData.customer.lname}
                              </span>
                            </div>
                          </div>
                        )}

                        <div>
                          <table
                            style={{
                              borderCollapse: "collapse",
                              width: "100%",
                            }}
                          >
                            <thead
                              style={{
                                width: "100%",
                              }}
                            >
                              <tr>
                                <th
                                  align="left"
                                  style={{
                                    fontSize: "14px",
                                    borderTop: "1px solid #cccccc",
                                    paddingTop: "10px",
                                    paddingBottom: "10px",
                                    borderBottom: "1px solid #cccccc",
                                  }}
                                >
                                  ITEM NAME
                                </th>
                                <th
                                  align="left"
                                  style={{
                                    fontSize: "14px",
                                    borderTop: "1px solid #cccccc",
                                    paddingTop: "10px",
                                    paddingBottom: "10px",
                                    borderBottom: "1px solid #cccccc",
                                  }}
                                >
                                  QTY
                                </th>
                                <th
                                  align="left"
                                  style={{
                                    fontSize: "14px",
                                    borderTop: "1px solid #cccccc",
                                    paddingTop: "10px",
                                    paddingBottom: "10px",
                                    borderBottom: "1px solid #cccccc",
                                  }}
                                >
                                  WT/PCS
                                </th>
                                <th
                                  style={{
                                    fontSize: "14px",
                                    borderTop: "1px solid #cccccc",
                                    paddingTop: "10px",
                                    paddingBottom: "10px",
                                    borderBottom: "1px solid #cccccc",
                                  }}
                                  align="right"
                                >
                                  AMOUNT
                                </th>
                              </tr>
                            </thead>
                            <tbody id="print_items">
                              {invoiceData &&
                                invoiceData.orderDetails &&
                                invoiceData.orderDetails.map((item) => (
                                  <>
                                    <tr>
                                      <td
                                        style={{
                                          fontSize: "14px",
                                          paddingTop: "10px",
                                          paddingBottom: "10px",
                                          borderBottom: "1px solid #cccccc",
                                        }}
                                      >
                                        {item.pr_name}
                                      </td>
                                      <td
                                        style={{
                                          fontSize: "14px",
                                          paddingTop: "10px",
                                          paddingBottom: "10px",
                                          borderBottom: "1px solid #cccccc",
                                        }}
                                      >
                                        {item.quantity}
                                      </td>{" "}
                                      <td
                                        style={{
                                          fontSize: "14px",
                                          paddingTop: "10px",
                                          paddingBottom: "10px",
                                          borderBottom: "1px solid #cccccc",
                                        }}
                                      >
                                        {item.pr_weight}
                                      </td>
                                      <td
                                        style={{
                                          fontSize: "14px",
                                          paddingTop: "10px",
                                          paddingBottom: "10px",
                                          borderBottom: "1px solid #cccccc",
                                        }}
                                        align="right"
                                      >
                                        {Number(
                                          Number(item.price) -
                                            item.cgst -
                                            item.sgst
                                        ).toFixed(1)}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        HSN:{item.hsn_number}| CGST@
                                        {item.poscgst}% | SGST@{item.possgst}%
                                      </td>
                                    </tr>
                                  </>
                                ))}
                            </tbody>
                          </table>
                        </div>

                        <div>
                          <table
                            style={{
                              borderCollapse: "collapse",
                              width: "100%",
                            }}
                          >
                            <tbody>
                              <tr id="packaging_charges_div">
                                {/* <td style="borderTop: 1px solid white !important">{{env('CONTACTLESS_DEL_TITLE')}}</td> */}
                                <td
                                  style={{
                                    borderTop: "1px solid white !important",
                                  }}
                                >
                                  &nbsp;
                                </td>
                                <td
                                  style={{
                                    borderTop: "1px solid white !important",
                                  }}
                                >
                                  &nbsp;
                                </td>
                                <td
                                  style={{
                                    borderTop: "1px solid white !important",
                                  }}
                                  align="right"
                                  id="print_packaging_charges"
                                ></td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    fontSize: "14px",

                                    paddingTop: "10px",
                                    paddingBottom: "10px",
                                    borderBottom: "1px solid #cccccc",
                                  }}
                                >
                                  Discount (-)
                                </td>
                                <td
                                  style={{
                                    fontSize: "14px",
                                    paddingTop: "10px",
                                    paddingBottom: "10px",
                                    borderBottom: "1px solid #cccccc",
                                  }}
                                >
                                  &nbsp;
                                </td>{" "}
                                <td
                                  style={{
                                    fontSize: "14px",
                                    paddingTop: "10px",
                                    paddingBottom: "10px",
                                    borderBottom: "1px solid #cccccc",
                                  }}
                                >
                                  &nbsp;
                                </td>
                                <td
                                  style={{
                                    fontSize: "14px",
                                    paddingTop: "10px",
                                    paddingBottom: "10px",
                                    borderBottom: "1px solid #cccccc",
                                  }}
                                  align="right"
                                >
                                  {invoiceData &&
                                    invoiceData.order &&
                                    invoiceData.order.discount_with_key.value}
                                </td>
                              </tr>

                              {props.invoiceFor === "Delivery" &&
                              invoiceData &&
                              invoiceData.order &&
                              invoiceData.order.orders_meta &&
                              invoiceData.order.orders_meta
                                .contactless_delivery_enabled ? (
                                <tr>
                                  <td
                                    style={{
                                      fontSize: "14px",

                                      paddingTop: "10px",
                                      paddingBottom: "10px",
                                      borderBottom: "1px solid #cccccc",
                                    }}
                                  >
                                    Contactless Delivery
                                  </td>
                                  <td
                                    style={{
                                      fontSize: "14px",
                                      paddingTop: "10px",
                                      paddingBottom: "10px",
                                      borderBottom: "1px solid #cccccc",
                                    }}
                                  >
                                    &nbsp;
                                  </td>{" "}
                                  <td
                                    style={{
                                      fontSize: "14px",
                                      paddingTop: "10px",
                                      paddingBottom: "10px",
                                      borderBottom: "1px solid #cccccc",
                                    }}
                                  >
                                    &nbsp;
                                  </td>
                                  <td
                                    style={{
                                      fontSize: "14px",
                                      paddingTop: "10px",
                                      paddingBottom: "10px",
                                      borderBottom: "1px solid #cccccc",
                                    }}
                                    align="right"
                                  >
                                    {invoiceData &&
                                      invoiceData.order &&
                                      invoiceData.order.orders_meta &&
                                      invoiceData.order.orders_meta
                                        .contactless_packing_charges}
                                  </td>
                                </tr>
                              ) : null}

                              {props.invoiceFor === "Delivery" &&
                              invoiceData &&
                              invoiceData.tax &&
                              invoiceData.tax.delivery_charge ? (
                                <tr>
                                  <td
                                    style={{
                                      fontSize: "14px",

                                      paddingTop: "10px",
                                      paddingBottom: "10px",
                                      borderBottom: "1px solid #cccccc",
                                    }}
                                  >
                                    Delivery Charge
                                  </td>
                                  <td
                                    style={{
                                      fontSize: "14px",
                                      paddingTop: "10px",
                                      paddingBottom: "10px",
                                      borderBottom: "1px solid #cccccc",
                                    }}
                                  >
                                    &nbsp;
                                  </td>{" "}
                                  <td
                                    style={{
                                      fontSize: "14px",
                                      paddingTop: "10px",
                                      paddingBottom: "10px",
                                      borderBottom: "1px solid #cccccc",
                                    }}
                                  >
                                    &nbsp;
                                  </td>
                                  <td
                                    style={{
                                      fontSize: "14px",
                                      paddingTop: "10px",
                                      paddingBottom: "10px",
                                      borderBottom: "1px solid #cccccc",
                                    }}
                                    align="right"
                                  >
                                    {invoiceData &&
                                      invoiceData.tax &&
                                      invoiceData.tax.delivery_charge &&
                                      Number(invoiceData.tax.delivery_charge)}
                                  </td>
                                </tr>
                              ) : null}

                              <tr className="vat_div">
                                <td
                                  className="cgst-text"
                                  style={{
                                    fontSize: "14px",
                                    borderTop: "1px solid #cccccc",
                                    paddingTop: "10px",
                                    paddingBottom: "10px",
                                    borderBottom: "1px solid #cccccc",
                                  }}
                                >
                                  CGST
                                </td>
                                <td
                                  style={{
                                    borderTop: "1px solid white !important",
                                  }}
                                >
                                  &nbsp;
                                </td>
                                <td
                                  style={{
                                    borderTop: "1px solid white !important",
                                  }}
                                >
                                  &nbsp;
                                </td>
                                <td
                                  style={{
                                    fontSize: "14px",
                                    borderTop: "1px solid #cccccc",
                                    paddingTop: "10px",
                                    paddingBottom: "10px",
                                    borderBottom: "1px solid #cccccc",
                                  }}
                                  align="right"
                                >
                                  {invoiceData &&
                                    invoiceData.order &&
                                    invoiceData.order.cgst}
                                </td>
                              </tr>
                              <tr id="sgstRow">
                                <td
                                  className="sgst-text"
                                  style={{
                                    fontSize: "14px",
                                    borderTop: "1px solid #cccccc",
                                    paddingTop: "10px",
                                    paddingBottom: "10px",
                                    borderBottom: "1px solid #cccccc",
                                  }}
                                >
                                  SGST
                                </td>
                                <td
                                  style={{
                                    fontSize: "14px",
                                    borderTop: "1px solid #cccccc",
                                    paddingTop: "10px",
                                    paddingBottom: "10px",
                                    borderBottom: "1px solid #cccccc",
                                  }}
                                >
                                  &nbsp;
                                </td>
                                <td
                                  style={{
                                    fontSize: "14px",
                                    borderTop: "1px solid #cccccc",
                                    paddingTop: "10px",
                                    paddingBottom: "10px",
                                    borderBottom: "1px solid #cccccc",
                                  }}
                                >
                                  &nbsp;
                                </td>
                                <td
                                  style={{
                                    fontSize: "14px",
                                    borderTop: "1px solid #cccccc",
                                    paddingTop: "10px",
                                    paddingBottom: "10px",
                                    borderBottom: "1px solid #cccccc",
                                  }}
                                  align="right"
                                >
                                  {invoiceData &&
                                    invoiceData.order &&
                                    invoiceData.order.sgst}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    borderTop: "2px solid black",
                                    borderBottom: "2px solid black",
                                  }}
                                >
                                  Net Amount
                                </td>
                                <td
                                  style={{
                                    fontSize: "14px",
                                    borderTop: "2px solid black",
                                    borderBottom: "2px solid black",
                                    paddingTop: "10px",
                                    paddingBottom: "10px",
                                  }}
                                >
                                  &nbsp;
                                </td>
                                <td
                                  style={{
                                    fontSize: "14px",

                                    paddingTop: "10px",
                                    paddingBottom: "10px",
                                    borderTop: "2px solid black",
                                    borderBottom: "2px solid black",
                                  }}
                                >
                                  &nbsp;
                                </td>
                                <td
                                  style={{
                                    fontSize: "14px",

                                    paddingTop: "10px",
                                    paddingBottom: "10px",
                                    borderTop: "2px solid black",
                                    borderBottom: "2px solid black",
                                  }}
                                  align="right"
                                  id="print_subtotal"
                                >
                                  {invoiceData &&
                                    invoiceData.order &&
                                    invoiceData.order.total}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <div>
                          <table
                            style={{
                              borderCollapse: "collapse",
                              width: "100%",
                            }}
                          >
                            <tbody>
                              {props.otpReducer.customer_type !== "guest" ? (
                                <tr>
                                  <td
                                    style={{
                                      fontSize: "14px",
                                      paddingTop: "10px",
                                      paddingBottom: "10px",
                                      borderBottom: "1px solid #cccccc",
                                    }}
                                  >
                                    Licious Wallet
                                  </td>
                                  <td
                                    style={{
                                      borderTop: "1px solid white !important",
                                    }}
                                  >
                                    &nbsp;
                                  </td>
                                  <td
                                    style={{
                                      borderTop: "1px solid white !important",
                                    }}
                                  >
                                    &nbsp;
                                  </td>
                                  <td
                                    style={{
                                      fontSize: "14px",
                                      paddingTop: "10px",
                                      paddingBottom: "10px",
                                    }}
                                    align="right"
                                    id="print_subtotal"
                                  >
                                    {invoiceData &&
                                      invoiceData.order &&
                                      invoiceData.order.wallet_amount}
                                  </td>
                                </tr>
                              ) : null}

                              {/* <tr id="eg_div">
                              <td
                                style={{
                                  fontSize:'14px',
                                  borderTop: '1px solid #cccccc',
                                  paddingTop: '10px',
                                  paddingBottom: '10px',
                                  borderBottom: '1px solid #cccccc',
                                }}
                                id="eg_text"
                              >
                                Variable weight adjustment on currect order
                                (debited from your Licious Wallet)
                              </td>
                              <td
                                style={{
                                  fontSize:'14px',
                                  borderTop: '1px solid #cccccc',
                                  paddingTop: '10px',
                                  paddingBottom: '10px',
                                  borderBottom: '1px solid #cccccc',
                                }}
                              >
                                &nbsp;
                              </td>
                              <td
                                style={{
                                  fontSize:'14px',
                                  borderTop: '1px solid #cccccc',
                                  paddingTop: '10px',
                                  paddingBottom: '10px',
                                  borderBottom: '1px solid #cccccc',
                                }}
                                align="right"
                                id="print_eg"
                              ></td>
                            </tr> */}
                              <tr>
                                <td
                                  style={{
                                    fontSize: "14px",
                                    borderTop: "1px solid #cccccc",
                                    paddingTop: "10px",
                                    paddingBottom: "10px",
                                    borderBottom: "1px solid #cccccc",
                                  }}
                                >
                                  {props.invoiceFor !== "Takeaway"
                                    ? "Amount Paid in Advance"
                                    : "Amount Paid"}
                                </td>
                                <td
                                  style={{
                                    fontSize: "14px",
                                    borderTop: "1px solid #cccccc",
                                    paddingTop: "10px",
                                    paddingBottom: "10px",
                                    borderBottom: "1px solid #cccccc",
                                  }}
                                >
                                  &nbsp;
                                </td>
                                <td
                                  style={{
                                    fontSize: "14px",
                                    borderTop: "1px solid #cccccc",
                                    paddingTop: "10px",
                                    paddingBottom: "10px",
                                    borderBottom: "1px solid #cccccc",
                                  }}
                                >
                                  &nbsp;
                                </td>
                                <td
                                  style={{
                                    fontSize: "14px",
                                    borderTop: "1px solid #cccccc",
                                    paddingTop: "10px",
                                    paddingBottom: "10px",
                                    borderBottom: "1px solid #cccccc",
                                  }}
                                  align="right"
                                  id="print_amtToPaid"
                                >
                                  {invoiceData &&
                                    invoiceData.order &&
                                    invoiceData.order.subtotal_with_key.value}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <div
                          className="col-md-12 hidden"
                          style={{ margin: "5px 0" }}
                        >
                          Payment Type:{" "}
                          <span id="print_payType">
                            {invoiceData && invoiceData.order.payment_type}
                          </span>
                        </div>

                        <div
                          className="col-md-12 hidden"
                          style={{ margin: "5px 0" }}
                        >
                          <span id="print_payStat">Payment Status </span>
                          {invoiceData && invoiceData.order.payment_status}
                        </div>
                        <div className="col-md-12">
                          {/* <table
                            style={{
                              borderCollapse: 'collapse',
                              width: '100%',
                            }}
                          >
                            <tbody>
                              <tr id="tax_non_inter_state">
                                <th align="left">Tax Breakup</th>
                                <th
                                  style={{
                                    // borderTop: '1px solid white',
                                    display: 'table-cell',
                                    textAlign: 'center;!important',
                                  }}
                                >
                                  0 %
                                </th>
                                <th
                                  style={{
                                    // borderTop: '1px solid white',
                                    display: 'table-cell',
                                    textAlign: 'center;!important',
                                  }}
                                >
                                  6 %
                                </th>
                                <th
                                  // align="right"
                                  style={{
                                    // borderTop: '1px solid white',
                                    display: 'table-cell',
                                    textAlign: 'center;!important',
                                  }}
                                >
                                  6 %
                                </th>
                                <th
                                  id="l_tax_cgst"
                                  style={{
                                    // borderTop: '1px solid white',
                                    display: 'table-cell',
                                    textAlign: 'center;!important',
                                  }}
                                >
                                  9%
                                </th>
                                <th
                                  style={{
                                    // borderTop: '1px solid white',
                                    display: 'table-cell',
                                    textAlign: 'center;!important',
                                  }}
                                >
                                  9 %
                                </th>
                              </tr>

                              <tr className="vat_div">
                                <td
                                  className="cgst-text"
                                  style={{
                                    borderTop: '1px solid white',
                                    display: 'table-cell',
                                    textAlign: 'center;!important',
                                    paddingRight: '100px',
                                  }}
                                >
                                  CGST
                                </td>
                                <td
                                  style={{
                                    // borderTop: '1px solid white',
                                    display: 'table-cell',
                                    textAlign: 'center;!important',
                                  }}
                                >
                                  &#8377;0
                                </td>
                                <td
                                  style={{
                                    borderTop: '1px solid white',
                                    display: 'table-cell',
                                    textAlign: 'center;!important',
                                  }}
                                  id="print_pcgst"
                                >
                                  &#8377;0
                                </td>
                                <td
                                  // style="borderTop: 1px solid white;display: table-cell; textAlign: center;!important"
                                  id="print_dcgst"
                                >
                                  &#8377;0
                                </td>
                                <td
                                  style={{
                                    borderTop: '1px solid white',
                                    display: 'table-cell',
                                    textAlign: 'center;!important',
                                  }}
                                  id="l_print_pcgst"
                                >
                                  &#8377;0
                                </td>
                                <td
                                  style={{
                                    borderTop: '1px solid white',
                                    display: 'table-cell',
                                    textAlign: 'center;!important',
                                  }}
                                  id="l_print_dcgst"
                                  // align="right"
                                >
                                  &#8377;0
                                </td>
                              </tr>
                              <tr id="sgstRow2">
                                <td
                                  className="sgst-text"
                                  style={{
                                    borderTop: '1px solid white',
                                    display: 'table-cell',
                                    textAlign: 'center;!important',
                                  }}
                                >
                                  SGST
                                </td>
                                <td
                                  style={{
                                    borderTop: '1px solid white',
                                    display: 'table-cell',
                                    textAlign: 'center;!important',
                                  }}
                                >
                                  &#8377;0
                                </td>
                                <td
                                  style={{
                                    borderTop: '1px solid white',
                                    display: 'table-cell',
                                    textAlign: 'center;!important',
                                  }}
                                  id="print_psgst"
                                >
                                  &#8377;0
                                </td>
                                <td
                                  style={{
                                    borderTop: '1px solid white',
                                    display: 'table-cell',
                                    textAlign: 'center;!important',
                                  }}
                                  id="print_dsgst"
                                >
                                  &#8377;0
                                </td>
                                <td
                                  style={{
                                    borderTop: '1px solid white',
                                    display: 'table-cell',
                                    textAlign: 'center !important',
                                  }}
                                  id="l_print_psgst"
                                >
                                  &#8377;0
                                </td>
                                <td
                                  style={{
                                    borderTop: '1px solid white',
                                    display: 'table-cell',
                                    textAlign: 'center !important',
                                  }}
                                  id="l_print_psgst"
                                >
                                  &#8377;0
                                </td>
                              </tr>
                            </tbody>
                          </table> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="receipt-action-button-holder">
              <ConfirmButton
                className="receipt-action-button"
                onClick={() => props.setShow(false)}
              >
                Cancel
              </ConfirmButton>
              <ConfirmButton
                className="receipt-action-button"
                onClick={() => handleHTMLExtract()}
              >
                Print
              </ConfirmButton>
            </div>
          </div>
        </>
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  return state;
};
export default connect(mapStateToProps, null)(Invoice);

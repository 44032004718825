import React, { Component } from "react";
import { Router } from "react-router-dom";
import { Provider } from "react-redux";
import ReduxComponentConnect from "./ReduxComponent";

import history from "./utils/history";
import ConfigureStore from "./middleware/ConfigStore";
import "./App.scss";
import ClevertapReact from "clevertap-react";
import CacheBuster from "react-cache-buster";
import { version } from "../package.json";
import SmileyLoader from "../src/components/Loader/SmileyLoader";

const { REACT_APP_CLEVERTAP_TRACKING_ID } = process.env;
ClevertapReact.initialize(REACT_APP_CLEVERTAP_TRACKING_ID);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false,
      showLeftPanel: false,
    };
  }

  componentDidMount() {
    // this.initializeReactGA()
    if (window.analytics) {
      window.analytics.load(process.env.REACT_APP_SEGMENT_KEY);
    }
    if (!!typeof localStorage) {
      const accessToken = localStorage.getItem("accessToken");
      if (accessToken) {
        this.setState({ loggedIn: true });
      }
    }
  }

  // toggleLeftPanel = () => {
  //   this.setState({
  //     showLeftPanel: !this.state.showLeftPanel,
  //   })
  // }

  render() {
    return (
      <CacheBuster
        currentVersion={version}
        isEnabled={true} //If false, the library is disabled.
        isVerboseMode={false} //If true, the library writes verbose logs to console.
        loadingComponent={<SmileyLoader />} //If not pass, nothing appears at the time of new version check.
      >
        <Provider store={ConfigureStore()}>
          <Router history={history}>
            <div className="merchant-app-shell">
              <ReduxComponentConnect toggleLeftPanel={this.toggleLeftPanel} />
            </div>
          </Router>
        </Provider>
      </CacheBuster>
    );
  }
}

export default App;

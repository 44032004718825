import { put, takeEvery, call, fork } from "redux-saga/effects";
import {
  GET_AUTOCOMPLETE_LOCALITY_SUCCESS,
  GET_AUTOCOMPLETE_LOCALITY,
  GET_GEOLOCATION_REQUEST,
  GET_LOCATION_HUB_SUCCESS,
  HUB_LOCALITY_REQUEST,
  LOCALITY_MODAL_SHOWN,
} from "../actions/DeliveryOrderActions";
import { DATA_LOADING } from "../actions/CommonActions";
import ToastMessage from "../utils/ToastMessage";
import {
  API_GetAutocompleteLocality,
  API_GetGeoLocation,
  API_GetLocationHub,
} from "../apiServices/DeliveryOrderAPI";

function* getAutocompleteLocation(action) {
  let errMsg = "Failed to Fetch Catalog Products";
  try {
    let response = yield call(
      API_GetAutocompleteLocality,
      action.payload.params
    );
    if (response.type === "success") {
      yield put({ type: DATA_LOADING, payload: false });
      yield put({
        type: GET_AUTOCOMPLETE_LOCALITY_SUCCESS,
        payload: response.data,
      });
    } else {
      if (!!response.message || response.statusCode === 504) {
        yield put({ type: DATA_LOADING, payload: false });
        errMsg = response.message ? response.message : response.statusMessage;
      }
      ToastMessage(errMsg);
    }
  } catch (error) {
    if (!!error.message) {
      yield put({ type: DATA_LOADING, payload: false });
      errMsg = error.message;
    }
    ToastMessage(errMsg);
  }
}

function* getGeoLocation(action) {
  let errMsg = "failed to fetch Hub";
  try {
    let response = yield call(API_GetGeoLocation, action.payload.params);
    if (response.type === "success") {
      yield put({ type: DATA_LOADING, payload: false });
      let params = {
        lat: response.data[0].lat,
        lng: response.data[0].lng,
      };

      try {
        yield put({ type: DATA_LOADING, payload: true });
        let hubApiResponse = yield call(API_GetLocationHub, params);

        if (hubApiResponse.status === "success") {
          yield put({
            type: GET_LOCATION_HUB_SUCCESS,
            payload: hubApiResponse.hub,
          });
        } else {
          if (!!hubApiResponse.message) {
            yield put({ type: LOCALITY_MODAL_SHOWN, payload: true });
            yield put({ type: DATA_LOADING, payload: false });
            errMsg = hubApiResponse.message;
          }
          ToastMessage(errMsg);
        }
      } catch (error) {
        if (!!error.message) {
          yield put({ type: DATA_LOADING, payload: false });
          errMsg = error.message;
        }
        ToastMessage(errMsg);
      }
    } else {
      if (!!response.message || response.statusCode === 504) {
        yield put({ type: DATA_LOADING, payload: false });
        errMsg = response.message ? response.message : response.statusMessage;
      }
      ToastMessage(errMsg);
    }
  } catch (error) {
    if (!!error.message) {
      yield put({ type: DATA_LOADING, payload: false });
      errMsg = error.message;
    }
    ToastMessage(errMsg);
  }
}

function* setLocalityHub(action) {
  try {
    yield put({
      type: GET_LOCATION_HUB_SUCCESS,
      payload: action.payload.params.hub_id,
    });
  } catch (e) {}
}
export default function* DeliveryOrderSagas() {
  yield [fork(takeEvery, GET_AUTOCOMPLETE_LOCALITY, getAutocompleteLocation)];
  yield [fork(takeEvery, GET_GEOLOCATION_REQUEST, getGeoLocation)];
  yield [fork(takeEvery, HUB_LOCALITY_REQUEST, setLocalityHub)];
}

import React, { useState, useEffect } from "react";
import "./LocalityModal.scss";
import Search from "../../assets/searchIcon.png";
import GenericModal from "../GenricModal/GenricModal";
import ConfirmButton from "../Buttons/ConfirmButton/ConfirmButton";
import classnames from "classnames";
import { ReactComponent as IconSkip } from "../../assets/new-ebo-icons/Icon-Skip.svg";
import { connect } from "react-redux";
import {
  localityModal,
  setSearchedText,
  SetSelectedLocality,
} from "../../actions/DeliveryOrderActions";
import { setProgressStep } from "../../actions/CommonActions";
import { checkout, triggerEvent } from "../../utils/segment";

function LocalityModal(props) {
  const [showLocalityDropdown, setShowLocalityDropdown] = useState(false);
  const { TotalCartItems } = props.takeAwayReducer;
  useEffect(() => {
    if (props.DeliveryOrderReducer.localityModal) {
      props.saveSearchedText("");
    }
  }, [props.DeliveryOrderReducer.localityModal]);
  const showSkipToPayment =
    TotalCartItems.cartItems && TotalCartItems.cartItems.length === 0
      ? false
      : true;
  const handleSearch = () => {
    setShowLocalityDropdown(true);
    props.handleChange();
    // setSearchVal(inputText.value);
  };

  const handleSelect = (item) => {
    props.saveSelectedLocality(item);
    setShowLocalityDropdown(false);
    props.saveSearchedText(item.description);
  };

  const handleSearchClear = () => {
    props.saveSearchedText("");
    setShowLocalityDropdown(false);
  };
  return (
    <GenericModal
      show={props.show}
      className="locality-search-modal"
      cancel={props.handleLocalityModalClose}
    >
      <div className="select-locality-text">Select a locality</div>
      <div className="locality_modal_container">
        <input
          type="text"
          placeholder="Search Locality"
          className="input-type"
          onChange={(event) => {
            const val = event.target.value;
            props.saveSearchedText(val);
            handleSearch();
          }}
          value={props.DeliveryOrderReducer.searchedText}
        />
        {props.DeliveryOrderReducer.searchedText !== "" ? (
          <button
            className="clear-button"
            onClick={(e) => handleSearchClear(e)}
          >
            CLEAR
          </button>
        ) : (
          <div className="Search-icon">
            <img className="Icon-Image" src={Search} width="20" alt="" />
          </div>
        )}
        {showLocalityDropdown && props.AutocompleteSuggestions.length && (
          <div className="dropdown-container">
            <div className="dropdown-listcontainer">
              {props.AutocompleteSuggestions.length &&
                props.AutocompleteSuggestions.map((item) => {
                  return (
                    <div
                      className="list-item"
                      onClick={(e) => handleSelect(item)}
                    >
                      {item.description}
                    </div>
                  );
                })}
            </div>
          </div>
        )}
      </div>
      <ConfirmButton
        className={classnames("continue-button", {
          active: props.DeliveryOrderReducer.searchedText,
        })}
        onClick={() => {
          if (props.DeliveryOrderReducer.searchedText) {
            props.confirmLocation();
          }
        }}
      >
        Continue
      </ConfirmButton>
      {showSkipToPayment && (
        <>
          <div className="circle-separator">
            <div className="circle-separator-text">OR</div>
          </div>
          <div>
            <ConfirmButton
              className="skip-to-payment-button"
              onClick={() => {
                triggerEvent({
                  action_type: "checkout",
                  is_guest:
                    props.otpReducer.customer_type === "guest" ? true : false,
                  order_id: "",
                  is_existing_customer:
                    props.otpReducer.customer_type !== "guest" &&
                    !props.otpReducer.new_customer
                      ? true
                      : false,
                  attributes: {},
                  item_count_takeaway:
                    props.takeAwayReducer &&
                    props.takeAwayReducer.cartData &&
                    props.takeAwayReducer.cartData.cartItems &&
                    props.takeAwayReducer.cartData.cartItems.length
                      ? props.takeAwayReducer.cartData.cartItems.length
                      : "",
                  item_count_delivery:
                    props.DeliveryOrderReducer &&
                    props.DeliveryOrderReducer.deliveryCart &&
                    props.DeliveryOrderReducer.deliveryCart.products &&
                    props.DeliveryOrderReducer.deliveryCart.products.length
                      ? props.DeliveryOrderReducer.deliveryCart.products.length
                      : "",
                  customer_key: props.otpReducer.customerDetails.customer_key,
                  id: props.otpReducer.uid,
                });
                props.orderJourneyStepChange(4);
                props.localityModalChange(false);
              }}
            >
              <div>Skip to Payment</div> <IconSkip height={12} />
            </ConfirmButton>
          </div>
        </>
      )}
    </GenericModal>
  );
}

const mapStateToProps = (state) => {
  return state;
};
const mapDispatchToProps = (dispatch) => {
  return {
    saveSearchedText: (payload) => dispatch(setSearchedText(payload)),
    localityModalChange: (value) => {
      dispatch(localityModal(value));
    },
    saveSelectedLocality: (payload) => dispatch(SetSelectedLocality(payload)),
    orderJourneyStepChange: (step) => {
      dispatch(setProgressStep(step));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LocalityModal);

import {
  SEARCH_USER_SUCCESS,
  SEARCH_USER_FAILURE,
  SAVER_USER_SUCCESS,
  SAVER_USER_FAILED,
  UPDATE_ADDR_SUCCESS,
  UPDATE_ADDR_FAILED,
  SAVE_USER_PROFILE,
} from "../actions/AddUserActions";

import {CLEAR_REGISTER_USER_DATA,CLEAR_USER_PROFILE} from "../actions/CommonActions"

const AddUserReducer = (state = {}, action) => {
  switch (action.type) {
    case CLEAR_USER_PROFILE:
      return Object.assign({}, state, {
        searchUserSuccess: null,
        customerAddress: null,
      });
    case SEARCH_USER_SUCCESS:
    case SAVE_USER_PROFILE:
      return Object.assign({}, state, { searchUserSuccess: action.data });
    case SEARCH_USER_FAILURE:
      return Object.assign({}, state, { searchUserFailed: action.data });
    case SAVER_USER_SUCCESS:
      return Object.assign({}, state, { saveUserSuccess: action.data });
    case SAVER_USER_FAILED:
      return Object.assign({}, state, { saveUserFailed: action.data });
    case UPDATE_ADDR_SUCCESS:
      return Object.assign({}, state, { updateUserAddrSuccess: action.data });
    case UPDATE_ADDR_FAILED:
      return Object.assign({}, state, { updateUserAddrFailed: action.data });
    // case SAVE_USER_PROFILE :
    // 	return Object.assign({}, state, {saveUserProfile: action.data});
   
    case CLEAR_REGISTER_USER_DATA: {
      return {};
    }
    default:
      return state;
  }
};

export default AddUserReducer;

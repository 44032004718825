import React from "react";
import { connect } from "react-redux";
import GenericModal from "../GenricModal/GenricModal";
import "./PaymentModal.scss";
import PaymentFailed from "../../assets/payment-failed.gif";
import ConfirmButton from "../Buttons/ConfirmButton/ConfirmButton";
import { triggerEvent } from "../../utils/segment";

const PaymentModal = (props) => {
  triggerEvent({
    action_type: "payment_failed",
    is_guest: props.otpReducer.customer_type === "guest" ? true : false,
    order_id: props.paymentDetails.orderData.order_id,
    is_existing_customer:
      props.otpReducer.customer_type !== "guest" &&
      !props.otpReducer.new_customer
        ? true
        : false,
    attributes: {},
    item_count_takeaway:
      props.takeAwayReducer &&
      props.takeAwayReducer.cartData &&
      props.takeAwayReducer.cartData.cartItems &&
      props.takeAwayReducer.cartData.cartItems.length
        ? props.takeAwayReducer.cartData.cartItems.length
        : "",
    item_count_delivery:
      props.DeliveryOrderReducer &&
      props.DeliveryOrderReducer.deliveryCart &&
      props.DeliveryOrderReducer.deliveryCart.products &&
      props.DeliveryOrderReducer.deliveryCart.products.length
        ? props.DeliveryOrderReducer.deliveryCart.products.length
        : "",
    customer_key: props.otpReducer.customerDetails.customer_key,
    id: props.otpReducer.uid,
  });

  return (
    <GenericModal
      show={true}
      cancel={() => {}}
      className="payment-confirmation-modal"
    >
      <div>
        <div>Payment Status</div>
        <div className="loader-container">
          <img src={PaymentFailed} className="failure-image" />
        </div>
        <div className="ongoing-payment-message">
          Payment has failed! Please try again.
        </div>
        <ConfirmButton onClick={() => props.setRetryPayment(true)}>
          Continue To Retry Payment
        </ConfirmButton>
      </div>
    </GenericModal>
  );
};

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps, null)(PaymentModal);

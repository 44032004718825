import ClevertapReact from "clevertap-react";

export const UserLoginIn = (payload) =>
  ClevertapReact.event("User Logged In", payload);

export const CustomerProfile = (payload) => {
  const prof = {
    Site: {
      Name: payload.name,
      Identity: payload.customer_key,
      email: payload.email,
    },
  };
  ClevertapReact.profile(prof);
  ClevertapReact.profile(payload);
};

export const UserVerified = (payload) => {
  const prof = {
    Site: {
      Name: payload.name,
      Identity: payload.customer_key,
      email: payload.email,
    },
  };
  ClevertapReact.profile(prof);
  ClevertapReact.event("StorePOS_CustomerAccount_Verified", {
    store_city_name: payload.city,
    city_id: payload.city_id,
    store_id: payload.store_id,
    user_type: payload.user_type,
    identity: payload.customer_key,
    phone: payload.phone,
    email: payload.email,
    name: payload.name,
    status: payload.status,
    status_reason: payload.status_reason ? payload.status_reason : "NA",
  });
};

export const CustomerDetailConFirmed = (payload) => {
  const prof = {
    Site: {
      Name: payload.name,
      Identity: payload.customer_key,
      email: payload.email,
    },
  };
  ClevertapReact.profile(prof);
  ClevertapReact.event("StorePOS_CustomerDetail_Confirmed", {
    store_city_name: payload.city,
    city_id: payload.city_id,
    store_id: payload.store_id,
    user_type: payload.user_type,
    meatopia_status: payload.meatopia_status,
    identity: payload.customer_key,
    phone: payload.phone,
    email: payload.email,
    name: payload.name,
    status: payload.status,
    status_reason: payload.status_reason,
  });
};

export const CustomerSignUpSuccess = (payload) => {
  const prof = {
    Site: {
      Name: payload.name,
      Identity: payload.customer_key,
      email: payload.email,
    },
  };
  ClevertapReact.profile(prof);
  ClevertapReact.profile(prof);
  ClevertapReact.event("StorePOS_Customer_Signup", {
    store_city_name: payload.city,
    city_id: payload.city_id,
    store_id: payload.store_id,
    user_type: payload.store_id,
    meatopia_status: payload.meatopia_status,
    identity: payload.customer_key,
    phone: payload.phone,
    email: payload.email,
    name: payload.name,
    status: payload.status,
  });
};

export const DeliveryHubSet = (payload) => {
  ClevertapReact.event("StorePOS_Location_MyHubSet", {
    user_type: payload.user_type,
    store_city_name: payload.city,
    city_id: payload.city_id,
    store_id: payload.store_id,
    meatopia_status: payload.meatopia_status,
    delivery_city_name: payload.city_name,
    hub_id: payload.hub_id,
    locality_name: payload.locality_name,
    status: payload.status,
    is_serviceable: payload.is_serviceable,
    flow: "set",
    status_reason: payload.status_reason,
  });
};

export const CustomerOnPaymentPage = (payload) => {
  ClevertapReact.event("StorePOS_ProceedtoPay", {
    //Called on clicking Pay Now or Retry Payment button
    master_paymode: payload.master_paymode,
    user_type: payload.user_type,
    meatopia_status: payload.meatopia_status,
    store_city_name: payload.store_city_name,
    delivery_city_name: payload.delivery_city_name,
    store_id: payload.store_id,
    hub_id: payload.hub_id,
    takeaway_item_name: payload.takeaway_item_names,
    delivery_item_name: payload.delivery_item_names,
    coupon_name: payload.coupon_name,
    bill_payableamount: payload.bill_payableamount,
    order_type: payload.order_type,
  });
};

export const OrderCreated = (payload) => {
  ClevertapReact.event("StorePOS_Charged", {
    //Called upon completion of payment and order flow both
    user_type: payload.user_type,
    meatopia_status: payload.meatopia_status,
    store_city_name: payload.store_city_name,
    delivery_city_name: payload.delivery_city_name,
    store_id: payload.store_id,
    hub_id: payload.hub_id,
    order_id: payload.order_id,
    takeaway_item_name: payload.takeaway_item_names,
    delivery_item_name: payload.delivery_item_names,
    delivery_item_count: payload.delivery_item_count,
    takeaway_item_count: payload.takeaway_item_count,
    bill_paidamount: payload.bill_paidamount,
    bill_discountamount: payload.bill_discountamount,
    bill_walletamount: payload.bill_walletamount,
    bill_deliveryamount: payload.bill_deliveryamount,
    coupon_name: payload.coupon_name,
    address_id: payload.address_id,
    order_type: payload.order_type,
  });
};

import React from 'react'
import './Switch.scss'

const Switch = (props) => {
  return (
    <label class="switch">
      <input
        type="checkbox"
        checked={props.active}
        onChange={() => props.callback()}
      />
      <span class="slider round" />
    </label>
  )
}

export default Switch
